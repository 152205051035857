import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { removeSignNowFile } from 'store/actions/signNow';
import { RootStateType } from 'store/reducers';
import { UseDocumentThumbnailHookType } from 'types/signNow/base';
import { IPDFFileInfo } from 'types/signNow/store';

const useDocumentThumbnail: UseDocumentThumbnailHookType = () => {
  const dispatch = useDispatch();
  const files: IPDFFileInfo[] | null = useSelector((state: RootStateType) => state.signNow.files);
  const [isRemoveFileModalOpen, setIsRemoveFileModalOpen] = useState<boolean>(false);

  const onCancelRemoveFileHandler = (): void => {
    setIsRemoveFileModalOpen(false);
  };

  const onConfirmRemoveFileHandler = (fileKey: string): void => {
    dispatch(removeSignNowFile(fileKey));
    setIsRemoveFileModalOpen(false);
  };

  const onRemoveFileHandler = (): void => {
    setIsRemoveFileModalOpen(true);
  };

  return {
    files,
    isRemoveFileModalOpen,
    onCancelRemoveFileHandler,
    onConfirmRemoveFileHandler,
    onRemoveFileHandler,
  };
};

export default useDocumentThumbnail;
import { FC, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { ReactEditor, useSlate } from 'slate-react';

import PublicFieldView from 'components/Editor/FormView/Fields/PublicFieldView';
import TitleAndDescription from 'components/Editor/FormView/TitleAndDescription';
import SortableListContainer from 'components/SortableListContainer';
import useFieldsHandler from 'hooks/useFieldsHandler';
import { useRolePermission } from 'hooks/useRolePermission';
import { useSortedSections } from 'hooks/useSortedSections';
import { setPublicFormValidation } from 'store/actions/editorSlate';
import { RootStateType } from 'store/reducers';
import { ICustomElement, INewSection } from 'types/Editor';
import { IPublicPageDocumentStructure } from 'types/PublicPage';
import { ITemplateSection } from 'types/redux';
import { IValidated } from 'types/validation';
import { getFieldIDsToSelect, isFieldHasSubtype } from 'utils/editorFieldHelpers';
import {
  getIsCollectionFormBuilder,
  getIsDocumentWithFormBuilder,
  getIsFormBuilder,
  getIsFormDocument,
} from 'utils/PublicPage/documentTypeChecker';

type IProps = {
  section: INewSection;
  isPublicPage?: boolean;
  readOnlyMode?: boolean;
  isFormsView?: boolean;
  isFormTypeDocument?: boolean;
}

/**
 * Form View for:
 * - Public pages after redesign (NOT pdf)
 */
const Forms: FC<IProps> = ({
  section,
  isPublicPage = false,
  readOnlyMode = false,
  isFormsView = true,
  isFormTypeDocument = false,
}) => {
  const { permissionUsingSections, permissionUsingSectionsLight } = useRolePermission();
  const dispatch = useDispatch();
  const editor = useSlate();
  const {
    data,
    currentDocument,
    structure: {
      main,
    },
  } = useSelector((state: RootStateType) => state.publicPages);
  const editorSections = useSortedSections({
    isPublicPage,
    isFormsView,
    isFormPage: isFormTypeDocument,
    currentDocAssignment: main.mainAssignment,
  });
  const { updateFieldsHandler } = useFieldsHandler({ editor });

  const isCollectionFormBuilder: boolean = getIsCollectionFormBuilder(data);
  const isFormBuilder: boolean = getIsFormBuilder(data.view_mode);
  const isFormDocument: boolean = getIsFormDocument(currentDocument.documentType);
  const isDocumentWithFormBuilderView: boolean = getIsDocumentWithFormBuilder(data.view_mode);

  const sectionsInfo: ITemplateSection[] = useSelector((state: RootStateType) => state.template.sections);
  const { publicFormValidationResults } = useSelector((state: RootStateType) => state.editorSlate);
  const {
    slides,
  }: IPublicPageDocumentStructure = useSelector((state: RootStateType) => state.publicPages.currentDocument);

  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [formValidationResults, setFormValidationResults] = useState<IValidated>({
    validationFields: {},
    isError: false,
  });

  useEffect(() => {
    const currentSection = sectionsInfo.find((el) => el.key === section.key);
    if (currentSection) {
      setName(currentSection.name?.trim());
      setDescription(currentSection.description);
    }
    if (isFormBuilder || isDocumentWithFormBuilderView) {
      const currentFormBuilderSection = slides.find((el) => el.key === section.key);
      if (currentFormBuilderSection) {
        setName(currentFormBuilderSection.name?.trim());
        setDescription(currentFormBuilderSection.description);
      }
    }
  }, [sectionsInfo, section.key]);

  useEffect(() => (
    () => {
      dispatch(setPublicFormValidation({
        validationFields: {},
        isError: false,
      }));
    }
  ), []);

  useEffect(() => {
    if (isPublicPage) {
      setFormValidationResults(publicFormValidationResults);
    }
  }, [isPublicPage, publicFormValidationResults]);

  if (!editorSections || !section) return null;

  // TODO: Add debounce for updating fields
  const updateAllNodes = (field: Partial<ICustomElement>, value: Partial<ICustomElement> | string) => {
    updateFieldsHandler(field, typeof value === 'string' ? { value } : value);
  };

  const shouldCancelStart = (event: any) => {
    const textField = ['textarea', 'text', 'date'].includes(event.target.type);
    return (isPublicPage || readOnlyMode) ? true : textField;
  };

  const getFieldMessage = (fieldKey?: number): string => (
    (fieldKey && formValidationResults.validationFields[fieldKey]) || ''
  );

  if (!isPublicPage) {
    return (
      <div>This component should be used only for Public pages</div>
    );
  }

  const sortFieldFuncByPath = (fieldOne: Partial<ICustomElement>, fieldTwo: Partial<ICustomElement>) => {
    if (isCollectionFormBuilder || isFormBuilder || isFormDocument || isDocumentWithFormBuilderView) return 0;
    const positionIndex = (permissionUsingSectionsLight && !permissionUsingSections) ? 1 : 0;
    const pathOne = ReactEditor.findPath(editor, fieldOne as ICustomElement);
    const pathTwo = ReactEditor.findPath(editor, fieldTwo as ICustomElement);
    return pathOne[positionIndex] - pathTwo[positionIndex];
  };

  return (
    <SortableListContainer
      lockAxis="y"
      helperClass="section-form-view__navigation-item-active shadow-sm"
      shouldCancelStart={shouldCancelStart}
      distance={1}
    >
      <div className="d-flex flex-column align-self-center max-w-450">
        <TitleAndDescription
          description={description}
          isReadOnlyMode
          isPublicPage
          title={name}
        />
        {
          section.fields.sort(sortFieldFuncByPath).map((field: Partial<ICustomElement>) => {
            const idsToSelect = isPublicPage ? [] : getFieldIDsToSelect(
              field as ICustomElement,
              field.key || null,
              section.fields as ICustomElement[],
              editor,
              isFieldHasSubtype(field.subtype),
            );
            return (
              <PublicFieldView
                field={field}
                key={`PublicFieldView_${field.key}`}
                options={field.options}
                onChangeValue={updateAllNodes}
                validateField
                validationMessage={getFieldMessage(field.key)}
                readOnlyMode={readOnlyMode}
                isFormBuilder={isFormBuilder || isDocumentWithFormBuilderView}
                idsToSelect={idsToSelect}
              />
            );
          })
        }
      </div>
    </SortableListContainer>
  );
};

export default Forms;
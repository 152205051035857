import { GROUP_MANAGEMENT_USER_ACTIONS } from 'constants/general';
import {
  CHANGE_REFERRAL_CODE,
  CHANGE_USER_PASSWORD,
  CREATE_GROUP,
  CREATE_NEW_USER,
  DELETE_GROUP,
  DELETE_GROUPS_BATCH,
  DELETE_USER,
  GET_GROUP,
  GET_GROUPS,
  GET_REFERRAL_CODE,
  GET_USER_COMPANY_DATA,
  GET_USER_LIST_DATA,
  GET_USER_PROFILE_DATA,
  RESEND_INVITE_TO_USER,
  SET_FIRST_LOGIN_PARAMETER,
  SET_GROUP,
  SET_GROUPS,
  UPDATE_GROUP,
  UPDATE_GROUP_MEMBER,
  UPDATE_USER_COMPANY_DATA,
  UPDATE_USER_INFO,
  UPDATE_USER_PROFILE_DATA,
} from 'store/actions/actionTypes';
import { ActionPayload } from 'types/redux';
import {
  GroupType,
  ModalUserType,
  USER_MANAGEMENT_MODAL_STATUS,
  UserListGetterType,
} from 'types/userProfile';

export const getUserProfileData = () => ({
  type: GET_USER_PROFILE_DATA,
});

export const updateUserProfileData = (userProfile: any) => ({
  type: UPDATE_USER_PROFILE_DATA,
  payload: userProfile,
});

export const changeUserPassword = (userProfile: any) => ({
  type: CHANGE_USER_PASSWORD,
  payload: userProfile,
});

export const getUserCompanyData = () => ({
  type: GET_USER_COMPANY_DATA,
});

export const updateUserCompanyData = (companyData: any) => ({
  type: UPDATE_USER_COMPANY_DATA,
  payload: companyData,
});

export const getUserListData = () => ({
  type: GET_USER_LIST_DATA,
});

export const createNewUser = (newUser: ModalUserType) => ({
  type: CREATE_NEW_USER,
  payload: newUser,
});

export const updateUserInfo = (fieldsToUpdate: UserListGetterType, status: USER_MANAGEMENT_MODAL_STATUS) => ({
  type: UPDATE_USER_INFO,
  payload: {
    fieldsToUpdate,
    status,
  },
});

export const resendInviteToUser = (userId: number) => ({
  type: RESEND_INVITE_TO_USER,
  payload: userId,
});

export const deleteUserAction = (userId?: number) => ({
  type: DELETE_USER,
  payload: userId,
});

export const getGroups = (): { type: typeof GET_GROUPS } => ({
  type: GET_GROUPS,
});

export const setGroups = (
  groups: GroupType[],
): ActionPayload<{ groups: GroupType[] }> => ({
  type: SET_GROUPS,
  payload: {
    groups,
  },
});

export const deleteGroup = (
  groupId: string,
): ActionPayload<{ groupId: string }> => ({
  type: DELETE_GROUP,
  payload: {
    groupId,
  },
});

export const createGroup = (
  newGroup: { name: string },
): ActionPayload<{ newGroup: { name: string } }> => ({
  type: CREATE_GROUP,
  payload: {
    newGroup,
  },
});

export const updateGroup = (
  fieldsToUpdate: { name: string },
  groupId: string,
): ActionPayload<{ fieldsToUpdate: { name: string }, groupId: string }> => ({
  type: UPDATE_GROUP,
  payload: {
    fieldsToUpdate,
    groupId,
  },
});

export const deleteGroupsBatch = (
  groupIdsToDelete: string[],
): ActionPayload<{ groupIdsToDelete: string[] }> => ({
  type: DELETE_GROUPS_BATCH,
  payload: {
    groupIdsToDelete,
  },
});

export const getGroup = (
  groupId: string,
): ActionPayload<{ groupId: string }> => ({
  type: GET_GROUP,
  payload: {
    groupId,
  },
});

export const setGroup = (group: GroupType | null): ActionPayload<{ group: GroupType | null }> => ({
  type: SET_GROUP,
  payload: {
    group,
  },
});

export const updateGroupMember = (
  userId: string,
  groupId: string,
  action: GROUP_MANAGEMENT_USER_ACTIONS,
): ActionPayload<{ userId: string; groupId: string; action: GROUP_MANAGEMENT_USER_ACTIONS; }> => ({
  type: UPDATE_GROUP_MEMBER,
  payload: {
    userId,
    groupId,
    action,
  },
});

export const generateReferralCode = () => ({
  type: GET_REFERRAL_CODE,
});

export const changeReferralCode = (referralCode: string) => ({
  type: CHANGE_REFERRAL_CODE,
  payload: referralCode,
});

export const setFirstLoginParameter = (firstLogin: boolean) => ({
  type: SET_FIRST_LOGIN_PARAMETER,
  payload: firstLogin,
});
import { ChangeEvent, useEffect, useState } from 'react';

import { ContentEditableEvent } from 'react-contenteditable';

import { TEXT_FIELD_MASKS } from 'constants/fieldPropertiesTab';
import { PDFFieldType } from 'types/PdfTemplates';

interface IUseFieldMaskProps {
  hideValue?: boolean;
  realValue: string;
  // There might be three different handlers, which take an event as argument.
  // But we pass just simple object in order entrap real handlers. It's easier to leave type 'any'.
  onChangeRealValue: any;
  fieldMaskType?: TEXT_FIELD_MASKS;
  pdfField?: PDFFieldType;
}

const getCountCharactersToShow = (maskType: TEXT_FIELD_MASKS) => {
  switch (maskType) {
    case TEXT_FIELD_MASKS.LAST_FOUR:
      return 4;
    case TEXT_FIELD_MASKS.LAST_SIX:
      return 6;
    case TEXT_FIELD_MASKS.CUSTOM_TEXT:
    case TEXT_FIELD_MASKS.HIDDEN:
    case TEXT_FIELD_MASKS.NONE:
    default:
      return 0;
  }
};

export const transformValueToHiddenValue = (originValue: string, countCharactersToShow: number = 0) => {
  const charactersCount = originValue.length > countCharactersToShow ? countCharactersToShow : 0;
  const lastCharacters = charactersCount !== 0 ? originValue.slice(-charactersCount) : '';
  const transformedValue = lastCharacters.padStart(originValue.length, '•');
  return transformedValue;
};

const useFieldMaskValue = ({
  hideValue,
  realValue,
  onChangeRealValue,
  fieldMaskType = TEXT_FIELD_MASKS.NONE,
  pdfField = undefined,
}: IUseFieldMaskProps) => {
  const [countCharactersToShow, setCountCharactersToShow] = useState<0 | 4 | 6>(
    getCountCharactersToShow(fieldMaskType),
  );
  const [realLocalValue, setRealLocalValue] = useState<string>(realValue);
  const [realValueLength, setRealValueLength] = useState<number>(realLocalValue.length);
  const [hiddenValue, setHiddenValue] = useState<string>(
    transformValueToHiddenValue(realLocalValue, countCharactersToShow),
  );

  useEffect(() => {
    const temp = getCountCharactersToShow(fieldMaskType);
    setCountCharactersToShow(temp);
    setHiddenValue(transformValueToHiddenValue(realLocalValue, temp));
  }, [fieldMaskType, realLocalValue]);

  useEffect(() => {
    setRealLocalValue(realValue);
    setHiddenValue(transformValueToHiddenValue(realValue, countCharactersToShow));
  }, [realValue]);

  useEffect(() => {
    setRealValueLength(realLocalValue.length);
    if (!hideValue) {
      setHiddenValue(transformValueToHiddenValue(realLocalValue, countCharactersToShow));
    }
  }, [hideValue, realLocalValue]);

  const onChangeValueWithMask = ({ target }: ContentEditableEvent | ChangeEvent<HTMLInputElement>) => {
    const value = target.value;
    let updatedValue = '';
    const valueToUpdate = pdfField ? value : realLocalValue;
    if (value.length > realValueLength) {
      const lastCharacter = value[value.length - 1];
      updatedValue = `${valueToUpdate}${lastCharacter}`;
    } else {
      updatedValue = valueToUpdate.slice(0, valueToUpdate.length - 1);
    }

    setRealLocalValue(updatedValue);
    setRealValueLength(updatedValue.length);
    setHiddenValue(transformValueToHiddenValue(updatedValue, countCharactersToShow));
    if (pdfField) {
      onChangeRealValue({ target: { value: updatedValue } }, pdfField);
    } else {
      onChangeRealValue({ target: { value: updatedValue } });
    }
  };

  return {
    hiddenValue,
    onChangeValueWithMask,
  };
};

export default useFieldMaskValue;
import { toast } from 'react-toastify';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { SUCCESS_CODE } from 'constants/generalErrors';
import { deleteAttachment } from 'services/api';
import { createCollectionExecutedInSignNowFlow } from 'services/api/collectionsExecuted';
import {
  CLEAR_SIGN_NOW_FILE_FROM_STORE,
  CREATE_COLLECTION_IN_SIGN_NOW_FLOW,
  REMOVE_SIGN_NOW_FILE,
} from 'store/actions/actionTypes';
import {
  setCollectionIdInSignNow,
  setIsCollectionInSignNow,
  setPdfTemplateIdInSignNow,
  updateSignNowDocument,
} from 'store/actions/signNow';
import { RootStateType } from 'store/reducers';
import { safe } from 'store/sagas/errorHandlerSaga';
import { IResponseOfRequest } from 'types/Api';
import { Action } from 'types/redux';
import { ICreateCollectionInSignNowFlow, IPDFFileInfo, ISignNowDocument } from 'types/signNow/store';
import { ITemplateRequestObj } from 'types/Templates';
import { getPdfUrlAndWidth } from 'utils/pdfTemplatesHelpers';
import { getSignNowTemplateData } from 'utils/signNow/createDocumentHelper';

const removeSignNowFileSaga = function* ({ payload }: Action<string>) {
  const files: IPDFFileInfo[] | null = yield select((state: RootStateType) => state.signNow.files);
  if (!files) return;

  const file = files.find((file) => file.fileKey === payload);
  if (file) {
    const data: string = yield call(
      deleteAttachment,
      { path: file?.fileKey, isPdfDocument: true, isSignNow: true },
    );

    if (data) {
      toast.success(data);
      yield put({ type: CLEAR_SIGN_NOW_FILE_FROM_STORE, payload: file.fileKey });
    }
  }
};

const createCollectionInSignNowFlowSaga = function* ({ payload }: Action<ICreateCollectionInSignNowFlow>) {
  yield put(setIsCollectionInSignNow(true));
  const files: IPDFFileInfo[] = yield select((state: RootStateType) => state.signNow.files);
  const documents: ISignNowDocument[] = yield select((state: RootStateType) => state.signNow.documents);
  const newTemplatesArray: ITemplateRequestObj[] = [];
  files.forEach((file, index) => {
    newTemplatesArray.push(getSignNowTemplateData({
      fileName: documents[index].title ?? String(Date.now()),
      assignments: payload.assignments,
      doctypeID: payload.doctypeId,
      pdfFileLink: `${file.fileKey}?${file.pdfWidth}`,
    }));
  });

  const response: IResponseOfRequest<any> = yield createCollectionExecutedInSignNowFlow(newTemplatesArray);
  if (response && response.status === SUCCESS_CODE) {
    yield put(setCollectionIdInSignNow(response.data.id));
    for (const documentObject of response.data.documents) {
      const fileKey = getPdfUrlAndWidth(documentObject.document.template.pdf_file_url)[0];
      yield put(updateSignNowDocument({
        id: documentObject.document_id,
        fileKey,
      }));

      yield put(setPdfTemplateIdInSignNow(
        fileKey,
        documentObject.document.template_id,
      ));
    }
  }
};

export default [
  takeLatest(REMOVE_SIGN_NOW_FILE, safe(removeSignNowFileSaga, false, false)),
  takeLatest(CREATE_COLLECTION_IN_SIGN_NOW_FLOW, safe(createCollectionInSignNowFlowSaga, false, false)),
];
import { FC } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import ModalConfirmDialog from 'components/Modals/ModalConfirmDialog';
import { SELECT_FIELD, SELECT_FIELD_TYPE_SELECT } from 'constants/editors';
import {
  setColumnNameAndFieldToUpdateFieldsMapping,
  setFieldTypeCheckerModalOpen,
  setLaunchUpdateFieldsMapping,
} from 'store/actions/mondayComIntegration';
import { RootStateType } from 'store/reducers';
import { ICustomElement } from 'types/Editor';
import { PDFFieldType } from 'types/PdfTemplates';

interface IMondayComFieldTypeChecker {
  updateField: (props: {
    updatedField: Partial<ICustomElement | PDFFieldType>;
    withGroupedFieldsUpdating?: boolean;
  }) => void;
}

const MondayComFieldTypeChecker: FC<IMondayComFieldTypeChecker> = ({
  updateField,
}) => {
  const dispatch = useDispatch();
  const {
    fieldTypeCheckerModalOpen,
    columnNameAndFieldToUpdateFieldsMapping,
  } = useSelector((state: RootStateType) => state.mondayCom);

  const onConfirmTypeCheckerModal = () => {
    const updatedField: Partial<ICustomElement | PDFFieldType> = {
      key: columnNameAndFieldToUpdateFieldsMapping?.field.key,
      type: SELECT_FIELD,
      selectFieldType: SELECT_FIELD_TYPE_SELECT,
      addedOtherOption: false,
      options: columnNameAndFieldToUpdateFieldsMapping?.newOptions,
      value: '',
    };

    updateField({
      updatedField,
      withGroupedFieldsUpdating: true,
    });

    dispatch(setLaunchUpdateFieldsMapping(true));
    dispatch(setFieldTypeCheckerModalOpen(false));
  };

  const onCancelTypeCheckerModal = () => {
    dispatch(setColumnNameAndFieldToUpdateFieldsMapping(null));
    dispatch(setFieldTypeCheckerModalOpen(false));
  };

  return (
    <ModalConfirmDialog
      showDialog={fieldTypeCheckerModalOpen}
      onClose={onCancelTypeCheckerModal}
      onConfirm={onConfirmTypeCheckerModal}
      messageText="Warning: The options in the UnicornForms field are not the same as Monday. We will overwrite the UnicornForm field options to be the same as Monday. Do you wish to continue?"
    />
  );
};

export default MondayComFieldTypeChecker;
import ENDPOINT from 'constants/endpoints';
import { IResponseOfRequest } from 'types/Api';
import { axiosInstance } from 'utils/axiosInstance';

// TODO add correct type
export const getCollectionExecutedById = (id: string): Promise<IResponseOfRequest<any>> => (
  axiosInstance.get(`${ENDPOINT.COLLECTION_EXECUTED}/${id}`)
);

// TODO add correct type
export const updateCollectionExecutedById = (id: string, collectionExecuted: any): Promise<IResponseOfRequest<any>> => (
  axiosInstance.patch(`${ENDPOINT.COLLECTION_EXECUTED}/${id}`, collectionExecuted)
);

export const saveCollectionExecutedPublicLink = (body: any): Promise<IResponseOfRequest<any>> => (
  axiosInstance.post(`${ENDPOINT.PUBLIC_COLLECTION_EXECUTED_LINK}`, body)
);

export const createCollectionExecutedInSignNowFlow = (body: any): Promise<IResponseOfRequest<any>> => (
  axiosInstance.post(`${ENDPOINT.COLLECTION_EXECUTED_IN_SIGN_NOW}`, body)
);
import { FC } from 'react';

import cn from 'classnames';
import { EditorGridAxisCount } from 'constants/editors';

import 'scss/components/Editor/_editor.scss';

interface IEditorContainerProps {
  columns?: EditorGridAxisCount;
  isPublicPage?: boolean;
  withDataParameter?: boolean;
}

const EditorContainer: FC<IEditorContainerProps> = ({
  columns = EditorGridAxisCount.Three,
  isPublicPage = false,
  children,
  withDataParameter = true,
}) => (
  <div
    className={cn(
      'editor-container',
      {
        'one-column': columns === EditorGridAxisCount.One,
        'two-columns': columns === EditorGridAxisCount.Two,
        'three-columns': columns === EditorGridAxisCount.Three,
        'editor-container--public': isPublicPage,
      },
    )}
    {...(withDataParameter ? { 'data-pdf-node': 'pdf-wrapper' } : {})}
  >
    {children}
  </div>
);

export default EditorContainer;
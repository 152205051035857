import { FC, memo, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { CircularProgress, ThemeProvider } from '@mui/material';
import {
  CURRENT_PAGE_BY_DEFAULT,
  ROWS_PER_PAGE_OPTIONS_DEFAULT,
  SELECTED_ROW_HIGHLIGHT_CLASS,
  tableActionForUpdatingSelects,
} from 'constants/muiTable';
import MUIDataTable, { MUIDataTableColumn, MUIDataTableState } from 'mui-datatables';
import { setSelectedTableRow } from 'store/actions/muiTableState';

import Button from 'components/Base/Button';
import BatchDownloadExecutedDocumentsButton from 'components/EditorHeader/BatchDownloadExecutedDocumentsButton';
import BatchExportExcelButton from 'components/EditorHeader/BatchExportExcelButton';
import ModalConfirmDialog from 'components/Modals/ModalConfirmDialog';
import ROUTES from 'constants/routes';
import useQueryParametersManager from 'hooks/QueryParametersManager/useQueryParametersManager';
import { useRolePermission } from 'hooks/useRolePermission';
import { RootStateType } from 'store/reducers';
import { CustomSelectHeaderType, IDocumentsIdsArray, IMUITable, SelectedRowsType, TableRowsType } from 'types/Mui';
import { onBlurTableHandler } from 'utils/dataColumns';
import { deleteDocumentModalText } from 'utils/modalHelpers';
import { createQueryParametersObject, replaceQueryParam } from 'utils/QueryParameters/documentsTabsQueryParameters ';
import { createDocsIdsArray, createSendDocumentsArray } from 'utils/sendDocumentsHelpers';

import getTableTheme from 'style/TableTheme';

const options = {
  selectableRowsOnClick: false,
  selectableRowsHeader: false,
  // check that this is what we want (when have more data)
  fixedHeader: false,
  fixedSelectColumn: false,
};

const MUITable: FC<IMUITable> = ({
  columns,
  data,
  deleteRows,
  resetSelectedRows = false,
  isLoading = false,
  isAllDocumentsTab = true,
  isCollectionPage = false,
  onChangeColumn = () => null,
  templateNameForFilter = undefined,
  setTemplateNameForFilter,
  setActiveStatuses,
  activeStatuses,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { documents, collections } = useSelector((state: RootStateType) => state.user);
  const { selectedTableRow } = useSelector((state: RootStateType) => state.muiTableState);
  const [selectedRows, setSelectedRows] = useState<SelectedRowsType[]>([]);
  const [activeColumn, setActiveColumn] = useState<string | null>(null);
  const [showConfirmDeletingModal, setShowConfirmDeletingModal] = useState<boolean>(false);
  const [tableData, setTableData] = useState<MUIDataTableState>();
  const [rowsToDelete, setRowsToDelete] = useState<SelectedRowsType | undefined>();
  const [currentTemplateNameFilter, setCurrentTemplateNameFilter] = useState<string | undefined>();

  const [localColumns, setLocalColumns] = useState<MUIDataTableColumn[]>([]);
  const [localTableData, setLocalTableData] = useState<TableRowsType>([]);

  const { permissionUsingBulkSend, permissionUsingBatchExport, isUserReadOnly } = useRolePermission();
  const readOnlyUserOptions = isUserReadOnly ? { selectableRows: undefined } : {};

  const {
    urlQueryParams,
    onChangeTableFilterHandler,
    rowsPerPage,
    setRowsPerPage,
    currentPage,
    setCurrentPage,
    existingPageFromQueryParam,
  } = useQueryParametersManager({
    setActiveStatuses,
    activeStatuses,
  });

  const queryObject = createQueryParametersObject(urlQueryParams);

  useEffect(() => {
    if (existingPageFromQueryParam === CURRENT_PAGE_BY_DEFAULT) {
      setCurrentPage(CURRENT_PAGE_BY_DEFAULT);
    }
    setCurrentTemplateNameFilter(templateNameForFilter);
  }, [templateNameForFilter]);

  useEffect(() => {
    if (resetSelectedRows) {
      setSelectedRows([]);
    }
  }, [resetSelectedRows]);

  useEffect(() => {
    if (
      Boolean(columns)
      && columns.length
      && Boolean(data)
      && ((data.length && columns.length === data[0].length) || !data.length)
    ) {
      setLocalTableData(data);
      setLocalColumns(columns);
    }
  }, [columns, data]);

  useEffect(() => {
    setTimeout(() => {
      if (selectedTableRow !== null) {
        const selectedRowElement: Element | null = document.querySelector(
          `[data-testid="MUIDataTableBodyRow-${currentPage || ''}${selectedTableRow}"]`,
        );
        if (selectedRowElement) {
          onBlurTableHandler();
          selectedRowElement.classList.add(SELECTED_ROW_HIGHLIGHT_CLASS);
        }
      }
    }, 0);
  }, [selectedTableRow]);

  const applyFiltersUsingQueryParameters = (columnsArray: MUIDataTableColumn[]) => (
    columnsArray.map((column: MUIDataTableColumn) => {
      const columnCopy = { ...column };
      if (queryObject[columnCopy.name] && columnCopy.options) {
        columnCopy.options.filterList = [queryObject[columnCopy.name]];
        columnCopy.options.display = true;
        return columnCopy;
      }
      if (
        columnCopy.name === 'template_name'
        && columnCopy.options
        && typeof currentTemplateNameFilter !== 'undefined'
      ) {
        urlQueryParams.set(columnCopy.name as string, currentTemplateNameFilter);
        replaceQueryParam(urlQueryParams);
        columnCopy.options.filterList = [currentTemplateNameFilter];
        columnCopy.options.display = true;
        return columnCopy;
      }
      return columnCopy;
    })
  );

  const localColumnsWithQueryParameters = applyFiltersUsingQueryParameters(localColumns);

  const handlerRowsDelete = (rowData: SelectedRowsType) => {
    const idArray = rowData?.data?.map(
      ({ dataIndex }: { dataIndex: number }) => (
        { id: data[dataIndex][0], type: data[dataIndex][data[dataIndex].length - 1].type }
      ),
    ).flat();
    deleteRows(idArray);
    setSelectedRows([]);
  };

  const handleModalVisibility = (): void => {
    setShowConfirmDeletingModal((prevState: boolean) => !prevState);
  };

  const handlerSendDocuments = (
    updatedDocumentsArray: IDocumentsIdsArray[],
  ) => {
    createSendDocumentsArray(updatedDocumentsArray, dispatch, window.location.pathname, documents, collections);
    history.push(ROUTES.SEND_DOCUMENTS);
  };

  const CustomToolbarSelect: CustomSelectHeaderType = memo(({
    selectedRows,
    displayData,
  }) => {
    const updatedDocumentsArray: IDocumentsIdsArray[] = createDocsIdsArray(selectedRows, displayData);
    return (
      <div className="d-flex justify-content-between">
        <div>
          {
            isAllDocumentsTab && permissionUsingBulkSend
              ? (
                <Button
                  classes="me-2"
                  onClick={() => handlerSendDocuments(updatedDocumentsArray)}
                >
                  Send
                </Button>
              )
              : null
          }
          {
            !isAllDocumentsTab && !isCollectionPage && location.pathname === ROUTES.PDF_LIST && (
              <BatchDownloadExecutedDocumentsButton
                selectedRows={selectedRows}
                displayData={displayData}
                isCollectionPage={isCollectionPage}
              />
            )
          }
          {
            (!isAllDocumentsTab && permissionUsingBatchExport)
              ? (
                <BatchExportExcelButton
                  selectedRows={selectedRows}
                  displayData={displayData}
                  isCollectionPage={isCollectionPage}
                />
              )
              : null
          }
        </div>
        <div>
          <Button
            classes="button-outlined-pink mui-table-button hovered-red"
            onClick={() => {
              handleModalVisibility();
              setRowsToDelete(selectedRows);
            }}
          >
            Delete
          </Button>
        </div>
      </div>
    );
  });

  const onCloseChipFilter = (index: number) => {
    localColumnsWithQueryParameters.map((column: MUIDataTableColumn, columnIndex: number) => {
      const columnCopy = { ...column };
      if (columnIndex === index && columnCopy.options?.filterList) {
        if (setTemplateNameForFilter) {
          setTemplateNameForFilter(undefined);
        }
        columnCopy.options.display = false;
        columnCopy.options.filterList = [];
        return columnCopy;
      }
      return columnCopy;
    });
    setCurrentPage(CURRENT_PAGE_BY_DEFAULT);
  };

  return (
    <>
      <ThemeProvider theme={getTableTheme()}>
        <MUIDataTable
          columns={localColumnsWithQueryParameters}
          data={localTableData}
          title=""
          options={{
            ...options,
            ...readOnlyUserOptions,
            onFilterChipClose: (index: number) => {
              onCloseChipFilter(index);
            },
            onViewColumnsChange: (changedColumn: string, action: string) => {
              onChangeColumn(changedColumn, action);
            },
            isRowSelectable: (dataIndex: number) => {
              if (tableData?.displayData.length) {
                const currentRows = tableData?.displayData
                  .slice(rowsPerPage * currentPage, rowsPerPage * (currentPage + 1))
                  .map((row) => row.dataIndex);
                return currentRows.includes(dataIndex);
              }

              return dataIndex >= rowsPerPage * currentPage && dataIndex <= rowsPerPage * (currentPage + 1) - 1;
            },
            onTableChange: (action: string, tableState: MUIDataTableState) => {
              if (
                tableActionForUpdatingSelects.includes(action)
                || (action === 'propsUpdate' && tableData === undefined)
              ) {
                setTableData(tableState);
              }

              if (action === 'propsUpdate' && tableData) {
                const previousDisplayedDataIds = tableData.displayData.map((item) => item.dataIndex);
                const currentDisplayedDataIds = tableState.displayData.map((item) => item.dataIndex);

                const isArraysEqualsInLength = previousDisplayedDataIds?.length === currentDisplayedDataIds.length;
                if (activeColumn !== tableState.activeColumn) {
                  setActiveColumn(tableState.activeColumn);
                  setTableData(tableState);
                }
                if (!isArraysEqualsInLength) {
                  setTableData(tableState);
                }
              }
            },
            onFilterChange: (changedColumn, filterList, type, changedColumnIndex) => {
              onChangeTableFilterHandler(changedColumn, filterList[changedColumnIndex][0]);
              if (changedColumn === 'template_name' && type === 'chip') {
                setCurrentTemplateNameFilter(undefined);
              }
              if (changedColumn === 'template_name' && type !== 'chip') {
                setCurrentTemplateNameFilter(filterList[changedColumnIndex][0]);
              }
            },
            onRowClick: (rowData: string[], rowMeta: { dataIndex: number; rowIndex: number; }) => {
              dispatch(setSelectedTableRow(rowMeta.rowIndex));
            },
            selectableRowsHeader: true,
            rowsSelected: selectedRows,
            rowsPerPage,
            rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS_DEFAULT,
            page: currentPage,
            onChangeRowsPerPage: (numberOfRows: number) => setRowsPerPage(numberOfRows),
            onChangePage: (currentPage: number) => setCurrentPage(currentPage),
            textLabels: {
              body: {
                noMatch: isLoading
                  ? (
                    <CircularProgress color="primary" />
                  ) : 'Sorry, there is no matching data to display',
              },
            },
          }}
          components={{
            TableToolbarSelect: CustomToolbarSelect,
          }}
        />
      </ThemeProvider>
      <ModalConfirmDialog
        showDialog={showConfirmDeletingModal}
        onClose={handleModalVisibility}
        onConfirm={() => {
          handleModalVisibility();
          if (rowsToDelete) {
            handlerRowsDelete(rowsToDelete);
          }
        }}
        messageText={deleteDocumentModalText}
      />
    </>
  );
};

export default MUITable;
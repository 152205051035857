import {
  ADD_ASSIGNMENT,
  ADD_CUSTOM_ASSIGNMENT,
  ADD_FIELD_TO_ASSIGNMENTS,
  CHANGE_ASSIGNMENT_LABEL,
  CHANGE_ASSIGNMENT_TYPE,
  CHANGE_FIELD_IN_FOCUS_STATUS,
  CLEAN_ASSIGNMENTS,
  DELETE_PDF_FILE_LINK,
  REMOVE_ASSIGNMENT,
  REMOVE_SELECT_FIELD_ERROR,
  REMOVE_SELECT_FIELD_ERRORS,
  SET_ACTIVE_EDITOR_TAB,
  SET_ACTIVE_HOVERED_SECTION_KEY,
  SET_ALL_ASSIGNMENTS,
  SET_ASSIGNMENT_DELETION_INFO,
  SET_OPEN_ASSIGNMENTS_SETTINGS_MODAL,
  SET_PDF_FILE_LINK,
  SET_PUBLIC_FIELD_VALIDATION,
  SET_PUBLIC_FORM_VALIDATION,
  SET_SELECT_FIELD_ERROR,
  SET_SELECTED_ASSIGNMENT,
  SET_SELECTED_FIELD,
  SET_SELECTED_SECTION,
  SET_SELECTED_TABLE_QUESTION,
} from 'store/actions/actionTypes';
import { AssignmentsMainParts, AssignmentsObjectType } from 'types/Editor';
import { ActionPayload } from 'types/redux';
import { IValidated } from 'types/validation';

export const changeSelectedField = (elementKey: number | null) => ({
  type: SET_SELECTED_FIELD,
  payload: elementKey,
});

export const changeSelectedSection = (elementKey: number | null) => ({
  type: SET_SELECTED_SECTION,
  payload: elementKey,
});

export const setSelectFieldError = (isError: boolean, fieldKey: number | null) => ({
  type: SET_SELECT_FIELD_ERROR,
  payload: { isError, fieldKey },
});

export const setPublicFormValidation = (element: IValidated) => ({
  type: SET_PUBLIC_FORM_VALIDATION,
  payload: element,
});

export const setPdfFileLink = (link: string) => ({
  type: SET_PDF_FILE_LINK,
  payload: link,
});

export const deletePdfFileLink = () => ({
  type: DELETE_PDF_FILE_LINK,
});

export const setActiveEditorTab = (tabId: number) => ({
  type: SET_ACTIVE_EDITOR_TAB,
  payload: tabId,
});

export const deleteSelectFieldError = (fieldKey: number) => ({
  type: REMOVE_SELECT_FIELD_ERROR,
  payload: fieldKey,
});

export const deleteSelectFieldErrors = () => ({
  type: REMOVE_SELECT_FIELD_ERRORS,
});

export const setPublicFieldValidation = ({
  fieldKey,
  validationMessage,
}: {
  fieldKey: number,
  validationMessage: string
}) => ({
  type: SET_PUBLIC_FIELD_VALIDATION,
  payload: {
    fieldKey,
    validationMessage,
  },
});

export const changeFieldInFocusStatus = (fieldInFocus: boolean) => ({
  type: CHANGE_FIELD_IN_FOCUS_STATUS,
  payload: {
    fieldInFocus,
  },
});

export const addAssignment = (typeToUpdate: AssignmentsMainParts, length: number, position: number) => ({
  type: ADD_ASSIGNMENT,
  payload: {
    typeToUpdate,
    length,
    position,
  },
});

export const addCustomAssignment = (assignments: AssignmentsObjectType): ActionPayload<AssignmentsObjectType> => ({
  type: ADD_CUSTOM_ASSIGNMENT,
  payload: assignments,
});

export const cleanAssignments = () => ({
  type: CLEAN_ASSIGNMENTS,
});

export const setAllAssignments = (assignments: AssignmentsObjectType) => ({
  type: SET_ALL_ASSIGNMENTS,
  payload: assignments,
});

export const setSelectedTableQuestion = (key: number | null) => ({
  type: SET_SELECTED_TABLE_QUESTION,
  payload: key,
});

export const addFieldToAssignments = (key: number, assignment: string, typeToUpdate: AssignmentsMainParts) => ({
  type: ADD_FIELD_TO_ASSIGNMENTS,
  payload: {
    typeToUpdate,
    assignment,
    key,
  },
});

export const setActiveHoveredSectionKey = (key: number) => ({
  type: SET_ACTIVE_HOVERED_SECTION_KEY,
  payload: key,
});

export const setOpenAssignmentsSettingsModal = (isOpen: boolean) => ({
  type: SET_OPEN_ASSIGNMENTS_SETTINGS_MODAL,
  payload: isOpen,
});

export const changeAssignmentLabel = (
  assignmentType: string,
  newLabel: string,
) => ({
  type: CHANGE_ASSIGNMENT_LABEL,
  payload: {
    assignmentType,
    newLabel,
  },
});

export const changeAssignmentType = (
  previousAssignmentType: string,
  previousMainType: AssignmentsMainParts,
  newMainType: AssignmentsMainParts,
) => ({
  type: CHANGE_ASSIGNMENT_TYPE,
  payload: {
    previousAssignmentType,
    previousMainType,
    newMainType,
  },
});

export const removeAssignment = (assignmentType: string | null) => ({
  type: REMOVE_ASSIGNMENT,
  payload: assignmentType,
});

export const setAssignmentDeletionInfo = (
  openConfirmationModal: boolean,
  assignmentTypeForDeletion: string | null,
) => ({
  type: SET_ASSIGNMENT_DELETION_INFO,
  payload: {
    openConfirmationModal,
    assignmentTypeForDeletion,
  },
});

export const setSelectedAssignment = (assignment: string) => ({
  type: SET_SELECTED_ASSIGNMENT,
  payload: assignment,
});
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';

import muiTableState from 'store/reducers/muiTableState';

import companyBranding from 'store/reducers/companyBranding';
import editorSlate from 'store/reducers/editorSlate';
import errorLoading from 'store/reducers/errorLoading';
import formBuilder from 'store/reducers/formBuilder';
import mondayCom from 'store/reducers/mondayCom';
import multiTemplates from 'store/reducers/multiTemplates';
import payment from 'store/reducers/payment';
import pdfTemplates from 'store/reducers/pdfTemplates';
import persistentReduxStorage, { storagePersistConfig } from 'store/reducers/persistentReduxStorage';
import publicPages from 'store/reducers/publicPages';
import sendDocuments from 'store/reducers/sendDocuments';
import signNow from 'store/reducers/signNow';
import template from 'store/reducers/template';
import user from 'store/reducers/userData';
import profile from 'store/reducers/userProfile';
import validation from 'store/reducers/validation';

const rootReducer = combineReducers({
  user,
  profile,
  companyBranding,
  errorLoading,
  formBuilder,
  publicPages,
  editorSlate,
  template,
  multiTemplates,
  pdfTemplates,
  validation,
  sendDocuments,
  payment,
  mondayCom,
  signNow,
  muiTableState,
  persistentStorage: persistReducer(storagePersistConfig, persistentReduxStorage),
});

export type RootStateType = ReturnType<typeof rootReducer>;

export default rootReducer;
import ENDPOINT from 'constants/endpoints';
import { IResponseOfRequest } from 'types/Api';
import { axiosInstance } from 'utils/axiosInstance';

export const getAutoUpdateResourcesEvent = (
  companyId: number,
  userId: number,
  browserTabId: number,
): Promise<IResponseOfRequest<any>> => (
  axiosInstance.get(`${ENDPOINT.AUTO_UPDATE_RESOURCES}/${companyId}/${userId}/${browserTabId}`)
);

export default undefined;
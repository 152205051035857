import PublicSuccessComponent from 'components/PublicPage/PublicSuccessComponent';
import {
  URL_MANAGER_DOCUMENT,
  URL_MANAGER_TEMPLATE,
  URL_PUBLIC_COLLECTION,
  URL_PUBLIC_DOCUMENT,
  URL_PUBLIC_FILL_OUT,
  URL_PUBLIC_SUCCESS_SUMMARY_PAGE,
  URL_PUBLIC_TEMPLATE,
} from 'constants/general';
import ROUTES from 'constants/routes';
import CreateSection from 'containers/CreateSection';
import EditSection from 'containers/EditSection';
import Sections from 'containers/Sections';
import CollectionDetails from 'pages/CollectionDetails';
import CollectionExecutedDetails from 'pages/CollectionExecutedDetails';
import Collections from 'pages/Collections';
import CreateDocument from 'pages/CreateDocument';
import Dashboard from 'pages/Dashboard';
import Documents from 'pages/Documents';
import EditDocument from 'pages/EditDocument';
import EditDocumentExecuted from 'pages/EditDocumentExecuted';
import Error404 from 'pages/Error404';
import FormDetails from 'pages/FormDetails';
import FormExecutedDetails from 'pages/FormExecutedDetails';
import Forms from 'pages/Forms';
import Greeting from 'pages/Greeting';
import Login from 'pages/Login';
import PdfDocumentDetails from 'pages/PdfDocumentDetails';
import PdfDocumentExecutedDetails from 'pages/PdfDocumentExecutedDetails';
import PDFList from 'pages/PDFList';
import PublicDocument from 'pages/PublicDocument';
import ResetPassword from 'pages/ResetPassword';
import SendDocuments from 'pages/SendDocuments';
import Billing from 'pages/SettingsMenu/Billing';
import CompanyBranding from 'pages/SettingsMenu/CompanyBranding';
import GroupListing from 'pages/SettingsMenu/GroupListing';
import GroupManagement from 'pages/SettingsMenu/GroupManagement';
import IntegrationsSettings from 'pages/SettingsMenu/Integrations';
import Notifications from 'pages/SettingsMenu/Notifications';
import Organization from 'pages/SettingsMenu/Organization';
import Profile from 'pages/SettingsMenu/Profile';
import ReferralLink from 'pages/SettingsMenu/ReferralLink';
import UserManagement from 'pages/SettingsMenu/UserManagement';
import SignNowPage from 'pages/SignNowPage';
import { IRoute } from 'types/Route';

export const pageNotFoundRoute: IRoute[] = [
  {
    path: '*',
    component: Error404,
  },
];

export const companyBrandingRoute: IRoute[] = [
  {
    path: ROUTES.BRANDING,
    component: CompanyBranding,
  },
];

export const adminsRoutes: IRoute[] = [
  {
    path: ROUTES.BILLING,
    component: Billing,
  },
  {
    path: ROUTES.REFERRAL_LINK,
    component: ReferralLink,
  },
  {
    path: ROUTES.INTEGRATIONS,
    component: IntegrationsSettings,
  },
];

export const limitedRoutes: IRoute[] = [
  {
    path: ROUTES.DOCUMENTS,
    component: Documents,
  },
  {
    path: ROUTES.DOCUMENTS_EDITOR,
    component: CreateDocument,
  },
  {
    path: `${ROUTES.DOCUMENTS_EDITOR}/:id(\\d+)`,
    component: EditDocument,
  },
  {
    path: ROUTES.PROFILE,
    component: Profile,
  },
  {
    path: ROUTES.ORGANIZATION,
    component: Organization,
  },
];

export const basicRoutes: IRoute[] = [
  {
    path: ROUTES.DASHBOARD,
    component: Dashboard,
  },
  {
    path: ROUTES.PROFILE,
    component: Profile,
  },
  // It's hided because there is nothing to show inside it for now.
  // {
  //   path: ROUTES.SECURITY_SETTINGS,
  //   component: SecuritySettings,
  // },
];

export const extendedRoutes: IRoute[] = [
  // TODO Add this route to limited Routes if Patrick approve that
  {
    path: ROUTES.SEND_DOCUMENTS,
    component: SendDocuments,
  },
  // {
  //   path: '/library',
  //   component: Data,
  // },
  {
    path: ROUTES.ORGANIZATION,
    component: Organization,
  },
  {
    path: `/${URL_MANAGER_TEMPLATE}/:id`,
    component: Greeting,
  },
  {
    path: `/${URL_MANAGER_TEMPLATE}/${URL_PUBLIC_FILL_OUT}/:id`,
    component: PublicDocument,
  },
  {
    path: `/${URL_MANAGER_DOCUMENT}/:id`,
    component: Greeting,
  },
  {
    path: `/${URL_MANAGER_DOCUMENT}/${URL_PUBLIC_FILL_OUT}/:id`,
    component: PublicDocument,
  },
  {
    path: `/${URL_PUBLIC_TEMPLATE}/:id`,
    component: Greeting,
  },
  {
    path: `/${URL_PUBLIC_TEMPLATE}/${URL_PUBLIC_FILL_OUT}/:id`,
    component: PublicDocument,
  },
  {
    path: `/${URL_PUBLIC_DOCUMENT}/:id`,
    component: Greeting,
  },
  {
    path: `/${URL_PUBLIC_DOCUMENT}/${URL_PUBLIC_FILL_OUT}/:id`,
    component: PublicDocument,
  },
  {
    path: `/${URL_PUBLIC_COLLECTION}/:id`,
    component: Greeting,
  },
  {
    path: `/${URL_PUBLIC_COLLECTION}/${URL_PUBLIC_FILL_OUT}/:id`,
    component: PublicDocument,
  },
  {
    path: `/${ROUTES.PUBLIC_COLLECTION_EXECUTED}/:id`,
    component: Greeting,
  },
  {
    path: `/${ROUTES.PUBLIC_COLLECTION_EXECUTED}/${URL_PUBLIC_FILL_OUT}/:id`,
    component: PublicDocument,
  },
  {
    path: `/${URL_PUBLIC_SUCCESS_SUMMARY_PAGE}`,
    component: PublicSuccessComponent,
  },
  {
    path: ROUTES.USER_MANAGEMENT,
    component: UserManagement,
  },
  {
    path: ROUTES.NOTIFICATIONS,
    component: Notifications,
  },
];

// Slate documents
export const basicDocumentsRoutes: IRoute[] = [
  {
    path: ROUTES.DOCUMENTS,
    component: Documents,
  },
  {
    path: `${ROUTES.DOCUMENTS_EXECUTED_EDITOR}/:id(\\d+)`,
    component: EditDocumentExecuted,
  },
];

export const extendedDocumentsRoutes: IRoute[] = [
  {
    path: ROUTES.DOCUMENTS_EDITOR,
    component: CreateDocument,
  },
  {
    path: `${ROUTES.DOCUMENTS_EDITOR}/:id(\\d+)`,
    component: EditDocument,
  },
  {
    path: ROUTES.DOCUMENTS_EXECUTED_EDITOR_SINGLE,
    component: CreateDocument,
  },
];

// PDF's
export const basicPDFRoutes: IRoute[] = [
  {
    path: ROUTES.PDF_LIST,
    component: PDFList,
  },
  {
    path: `${ROUTES.PDF_EXECUTED_EDITOR}/:id`,
    component: PdfDocumentExecutedDetails,
  },
];

export const extendedPDFRoutes: IRoute[] = [
  {
    path: ROUTES.PDF_EDITOR,
    component: PdfDocumentDetails,
  },
  {
    path: `${ROUTES.PDF_EDITOR}/:id(\\d+)`,
    component: PdfDocumentDetails,
  },
  {
    path: ROUTES.PDF_EXECUTED_EDITOR_SINGLE,
    component: PdfDocumentDetails,
  },
];

// Old sections
export const sectionsRoutes: IRoute[] = [
  {
    path: ROUTES.SECTIONS,
    component: Sections,
  },
  {
    path: ROUTES.SECTIONS_EDITOR,
    component: CreateSection,
  },
  {
    path: `${ROUTES.SECTIONS_EDITOR}/:id`,
    component: EditSection,
  },
];

// Groups managment
export const groupManagementRoutes: IRoute[] = [
  {
    path: ROUTES.GROUP_MANAGEMENT,
    component: GroupManagement,
  },
  {
    path: `${ROUTES.GROUP_MANAGEMENT}/:id`,
    component: GroupListing,
  },
];

// Form document
export const basicFormsRoutes: IRoute[] = [
  {
    path: ROUTES.FORMS,
    component: Forms,
  },
  {
    path: `${ROUTES.FORM_EXECUTED_EDITOR}/:id(\\d+)`,
    component: FormExecutedDetails,
  },
];

export const extendedFormsRoutes: IRoute[] = [
  // FORMS
  {
    path: ROUTES.FORM_EDITOR,
    component: FormDetails,
  },
  {
    path: `${ROUTES.FORM_EDITOR}/:id(\\d+)`,
    component: FormDetails,
  },
  {
    path: ROUTES.FORM_EXECUTED_EDITOR_SINGLE,
    component: FormDetails,
  },
];

// Collections
export const collectionsRoutes: IRoute[] = [
  {
    path: ROUTES.COLLECTIONS,
    component: Collections,
  },
  {
    path: ROUTES.COLLECTIONS_EDITOR,
    component: CollectionDetails,
  },
  {
    path: `${ROUTES.COLLECTIONS_EDITOR}/:id(\\d+)`,
    component: CollectionDetails,
  },
  {
    path: `${ROUTES.COLLECTIONS_EXECUTED_EDITOR}/:id(\\d+)`,
    component: CollectionExecutedDetails,
  },
];

// Sign Now
export const signNowRoutes: IRoute[] = [
  {
    path: ROUTES.SIGN_NOW,
    component: SignNowPage,
  },
  {
    path: `${ROUTES.SIGN_NOW}/create`,
    component: SignNowPage,
  },
  {
    path: `${ROUTES.SIGN_NOW}/:id`,
    component: SignNowPage,
  },
];

// Public pages
export const publicRoutes: IRoute[] = [
  {
    path: ROUTES.LOGIN,
    component: Login,
  },
  {
    path: `${ROUTES.RESET_PASSWORD}/:id`,
    component: ResetPassword,
  },
  {
    path: `/${URL_PUBLIC_TEMPLATE}/:id`,
    component: Greeting,
  },
  {
    path: `/${URL_PUBLIC_TEMPLATE}/${URL_PUBLIC_FILL_OUT}/:id`,
    component: PublicDocument,
  },
  {
    path: `/${URL_PUBLIC_DOCUMENT}/:id`,
    component: Greeting,
  },
  {
    path: `/${URL_PUBLIC_DOCUMENT}/${URL_PUBLIC_FILL_OUT}/:id`,
    component: PublicDocument,
  },
  {
    path: `/${URL_PUBLIC_COLLECTION}/:id`,
    component: Greeting,
  },
  {
    path: `/${URL_PUBLIC_COLLECTION}/${URL_PUBLIC_FILL_OUT}/:id`,
    component: PublicDocument,
  },
  {
    path: `/${ROUTES.PUBLIC_COLLECTION_EXECUTED}/:id`,
    component: Greeting,
  },
  {
    path: `/${ROUTES.PUBLIC_COLLECTION_EXECUTED}/${URL_PUBLIC_FILL_OUT}/:id`,
    component: PublicDocument,
  },
  {
    path: `/${URL_PUBLIC_SUCCESS_SUMMARY_PAGE}`,
    component: PublicSuccessComponent,
  },
];

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-use-before-define */
import {
  ChangeEvent,
  ChangeEventHandler,
  Dispatch,
  KeyboardEvent,
  MouseEvent,
  MouseEventHandler,
  MutableRefObject,
  SetStateAction,
  SyntheticEvent,
} from 'react';

import { Dispatch as ReduxDispatch } from 'redux';

import {
  BaseEditor,
  BaseRange as SlateBaseRange,
  Descendant,
  Editor as SlateEditor,
  Element as SlateElement,
} from 'slate';
import { HistoryEditor } from 'slate-history';
import { ReactEditor } from 'slate-react';

import {
  DROPDOWN_TYPES,
  FIELD_COLOR_MANAGER,
  FIELD_COLOR_RECIPIENT,
  FIELD_GROUPING_VALUE,
  FIELD_TYPE,
  FONT_TYPE,
  LIST_STYLE_DECIMAL_CHAPTER,
  LIST_STYLE_DECIMAL_LEGAL,
  QUESTION_ANSWER_SELECT,
  QUESTION_ANSWER_STRING,
  SELECT_FIELD_TYPE_CHECKBOX,
  SELECT_FIELD_TYPE_RADIO,
  SELECT_FIELD_TYPE_SELECT,
  TEXT_SIZE,
} from 'constants/editors';
import { TEXT_FIELD_MASKS } from 'constants/fieldPropertiesTab';
import { SIGNATURE_TABS } from 'constants/signatures';
import { TableQuestionSide } from 'constants/tableQuestion';
import { MondayComIntegrationObjectType } from 'types/mondayCom/mondayComBoard';
import { PDFFieldType } from 'types/PdfTemplates';
import { IErrorField, IValidated } from 'types/validation';

export type CustomEditorType = BaseEditor & ReactEditor & HistoryEditor & ICustomNode & ICustomEditor;
export type PluginsType = (editor: CustomEditorType) => CustomEditorType;
export type FilterCallbackType<T, R = T> = (node: T) => R;

export type MarkFormatType = 'code' | 'underline' | 'italic' | 'subscript' | 'superscript' | 'strikethrough'
  | 'bold' | 'highlight' | 'text-size';
export type BlockTextAlignType = 'text-left' | 'text-center' | 'text-right' | 'text-justify';
export type BlockIndentType = 'indent-increase' | 'indent-decrease';
export type BlockFormatType = 'table' | 'table-head' | 'table-body' | 'table-row' | 'table-cell' | 'table-content'
  | 'headline-one' | 'headline-two' | 'headline-three' | 'headline-four' | 'headline-five' | 'headline-six'
  | 'block-quote' | 'numbered-list' | 'bulleted-list' | 'paragraph' | 'text-field' | 'select-field' | 'date-field'
  | 'list-item' | 'section' | 'link' | 'image' | 'check-list-item' | 'video' | 'signing-field' | 'textarea-field'
  | 'attachment-field' | 'numbered-list-item' | 'list-item-text' | 'question-field' | 'checkbox-field'
  | 'form-builder-paragraph'
  | 'table-question' | 'table-question-caption' | 'table-question-head' | 'table-question-body'
  | 'table-question-row' | 'table-question-cell' | 'table-question-field-cell'
  | 'table-question-vertical-resize-bar' | 'table-question-horizontal-resize-bar';
export type ListStyleViewType = '' | typeof LIST_STYLE_DECIMAL_CHAPTER | typeof LIST_STYLE_DECIMAL_LEGAL;

export type FieldAssignmetsType = 'recipient' | 'manager' | 'readOnly' | '' | string;
export type FieldGroupByType = typeof FIELD_GROUPING_VALUE[keyof typeof FIELD_GROUPING_VALUE];
export type QuestionAnswerType = typeof QUESTION_ANSWER_STRING | typeof QUESTION_ANSWER_SELECT;
export type SelectFieldOptionsType = typeof SELECT_FIELD_TYPE_SELECT
  | typeof SELECT_FIELD_TYPE_RADIO
  | typeof SELECT_FIELD_TYPE_CHECKBOX;
export type FieldAssignmentsColor = typeof FIELD_COLOR_RECIPIENT | typeof FIELD_COLOR_MANAGER;
export enum LABEL_CONFIGURATION {
  LEFT = 'left',
  RIGHT = 'right',
  LEGAL = 'legal',
  NONE = 'none',
  TOP = 'top',
}
export type ChangedPropertyDataType = {
  value: string | null;
  method: () => void;
  resetMethod: () => void;
};

export const isBlockFormatType = (value: string): value is BlockFormatType => (
  FIELD_TYPE.includes(value)
);

export type LinkOptionsType = { url: string; blank: boolean };
export type ImageOptionsType = { url: string; alt: string };
export type SelectFieldOptionType = {
  id: number;
  label: string;
  checked?: boolean;
  isSelectOtherOption?: boolean;
};
export type SelectFieldValue = {
  value: string;
  options: SelectFieldOptionType[];
  selectedOtherOption: boolean;
};

export enum USING_TOOLBAR {
  HORIZONTAL_TOOLBAR = 'HORIZONTAL_TOOLBAR',
  VERTICAL_TOOLBAR = 'VERTICAL_TOOLBAR',
  MENTION_TOOLBAR = 'MENTION_TOOLBAR',
}

export type UseToolbarType = () =>
  | USING_TOOLBAR.HORIZONTAL_TOOLBAR
  | USING_TOOLBAR.VERTICAL_TOOLBAR
  | USING_TOOLBAR.MENTION_TOOLBAR
  | null;

export interface IToolbarCommand<FormatType, ComponentPropsType> {
  id: number;
  category: string;
  format?: FormatType;
  formats?: FormatType;
  title: string;
  icon: React.ReactNode;
  Component: React.FC<ComponentPropsType>;
}

export interface IHotkeySetting {
  style: MarkFormatType;
  value: string;
}

export interface IToolbarTab {
  label: string;
  type: 'ALL' | 'BASIC' | 'MEDIA' | 'DEVELOPER' | 'EMBED';
}

export interface IFontSize {
  [key: string]: number;
}

export interface ITextAlign {
  [key: string]: string;
}

export interface IMonthNames {
  [key: string]: string;
}

export interface IRefEditable {
  ref?: MutableRefObject<HTMLElement | null>;
}

export interface IEditorProps {
  previewMode?: boolean;
  content: Descendant[];
  search?: string;
  viewForms?: boolean;
  viewFormsName?: string;
  viewFormsTitle?: string;
  changeFormTitle?: (value: any) => void;
  viewFormsDescription?: string;
  changeFormDescription?: (value: string) => void;
  showDescription?: boolean;
  styledSections?: boolean;
  visibleEditorDevTools?: boolean;
  onChange: (value: any) => void;
  onChangeApiVars?: (value: any) => void;
  onCreateForm?: (createForm: any) => void;
  modal?: boolean;
  readOnlyMode?: boolean;
  isFormsView?: boolean;
  formErrors?: IErrorField;
  setFormErrors?: Dispatch<SetStateAction<IErrorField>>;
  isFormOfForms?: boolean;
  showOnlyEditor?: boolean;
  isCollectionPage?: boolean;
  isTemplate?: boolean;
  isCompletedModal?: boolean;
  currentSectionPadding?: string;
}

export interface IPdfEditorProps {
  visibleEditorDevTools?: boolean;
  readOnlyMode?: boolean;
  isPublicPage?: boolean;
  isFormsView?: boolean;
  modal?: boolean;
  isMultiTemplate?: boolean;
  pdfTemplateId?: number;
  isCollectionPage?: boolean;
  customFields?: PDFFieldType[] | null;
  isFormBuilderOpened?: boolean;
}

export interface IPdfViewerProps {
  content: Descendant[];
}

export interface IToggleWebPrintProps {
  modal: boolean;
  printView: boolean;
  togglePrintView: () => void;
}

export interface IElementWithHeader {
  element: any;
  header?: boolean;
  checkbox?: boolean;
  checkboxCheck?: boolean | undefined;
}

export interface IPdfBlock {
  pdfBlock: any;
  styleObject?: any;
}

export interface IBlockButtonProps {
  key?: number;
  format: BlockFormatType;
  title?: string;
  icon: React.ReactNode | string;
  horizontalToolbar?: boolean;
  selectVariant?: SelectFieldOptionsType;
}

export interface IBlockIndentButtonProps {
  key?: number;
  format: BlockIndentType;
  title?: string;
  icon: React.ReactNode | string;
  horizontalToolbar?: boolean;
}

export interface IFontTypeButtonProps {
  format: BlockFormatType;
  value: string;
  icon: React.ReactNode | string;
  additionalClassname?: string;
}

export interface IBlockAlignButtonProps {
  format: BlockTextAlignType;
  icon: React.ReactNode | string;
  horizontalToolbar?: boolean;
}

export interface IBlocksSelectProps {
  key?: number;
  formats: any[];
  title?: string;
  icon?: React.ReactNode | string;
  horizontalToolbar?: boolean;
  dropdownType: DROPDOWN_TYPES;
}

export interface IMarkButtonProps {
  key?: number;
  format: MarkFormatType;
  size?: string;
  icon: React.ReactNode | string;
  title?: string;
  horizontalToolbar?: boolean;
}

export interface IMarksSelectProps {
  key?: number;
  formats: any[];
  icon: React.ReactNode | string;
  title?: string;
  horizontalToolbar?: boolean;
}

export interface IWrapperBlockEditableProps {
  children: JSX.Element & object & IRefEditable & React.ReactNode;
  element: ICustomElement;
}

export interface IUseSortedSectionsProps {
  isPublicPage?: boolean;
  isFormsView?: boolean;
  isFormPage?: boolean;
  currentDocAssignment?: string;
}

export type UseSortedSectionsHookType = (props: IUseSortedSectionsProps) => INewSection[];

export interface INewSection {
  key: number;
  position: number;
  fields: Partial<SlateElement>[];
}

export interface ICustomEditor {
  publicPage: boolean;
  ranges: ICustomRange[] | null;
  forms: ICustomElement[] | null;
  formPage?: boolean;
  sections?: INewSection[];
  sortedSections?: INewSection[];
  sortedForms?: ICustomElement[] | null;
  findNodesAndUpdate: (callback: FilterCallbackType<Descendant>) => void;
  dispatch?: ReduxDispatch;
}

export interface ITypeKey {
  type: BlockFormatType;
  key?: number;
  templateId?: number;

  name?: string;
  description?: string;
}

interface ICustomNode {
  type: BlockFormatType;
  value?: string;
  url?: string;
  blank?: boolean;
  alt?: string;
  name?: string;
  text?: string;
  checked?: boolean;
  textAlign?: BlockTextAlignType;
  children: (ICustomText | ICustomElement)[];
  'font-type'?: string;
  selectedCell?: boolean;
  uuid?: string;
}

export interface ICustomElement {
  type: BlockFormatType;
  subtype?: string;
  assignment?: FieldAssignmetsType;
  isTodaysDate?: boolean;
  key?: number;
  value?: string;
  url?: string;
  blank?: boolean;
  alt?: string;
  name?: string;
  groupBy?: FieldGroupByType;
  groupByKey?: string;
  filterName?: string;
  // for PDF:
  fieldName?: string;
  text?: string;
  checked?: boolean;
  textAlign?: BlockTextAlignType;
  options?: SelectFieldOptionType[];
  children: (ICustomText | ICustomElement)[];
  sectionName?: boolean;
  [FONT_TYPE]?: string;
  listStyle?: ListStyleViewType;
  liststyle?: ListStyleViewType;
  margin?: number;
  textIndent?: number;
  position?: number;
  requiredField?: boolean;
  helpText?: string;
  validationText?: string;
  visible?: boolean;
  selectedCell?: boolean;
  uuid?: string;
  width?: number;
  height?: number;
  rowspan?: number;
  colspan?: number;
  properties?: {
    count?: number;
    limit?: number;
    maxLimit?: number;
    maxMBLimit?: number;
  };
  answerType?: QuestionAnswerType;
  shortName?: string;
  labelConfiguration?: LABEL_CONFIGURATION;
  maxLength?: number | string;
  className?: string;
  signatureTab?: SIGNATURE_TABS;
  selectFieldType?: SelectFieldOptionsType;
  addedOtherOption?: boolean;
  selectedOtherOption?: boolean;
  fontSize?: number | string;
  dateMask?: string;
  signatureFieldVariation?: string;
  captionSide?: TableQuestionSide;
  isTableField?: boolean;
  starting_hash?: string;
  textFieldMask?: TEXT_FIELD_MASKS;
  textFieldMaskCustomText?: string;
  isReadOnly?: boolean;
}

export interface ICustomElementWithTextSize extends ICustomElement{
  [TEXT_SIZE]?: string;
}

export interface IAttachmentElement {
  type: 'attachment-field';
  key: number;
  value: string;
  properties: {
    count?: number,
    limit: number,
    maxLimit: number,
    maxMBLimit: number,
  };
  assignment?: FieldAssignmetsType;
  name?: string;
  children: (ICustomText | ICustomElement)[];
  position?: number;
  requiredField?: boolean;
  helpText?: string;
  validationText?: string;
}

interface ICustomText {
  text: string;
  bold?: boolean;
  italic?: boolean;
  code?: boolean;
  underline?: boolean;
  subscript?: boolean;
  superscript?: boolean;
  highlight?: boolean;
  strikethrough?: boolean;
  'text-size'?: boolean; // TODO: refactor parameters to camel case format
  size?: string;
  children?: (ICustomText | ICustomElement)[];
  key?: number;
  selectedCell?: boolean;
}

interface ICustomRange extends SlateBaseRange {
  highlight?: boolean;
}

declare module 'slate' {
  interface CustomTypes {
    Editor: CustomEditorType;
    Element: ICustomElement;
    Text: ICustomText;
    Range: ICustomRange;
  }
}

type SelectFieldErrorType = {
  [key: number]: boolean;
}

export const enum AssignmentsDropdownLabels {
  INTERNAL_USER = 'Account User (Authentication Required)',
  EXTERNAL_RECIPIENT = 'External Recipient (Normal Security)',
}

export const enum AssignmentsMainParts {
  MANAGERS = 'managers',
  RECIPIENTS = 'recipients',
}

export type AssignmentType = {
  type: string;
  label: string;
  link?: string;
  fieldsKeys?: number[];
  email?: string;
  position?: number;
  firstName?: string;
  lastName?: string;
}

export type AssignmentPositions = Record<string, number>

export type AssignmentsObjectType = {
  [AssignmentsMainParts.MANAGERS]: AssignmentType[];
  [AssignmentsMainParts.RECIPIENTS]: AssignmentType[];
}

export type AssignmentSigner = Pick<AssignmentType, 'type' | 'label'> & { position: number };

export type ShareLinksAssignmentsType = {
  origin: string;
  assignments: AssignmentType[];
  monday_com_integration?: MondayComIntegrationObjectType[];
}

export interface ISlateEditor {
  selectedField: number | null;
  selectedSection: number | null;
  isSelectFieldError: SelectFieldErrorType;
  pdfFileLink: string;
  activeEditorTab: number;
  publicFormValidationResults: IValidated;
  fieldInFocus: boolean;
  assignments: AssignmentsObjectType;
  selectedAssignment: string;
  selectedTableQuestionKey: number | null;
  activeHoveredSectionKey: number | null;
  openAssignmentsSettingsModal: boolean;
  openAssignmentDeletionConfirmationModal: boolean;
  assignmentTypeForDeletion: string | null;
}

export interface IDropdownItem {
  format: BlockFormatType | BlockTextAlignType | MarkFormatType;
  type: string;
  label?: string;
}

export interface IToolbarDropdown {
  formats: IDropdownItem[];
  setIsDropdownVisible: Dispatch<SetStateAction<boolean>>;
  dropdownType: DROPDOWN_TYPES;
}

export interface ISubtypesFromFieldsGetter {
  key: number;
  subtype: string;
  value: string;
  requiredField: boolean;
  maxLength: string | number;
  validationText: string;
}

export interface ISortIndex {
  oldIndex: number;
  newIndex: number;
}

export type FieldViewPropsChangeValueHandler = (
  event: any,
  fieldKey: number,
  trim?: boolean,
  currentTab?: SIGNATURE_TABS,
) => void;

export type RadioCheckboxUpdateEventHandler = ChangeEventHandler<HTMLInputElement>
  & MouseEventHandler<HTMLInputElement>;
export type RadioCheckboxUpdateEvent = ChangeEvent<HTMLInputElement> | MouseEvent<HTMLInputElement>;
export type SelectUpdateEventHandler = ChangeEventHandler<HTMLSelectElement> & RadioCheckboxUpdateEventHandler;
export type FormSelectUpdateEvent = SyntheticEvent | ChangeEvent<HTMLInputElement> | MouseEvent<HTMLInputElement>;
export type PDFFieldRadioCheckboxUpdateHandler = (event: RadioCheckboxUpdateEvent, field: PDFFieldType) => void;

export interface IUpdateSelectFieldOptionsHandlerProps<T = SelectFieldOptionType> {
  options: T[];
  viewMode: SelectFieldOptionsType | undefined;
  id: number;
  selectedIndex: number;
}

export interface IUpdatedSelectFieldOptions<T = SelectFieldOptionType> {
  updatedOptions: T[];
  value: string;
}

export interface IHandleTableKeyProps {
  editor: SlateEditor,
  event: KeyboardEvent,
  isDelete: boolean,
}

export type FieldType = ICustomElement | PDFFieldType;
import { FC, memo } from 'react';

import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import Logo from 'components/Intro/Logo';
import useStyleSchema, { CustomStylesEnum } from 'hooks/useStylesFromSchema';

import classes from 'scss/components/Modal/ConfirmationModal.module.scss';

interface ISendEmailWithPasswordlessLinkModal {
  isModalOpen: boolean;
  headerText: string;
  bodyText: string;
}

const SendEmailWithPasswordlessLinkModal: FC<ISendEmailWithPasswordlessLinkModal> = ({
  isModalOpen,
  headerText,
  bodyText,
}) => {
  const styleSchema = useStyleSchema<
    CustomStylesEnum.button
    | CustomStylesEnum.outlinedBorderButton
    | CustomStylesEnum.title
    | CustomStylesEnum.description
  >([
    CustomStylesEnum.button,
    CustomStylesEnum.outlinedBorderButton,
    CustomStylesEnum.title,
    CustomStylesEnum.description,
  ]);

  return (
    <Modal isOpen={isModalOpen} centered modalClassName="restore-data-modal">
      <ModalHeader>
        <span className={classes.Logo}>
          <Logo />
        </span>
        <span
          className={classes.Title}
          style={styleSchema.title}
        >
          {headerText}
        </span>
      </ModalHeader>
      <ModalBody>
        <div className={classes.Body}>
          <div
            className="text-center"
            style={styleSchema.description}
          >
            <p>{bodyText}</p>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default memo(SendEmailWithPasswordlessLinkModal);
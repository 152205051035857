import { FC, memo, useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import AttachmentLoadingModal from 'components/Modals/AttachmentLoadingModal';
import ConfirmationModal from 'components/Modals/ConfirmationModal';
import ModalProgressBar from 'components/Modals/ModalProgressBar';
import ShareLinkModal from 'components/Modals/ModalWrapper';
import PoliciesModal from 'components/Modals/Policies/PoliciesModal';
import VerifyUniqueCodeModal from 'components/Modals/SMSValidation/VerifyUniqueCodeModal';
import IntroduceUserForm from 'components/PublicPage/IntroduceUserForm';
import MondayComSelectExistingRecordForSubitemModal from 'components/PublicPage/MondayComSelectExistingRecordForSubitemModal';
import PIIUserDataModal from 'components/PublicPage/PIIUserDataModal';
import PublicPage from 'components/PublicPage/PublicPage';
import RememberPIIEmailHasBeenSentModal from 'components/PublicPage/RememberPIIEmailHasBeenSentModal';
import RememberPIIUserDataModal from 'components/PublicPage/RememberPIIUserDataModal';
import RestoreDataModal from 'components/PublicPage/RestoreDataModal';
import { EXECUTED_DOCUMENTS_ROUTES, MESSAGE_AFTER_SUBMIT_FORM } from 'constants/general';
import { EMAIL_VALIDATION_HEADER, EMAIL_VALIDATION_MODAL_TEXT } from 'constants/mondayComIntegration';
import { DISABLE_GET_STARTED_POP_UP_KEY, PROVIDE_REFERENCE_NUMBER } from 'constants/pageSettings';
import { PUBLIC_PAGE_STATUS } from 'constants/publicPage';
import { ShareViewModes } from 'constants/shareViewModes';
import useAutoUpdateUnfinishedDocument from 'hooks/AutoUpdateUnfinishedDocument/useAutoUpdateUnfinishedDocument';
import useAutoFillFields from 'hooks/useAutoFillFields';
import useBodyOverflow from 'hooks/useBodyOverflow';
import useRedirectToPublicPage from 'hooks/useRedirectToPublicPage';
import { useRolePermission } from 'hooks/useRolePermission';
import useSaveDocumentHandler from 'hooks/useSaveDocumentHandler';
import { clearPdfTemplateData } from 'store/actions/pdfTemplates';
import {
  setHistoryLogCurrentDocument,
  updatePublicPageData,
  updatePublicPageDocuments,
  updatePublicPagePIIDataInAllDocuments,
} from 'store/actions/publicPages';
import { RootStateType } from 'store/reducers';
import { HISTORY_ACTIONS } from 'types/DocumentsHistory';
import { PageSettingsType } from 'types/PageSettingsType';
import { IPublicPageDocumentStructure, IPublicPageState } from 'types/PublicPage';
import { trackAmplitudeEvent } from 'utils/amplitude/amplitudeTrackingUtlis';
import { getAmplitudeEventName } from 'utils/amplitude/amplitudeUtils';
import { COLLECTION_WAS_VIEWED_BY_RECIPIENT } from 'utils/amplitude/amplituteConstants';
import { getUTCDate, saveHistoryRecord } from 'utils/documentsHistory';
import { isEmptyObject } from 'utils/isEmptyObject';
import { getPageSettingByKey, getPageSettings } from 'utils/PageSettings';
import { getIsCollectionFormBuilder } from 'utils/PublicPage/documentTypeChecker';
import { isCollectionExecutedType, isCollectionType } from 'utils/typeChecker';

const PublicDocument: FC = () => {
  const dispatch = useDispatch();
  useBodyOverflow('auto');
  const redirectToGreetingPage = useRedirectToPublicPage(null, '');
  const {
    data,
    currentDocument,
    structure,
    documentUUID,
  }: IPublicPageState = useSelector((state: RootStateType) => state.publicPages);

  const isAuthenticated: boolean = useSelector((state: RootStateType) => state.user.isAuthenticated);
  const pageSettings: PageSettingsType | null = useSelector((state: RootStateType) => getPageSettings(state));
  const isDisabledGetStartedPopup = Boolean(getPageSettingByKey(pageSettings, DISABLE_GET_STARTED_POP_UP_KEY));
  const provideReferenceNumber = Boolean(getPageSettingByKey(pageSettings, PROVIDE_REFERENCE_NUMBER));

  const { permissionUsingRememberFormProgress } = useRolePermission();
  const {
    isReadOnlyDocument,
    isShowConfirmationModal,
    setIsShowConfirmationModal,
    isDisabledModalButton,
    documentsCount,
    saveDocumentHandler,
    isLoaderVisible,
    onFinishRedirectHandler,
    shouldShowNoDataEnteredModalWindow,
    showNoDataEnteredModal,
    onConfirmNoDataEnteredModalWindow,
    onCancelNoDataEnteredModalWindow,
    content,
    setContent,
  } = useSaveDocumentHandler();

  useAutoUpdateUnfinishedDocument(
    content,
    saveDocumentHandler,
    documentUUID,
    isDisabledModalButton,
  );

  const isPossibleToUseRememberFormProgress = (data && data.remember_form_progress)
    || permissionUsingRememberFormProgress;

  const [showShareLinkModal, setShowShareLinkModal] = useState<boolean>(false);
  const [attachmentsCount] = useState<number>(0);

  const isExecutedDocument = EXECUTED_DOCUMENTS_ROUTES.includes(structure.main.pageType);

  const autoFillPIIFieldsInContentHandler = useAutoFillFields({
    contentData: content,
    updateContent: setContent,
  });

  useEffect(() => {
    if (isExecutedDocument && currentDocument?.id) {
      saveHistoryRecord(HISTORY_ACTIONS.VIEWED, currentDocument?.id);
    } else {
      dispatch(setHistoryLogCurrentDocument({ target: HISTORY_ACTIONS.VIEWED, value: getUTCDate() }));
    }
  }, [currentDocument?.id, isExecutedDocument]);

  useEffect(() => {
    if (!data) return;

    trackAmplitudeEvent({
      eventName: isCollectionType(data)
        ? COLLECTION_WAS_VIEWED_BY_RECIPIENT
        : getAmplitudeEventName({ data }).viewedByRecipient,
    });
    return () => {
      dispatch(clearPdfTemplateData());
    };
  }, [data]);

  useEffect(() => {
    if (isEmptyObject(data)) {
      redirectToGreetingPage();
    }
  }, [data, redirectToGreetingPage]);

  const finishUploadingHandler = () => {
    setShowShareLinkModal(true);
  };

  const saveDocument = useCallback((status: PUBLIC_PAGE_STATUS, redirect: boolean) => {
    if (!currentDocument) return;
    const updatedDoc: IPublicPageDocumentStructure = {
      ...currentDocument,
      status,
    };
    dispatch(updatePublicPageDocuments(updatedDoc));
    dispatch(updatePublicPageData(content, currentDocument.id));
    if ((isCollectionType(data) && !getIsCollectionFormBuilder(data)) || (isCollectionExecutedType(data))) {
      dispatch(updatePublicPagePIIDataInAllDocuments());
    }

    if ((status === PUBLIC_PAGE_STATUS.DONE && documentsCount === 1) && !isReadOnlyDocument) {
      if (shouldShowNoDataEnteredModalWindow(content)) {
        return;
      }
      setIsShowConfirmationModal(true);
      return;
    }

    if (redirect) {
      redirectToGreetingPage();
    }
  }, [content, currentDocument, data, dispatch, redirectToGreetingPage, documentsCount]);

  const saveDocumentCallback = useCallback((status: PUBLIC_PAGE_STATUS, redirect = true) => {
    saveDocument(status, redirect);
  }, [saveDocument]);

  if (!currentDocument) return null;

  return (
    <PublicPage
      content={content}
      setContent={setContent}
      onSave={saveDocumentCallback}
    >
      {
        attachmentsCount && !showShareLinkModal
          ? <ModalProgressBar generalCount={attachmentsCount} onFinish={finishUploadingHandler} />
          : null
      }
      <ShareLinkModal
        isSubmitForm
        result={MESSAGE_AFTER_SUBMIT_FORM}
        showModal={showShareLinkModal}
        onCloseModal={setShowShareLinkModal}
      />
      {
        ![
          ShareViewModes.SHARE_MODE_FORM,
          ShareViewModes.SHARE_MODE_FORM_BUILDER,
        ].includes(data?.view_mode ?? ShareViewModes.SHARE_MODE_BOTH) && (
          <PoliciesModal />
        )
      }
      {
        !isDisabledGetStartedPopup && (
          <IntroduceUserForm autoFillPIIFieldsInContentHandler={autoFillPIIFieldsInContentHandler} />
        )
      }
      <PIIUserDataModal autoFillPIIFieldsInContentHandler={autoFillPIIFieldsInContentHandler} />
      <RememberPIIUserDataModal />
      <RememberPIIEmailHasBeenSentModal />
      <MondayComSelectExistingRecordForSubitemModal
        offerToSelectApplicants={false}
        headerText={EMAIL_VALIDATION_HEADER}
        bodyText={EMAIL_VALIDATION_MODAL_TEXT}
      />
      <VerifyUniqueCodeModal />
      {isAuthenticated && isPossibleToUseRememberFormProgress && (<RestoreDataModal />)}
      {
        !isReadOnlyDocument && (
          <ConfirmationModal
            withLogo
            withBranding
            showSpinnerIfDisabled
            isOpen={isShowConfirmationModal}
            isDisabled={isDisabledModalButton}
            onCancel={() => setIsShowConfirmationModal(false)}
            onConfirm={isReadOnlyDocument ? redirectToGreetingPage : saveDocumentHandler}
            cancelButtonText="Go Back to Editing"
            confirmButtonText="I agree"
            description={(
              <>
                <p className="mb-1">
                  The document is ready for submission.
                </p>
                <p>
                  I agree to sign this document electronically and agree to the <PoliciesModal showInline />.
                </p>
                {
                  provideReferenceNumber && documentUUID && (
                    <p>
                      Please keep this Reference Number for your Records: {documentUUID}
                    </p>
                  )
                }
              </>
            )}
          />
        )
      }
      {
        !isReadOnlyDocument && (
          <ConfirmationModal
            withLogo
            withBranding
            isOpen={showNoDataEnteredModal}
            onCancel={onCancelNoDataEnteredModalWindow}
            onConfirm={onConfirmNoDataEnteredModalWindow}
            cancelButtonText="No"
            confirmButtonText="Yes"
            description={(
              <p className="mb-1">
                Are you sure you want to submit this document? You have not entered data into any field.
              </p>
            )}
          />
        )
      }
      <AttachmentLoadingModal
        isVisible={isLoaderVisible}
        onFinish={onFinishRedirectHandler}
      />
    </PublicPage>
  );
};

export default memo(PublicDocument);
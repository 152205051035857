import { EMAIL_SUBJECT, SharingResources } from 'constants/sendDocuments';
import { ShareViewModes } from 'constants/shareViewModes';
import { SignerFlow } from 'constants/signNow';
import {
  CLEAR_STORE,
  SET_CARBON_COPY_USERS_INFO,
  SET_EMAIL_INFO,
  SET_EXECUTED_DOC_ID,
  SET_LINK_VIEW_MODE,
  SET_ONLY_COLLECTION,
  SET_SEND_DOCUMENTS,
  SET_SIGNER_FLOW,
  SET_SIGNERS,
  SET_UPDATED_SIGNERS,
} from 'store/actions/actionTypes';
import { Action } from 'types/redux';
import { SendDocumentsReducerType } from 'types/SendDocuments';

const initialState: SendDocumentsReducerType = {
  initialLocation: null,
  documentsInfo: [],
  signersInfo: [],
  updatedSignersInfo: null,
  emailSubject: EMAIL_SUBJECT,
  emailMessage: '',
  resourceType: SharingResources.DOCUMENT,
  onlyCollection: null,
  executedDocId: '',
  linkViewMode: ShareViewModes.SHARE_MODE_BOTH,
  isDocumentCompleted: false,
  carbonCopyUsersInfo: [],
  signingOrderFlow: SignerFlow.OrderedSigning,
  isExecutedCollection: false,
};

// Eslint error - Default parameters should be last, it's impossible to fix it quickly
// eslint-disable-next-line
const sendDocuments = (state: SendDocumentsReducerType = initialState, { type, payload }: Action): SendDocumentsReducerType => {
  switch (type) {
    case CLEAR_STORE:
      return initialState;
    case SET_SEND_DOCUMENTS:
      return {
        ...state,
        documentsInfo: payload.documentsInfo,
        initialLocation: payload.initialLocation,
        resourceType: payload.resourceType,
        isDocumentCompleted: payload.isDocumentCompleted,
        isExecutedCollection: payload.isExecutedCollection,
      };
    case SET_ONLY_COLLECTION:
      return {
        ...state,
        onlyCollection: payload,
      };
    case SET_SIGNERS:
      return {
        ...state,
        signersInfo: payload,
      };
    case SET_UPDATED_SIGNERS:
      return {
        ...state,
        updatedSignersInfo: payload,
      };
    case SET_EMAIL_INFO:
      return {
        ...state,
        [payload.target]: payload.value,
      };
    case SET_EXECUTED_DOC_ID:
      return {
        ...state,
        executedDocId: payload,
      };
    case SET_LINK_VIEW_MODE:
      return {
        ...state,
        linkViewMode: payload,
      };
    case SET_CARBON_COPY_USERS_INFO:
      return {
        ...state,
        carbonCopyUsersInfo: payload,
      };
    case SET_SIGNER_FLOW:
      return {
        ...state,
        signingOrderFlow: payload,
      };
    default:
      return state;
  }
};

export default sendDocuments;
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';

import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import Button from 'components/Base/Button';
import DescriptionEditor from 'components/Editor/FormView/DescriptionEditor';
import { SAVE } from 'constants/general';

interface IDocumentDescription {
  showDescriptionModal: boolean;
  setShowDescriptionModal: Dispatch<SetStateAction<boolean>>;
  description?: string;
  setDescription?: Dispatch<SetStateAction<string>> | ((description: string) => void);
  readOnlyMode?: boolean;
}

const DocumentDescriptionModal: FC<IDocumentDescription> = ({
  showDescriptionModal,
  setShowDescriptionModal,
  description = '',
  setDescription = () => null,
  readOnlyMode = false,
}) => {
  const [localDescription, setLocalDescription] = useState<string>(description);

  useEffect(() => {
    setLocalDescription(description);
  }, [description]);

  const handlerSaveButton = () => {
    setDescription(localDescription);
    setShowDescriptionModal(false);
  };

  return (
    <Modal centered isOpen={showDescriptionModal}>
      <ModalHeader className="modal-header border-0" toggle={() => setShowDescriptionModal(false)}>
        <div className="description-title text-center">
          Description
        </div>
      </ModalHeader>

      <div className="description-title-info mb-1">
        This appears as a separate page at the very beginning of your document
      </div>
      <ModalBody className="p-0">
        <DescriptionEditor
          isReadOnly={readOnlyMode}
          onChange={setLocalDescription}
          value={localDescription}
        />
      </ModalBody>

      <ModalFooter className="modal-footer border-0">
        {
          !readOnlyMode
            ? (
              <Button
                classes="button-contained-pink"
                onClick={handlerSaveButton}
              >
                {SAVE}
              </Button>
            )
            : null
        }
      </ModalFooter>
    </Modal>
  );
};

export default DocumentDescriptionModal;
export const QueryParameters = {
  TAB: 'tab',
  NAME: 'name',
  CATEGORY: 'category',
  CREATOR: 'creator',
  UPDATER: 'updater',
  TYPE: 'type',
  CREATED: 'created',
  UPDATED: 'updated',
  RECIPIENT_EMAIL: 'recipient_email',
  DOCUMENT_EXECUTED_UUID: 'document_executed_uuid',
  STATUS: 'status',
  RECIPIENT_NAME: 'recipient_name',
  TEMPLATE_NAME: 'template_name',
  ROWS_PER_PAGE: 'rows_per_page',
  CURRENT_PAGE: 'current_page',
} as const;

export default undefined;
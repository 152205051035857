import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import { Element as SlateElement } from 'slate';

import { ATTACHMENT_FIELD, CHECKBOX_FIELD, SELECT_FIELD, TEXT_FIELD } from 'constants/editors';
import { FIELD_MASK_CUSTOM_TEXT_DEFAULT, TEXT_FIELD_MASKS } from 'constants/fieldPropertiesTab';
import { transformValueToHiddenValue } from 'hooks/useFieldMaskValue';
import { getFilesFullLinks } from 'services/api';
import { apiErrorHandler } from 'services/apiErrorHandler';
import { RootStateType } from 'store/reducers';
import { createFieldName } from 'utils/editorFieldHelpers';
import { fileLinkDownloadHelper } from 'utils/fileLinkDownload';
import { getIsPDFDocument } from 'utils/PublicPage/documentTypeChecker';
import getFieldsFromData from 'utils/PublicPage/getFieldsFromData';

export type CSVHeaderType = { label: string, key: string };
export type CSVDataType = { [key: string]: string };

type UseCSVDataResponse = {
  headers: CSVHeaderType[];
  data: CSVDataType[];
  attachments: string[];
  downloadAttachmentsZIP: (attachmentsList: string[], archiveName?: string) => Promise<void>;
  getNameForGeneratedFiles: () => void;
}

const useCSVData = (): UseCSVDataResponse => {
  const { documentDetails } = useSelector((state: RootStateType) => state.user);
  const [headers, setHeaders] = useState<CSVHeaderType[]>([]);
  const [data, setData] = useState<CSVDataType[]>([]);
  const [attachments, setAttachments] = useState<string[]>([]);

  const getFieldValue = (field: Partial<SlateElement>): string | boolean | undefined => {
    switch (field.type) {
      case CHECKBOX_FIELD:
        return field.checked;
      case SELECT_FIELD:
        if (field.selectFieldType && field.selectFieldType === 'checkbox') {
          const firstCheckedOption = field.options?.find((option) => option.checked);
          if (firstCheckedOption) {
            return firstCheckedOption.label;
          }
          return field.value;
        }
        return field.value;
      case TEXT_FIELD:
        if (field.textFieldMask) {
          switch (field.textFieldMask) {
            case TEXT_FIELD_MASKS.LAST_FOUR:
              return transformValueToHiddenValue(field.value ?? '', 4);
            case TEXT_FIELD_MASKS.LAST_SIX:
              return transformValueToHiddenValue(field.value ?? '', 6);
            case TEXT_FIELD_MASKS.CUSTOM_TEXT:
              return field.textFieldMaskCustomText ?? FIELD_MASK_CUSTOM_TEXT_DEFAULT;
            case TEXT_FIELD_MASKS.HIDDEN:
              return '';
            default:
              return field.value;
          }
        } else {
          return field.value;
        }
      default:
        return field.value;
    }
  };

  useEffect(() => {
    if (documentDetails) {
      const isPDFType = getIsPDFDocument(documentDetails.type);
      const fields = getFieldsFromData({ data: documentDetails, isPDF: isPDFType });
      const attachmentsArray: string[] = [];
      const headersArray: CSVHeaderType[] = [];
      let dataArray: CSVDataType = {};
      if (fields.length > 0) {
        fields.forEach((item: any) => {
          if (item.type !== ATTACHMENT_FIELD) {
            const fieldName = isPDFType ? item.fieldName : createFieldName(item.children);
            const existingHeaderIndex = headersArray.findIndex((header) => header.label === fieldName);
            if (existingHeaderIndex >= 0) {
              headersArray[existingHeaderIndex].key = String(item.key);
            } else {
              headersArray.push({ label: fieldName, key: String(item.key) });
            }
            dataArray = {
              ...dataArray,
              [String(item.key)]: getFieldValue(item),
            } as CSVDataType;
          } else {
            attachmentsArray.push(
              ...(item.value ? item.value.split(',') : []),
            );
          }
        });
        setHeaders(headersArray);
        setData([dataArray]);
        setAttachments(attachmentsArray);
      }
    }
  }, [documentDetails]);

  const getFileNameFromLink = (fileLink: string) => {
    const fileName = fileLink.split('/').pop() || 'default';
    return fileName.split('?')[0];
  };

  const getNameForGeneratedFiles = () => (
    documentDetails ? documentDetails.name.replaceAll(' ', '_') : 'documents'
  );

  const downloadAttachmentsAndCreateZipArchive = (
    zip: JSZip,
    filesLinks: string[],
    archiveName?: string,
    index: number = 0,
  ) => {
    JSZipUtils.getBinaryContent(filesLinks[index], (err: Error, data: ArrayBuffer | string) => {
      const fileName = getFileNameFromLink(filesLinks[index]);
      if (err) {
        toast.error(`Downloading file ${fileName} was failed.`);
      } else {
        zip.file(fileName, data, { binary: true });
        if (index === filesLinks.length - 1) {
          zip.generateAsync({ type: 'blob' }).then((content) => {
            saveAs(
              content,
              archiveName ?? `Attachments_in_${getNameForGeneratedFiles()}.zip`,
            );
          });
        } else {
          downloadAttachmentsAndCreateZipArchive(zip, filesLinks, archiveName, index + 1);
        }
      }
    });
  };

  const downloadAttachmentsZIP = async (
    attachmentsList: string[],
    archiveName?: string,
  ) => {
    if (attachmentsList.length !== 0 && attachmentsList.some((item) => item.length)) {
      const filesLinksResponse: any = await apiErrorHandler(getFilesFullLinks, attachmentsList);
      if (filesLinksResponse.data) {
        const filesLinks = filesLinksResponse.data;
        if (filesLinks.length === 1) {
          fileLinkDownloadHelper(filesLinks);
        } else {
          const zip = new JSZip();
          toast.info('Creating a zip archive with attachments will take a few seconds, please wait...');
          downloadAttachmentsAndCreateZipArchive(zip, filesLinks, archiveName);
        }
      }
    }
  };

  return { headers, data, attachments, downloadAttachmentsZIP, getNameForGeneratedFiles };
};

export default useCSVData;
import { useSelector } from 'react-redux';

import { RootStateType } from 'store/reducers';

const useFirstLogin = () => {
  const firstLogin = useSelector((state: RootStateType) => state.profile.firstLogin);
  const {
    stripe_paid_link: stripePaidLink,
    trial_days_left: stripeTrialDaysLeft,
  } = useSelector((state: RootStateType) => state.payment);

  return {
    isFirstLogin: firstLogin,
    stripePaidLink,
    stripeTrialDaysLeft,
  };
};

export default useFirstLogin;
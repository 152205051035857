import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

const usePaymentNotifications = () => {
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const cancel = searchParams.get('cancel');
    const success = searchParams.get('success');
    const paymentMethodSuccess = searchParams.get('payment-method-success');
    const paymentMethodCancel = searchParams.get('payment-method-cancel');

    if (success) {
      toast.success('Hooray! The payment is successful!');
    }
    if (cancel) {
      toast.warning('The payment session is canceled.');
    }
    if (paymentMethodSuccess) {
      toast.success('Hooray! The payment method was saved!');
    }
    if (paymentMethodCancel) {
      toast.warning('The payment method saving was canceled!');
    }
  }, [location]);
};

export default usePaymentNotifications;
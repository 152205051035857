import { FC } from 'react';

import SignersContainer from 'components/SendDocuments/SignersContainer';
import ButtonAddOutline from 'components/Sidebar/ButtonAddOutline';
import DocumentThumbnail from 'components/SignNow/DocumentThumbnail';
import SignerFlowComponent from 'components/SignNow/SignerFlow';
import { SIGNER_FLOW_CONFIG, SignerFlow } from 'constants/signNow';
import { useSignerFlow } from 'hooks/signNow';
import { IStepManagerChildComponentProps } from 'types/signNow/base';

const SelectSigners: FC<IStepManagerChildComponentProps> = ({
  onNextHandler,
}) => {
  const {
    isBulkSend,
    signers,
    setSigners,
    addSignerHandler,
    currentSignerFlow,
    onChangeCurrentSignFlow,
  } = useSignerFlow({
    config: SIGNER_FLOW_CONFIG,
    defaultItem: SignerFlow.NoOrder,
    onNextHandler,
  });

  return (
    <div className="step-manager-child">
      <div className="select-signers">
        <div className="d-flex justify-content-between align-items-end flex-wrap gap-4">
          {
            !isBulkSend
              ? (
                <SignerFlowComponent
                  config={SIGNER_FLOW_CONFIG}
                  onChange={onChangeCurrentSignFlow}
                  currentSignerFlow={currentSignerFlow}
                />
              )
              : (<div />)
          }
          <DocumentThumbnail />
        </div>
        <SignersContainer
          canDeleteCard={currentSignerFlow !== SignerFlow.JustMe}
          isDisabled={currentSignerFlow === SignerFlow.JustMe}
          isOrderVisible={currentSignerFlow === SignerFlow.OrderedSigning}
          isSignNow
          signersArray={signers}
          setSignersArray={setSigners}
        />
        {
          currentSignerFlow !== SignerFlow.JustMe && !isBulkSend && (
            <ButtonAddOutline onClick={addSignerHandler} title="Add signers" />
          )
        }
      </div>
    </div>
  );
};

export default SelectSigners;
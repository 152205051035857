import { FC } from 'react';

import ReviewAndSend from 'components/SendDocuments/ReviewAndSend';
import SelectDocumentSigners from 'components/SendDocuments/SelectDocumentSigners';
import SendDocumentsFinal from 'components/SendDocuments/SendDocumentsFinal';
import { IInnerComponentsWrapper, INNER_PAGES } from 'types/SendDocuments';

const InnerComponentsWrapper: FC<IInnerComponentsWrapper> = ({
  innerPage,
  signersArray,
  setSignersArray,
}) => {
  switch (innerPage) {
    case INNER_PAGES.FIRST_PAGE:
      return (
        <SelectDocumentSigners
          signersArray={signersArray}
          setSignersArray={setSignersArray}
        />
      );
    case INNER_PAGES.SECOND_PAGE:
      return <ReviewAndSend />;
    case INNER_PAGES.THIRD_PAGE:
      return <SendDocumentsFinal />;
    default:
      return null;
  }
};

export default InnerComponentsWrapper;
import {
  ChangeEvent,
  ChangeEventHandler,
  FC,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Input as ReactstrapInput } from 'reactstrap';

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import {
  Autocomplete,
  Checkbox,
  FormHelperText,
  InputAdornment,
  SxProps,
  TextField,
  TextFieldProps,
  Theme,
} from '@mui/material';
import { useSlate as getSlateEditor } from 'slate-react';

import AttachmentDropzone from 'components/AttachmentDropzone';
import DateCalendar from 'components/Base/DateCalendar';
import OtherOptionElement from 'components/Editor/components/Fields/OtherOptionElement';
import FieldName from 'components/Editor/FormView/Fields/FieldName';
import FormInputField from 'components/Editor/FormView/PublicFields/FormInputField';
import ReadableParagraph from 'components/FormConstructor/Elements/ReadOnlyParagraph';
import ShowSignatureValue from 'components/Modals/SignatureModal/ShowSignatureValue';
import SigningModal from 'components/Modals/SignatureModal/SigningModal';
import {
  ATTACHMENT_FIELD,
  DATE_FIELD,
  FORM_BUILDER_PARAGRAPH,
  MAX_FIELD_VALUE_LENGTH,
  PUBLIC_FORM_MAX_WIDTH,
  QUESTION_ANSWER_SELECT,
  QUESTION_ANSWER_STRING,
  QUESTION_FIELD,
  SELECT_FIELD,
  SIGNING_FIELD,
  TEXT_FIELD,
  TEXTAREA_FIELD,
} from 'constants/editors';
import { TEXT_FIELD_MASKS } from 'constants/fieldPropertiesTab';
import { FORM_TYPE, TABLET_SCREEN_WIDTH } from 'constants/general';
import { SIGNATURE_FIELD_DEFAULT_VARIATION, SIGNATURE_TABS } from 'constants/signatures';
import { FORM_TAB_ID } from 'constants/tabs';
import useDefaultSignatureUnauthenticatedUser, {
  RETURNED_SIGNATURE_TAB,
  RETURNED_SIGNATURE_TYPE,
  RETURNED_SIGNATURE_VALUE,
} from 'hooks/useDefaultSignatureUnauthenticatedUser';
import useScreenSize from 'hooks/useScreenSize';
import useStyleSchema, { CustomStylesEnum } from 'hooks/useStylesFromSchema';
import useTextWidth from 'hooks/useTextWidth';
import useTrackingLogEvents from 'hooks/useTrackingLogEvents';
import { changeFieldInFocusStatus, setPublicFieldValidation } from 'store/actions/editorSlate';
import {
  updateFormBuilderField,
  updatePublicPageIntroCurrentAttachmentField,
} from 'store/actions/publicPages';
import { RootStateType } from 'store/reducers';
import SignatureIcon from 'svg/SignatureIcon';
import { CssStyleObjectType } from 'types/BrandingColorSchema';
import {
  ICustomElement,
  RadioCheckboxUpdateEventHandler,
  SelectFieldOptionsType,
  SelectFieldOptionType,
} from 'types/Editor';
import { PDFFieldType } from 'types/PdfTemplates';
import { IPublicPageState } from 'types/PublicPage';
import { IField } from 'types/validation';
import { createNameConstant } from 'utils/createNameConstant';
import {
  getCurrentDate,
  getPublicSelectedFieldClass,
  isFindField,
  setSelectedFieldState,
  updateSelectFieldOptionsHandler,
} from 'utils/editorFieldHelpers';
import { getDefaultDateMask } from 'utils/editorHelpers';
import { getSelectValuesToUpdate } from 'utils/editorSelectFieldHelpers';
import {
  getCheckboxPrintClass,
  getInputTypeByViewMode,
  getIsRadioOrCheckbox,
} from 'utils/Fields/checkboxRadioHelpers';
import { onFieldCustomKeyDown, onSelectFieldKeyDown } from 'utils/hotkeysHelpers';
import { isCheckboxFieldType } from 'utils/pdfTemplatesHelpers';
import getFieldName from 'utils/PublicPage/getFieldName';
import { isTopLevelTextNode } from 'utils/slateEditorUtils';
import { getSignatureValueAndActiveTab } from 'utils/userPersonalData/personalDataHelpers';
import { validateFormField } from 'utils/validation';

import 'scss/components/Dropzone/dropzone.scss';
import styled from '@emotion/styled';
import vars from 'variables.module.scss';

interface IStyledTextField {
  style?: CssStyleObjectType;
}

export const StyledTextField = styled(TextField)<IStyledTextField>`
  label {
    font-family: ${vars.mainFont};
    color: ${vars.blackColor};
  }
  label.Mui-focused {
    color: ${(props) => props?.style?.labelColor || vars.brandPrimaryFocused};
    border-width: 1px;
    font-family: ${vars.mainFont};
    font-weight: ${vars.normalText};
  }
  .MuiOutlinedInput-root.Mui-focused fieldset {
    border-color: ${(props) => props.style?.labelColor || vars.brandPrimaryFocused};
    border-width: 1px;
  }
`;

export interface IFieldViewProps {
  field: Partial<ICustomElement>;
  options?: SelectFieldOptionType[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChangeValue: (
    field: Partial<ICustomElement | PDFFieldType>,
    updatedFieldData: Partial<ICustomElement | PDFFieldType>
  ) => void;
  readOnlyMode: boolean;
  validateField?: boolean;
  validationMessage?: string;
  isPdfFormField?: boolean;
  isFormsView?: boolean;
  isFormBuilder?: boolean;
  idsToSelect?: number[];
}

const PublicFieldView: FC<IFieldViewProps> = ({
  field,
  options,
  onChangeValue,
  readOnlyMode,
  validateField = false,
  validationMessage = '',
  isPdfFormField = false,
  isFormBuilder = false,
  idsToSelect = [],
}) => {
  /**
   * Component is used for render form fields on public pages: form tab on Slate and PDF documents, Form documents.
   */
  const dispatch = useDispatch();
  const editor = !isPdfFormField ? getSlateEditor() : undefined;
  const fieldName = getFieldName(field, isPdfFormField);
  const helpText = (validateField && field.helpText) || '';
  const isRequired = (validateField && field.requiredField) || false;
  const isCheckboxField = isCheckboxFieldType(field.type);

  const callSigningTrackingEvents = useTrackingLogEvents(Number(field.key), field.assignment);
  const {
    unauthenticatedPublicUser,
    saveSignatureDataToSessionStorage,
    getSignatureDataFromSessionStorage,
  } = useDefaultSignatureUnauthenticatedUser();

  const {
    save_signature: saveSignature,
    signature_path: signaturePath,
  } = useSelector((state: RootStateType) => state.profile?.personalData ?? {});
  const { currentDocument }: IPublicPageState = useSelector((state: RootStateType) => state.publicPages);
  const { selectedField, fieldInFocus } = useSelector((state: RootStateType) => state.editorSlate);
  const { selectedFieldKey } = useSelector((state: RootStateType) => state.pdfTemplates);
  const styleSchema = useStyleSchema<CustomStylesEnum.input>([CustomStylesEnum.input]);

  const [showSigningModal, setShowSigningModal] = useState<boolean>(false);
  // It seems it doesn't work because of rerenders.
  const [isFieldFirstClick, setIsFieldFirstClick] = useState<boolean>(true);
  const [signatureTab, setSignatureTab] = useState<SIGNATURE_TABS>(field.signatureTab ?? SIGNATURE_TABS.DRAW_TAB);

  const [fieldValue, setFieldValue] = useState<string | SelectFieldOptionType>('');
  const [otherOptionValue, setOtherOptionValue] = useState<string>('');
  const [isFieldChecked, setIsFieldChecked] = useState<boolean>(false);
  const labelRef = useRef(null);

  const [screenWidth, screenHeight] = useScreenSize();

  const [fieldValidation, setFieldValidation] = useState<{
    isError: boolean,
    validationMessage: string,
  }>({
    isError: false,
    validationMessage: '',
  });

  const scrollElementIntoView = (element: Element, behavior?: ScrollBehavior) => {
    const MAX_SCREEN_WIDTH_FOR_FOCUS_MODE: number = 1024;
    if (screenWidth <= MAX_SCREEN_WIDTH_FOR_FOCUS_MODE && screenHeight <= TABLET_SCREEN_WIDTH) {
      const wrapper = document.querySelector('[data-scroll-wrapper]');
      const scrollTop = wrapper?.scrollTop || 0;
      const topPadding = fieldInFocus ? 80 : 200;
      const finalOffset = element.getBoundingClientRect().top + scrollTop - topPadding;

      wrapper?.scrollTo({
        top: finalOffset,
        behavior: behavior || 'auto',
      });
    }
  };

  const fieldFocusHandler = (): void => {
    dispatch(changeFieldInFocusStatus(true));
    if (labelRef.current) scrollElementIntoView(labelRef.current, 'smooth');
  };

  const showErrorMessage = (validateField && validationMessage.length > 0) || false;
  const isError = showErrorMessage || fieldValidation.isError;
  const errorMessage = validationMessage || fieldValidation.validationMessage;
  const errorIcon = isError
    ? <InputAdornment position="end"><ErrorOutlineOutlinedIcon color="error" /></InputAdornment>
    : null;
  const fieldNameWidth: number = useTextWidth({ text: fieldName, font: `${vars.mainFontSize} ${vars.mainFont}` });
  const shouldShowFieldnameAboveField: boolean = fieldNameWidth > PUBLIC_FORM_MAX_WIDTH || fieldNameWidth > screenWidth;
  const borderFieldStyle: SxProps<Theme> | null = shouldShowFieldnameAboveField ? {
    '& .MuiOutlinedInput-notchedOutline legend': {
      display: 'none',
    },
  } : null;

  const getTextFieldLabel = (isQuestion: boolean): string => {
    if (isQuestion) {
      return field.shortName && shouldShowFieldnameAboveField ? '' : (field.shortName || 'Answer');
    }

    return shouldShowFieldnameAboveField ? '' : fieldName;
  };

  useEffect(() => {
    const tabToShow = field.signatureTab ?? SIGNATURE_TABS.DRAW_TAB;
    if (tabToShow !== signatureTab) {
      setSignatureTab(tabToShow);
    }
  }, [field.signatureTab]);

  /**
   * Updating date to current in the date field. Changing 'isTodaysDate' to false for each field after that.
   * Works only on public page and for form builder.
   */
  useEffect(() => {
    if (!readOnlyMode && isFormBuilder && field?.isTodaysDate) {
      const currentDate = getCurrentDate();
      dispatch(updateFormBuilderField([field.key || 0], { value: currentDate }, { isTodaysDate: false }));
      onChangeValue(field, { value: currentDate });
      // TODO: Update form builder fields
    }
  }, [field.isTodaysDate]);

  useEffect(() => {
    if (field.value !== undefined && field.value !== fieldValue) {
      setFieldValue(field.value);
    }
    if (field.selectedOtherOption && otherOptionValue !== field.value) {
      setOtherOptionValue(field.value ?? '');
    }
  }, [field.value, field.selectedOtherOption]);

  // This useEffect works only for slate documents, not for form builder.
  useEffect(() => {
    if (validateField && field.isTodaysDate && editor && editor.children.length && !field.value) {
      const todayValue = getCurrentDate();
      setFieldValue(todayValue);
      editor.findNodesAndUpdate((node) => {
        if (!isFindField(node, field.key)) {
          return node;
        }
        const updatedIsTodaysDate = {
          ...field,
          isTodaysDate: false,
          value: todayValue,
        };
        return { ...node, ...updatedIsTodaysDate };
      });
      // TODO: Update form grouped fields
    }
  }, [editor, field, field.key, validateField]);

  useEffect(() => {
    if (
      isCheckboxField
      && field.checked !== isFieldChecked
    ) {
      setIsFieldChecked(field.checked ?? false);
    }
  }, [field.checked]);

  const fieldValidationHandler = ({
    event,
    newValue = '',
    updatedProperties,
  }: {
    event?: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | null | undefined | Date,
    updatedProperties?: Partial<ICustomElement>,
  }): void => {
    if ((field.requiredField || field.subtype) && validateField) {
      const fieldValidationInfo: string = validateFormField({
        ...field,
        value: event ? event.target.value : newValue,
        ...(updatedProperties && { ...updatedProperties }),
      } as IField);

      dispatch(setPublicFieldValidation({
        fieldKey: field.key || 0,
        validationMessage: fieldValidationInfo,
      }));

      setFieldValidation({
        isError: Boolean(fieldValidationInfo),
        validationMessage: fieldValidationInfo,
      });
    }
  };

  const changeTextValueHandler = (value: string) => {
    let newValue = value;
    if (field.maxLength !== undefined) {
      const maxLength: number = typeof field.maxLength === 'number' ? field.maxLength : MAX_FIELD_VALUE_LENGTH;
      newValue = (newValue).slice(0, maxLength);
    }
    onChangeValue(field, { value: newValue });

    if (newValue !== fieldValue) {
      setFieldValue(newValue);
    }
  };

  const handleSignatureSave = (base64String: string, currentTab: SIGNATURE_TABS) => {
    if (unauthenticatedPublicUser) {
      saveSignatureDataToSessionStorage(base64String, currentTab, field.signatureFieldVariation);
    }
    setSignatureTab(currentTab);
    onChangeValue(field, { value: base64String, signatureTab: currentTab });
  };

  const handleSelectChange = (_: SyntheticEvent, newValue: string | null) => {
    const currentOption = options?.find((option) => option.label === newValue);
    fieldValidationHandler({ newValue });
    setOtherOptionValue('');
    if (currentOption?.isSelectOtherOption) {
      onChangeValue(field, { value: '', selectedOtherOption: true });
    } else {
      onChangeValue(field, { value: newValue || '', selectedOtherOption: false });
    }
  };

  const handleChangeRadioValue: RadioCheckboxUpdateEventHandler = (event) => {
    const target = event.target as HTMLInputElement;
    const { id } = target.dataset;

    const { updatedOptions, value } = updateSelectFieldOptionsHandler({
      options: field.options || [],
      viewMode: field.selectFieldType,
      id: Number(id),
      selectedIndex: 0,
    });

    const previousValue = field.selectedOtherOption ? otherOptionValue : '';
    const updatedProperties = getSelectValuesToUpdate({
      fieldValue: value,
      previousValue,
      updatedOptions,
      fieldView: field.selectFieldType,
    });

    fieldValidationHandler({ updatedProperties });
    onChangeValue(field, updatedProperties);
  };

  const onChangeHandlerOtherOption: ChangeEventHandler<HTMLInputElement> = ({ target: { value } }) => {
    setOtherOptionValue(value);
    onChangeValue(field, { value });
  };

  const handleDateChange = (value: string) => {
    onChangeValue(field, { value });
    fieldValidationHandler({ newValue: value });
  };

  const handleCheckboxChecked = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setIsFieldChecked(target.checked);
    onChangeValue(field, { checked: target.checked });
  };

  const handlerChangeAttachmentCount = (currentCount: number, attachedField?: string[]) => {
    if (validateField && field.key) {
      const updatedAttachmentLimit = {
        properties: {
          ...field.properties,
          count: currentCount,
        },
        value: attachedField !== undefined ? attachedField?.join() : field.value,
      };
      onChangeValue(field, updatedAttachmentLimit);
    }
  };

  const handlerUpdateAttachmentValueAndCount = (newValueArray: string[]) => {
    if (!field.key || !currentDocument?.id) return;
    const updatedAttachmentField = {
      ...field,
      value: newValueArray.join(','),
      properties: {
        ...field.properties,
        count: newValueArray.length,
      },
    };

    dispatch(updatePublicPageIntroCurrentAttachmentField(
      currentDocument?.id,
      field.key,
      newValueArray.join(),
    ));
    onChangeValue(field, updatedAttachmentField);
  };

  const clickFieldHandler = () => {
    const selectedKey = isPdfFormField ? selectedFieldKey : selectedField;
    setSelectedFieldState(selectedKey, field.key, dispatch, isPdfFormField);
  };

  const onOpenSignModal = async () => {
    if (!readOnlyMode) {
      if (saveSignature && signaturePath && isFieldFirstClick) {
        setIsFieldFirstClick(false);
        const { signatureValue, tabToShow } = await getSignatureValueAndActiveTab();
        handleSignatureSave(signatureValue, tabToShow);
        callSigningTrackingEvents();
        // } else if (unauthenticatedPublicUser && !field.value && userSignature) {
      } else if (unauthenticatedPublicUser) {
        /**
         * This flow is used for unauthenticated users on public pages
         */

        const {
          [RETURNED_SIGNATURE_VALUE]: userSignature,
          [RETURNED_SIGNATURE_TAB]: userSignatureTab,
          [RETURNED_SIGNATURE_TYPE]: userSignatureType,
        } = getSignatureDataFromSessionStorage(field.signatureFieldVariation);

        if (isFieldFirstClick) {
          if (
            !field.value
            && userSignature
            && (
              (field.signatureFieldVariation === userSignatureType)
              || (!field.signatureFieldVariation && userSignatureType === SIGNATURE_FIELD_DEFAULT_VARIATION)
            )
          ) {
            setIsFieldFirstClick(false);
            handleSignatureSave(userSignature, userSignatureTab);
            callSigningTrackingEvents();
          } else {
            setIsFieldFirstClick(true);
            setShowSigningModal(true);
          }
        } else if (field.value && userSignature) {
          setIsFieldFirstClick(true);
        }
      } else {
        setIsFieldFirstClick(true);
        setShowSigningModal(true);
      }
    }
  };

  const showAttachmentValue = () => {
    if (field.properties?.count === undefined) {
      const filesCount = field.value ? field.value.split(',').length : 0;
      handlerChangeAttachmentCount(filesCount);
    }

    return (
      <>
        <FieldName name={fieldName} isRequired={isRequired} additionalClassName="field-name" />
        <ReactstrapInput
          className={`attachments_${field.key}`}
          type="hidden"
          name="value"
          value={fieldValue as string}
          required={isRequired}
          invalid={showErrorMessage}
          readOnly
        />
        {
          !readOnlyMode && (
            <AttachmentDropzone
              key={`attachments_${field.key}`}
              fieldKey={field.key}
              fieldValue={fieldValue}
              helpText={helpText}
              properties={field.properties}
              onChangeCount={handlerChangeAttachmentCount}
              onRemoveValue={handlerUpdateAttachmentValueAndCount}
              isValid={!showErrorMessage}
              errorMessage={validationMessage}
              isPDFDocument={isPdfFormField}
              field={field}
              useType={FORM_TYPE}
            />
          )
        }
      </>
    );
  };

  const renderTextField = useCallback(({
    isQuestion = false,
    isTextarea = false,
  }: {
    isQuestion?: boolean;
    isTextarea?: boolean;
  } = {}) => (
    <>
      {!isQuestion && shouldShowFieldnameAboveField && <FieldName name={fieldName} isRequired={isRequired} />}
      <FormInputField
        name={fieldName}
        label={getTextFieldLabel(isQuestion)}
        initValue={fieldValue as string}
        onChangeInitValue={changeTextValueHandler}
        onFocus={fieldFocusHandler}
        maxLength={field.maxLength ?? ''}
        styles={styleSchema.input}
        isRequired={isRequired}
        isError={isError}
        isReadOnly={readOnlyMode}
        isQuestion={isQuestion}
        isPDF={isPdfFormField}
        errorMessage={errorMessage}
        helpMessage={helpText}
        borderFieldStyle={borderFieldStyle}
        errorIcon={errorIcon}
        isTextArea={isTextarea}
        fieldMaskType={field.textFieldMask}
      />
    </>
  ), [fieldValue, isError]);

  const handleSelectFieldKeyDown = (event: React.KeyboardEvent) => {
    onSelectFieldKeyDown(event, handleSelectChange, options);
  };

  const renderSelectField = (isQuestion: boolean = false) => (
    <>
      {shouldShowFieldnameAboveField && <FieldName name={fieldName} isRequired={isRequired} />}
      <Autocomplete
        className="select-field-autocomplete"
        disablePortal
        options={[''].concat((options || []).map((option) => option.label))}
        blurOnSelect
        size="small"
        isOptionEqualToValue={(option, value) => (
          option === value as string
        )}
        value={
          field.selectedOtherOption
            ? field.options?.find((option) => option.isSelectOtherOption)?.label
            : fieldValue as string
        }
        noOptionsText={fieldName}
        onChange={handleSelectChange}
        onFocus={fieldFocusHandler}
        disabled={readOnlyMode}
        readOnly={readOnlyMode}
        tabIndex={-1}
        renderInput={(params) => {
          const additionalParams = {
            ...params,
            inputProps: {
              ...params.inputProps,
              onKeyDown: handleSelectFieldKeyDown,
            },
            tabIndex: -1,
          };
          return (
            <StyledTextField
              {...additionalParams}
              required={isRequired}
              style={styleSchema.input}
              label={getTextFieldLabel(isQuestion)}
              value={fieldValue}
              helperText={
                isError
                  ? (
                    <FormHelperText error variant="outlined">
                      <ErrorOutlineOutlinedIcon color="error" />
                      <span className="px-1">{errorMessage}</span>
                    </FormHelperText>
                  )
                  : helpText
              }
              error={isError}
              sx={borderFieldStyle}
            />
          );
        }}
      />
      {
        field.selectedOtherOption && (
          <OtherOptionElement
            name={field.name}
            value={otherOptionValue}
            onChangeHandler={onChangeHandlerOtherOption}
            simpleInput={false}
            addMargin
            disabled={readOnlyMode}
            style={styleSchema.input}
          />
        )
      }
    </>
  );

  const renderSelectRadioField = (selectFieldType: SelectFieldOptionsType) => (
    <>
      <FieldName name={fieldName} isRequired={isRequired} />
      <div className="form-radio-select">
        {
          field?.options?.map(({ id, label, checked }) => (
            <div key={id} className="form-radio-select__label">
              <ReactstrapInput
                type={getInputTypeByViewMode(selectFieldType)}
                name={`form-tab-${createNameConstant(field.name || '')}-${field.key}`}
                checked={checked}
                data-value={label}
                data-id={id}
                onChange={handleChangeRadioValue}
                onClick={handleChangeRadioValue}
                onFocus={fieldFocusHandler}
                disabled={readOnlyMode}
                className={`radio-select-${field.assignment} form-radio-select__input ${getCheckboxPrintClass(field.selectFieldType)
                }`}
                data-pdf-field
              />
              <span className="form-radio-select__text">
                {label}
              </span>
            </div>
          ))
        }
      </div>
      {
        field.selectedOtherOption && (
          <OtherOptionElement
            name={field.name}
            value={otherOptionValue}
            onChangeHandler={onChangeHandlerOtherOption}
            simpleInput={false}
            addMargin
            disabled={readOnlyMode}
            style={styleSchema.input}
          />
        )
      }
      {
        isError
          ? <p className="form-tab-help-text form-tab-help-text--error">{errorMessage}</p>
          : <p className="form-tab-help-text">{helpText}</p>
      }
    </>
  );

  const renderQuestionField = () => {
    const isQuestion = true;
    const longNameNode = <FieldName name={fieldName} isRequired={false} additionalClassName="field-name" />;
    if (field.answerType === QUESTION_ANSWER_STRING) {
      return (
        <>
          {longNameNode}
          {renderTextField({ isQuestion })}
        </>
      );
    }
    return (
      <>
        {longNameNode}
        {renderSelectField(isQuestion)}
      </>
    );
  };

  const renderCheckboxField = () => (
    <>
      <div className="d-flex align-items-center">
        <Checkbox
          checked={isFieldChecked}
          onChange={handleCheckboxChecked}
          name="value"
          disabled={readOnlyMode}
          sx={{
            padding: 0,
          }}
        />
        <span className="px-1">
          {fieldName}
        </span>
      </div>
      {helpText && <p className="checkbox-help-text">{helpText}</p>}
    </>
  );

  const dateInputComponent = (params: TextFieldProps) => (
    <>
      {shouldShowFieldnameAboveField && <FieldName name={fieldName} isRequired={isRequired} />}
      <StyledTextField
        {...params}
        ref={params.inputRef}
        style={styleSchema.input}
        size="small"
        label={!shouldShowFieldnameAboveField ? fieldName : ''}
        fullWidth
        error={isError}
        onFocus={fieldFocusHandler}
        helperText={
          isError
            ? errorMessage
            : helpText
        }
        variant="outlined"
        required={isRequired}
        sx={borderFieldStyle}
      />
    </>
  );

  const renderDateField = () => (
    <DateCalendar
      fieldValue={String(fieldValue)}
      dateMask={getDefaultDateMask(field.dateMask)}
      isReadOnlyMode={readOnlyMode || field.isReadOnly}
      onChangeDateValue={handleDateChange}
      inputComponent={dateInputComponent}
      showAdornment
    />
  );

  const renderSigningField = () => {
    if (!fieldValue) {
      return (
        <>
          <FieldName name={fieldName} isRequired={isRequired} additionalClassName="field-name" />
          <div className="w-100 text-center signature-field-wrapper">
            <div
              className="signature-field"
              onClick={readOnlyMode ? undefined : onOpenSignModal}
              role="button"
              tabIndex={readOnlyMode ? -1 : 0}
              onKeyDown={(event) => onFieldCustomKeyDown(event, readOnlyMode ? undefined : onOpenSignModal)}
            >
              <div className="w-100 d-flex align-items-center justify-content-center p-2">
                <span className="signature-icon my-3"><SignatureIcon /></span>
                <span className="field-help-text signature-help-text m-2">{helpText || 'Please sign here'}</span>
              </div>
              <ReactstrapInput
                type="hidden"
                name="value"
                defaultValue={fieldValue}
                required={isRequired}
                invalid={showErrorMessage}
                autoComplete="off"
                readOnly={readOnlyMode}
                className="py-4 signature-field"
              />
            </div>
            {showErrorMessage ? (
              <FormHelperText error variant="outlined">
                <ErrorOutlineOutlinedIcon color="error" />
                <span className="px-1">{validationMessage || 'Please sign here'}</span>
              </FormHelperText>
            ) : null}
          </div>
        </>
      );
    }
    return (
      <>
        <FieldName name={fieldName} isRequired={isRequired} />
        <div
          className="signature-field"
          role="button"
          tabIndex={readOnlyMode ? -1 : 0}
          onKeyDown={(event) => onFieldCustomKeyDown(event, readOnlyMode ? undefined : onOpenSignModal)}
        >
          <button
            className="signature-field-button button signature-field-button-public"
            type="button"
            onClick={onOpenSignModal}
            disabled={readOnlyMode}
            tabIndex={-1}
          >
            <ShowSignatureValue
              value={field.value}
            />
          </button>
        </div>
      </>
    );
  };

  const renderFormBuilderParagraph = () => (
    <div className="form-builder-paragraph form-description quill border-0 form-description mw-100 h-auto">
      <div className="ql-container ql-bubble">
        <div
          className="ql-editor"
          dangerouslySetInnerHTML={{ __html: field.value ?? '' }}
        />
      </div>
    </div>
  );

  const isSelectWithoutOptions = field.type === SELECT_FIELD && !options?.length;
  const isQuestionSelectWithoutOptions = field.type === QUESTION_FIELD
    && field.answerType === QUESTION_ANSWER_SELECT
    && !options?.length;

  if (editor && editor.formPage && isTopLevelTextNode(field.type) && field.children) {
    return <ReadableParagraph element={field} />;
  }

  const fieldClasses = idsToSelect.length
    ? getPublicSelectedFieldClass(
      field,
      isPdfFormField ? selectedFieldKey : selectedField,
      idsToSelect,
    ) : '';
  const labelClasses = `${(editor && editor.formPage) ? 'py-2' : 'mt-3 pt-2'}${fieldClasses}${field.textFieldMask === TEXT_FIELD_MASKS.HIDDEN ? ' hidden-field-in-pdf' : ''}`;

  const renderField = (fieldType: string = '', selectFieldType: SelectFieldOptionsType | undefined = undefined) => {
    if (isCheckboxField) return renderCheckboxField();
    switch (fieldType) {
      case TEXT_FIELD:
        return renderTextField();
      case DATE_FIELD:
        return renderDateField();
      case TEXTAREA_FIELD:
        return renderTextField({ isTextarea: true });
      case SELECT_FIELD:
        if (selectFieldType && getIsRadioOrCheckbox(selectFieldType)) {
          return renderSelectRadioField(selectFieldType);
        }
        return renderSelectField();
      case SIGNING_FIELD:
        return renderSigningField();
      case ATTACHMENT_FIELD:
        return showAttachmentValue();
      case QUESTION_FIELD:
        return renderQuestionField();
      case FORM_BUILDER_PARAGRAPH:
        return renderFormBuilderParagraph();
      default:
        return renderTextField();
    }
  };

  return field && (
    <>
      {
        isSelectWithoutOptions || isQuestionSelectWithoutOptions
          ? null
          : (
            <label
              className={labelClasses}
              ref={labelRef}
              onClick={clickFieldHandler}
              data-field-key={field.key}
              data-active-tab={FORM_TAB_ID}
            >
              {renderField(field.type, field.selectFieldType)}
            </label>
          )
      }
      {field.type === SIGNING_FIELD && (
        <SigningModal
          fieldKey={field.key}
          existingValue={field.value}
          currentTab={signatureTab}
          showModal={showSigningModal}
          onSave={handleSignatureSave}
          onCloseModal={setShowSigningModal}
          assignment={field.assignment}
          signatureFieldVariation={field.signatureFieldVariation}
        />
      )}
    </>
  );
};

export default PublicFieldView;
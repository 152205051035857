import { toast } from 'react-toastify';
import { put, select, takeLatest } from 'redux-saga/effects';

import { SUCCESS_CODE, SUCCESS_CREATE_CODE } from 'constants/generalErrors';
import {
  checkPIIDataInDatabasePerUser,
  deletePersonalSignatureData,
  getPIIUserData,
  getUserSigningCertificates,
  registerUserAndSendEmailInPIIRecallFlow,
  saveTemporaryPIIData,
  updatePersonalData,
  updatePersonalSignatureData,
  updateUserSigningCertificates,
} from 'services/api/userPersonalData';
import {
  CHECK_REMEMBERED_PII_DATA_IN_DATABASE,
  DELETE_DEFAULT_SIGNATURE_DATA,
  GET_DEFAULT_SIGNATURE_DATA,
  GET_SIGNING_CERTIFICATES,
  GET_USER_PII_DATA,
  SAVE_TEMPORARY_USER_PII_DATA_IN_DATABASE,
  SEND_EMAIL_IN_PII_RECALL_FLOW,
  SET_DEFAULT_SIGNATURE_DATA,
  SET_SHOW_RECALL_PII_DATA_MOCAL_WINDOW,
  SET_USER_PERSONAL_DATA,
  SET_USER_PII_DATA,
  SET_USER_SIGNING_CERTIFICATE,
  UPDATE_USER_PERSONAL_DATA,
  UPDATE_USER_PERSONAL_SIGNATURE,
  UPDATE_USER_SIGNING_CERTIFICATE,
} from 'store/actions/actionTypes';
import { setShowEmailHasBeenSentModal } from 'store/actions/publicPages';
import { RootStateType } from 'store/reducers';
import { safe } from 'store/sagas/errorHandlerSaga';
import { IResponseOfRequest } from 'types/Api';
import { FieldGroupByType } from 'types/Editor';
import { AggregatedFieldsStructureType } from 'types/PublicPage';
import { IUserPersonalDataObject, IUserPersonalSignatureObject, PIIDataType, PIIUserDataType } from 'types/userProfile';
import { isNotEmptyObject } from 'utils/isEmptyObject';
import { getUserEmailFromPII, getUserInfoFromPII } from 'utils/PIIAndGrouping/groupedAndPIIFieldsHelper';
import { getDocTypeByPageType, getSignatureValueAndActiveTab } from 'utils/userPersonalData/personalDataHelpers';

const updateUserPersonalData = function* ({ payload }: { payload: Partial<IUserPersonalDataObject> }) {
  const updatedData: IResponseOfRequest<IUserPersonalDataObject> = yield updatePersonalData(payload);
  if (updatedData?.data && [SUCCESS_CODE, SUCCESS_CREATE_CODE].includes(updatedData.status || 0)) {
    yield put({ type: SET_USER_PERSONAL_DATA, payload: updatedData.data });
  }
};

const updateUserPersonalSignature = function* ({ payload }: { payload: IUserPersonalSignatureObject }) {
  const updatedData: IResponseOfRequest<IUserPersonalDataObject> = yield updatePersonalSignatureData(payload);
  if (updatedData?.data && updatedData.status === SUCCESS_CODE) {
    yield put({ type: SET_USER_PERSONAL_DATA, payload: updatedData.data });
    toast.success('Signature was saved');
  }
};

const getUserPIIDataSaga = function* () {
  const piiData: IResponseOfRequest<PIIDataType> = yield getPIIUserData();
  const userData = piiData.data;

  if (isNotEmptyObject(userData)) {
    yield put({ type: SET_USER_PII_DATA, payload: userData });
  }
};

/**
 * Get encypted default signature, it's hash and signature modal tab from the database.
 * Set this data to the store.
 */
const getDefaultSignatureData = function* () {
  const signatureObject: IResponseOfRequest<unknown> = yield getSignatureValueAndActiveTab();
  yield put({ type: SET_DEFAULT_SIGNATURE_DATA, payload: signatureObject });
};

const deleteDefaultSignatureData = function* () {
  const response: IResponseOfRequest<{ message: string }> = yield deletePersonalSignatureData();
  if (response?.data && response?.status === SUCCESS_CODE) {
    yield put({ type: SET_DEFAULT_SIGNATURE_DATA, payload: null });
    yield put({ type: SET_USER_PERSONAL_DATA, payload: { save_signature: false, signature_path: null } });
    toast.success(response.data.message);
  }
};

const getUserSigningCertificate = function* () {
  const userCertificatePathResponse: IResponseOfRequest<unknown> = yield getUserSigningCertificates();
  if (userCertificatePathResponse && userCertificatePathResponse.status === SUCCESS_CODE) {
    yield put({ type: SET_USER_SIGNING_CERTIFICATE, payload: userCertificatePathResponse.data });
  }
};

const updateUserSigningCertificate = function* ({ payload }: { payload: FormData }) {
  const updateResponse: IResponseOfRequest<{ message: string }> = yield updateUserSigningCertificates(payload);
  if (updateResponse && updateResponse.status === SUCCESS_CODE) {
    yield put({ type: SET_USER_SIGNING_CERTIFICATE, payload: updateResponse.data });
    toast.success('Certificate and key have been updated.');
  } else {
    toast.success('Something went wrong, please try later.');
  }
};

/**
 * Function save user's PII data if they are unauthorized and fill out documents on public pages.
 * It's temporary data, but we need it in order to restore document progress.
*/
const savePIIUserDataInDatabase = function* ({
  payload,
}: {
  payload: {
    subtype: string,
    value: string,
    groupBy?: FieldGroupByType,
    groupByKey?: string,
    filterName?: string,
  }[] }) {
  const piiData: PIIDataType[] = yield select((state: RootStateType) => (
    state.profile.piiData
  ));
  const userEmail: string = getUserEmailFromPII(piiData);

  const { email: docCreatorEmail }: { email: string } = yield select(
    (state: RootStateType) => state.publicPages.data.creator,
  );
  yield saveTemporaryPIIData({
    userEmail,
    docCreatorEmail,
    piiData: payload,
  });
};

const checkPIIDataForUser = function* () {
  const piiData: PIIDataType[] = yield select((state: RootStateType) => state.profile.piiData);
  const userEmail: string = getUserEmailFromPII(piiData);
  if (!userEmail) return;
  const groupedFieldsStructure: AggregatedFieldsStructureType[] = yield select(
    (state: RootStateType) => state.publicPages.structure.groupedFieldsStructure,
  );

  const response: IResponseOfRequest<{ message: string }> = yield checkPIIDataInDatabasePerUser(
    userEmail,
    groupedFieldsStructure,
  );
  if (response && response.status === SUCCESS_CODE && response.data) {
    yield put({ type: SET_SHOW_RECALL_PII_DATA_MOCAL_WINDOW, payload: response.data });
  }
};

const sendEmailWithPasswordlessLinkAndRegisterUser = function* () {
  const piiData: PIIDataType[] = yield select((state: RootStateType) => state.profile.piiData);
  const startLink: string = yield select((state: RootStateType) => state.publicPages.startLink);
  const { sender: documentSender, id }: {
    sender: Record<string, string>,
    id: number,
  } = yield select((state: RootStateType) => state.publicPages.data);
  const userInfo: PIIUserDataType = getUserInfoFromPII(piiData);
  const documentType: string = yield select((state: RootStateType) => state.publicPages.structure?.main?.pageType);
  const response: IResponseOfRequest<{ message: string }> = yield registerUserAndSendEmailInPIIRecallFlow(
    userInfo,
    startLink,
    documentSender.email,
    { id, type: getDocTypeByPageType(documentType) },
  );
  if (response && response.status === SUCCESS_CODE && response.data) {
    yield put(setShowEmailHasBeenSentModal(true));
  }
};

export default [
  takeLatest(UPDATE_USER_PERSONAL_DATA, safe(updateUserPersonalData)),
  takeLatest(UPDATE_USER_PERSONAL_SIGNATURE, safe(updateUserPersonalSignature)),
  takeLatest(GET_USER_PII_DATA, safe(getUserPIIDataSaga)),
  takeLatest(GET_DEFAULT_SIGNATURE_DATA, safe(getDefaultSignatureData)),
  takeLatest(DELETE_DEFAULT_SIGNATURE_DATA, safe(deleteDefaultSignatureData)),
  takeLatest(GET_SIGNING_CERTIFICATES, safe(getUserSigningCertificate)),
  takeLatest(UPDATE_USER_SIGNING_CERTIFICATE, safe(updateUserSigningCertificate)),
  takeLatest(SAVE_TEMPORARY_USER_PII_DATA_IN_DATABASE, safe(savePIIUserDataInDatabase, false, true)),
  takeLatest(CHECK_REMEMBERED_PII_DATA_IN_DATABASE, safe(checkPIIDataForUser, false, true)),
  takeLatest(SEND_EMAIL_IN_PII_RECALL_FLOW, safe(sendEmailWithPasswordlessLinkAndRegisterUser, false, true)),
];
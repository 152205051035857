import { FC, useState } from 'react';

import DescriptionEditor from 'components/Editor/FormView/DescriptionEditor';
import { KEY_CODE } from 'constants/editors';

interface IFormBulderDescription {
  description: string;
  setDescription: (sectionDescription: string) => void;
}

const FormBuilderDescription: FC<IFormBulderDescription> = ({
  description,
  setDescription,
}) => {
  const [isReadOnlyMode, setIsReadOnlyMode] = useState<boolean>(true);
  const [sectionDescription, setSectionDescription] = useState<string>(description);

  const onSaveDescription = (): void => {
    setIsReadOnlyMode(!isReadOnlyMode);
    setDescription(sectionDescription);
  };

  const changeReadOnlyMode = () => {
    setIsReadOnlyMode(!isReadOnlyMode);
  };

  return (
    <div
      role="button"
      tabIndex={0}
      className="form-builder_section_description"
      onKeyDown={(event) => {
        if (event.key === KEY_CODE.ENTER) {
          onSaveDescription();
        }
      }}
    >
      {isReadOnlyMode
        ? (
          <div className="form-description-public">
            {description ? (
              <p
                className="text"
                onClick={changeReadOnlyMode}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: description }}
              />
            ) : (
              <p onClick={changeReadOnlyMode} className="text">Description goes here</p>
            )}
          </div>
        )
        : (
          <div>
            <DescriptionEditor
              isReadOnly={false}
              value={sectionDescription}
              onChange={setSectionDescription}
            />
            {
              onSaveDescription !== undefined && (
                <div className="description-save-wrapper">
                  <button
                    type="button"
                    className="description-save-button"
                    onClick={onSaveDescription}
                  >
                    {sectionDescription ? 'Save Description' : 'Close'}
                  </button>
                </div>
              )
            }
          </div>
        )}
    </div>
  );
};

export default FormBuilderDescription;
import { Components, createTheme, ThemeOptions } from '@mui/material';

import globalTheme from 'scss/MuiTheme';
import vars from 'variables.module.scss';

interface IThemeOptions extends ThemeOptions {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  components?: Components<{ [key: string]: any }>;
}

const theme = createTheme({});

export default () => createTheme({
  components: {
    ...globalTheme.components,
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontFamily: vars.mainFont,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontFamily: vars.mainFont,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: vars.mainFont,
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          fontFamily: `${vars.mainFont} !important`,
        },
      },
    },
    MUIDataTableBodyRow: {
      styleOverrides: {
        responsiveStacked: {
          [theme.breakpoints.down('md')]: {
            display: 'inherit',
          },
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          width: '100%',
          tableLayout: 'fixed',
          [theme.breakpoints.down('sm')]: {
            tableLayout: 'initial',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: 'none !important',
          '& .MuiAccordion-root': {
            backgroundColor: 'unset',
          },
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        regular: {
          minHeight: '2rem !important',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: '0.5rem',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: '1.25rem',
          width: '1.25rem',
          height: '1.25rem',
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          position: 'absolute',
          top: 'calc(50% - 6px)',
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          position: 'relative',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: vars.mainFont,
          '&:last-of-type': {
            width: '100px',
          },
          '& .datatables-noprint:first-of-type': {
            width: '0%',
          },
          '&:last-of-type:first-of-type': {
            width: '100%',
            textAlign: 'center',
          },
        },
        head: {
          fontWeight: 700,
          fontSize: '0.75rem',
          color: vars.darkGray,
        },
        body: {
          color: vars.black,
          '& div': {
            overflowWrap: 'break-word',
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          border: 0,
        },
        hover: {
          '&:hover': {
            backgroundColor: vars.veryLightGray,
          },
        },
      },
    },
    MUIDataTableFilter: {
      styleOverrides: {
        root: {
          fontFamily: vars.mainFont,
        },
        title: {
          fontFamily: vars.mainFont,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        body2: {
          fontSize: '0.75rem',
          color: vars.darkGray,
        },
        caption: {
          fontFamily: vars.mainFont,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(224, 224, 224, 1)',
          width: '100%',
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        selectLabel: {
          fontFamily: vars.mainFont,
          margin: 0,
        },
        displayedRows: {
          fontFamily: vars.mainFont,
          margin: 0,
        },
        select: {
          fontFamily: vars.mainFont,
        },
      },
    },
    MUIDataTableToolbar: {
      styleOverrides: {
        actions: {
          '& button': {
            margin: '0 4px',
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          '&#pagination-menu-list': {
            display: 'flex',
            flexDirection: 'column',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          /*
          Style rewriting for filter options. Other MUI default styles set 'display: inline;' for these elements.
          Using '!important' it's only way to set this style to 'display: block;'
          It applies to all elements with style MuiMenuItem.
          */
          display: 'block !important',
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 'unset',
        },
      },
    },
  },
} as IThemeOptions);
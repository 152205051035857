import { FC, memo, RefObject, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Element as SlateElement, Text, Transforms } from 'slate';
import { ReactEditor, RenderElementProps, useSlate } from 'slate-react';

import cn from 'classnames';
import AttachmentElement from 'components/Editor/components/AttachmentEditable';
import CheckListItemElement from 'components/Editor/components/CheckListEditable';
import DateElement from 'components/Editor/components/DateEditable';
import SelectElement from 'components/Editor/components/Fields/SelectEditable';
import TableQuestion from 'components/Editor/components/Fields/TableQuestion/TableQuestion';
import TableQuestionBody from 'components/Editor/components/Fields/TableQuestion/TableQuestionBody';
import TableQuestionCaption from 'components/Editor/components/Fields/TableQuestion/TableQuestionCaption';
import TableQuestionCell from 'components/Editor/components/Fields/TableQuestion/TableQuestionCell';
import TableQuestionResizeBar from 'components/Editor/components/Fields/TableQuestion/TableQuestionResizeBar';
import TableQuestionRow from 'components/Editor/components/Fields/TableQuestion/TableQuestionRow';
import ImageElement from 'components/Editor/components/ImageEditable';
import InputElement from 'components/Editor/components/InputEditable';
import ListItemWrapperEditable from 'components/Editor/components/ListItemWrapperEditable';
import QuestionEditable from 'components/Editor/components/QuestionEditable';
import SigningElement from 'components/Editor/components/SigningEditable';
import TextAreaElement from 'components/Editor/components/TextAreaEditable';
import WrapperBlockEditable from 'components/Editor/components/WrapperBlockEditable';
import { Table } from 'components/Editor/editor-custom-plugins/table/elements';
import {
  ATTACHMENT_FIELD,
  BULLETED_LIST,
  CHECK_LIST_ITEM,
  DATE_FIELD,
  HEADLINE_FIVE,
  HEADLINE_FOUR,
  HEADLINE_ONE,
  HEADLINE_SIX,
  HEADLINE_THREE,
  HEADLINE_TWO,
  IMAGE_TYPE,
  LINK_TYPE,
  LIST_ITEM,
  LIST_ITEM_TEXT,
  NO_BREAK_SPACE,
  NUMBERED_LIST,
  NUMBERED_LIST_ITEM,
  PARAGRAPH,
  QUESTION_FIELD,
  SECTION,
  SELECT_FIELD,
  SIGNING_FIELD,
  TABLE_QUESTION,
  TABLE_QUESTION_BODY,
  TABLE_QUESTION_CAPTION,
  TABLE_QUESTION_CELL,
  TABLE_QUESTION_FIELD_CELL,
  TABLE_QUESTION_HORIZONTAL_RESIZE_BAR,
  TABLE_QUESTION_ROW,
  TABLE_QUESTION_VERTICAL_RESIZE_BAR,
  TEXT_FIELD,
  TEXTAREA_FIELD,
  WHITE_SPACE,
} from 'constants/editors';
import { DOCUMENT_TAB_ID } from 'constants/tabs';
import { RootStateType } from 'store/reducers';
import { SelectFieldOptionsType } from 'types/Editor';
import { IPublicPageDocumentStructure } from 'types/PublicPage';
import shouldShowBrandingInRoutes from 'utils/CompanyBranding/shouldShowBrandingInRoutes';

export interface IRenderElementProps extends RenderElementProps {
  styledSections?: boolean;
  readOnlyMode?: boolean;
  isPublicPage?: boolean;
  isError?: boolean;
  validationErrorText?: string | null;
  filledInFields?: Array<{ key: number; isFilledIn: boolean; }>;
  editorRef?: RefObject<HTMLDivElement>;
}

export interface IRenderElementPropsWithValidation extends IRenderElementProps {
  isError: boolean;
  validationErrorText: string | null;
}

export interface IRenderSelectElementPropsWithValidation extends IRenderElementPropsWithValidation {
  viewMode?: SelectFieldOptionsType;
}

const Element: FC<IRenderElementProps> = (props) => {
  const {
    attributes,
    children,
    element,
    styledSections,
    isPublicPage,
    readOnlyMode,
    editorRef,
  } = props;

  const location = useLocation();
  const editor = useSlate();

  const { isError, validationFields } = useSelector((state: RootStateType) => (
    state.editorSlate.publicFormValidationResults
  ));
  const currentDocument: IPublicPageDocumentStructure | null = useSelector(
    (state: RootStateType) => state.publicPages.currentDocument,
  );
  const validationErrorText = validationFields[element.key || 0];
  const isAvailableTranslate = shouldShowBrandingInRoutes(location.pathname);

  const propsWithValidation: IRenderElementPropsWithValidation = {
    ...props,
    isError,
    validationErrorText,
  };

  useEffect(() => {
    if (element.type === PARAGRAPH && isPublicPage && currentDocument?.activeEditorTab === DOCUMENT_TAB_ID) {
      const hasFields = element.children.some((child) => SlateElement.isElement(child));
      if (hasFields) {
        element.children.forEach((child) => {
          if (Text.isText(child)) {
            const path = ReactEditor.findPath(editor, child);
            Transforms.insertText(
              editor,
              child.text.replaceAll(WHITE_SPACE, NO_BREAK_SPACE),
              { at: path },
            );
          }
        });
      }
    }
  }, []);

  switch (element.type) {
    case 'table':
    case 'table-row':
    case 'table-cell':
      return <Table {...props} />;
    case 'table-body':
      return <tbody slate-table-element="tbody">{children}</tbody>; // eslint-disable-line react/no-unknown-property
    case 'table-content':
      return (
        <WrapperBlockEditable element={element}>
          <p {...attributes} className="table-content">{children}</p>
        </WrapperBlockEditable>
      );
    case 'table-head':
      return <thead {...attributes}>{children}</thead>;
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>;
    case HEADLINE_ONE:
      return (
        <WrapperBlockEditable element={element}>
          <h1 className="mb-1" {...attributes}>{children}</h1>
        </WrapperBlockEditable>
      );
    case HEADLINE_TWO:
      return (
        <WrapperBlockEditable element={element}>
          <h2 {...attributes}>{children}</h2>
        </WrapperBlockEditable>
      );
    case HEADLINE_THREE:
      return (
        <WrapperBlockEditable element={element}>
          <h3 {...attributes}>{children}</h3>
        </WrapperBlockEditable>
      );
    case HEADLINE_FOUR:
      return (
        <WrapperBlockEditable element={element}>
          <h4 {...attributes}>{children}</h4>
        </WrapperBlockEditable>
      );
    case HEADLINE_FIVE:
      return (
        <WrapperBlockEditable element={element}>
          <h5 {...attributes}>{children}</h5>
        </WrapperBlockEditable>
      );
    case HEADLINE_SIX:
      return (
        <WrapperBlockEditable element={element}>
          <h6 {...attributes}>{children}</h6>
        </WrapperBlockEditable>
      );
    case SECTION:
      return (
        <section
          className={cn(
            'document-editor__section',
            {
              'document-editor__section--public': !styledSections,
              notranslate: !isAvailableTranslate,
            },
          )}
          {...attributes}
          data-section-key={element.key}
        >
          {children}
        </section>
      );
    case IMAGE_TYPE:
      return <ImageElement {...props} />;
    case LINK_TYPE: {
      const target = element.blank ? '_blank' : '_self';
      return (
        <span>
          <a {...attributes} href={element.url} target={target}>{children}</a>
        </span>
      );
    }
    case TEXT_FIELD:
      return <InputElement {...propsWithValidation} editorRef={editorRef} />;
    case DATE_FIELD:
      return <DateElement {...propsWithValidation} />;
    case SELECT_FIELD:
      return <SelectElement {...propsWithValidation} viewMode={element.selectFieldType} />;
    case TEXTAREA_FIELD:
      return <TextAreaElement {...propsWithValidation} />;
    case SIGNING_FIELD:
      return <SigningElement {...propsWithValidation} />;
    case ATTACHMENT_FIELD:
      return <AttachmentElement {...propsWithValidation} readOnly={readOnlyMode} />;
    case QUESTION_FIELD:
      return <QuestionEditable {...propsWithValidation} />;
    case CHECK_LIST_ITEM:
      return <CheckListItemElement {...props} readOnly={readOnlyMode || isPublicPage} />;
    case BULLETED_LIST:
      return <ul {...attributes}>{children}</ul>;
    case NUMBERED_LIST:
      return <ol {...attributes} data-list-style={element.listStyle || ''}>{children}</ol>;
    case LIST_ITEM:
    case NUMBERED_LIST_ITEM:
      return (
        <WrapperBlockEditable element={element}>
          <ListItemWrapperEditable element={element}>
            <li {...attributes} data-type={element.type} className="slate-list-item">{children}</li>
          </ListItemWrapperEditable>
        </WrapperBlockEditable>
      );
    case LIST_ITEM_TEXT:
      return <div>{children}</div>;
    case TABLE_QUESTION:
      return <TableQuestion {...props} />;
    case TABLE_QUESTION_VERTICAL_RESIZE_BAR:
      return <TableQuestionResizeBar isVertical {...props} />;
    case TABLE_QUESTION_HORIZONTAL_RESIZE_BAR:
      return <TableQuestionResizeBar {...props} />;
    case TABLE_QUESTION_CAPTION:
      return <TableQuestionCaption {...props} />;
    case TABLE_QUESTION_BODY:
      return <TableQuestionBody {...props} />;
    case TABLE_QUESTION_ROW:
      return <TableQuestionRow {...props} />;
    case TABLE_QUESTION_CELL:
    case TABLE_QUESTION_FIELD_CELL:
      return <TableQuestionCell {...props} />;
    default: {
      return (
        <WrapperBlockEditable element={element}>
          <p {...attributes}>
            {children}
          </p>
        </WrapperBlockEditable>
      );
    }
  }
};

export default memo(Element);
import { FC } from 'react';

import AddIcon from 'svg/AddIcon';

interface IButtonAddOutline {
  onClick: () => void;
  title?: string;
  disabled?: boolean;
}

const ButtonAddOutline: FC<IButtonAddOutline> = ({
  onClick,
  title = 'Add section',
  disabled = false,
}) => (
  <button
    type="button"
    className="d-flex align-items-center mt-4 mb-2 p-0 outline-sortable-container-add-button"
    onClick={onClick}
    disabled={disabled}
  >
    <AddIcon />
    <div className="ms-2">
      { title }
    </div>
  </button>
);

export default ButtonAddOutline;
const assignmentColors = [
  '#2971D6',
  '#37BE5F',
  '#8c50dc',
  '#d2784a',
  '#18a0b8',
  '#304070',
  '#8e0896',
  '#4ca804',
  '#fa68de',
  '#f8e028',
  '#4c3004',
  '#fe784a',
  '#a8a0b8',
  '#5450dc',
  '#a4784c',
  '#a0c0e0',
  '#506070',
  '#7668de',
  '#aa8cc8',
  '#a4f094',
];

export default assignmentColors;
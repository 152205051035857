import { CSSProperties, FC, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { SortEnd } from 'react-sortable-hoc';

import FormBuilderSection from 'components/FormBuilder/FormBuilderSection';
import { SortableItem, SortableList } from 'components/FormBuilder/FormBuilderSortableComponents';
import SelectableFieldsWrapper from 'components/FormBuilder/SelectableFieldsWrapper';
import AddSectionButton from 'components/FormConstructor/Buttons/FormAddSectionButton';
import { FORM_BUILDER_SECTION_WRAPPER_ID } from 'constants/general';
import useBodyOverflow from 'hooks/useBodyOverflow';
import useRootStyles from 'hooks/useRootStyles';
import { addSectionsToFormBuilder, updateFormBuilderSectionsOrder } from 'store/actions/userData';
import { RootStateType } from 'store/reducers';
import { FormBuilderType } from 'types/FormBuilder';
import { IFormBuilderSection } from 'types/Sections';
import { createFormBuilderSection, setFormBuilderGroupedFields } from 'utils/FormBuilder/formBuilderHelpers';
import { getFormBuilderByType } from 'utils/FormBuilder/store';
import { isMobileDevice } from 'utils/mobileDeviceHelper';

import 'scss/components/_formBuilder.scss';
import 'scss/components/FormBuilder/_selectable.scss';

interface IFormBuilderProps {
  formBuilderType: FormBuilderType;
}

const FormBuilder: FC<IFormBuilderProps> = ({ formBuilderType }) => {
  useBodyOverflow('hidden');
  const styles: CSSProperties = useMemo(() => ({
    'overflow-y': 'hidden',
    display: 'grid',
    'grid-template-rows': 'auto 1fr',
    height: '100vh',
  }), []);
  useRootStyles(styles);
  const isMobile = isMobileDevice();
  const dispatch = useDispatch();
  const formBuilderFromStore = useSelector((state: RootStateType) => (
    getFormBuilderByType(state, formBuilderType)
  ));

  const formBuilder: IFormBuilderSection[] = formBuilderFromStore
    ? setFormBuilderGroupedFields(formBuilderFromStore)
    : [];

  const [hoveredSectionKey, setHoveredSectionKey] = useState<number>(0);

  const onSortEnd = (oldIndex: number, newIndex: number) => {
    const copyOfSectionsArray: IFormBuilderSection[] = [...formBuilder];
    const [item] = copyOfSectionsArray.splice(oldIndex, 1);

    copyOfSectionsArray.splice(newIndex, 0, item);

    dispatch(updateFormBuilderSectionsOrder(copyOfSectionsArray, formBuilderType));
  };

  return (
    <div className="container">
      <SelectableFieldsWrapper disabled={isMobile}>
        <div
          className="form-builder"
          id={FORM_BUILDER_SECTION_WRAPPER_ID}
          data-form-builder-scrollable="form-builder-scrollable"
        >
          <SortableList
            disableAutoscroll
            useDragHandle
            getContainer={() => {
              const node = document.getElementById(FORM_BUILDER_SECTION_WRAPPER_ID);
              return node || document.body;
            }}
            lockAxis="y"
            distance={1}
            onSortEnd={({ oldIndex, newIndex }: SortEnd) => onSortEnd(oldIndex, newIndex)}
            shouldCancelStart={() => false}
            hideSortableGhost
          >
            <>
              {formBuilder.map((formBuilderSection: IFormBuilderSection, idx: number) => (
                <div
                  data-section-key={formBuilderSection.key}
                  onMouseEnter={() => setHoveredSectionKey(formBuilderSection.key)}
                  key={`${formBuilderSection.name}-${formBuilderSection.key}`}
                >
                  <SortableItem
                    index={idx}
                  >
                    <FormBuilderSection
                      formBuilderSection={formBuilderSection}
                      hoveredSectionKey={hoveredSectionKey}
                      formBuilderType={formBuilderType}
                    />
                  </SortableItem>
                </div>
              ))}
            </>
          </SortableList>
          <AddSectionButton
            isFormBuilder
            onClick={() => dispatch(addSectionsToFormBuilder([createFormBuilderSection({})], formBuilderType))}
          />
        </div>
      </SelectableFieldsWrapper>
    </div>
  );
};

export default FormBuilder;
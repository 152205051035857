import { FC, memo, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Button from 'components/Base/Button';
import ModalContentWindow from 'components/Modals/ModalContentWindow';
import AutoFillValueField from 'components/PublicPage/AutoFillValueField';
import usePIIUserData from 'hooks/usePIIUserData';
import useReadOnlyMode from 'hooks/useReadOnlyMode';
import useStyleSchema, { CustomStylesEnum } from 'hooks/useStylesFromSchema';
import { setPIIDataModalWasVisible } from 'store/actions/publicPages';
import { RootStateType } from 'store/reducers';
import ArrowRightIcon from 'svg/ArrowIcons/ArrowRightIcon';
import { AggregatedFieldsStructureType } from 'types/PublicPage';
import { PIIDataKeysValues, PIIDataType } from 'types/userProfile';
import shouldShowBrandingInRoutes from 'utils/CompanyBranding/shouldShowBrandingInRoutes';
import { isNotEmptyObject } from 'utils/isEmptyObject';
import { findGroupMatch, findPIIMatch } from 'utils/PIIAndGrouping/groupedAndPIIFieldsHelper';
import { updatePIIFieldData } from 'utils/PublicPage/autoFillDataHelper';

import classes from 'scss/components/PublicFooter/PublicFooter.module.scss';

interface IPIIUserDataModalProps {
  autoFillPIIFieldsInContentHandler: (piiData: PIIDataType[]) => void;
}

const PIIUserDataModal: FC<IPIIUserDataModalProps> = ({
  autoFillPIIFieldsInContentHandler,
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const isBrandingAvailable = shouldShowBrandingInRoutes(location.pathname);
  const styleSchema = useStyleSchema<CustomStylesEnum.title | CustomStylesEnum.button>([
    CustomStylesEnum.button,
    CustomStylesEnum.title,
  ]);
  const titleStyleSchema = { ...(isBrandingAvailable && styleSchema.title) };

  const groupedFieldsStructure: AggregatedFieldsStructureType[] = useSelector((state: RootStateType) => (
    state.publicPages.structure.groupedFieldsStructure
  ));

  const {
    wasPIIDataModalWindowVisible,
    autofillPIIMondayItem,
  } = useSelector((state: RootStateType) => (
    state.publicPages
  ));

  const userPIIData: PIIDataType[] = usePIIUserData();
  const isReadOnlyMode: boolean = useReadOnlyMode();

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [piiDataKeys, setPiiDataKeys] = useState<PIIDataKeysValues>({});
  const [selectedPII, setSelectedPII] = useState<PIIDataType[]>(userPIIData);

  useEffect(() => {
    if (groupedFieldsStructure.length && userPIIData.length) {
      const fields: PIIDataKeysValues = {};
      const groupedFieldsStructureSubtypes = groupedFieldsStructure.map((field) => field.subtype);
      groupedFieldsStructure?.forEach((field) => {
        if (autofillPIIMondayItem && field.filterName === autofillPIIMondayItem.title) return;
        const matchedPII: PIIDataType[] = findGroupMatch(field, userPIIData);
        if (matchedPII.length > 1 && matchedPII[0].filterName) {
          fields[matchedPII[0].filterName] = {
            options: (matchedPII
              .filter((piiObject) => groupedFieldsStructureSubtypes.includes(piiObject.subtype))
              .map((item) => (item.groupByKey?.trim() ?? '')))
              .filter(Boolean),
            updated: false,
          };
        }
      });
      setPiiDataKeys(fields);
    }
  }, [
    autofillPIIMondayItem,
    groupedFieldsStructure,
    groupedFieldsStructure.length,
    userPIIData,
    userPIIData.length,
  ]);

  useEffect(() => {
    if (isNotEmptyObject(piiDataKeys) && !wasPIIDataModalWindowVisible && !isReadOnlyMode) {
      setIsModalVisible(true);
      dispatch(setPIIDataModalWasVisible());
    }
  }, [isReadOnlyMode, piiDataKeys]);

  const onChangeSelectValue = (filterName: string, label: string) => {
    setPiiDataKeys((prevState) => ({
      ...prevState,
      [filterName]: {
        ...prevState[filterName],
        updated: true,
      },
    }));

    setSelectedPII(updatePIIFieldData(groupedFieldsStructure, userPIIData, filterName, label));
  };

  const updatePIIUserDataHandler = () => {
    const notUpdatedFields = Object
      .entries(piiDataKeys)
      .filter(([, values]) => !values.updated)
      .map(([filterName]) => filterName);

    let updatePIIData = (selectedPII.length ? selectedPII : userPIIData);

    if (notUpdatedFields.length) {
      const aggregatedFieldsByFilter = groupedFieldsStructure.filter(
        (item) => notUpdatedFields.includes(item.name),
      );
      updatePIIData = updatePIIData.map((field) => {
        const target = aggregatedFieldsByFilter.find((item) => {
          const filterFieldTarget: PIIDataType[] = findPIIMatch(
            item as AggregatedFieldsStructureType,
            userPIIData,
          );
          return !!(aggregatedFieldsByFilter
            && filterFieldTarget.length
            && filterFieldTarget[0].groupByKey === field.groupByKey
            && filterFieldTarget[0].groupBy === field.groupBy
            && filterFieldTarget[0].filterName === field.filterName
            && filterFieldTarget[0].subtype === field.subtype);
        });
        if (target) {
          return {
            ...field,
            value: '',
          };
        }
        if (field.filterName && notUpdatedFields.includes(field.filterName)) {
          return {
            ...field,
            value: '',
          };
        }
        return field;
      });
    }
    autoFillPIIFieldsInContentHandler(updatePIIData);
    setIsModalVisible(false);
  };

  return (
    <ModalContentWindow
      centered
      showDefaultText={false}
      showModal={isModalVisible}
      onCloseModal={() => setIsModalVisible(false)}
      titleText=""
      showCloseButton={false}
    >
      <h1
        className="title"
        style={titleStyleSchema}
      >
        Please select a record below to pull associated data
      </h1>
      {
        Object.entries(piiDataKeys).map(([filterName, { options }]) => (
          <div
            className="mb-2"
            key={filterName}
          >
            <AutoFillValueField
              filterName={filterName}
              options={options}
              onChangeSelectValueHandler={(label: string) => onChangeSelectValue(filterName, label)}
            />
          </div>
        ))
      }
      <div className="text-center">
        <Button
          classes={classes.PublicButton}
          endIcon={<ArrowRightIcon />}
          onClick={updatePIIUserDataHandler}
          style={styleSchema.button}
        >
          Start Signing
        </Button>
      </div>
    </ModalContentWindow>
  );
};

export default memo(PIIUserDataModal);
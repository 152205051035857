import { useDispatch, useSelector } from 'react-redux';

import { setPdfPartOfMultiTemplate } from 'store/actions/collections';
import {
  setAllPdfFieldsAndInfo,
  setPdfTemplateFileLink,
  setPdfTemplateKey,
  setPdfTemplateWidth,
} from 'store/actions/pdfTemplates';
import { RootStateType } from 'store/reducers';

const useChangeDocumentInCollection = () => {
  const dispatch = useDispatch();
  const files = useSelector((state: RootStateType) => state.signNow.files);
  const pdfPartsOfMultiTemplate = useSelector(
    (state: RootStateType) => state.multiTemplates.pdfPartsOfMultiTemplate ?? {},
  );
  const pdfTemplate = useSelector((state: RootStateType) => state.pdfTemplates ?? {});

  const onChangeDocumentInCollection = (targetPdfFileKey: string | null) => {
    const targetPdfTemplate = files.find((file) => file.fileKey === targetPdfFileKey);
    const previousPdfTemplate = files.find((file) => file.fileKey === pdfTemplate.pdfTemplateKey);
    const currentPdfPartOfCollection = pdfPartsOfMultiTemplate[targetPdfTemplate?.pdfTemplateId ?? 0];

    // Set current pdf document to collection pdf documents store
    dispatch(setPdfPartOfMultiTemplate(
      {
        id: previousPdfTemplate?.pdfTemplateId,
        pdf_file_url: `${pdfTemplate.pdfTemplateKey}?${pdfTemplate.pdfTemplateWidth}`,
        pdf_full_link: pdfTemplate.pdfTemplateLink,
        name: pdfTemplate.pdfTemplateInfo.templateName,
        template_title: pdfTemplate.pdfTemplateInfo.templateTitle,
        description: pdfTemplate.pdfTemplateInfo.templateDescription,
      },
      pdfTemplate.pdfTemplateFields,
    ));

    // Set current pdf document to collection pdf documents store
    dispatch(setPdfTemplateKey(currentPdfPartOfCollection.pdfTemplateKey ?? ''));
    dispatch(setPdfTemplateWidth(currentPdfPartOfCollection.pdfTemplateWidth ?? 0));
    dispatch(setPdfTemplateFileLink(currentPdfPartOfCollection.pdfTemplateLink));
    dispatch(setAllPdfFieldsAndInfo({
      fields: currentPdfPartOfCollection.pdfTemplateFields,
      info: {
        templateName: currentPdfPartOfCollection.pdfTemplateInfo?.templateName,
        templateTitle: currentPdfPartOfCollection.pdfTemplateInfo?.templateTitle,
        templateDescription: currentPdfPartOfCollection.pdfTemplateInfo?.templateDescription,
      },
    }));
  };

  return onChangeDocumentInCollection;
};

export default useChangeDocumentInCollection;
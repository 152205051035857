import { ChangeEvent, ChangeEventHandler, SyntheticEvent, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { SortableElement } from 'react-sortable-hoc';

import cn from 'classnames';
import InputField from 'components/Base/InputField';
import SearchEmailField from 'components/SendDocuments/SearchEmailField';
import { SignerFlow } from 'constants/signNow';
import { RootStateType } from 'store/reducers';
import CloseIcon from 'svg/CloseIcon';
import DragHandleIcon from 'svg/DragHandleIcon';
import { ISignerCard, SIGNER_INFO, SignerType } from 'types/SendDocuments';
import { UserListGetterType, UserListStoreType } from 'types/userProfile';
import { isCCAssignment, isManagerAssignment, isRecipientAssignment } from 'utils/assignmentsHelpers';

import 'scss/components/_fieldProperties.scss';

const SignerCard = SortableElement(({
  signer,
  indexPosition,
  onChange = () => null,
  onDelete = () => null,
  isDisabled = false,
  isSignNow = false,
  canDeleteCard = false,
  isOnlyCollection = false,
}: ISignerCard) => {
  const userList: UserListStoreType = useSelector((state: RootStateType) => state.profile.userList);
  const { signingOrderFlow } = useSelector((state: RootStateType) => state.sendDocuments);
  const [localUserList, setLocalUserList] = useState<UserListGetterType[]>([]);

  const isManagerCard = isManagerAssignment(signer.type);
  const isRecipientCard = isRecipientAssignment(signer.type);
  const isCCCard = isCCAssignment(signer.type);

  useEffect(() => {
    setLocalUserList(userList.users.filter((user) => user.active));
  }, [userList.users, userList.users.length]);

  const emailChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(
      indexPosition,
      {
        [SIGNER_INFO.EMAIL]: event.target.value,
      },
    );
  };

  const emailSelectHandler = (_: SyntheticEvent<Element, Event>, newValue: string | null) => {
    const selectedUser = localUserList.find((user) => user.email === newValue);

    if (selectedUser) {
      onChange(
        indexPosition,
        {
          [SIGNER_INFO.EMAIL]: selectedUser.email,
          [SIGNER_INFO.FIRST_NAME]: selectedUser.first_name,
          [SIGNER_INFO.LAST_NAME]: selectedUser.last_name,
          ...(isSignNow && { [SIGNER_INFO.TYPE]: 'manager' }),
        },
      );
    }
  };

  const emailInputHandler: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = ({ target }) => {
    const value = target.value;
    const selectedUser = localUserList.find((user) => user.email === value);

    if (isSignNow) {
      onChange(
        indexPosition,
        {
          ...(selectedUser
            ? {
              [SIGNER_INFO.EMAIL]: selectedUser.email,
              [SIGNER_INFO.FIRST_NAME]: selectedUser.first_name,
              [SIGNER_INFO.LAST_NAME]: selectedUser.last_name,
              [SIGNER_INFO.TYPE]: SignerType.MANAGER,
            }
            : {
              [SIGNER_INFO.EMAIL]: value ?? '',
              [SIGNER_INFO.TYPE]: isCCCard ? SignerType.CC_RECIPIENT : SignerType.RECIPIENT,
            }),
        },
      );
    }
  };

  const cardLabel = isOnlyCollection ? 'Signer' : signer.label ?? 'Signer';

  return (
    <div className="container select-signers-card">
      <div className="signer-card-cursor">
        <DragHandleIcon />
      </div>
      <div className="row">
        <div className="col-12">
          <div
            className={cn(
              'signer-card',
              {
                'signer-card-manager': isManagerCard,
                'signer-card-recipient': isRecipientCard,
                'signer-card-cc': isCCCard,
              },
            )}
          >
            <div className="text-capitalize">
              {
                signingOrderFlow === SignerFlow.JustMe
                  ? 'Signer(Me)'
                  : cardLabel
              }
            </div>
            {
              canDeleteCard && (
                <button
                  type="button"
                  className="p-0 select-signers-close"
                  onClick={() => onDelete(indexPosition)}
                >
                  <CloseIcon />
                </button>
              )
            }
          </div>
        </div>
      </div>
      <div className="row my-4">
        <div className="col-6">
          <InputField
            required
            label="First name"
            value={signer.firstName}
            onChange={(event) => onChange(indexPosition, { [SIGNER_INFO.FIRST_NAME]: event.target.value })}
            disabled={isDisabled}
            showError={signer.firstNameError}
            helperText={signer.firstNameErrorText}
          />
        </div>
        <div className="col-6">
          <InputField
            required={false}
            label="Last name"
            value={signer.lastName}
            onChange={(event) => onChange(indexPosition, { [SIGNER_INFO.LAST_NAME]: event.target.value })}
            disabled={isDisabled}
          />
        </div>
      </div>
      <div className="row my-4">
        <div className="col-12">
          {
            isManagerCard || isSignNow ? (
              <SearchEmailField
                freeSolo={isSignNow}
                signer={signer}
                disabled={isDisabled}
                useDisabledAutocomplete={isSignNow}
                onSelectChange={emailSelectHandler}
                onInputChange={emailInputHandler}
                usersArray={localUserList}
              />
            ) : (
              <InputField
                required
                label="Recipient's email"
                value={signer.email}
                onChange={emailChangeHandler}
                disabled={isManagerCard || isDisabled}
                showError={signer.emailError}
                helperText={signer.emailErrorText}
              />
            )
          }
        </div>
      </div>
    </div>
  );
});

export default SignerCard;
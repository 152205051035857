import { OrganizationInfoType } from 'types/AccountSettings';
import { IErrorField } from 'types/validation';

export const SUBSCRIPTION_TYPE_TRIAL = 'trialing';
export const TRIAL_ACCOUNT_LABEL = 'Trial Account';
export const MAX_TEMPLATES_COUNT_RESTRICTION = 'You can\'t create more templates in a free account';
export const MAX_TEMPLATES_FOR_FREE_TIER = 10;
export const REGISTER_FREE_TIER_ACCOUNT_LINK = 'https://www.unicornforms.com/sign-up';
export const UNICORNFORMS_BILLING_LINK = 'https://billing.unicornforms.com';

export const UNITED_STATES_COUNTRY_ID = '233';
export const TEXAS_STATE_ID = 1407;
export const HOUSTON_CITY_ID = 118699;

export const INITIAL_FORM_ERRORS: IErrorField = {
  name: null,
  address: null,
  postal_code: null,
  country: null,
  state_name: null,
  city: null,
};

export const DEFAULT_ORGANIZATION_INFO: OrganizationInfoType = {
  name: '',
  address: '',
  postal_code: '',
  country: '',
  state_name: '',
  city: '',
};

export const ORGANIZATION_INFO_FIELDS_NAMES = {
  NAME: 'name',
  COUNTRY: 'country',
  STATE_NAME: 'state_name',
  CITY: 'city',
  ADDRESS: 'address',
  POSTAL_CODE: 'postal_code',
} as const;

// Custom Domain Settings
export const CUSTOM_DOMAIN_PLACEHOLDER = 'sign.yourcompany.com';
export const CUSTOM_DOMAIN_FIELD_NAME = 'custom-domain-field';
export const CUSTOM_DOMAIN_HELP_TEXT = 'This setting maps one of your own domain names to UnicornForms, such as sign.yourcompany.com.';
export const CUSTOM_DOMAIN_EXPLANATION_TEXT = 'When you enter a domain name, it will be used immediately. You must create the proper CNAME DNS record before adding the domain. DNS only, without proxy. DO NOT save this record until you do so. You cannot change your subdomain more than once in 24 hours. Your system administrator will know how to perform this action correctly or you can contact UnicornForms to help you. The following table may assist:';
import { Editor as SlateEditor, Element as SlateElement, Range as SlateRange, Transforms } from 'slate';

import {
  PDF_COORDS_Y_COEFFICIENT,
  RECIPIENT_ASSIGNMENT,
  SELECT_FIELD_OTHER_OPTION,
  SELECT_FIELD_TYPE_CHECKBOX,
  SELECT_FIELD_TYPE_SELECT,
} from 'constants/editors';
import {
  BlockFormatType,
  ICustomElement,
  isBlockFormatType,
  LABEL_CONFIGURATION,
  SelectFieldOptionsType,
  SelectFieldOptionType,
} from 'types/Editor';
import { FieldsCoords, FieldTypeOption } from 'types/PdfTemplates';
import { AddOptionSelectFieldType } from 'types/Properties';
import { isBlockActive } from 'utils/editorBlockHelpers';
import { createField, findAndChangeDuplicatedFieldKeys, isNodeTextNotEmpty } from 'utils/editorFieldHelpers';
import { isCheckboxSelectView } from 'utils/Fields/checkboxRadioHelpers';
import { isNotEmptyObject } from 'utils/isEmptyObject';

export const toggleSelectField = (
  editor: SlateEditor,
  format: BlockFormatType,
  options: SelectFieldOptionType[],
  selectVariant?: SelectFieldOptionsType,
  assignment: string = RECIPIENT_ASSIGNMENT,
): number | null => {
  const { selection, forms } = editor;

  Transforms.unwrapNodes(editor, {
    match: (node) => (
      !SlateEditor.isEditor(node)
      && SlateElement.isElement(node)
      && isBlockFormatType(node.type)
      && node.type !== format
    ),
  });

  const isActive = isBlockActive(editor, format);

  if (isActive) {
    Transforms.unwrapNodes(editor, {
      match: (node) => (
        !SlateEditor.isEditor(node)
        && SlateElement.isElement(node)
        && node.type === format
      ),
    });
    return null;
  }

  const isCollapsed = selection && SlateRange.isCollapsed(selection);
  const additionalProps = selectVariant === SELECT_FIELD_TYPE_CHECKBOX ? {
    labelConfiguration: LABEL_CONFIGURATION.NONE,
    selectFieldType: SELECT_FIELD_TYPE_CHECKBOX as SelectFieldOptionsType,
  } : {};
  const newSelectField: SlateElement = {
    ...createField(format, isCollapsed, forms?.length || 0, assignment),
    ...additionalProps,
    options,
  };
  const fieldKey = newSelectField?.key || null;

  if (isCollapsed) {
    Transforms.insertNodes(editor, newSelectField, { match: (node) => isNodeTextNotEmpty(node) });
    findAndChangeDuplicatedFieldKeys(fieldKey || 0, editor);
  } else {
    Transforms.wrapNodes(editor, newSelectField, { split: true });
    findAndChangeDuplicatedFieldKeys(fieldKey || 0, editor);
    Transforms.collapse(editor, { edge: 'end' });
  }

  return fieldKey;
};

export const getIsSelectedOtherOption = (
  options: SelectFieldOptionType[] | undefined,
) => options?.find((option) => option.isSelectOtherOption && option.checked);

export const getPDFSelectCoordsFromPrevOption = (coords: FieldsCoords | undefined): FieldsCoords => {
  const x = coords?.x ?? 0;
  const y = coords?.y ?? 0;
  const isPrevCoordsHasY = coords?.y === undefined;
  const yCoefficient = !isPrevCoordsHasY ? PDF_COORDS_Y_COEFFICIENT : 0;
  return {
    x,
    y: y + yCoefficient,
  };
};

export const addSelectOptionHelper = ({
  localSetOptions,
  options,
  isPdfFlow,
  isSelectOtherOption = false,
}: AddOptionSelectFieldType) => {
  const newOption = isSelectOtherOption
    ? { id: Date.now(), label: SELECT_FIELD_OTHER_OPTION, isSelectOtherOption }
    : { id: Date.now(), label: '' };
  const prevOption = options[options.length - 1] as FieldTypeOption | undefined;
  const prevCoords = getPDFSelectCoordsFromPrevOption(prevOption?.coords);
  const newOptions = isPdfFlow
    ? [
      ...options,
      {
        ...newOption,
        coords: prevCoords,
        ...(
          isSelectOtherOption
            ? { coordsForOther: getPDFSelectCoordsFromPrevOption(prevCoords) }
            : {}
        ),
      },
    ]
    : [...options, newOption];
  const additionalProps = isSelectOtherOption
    ? { addedOtherOption: true, selectedOtherOption: false }
    : {};

  localSetOptions(newOptions as FieldTypeOption[]);

  return {
    options: newOptions,
    ...additionalProps,
  };
};

export const getSelectValuesToUpdate = ({
  fieldValue,
  previousValue,
  updatedOptions,
  fieldView = SELECT_FIELD_TYPE_SELECT,
}: {
  fieldValue: string;
  previousValue: string;
  updatedOptions?: SelectFieldOptionType[];
  fieldView?: SelectFieldOptionsType;
}): Partial<ICustomElement> => {
  const isAllUnchecked: boolean = updatedOptions?.every((option) => !option.checked) ?? false;
  const selectedOtherOption = getIsSelectedOtherOption(updatedOptions);

  // value for simple selects and radio
  let updatedValue = isAllUnchecked || selectedOtherOption ? '' : fieldValue;

  // checkboxes value
  if (isCheckboxSelectView(fieldView) && !isAllUnchecked) {
    updatedValue = selectedOtherOption ? previousValue : '';
  }

  return {
    value: updatedValue,
    options: updatedOptions,
    selectedOtherOption: isNotEmptyObject(selectedOtherOption ?? {}),
  };
};
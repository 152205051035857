import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import FontDownloadIcon from '@mui/icons-material/FontDownload';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import StrikethroughSIcon from '@mui/icons-material/StrikethroughS';

import MarkButton from 'components/Editor/components/MarkButton';
import BlockButton from 'components/Editor/components/ToolbarFieldsButtons/BlockButton';
import ImageButton from 'components/Editor/components/ToolbarFieldsButtons/ImageButton';
import PropertiesSelect from 'components/Editor/components/ToolbarFieldsButtons/PropertiesSelect';
import TableButton from 'components/Editor/components/ToolbarFieldsButtons/TableButton';
import { BOLD, FONT_TYPE_FORMAT, ITALIC, STRIKE_THROUGH, UNDERLINE } from 'constants/editors';
import TableIcon from 'svg/TableIcon';
import {
  BlockFormatType,
  IBlockButtonProps,
  IBlocksSelectProps,
  IMarkButtonProps,
  IMarksSelectProps,
  IToolbarCommand,
  IToolbarTab,
  MarkFormatType,
} from 'types/Editor';

export type ToolbarListArrayType = (
  IToolbarCommand<BlockFormatType, IBlockButtonProps> |
  IToolbarCommand<MarkFormatType, IMarkButtonProps> |
  IToolbarCommand<any[], IBlocksSelectProps> |
  IToolbarCommand<any[], IMarksSelectProps>
)[];

export const TOOLBAR_LIST: ToolbarListArrayType = [
  {
    id: 1,
    category: 'ALL',
    format: 'bulleted-list',
    title: 'Bulleted list',
    icon: <FormatListBulletedIcon color="inherit" />,
    Component: (props) => <BlockButton {...props} />,
  },
  {
    id: 2,
    category: 'ALL',
    format: 'numbered-list',
    title: 'Numbered list',
    icon: <FormatListNumberedIcon color="inherit" />,
    Component: (props) => <BlockButton {...props} />,
  },
  {
    id: 3,
    category: 'ALL',
    format: 'check-list-item',
    title: 'Checklist',
    icon: <CheckBoxOutlinedIcon color="inherit" />,
    Component: (props) => <BlockButton {...props} />,
  },
  {
    id: 4,
    category: 'ALL',
    format: 'table',
    title: 'Table',
    icon: <TableIcon color="inherit" />,
    Component: (props) => <TableButton {...props} />,
  },
  {
    id: 12,
    category: 'BASIC',
    formats: FONT_TYPE_FORMAT,
    title: 'Font type',
    icon: <FontDownloadIcon color="inherit" />,
    Component: (props: any) => <PropertiesSelect {...props} />,
  },
  {
    id: 5,
    category: 'BASIC',
    format: BOLD,
    title: 'Text bold',
    icon: <FormatBoldIcon color="inherit" />,
    Component: (props) => <MarkButton {...props} />,
  },
  {
    id: 6,
    category: 'BASIC',
    format: ITALIC,
    title: 'Text italic',
    icon: <FormatItalicIcon color="inherit" />,
    Component: (props) => <MarkButton {...props} />,
  },
  {
    id: 7,
    category: 'BASIC',
    format: UNDERLINE,
    title: 'Text underline',
    icon: <FormatUnderlinedIcon color="inherit" />,
    Component: (props) => <MarkButton {...props} />,
  },
  {
    id: 8,
    category: 'BASIC',
    format: STRIKE_THROUGH,
    title: 'Text strike through',
    icon: <StrikethroughSIcon color="inherit" />,
    Component: (props) => <MarkButton {...props} />,
  },
  {
    id: 9,
    category: 'MEDIA',
    format: 'image',
    title: 'Picture',
    icon: <InsertPhotoIcon color="inherit" />,
    Component: (props) => <ImageButton {...props} />,
  },
];

export const TOOLBAR_TABS: IToolbarTab[] = [
  { label: 'All', type: 'ALL' },
  { label: 'Basic', type: 'BASIC' },
  { label: 'Media', type: 'MEDIA' },
  { label: 'Developer', type: 'DEVELOPER' },
  { label: 'Embed', type: 'EMBED' },
];

export const HORIZONTAL_TOOLBAR_DEFAULT_TOP_OFFSET = 20;
export const HORIZONTAL_TOOLBAR_HEIGHT = 98;
export const HORIZONTAL_TOOLBAR_WIDTH = 410;
export const VERTICAL_TOOLBAR_WIDTH = 280;
export const VERTICAL_TOOLBAR_HEIGHT = 375;
export const MENTION_TOOLBAR_WIDTH = 280;
export const MENTION_TOOLBAR_HEIGHT = 180;
/* eslint-disable @typescript-eslint/no-explicit-any */
import { toast } from 'react-toastify';
import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import ENDPOINT from 'constants/endpoints';
import { DOCUMENT_TYPE, FORM_TYPE, PDF_TYPE } from 'constants/general';
import { SUCCESS_CODE } from 'constants/generalErrors';
import ROUTES from 'constants/routes';
import {
  addSection,
  addTemplate,
  addTemplateWithSection,
  createDocument,
  deleteDocumentDetails,
  editDocumentDetails,
  editPartialSections,
  editSection,
  editTemplate,
  getDocumentDetails,
  getDocumentList,
  getDocuments,
  getMetadataResources,
  getResources,
  getSectionDetails,
  getTemplateDetails,
  removeSection,
  removeTemplate,
} from 'services/api';
import { getResourceCount } from 'services/api/serverSideEvents';
import { getNotificationCount } from 'services/api/userNotifications';
import { history } from 'services/history';
import {
  ADD_SECTION,
  AUTOREFRESH_PROCESS,
  CLEAR_PAGE_SETTING_VALUE_FROM_NEW_DOCUMENT,
  COPY_TEMPLATE,
  CREATE_DOCUMENT,
  CREATE_SECTION,
  CREATE_TEMPLATE,
  CREATE_TEMPLATE_AND_SECTION,
  DELETE_DOCUMENTS,
  DELETE_SECTIONS,
  DELETE_TEMPLATES,
  EDIT_DOCUMENT_DETAILS,
  EDIT_SECTION,
  EDIT_SECTIONS,
  EDIT_TEMPLATE,
  GET_DOCUMENT_DETAILS,
  GET_DOCUMENTS_COUNT,
  GET_MULTI_TEMPLATES_SUCCESS,
  GET_NOTIFICATION_COUNT,
  GET_SECTION_DETAILS,
  GET_START_DATA,
  GET_TEMPLATE_DETAILS,
  SET_ALL_ASSIGNMENTS,
  SET_CREATED_DOCUMENT_FROM_PUBLIC,
  SET_DOCUMENT_DETAILS,
  SET_DOCUMENTS,
  SET_LAST_CREATED_SECTION,
  SET_METADATA_RESOURCES,
  SET_NOTIFICATION_COUNT,
  SET_RESOURCES,
  SET_SECTION_DETAILS,
  SET_SECTIONS,
  SET_TEMPLATE_DETAILS,
  SET_TEMPLATES,
  SET_USER_PERSONAL_DATA,
  SET_USER_PROFILE_DATA,
  UPDATE_DOCUMENTS_COUNT,
} from 'store/actions/actionTypes';
import { setActiveStripeSubscription } from 'store/actions/payment';
import { clearPdfTemplateData } from 'store/actions/pdfTemplates';
import { setIsSignNowActive, updateSignNowDocument } from 'store/actions/signNow';
import { RootStateType } from 'store/reducers';
import { safe } from 'store/sagas/errorHandlerSaga';
import { IResponseOfRequest } from 'types/Api';
import { IDocumentDetails } from 'types/Documents';
import { ICollectionDetails } from 'types/MultiTemplate';
import { PageSettingsType } from 'types/PageSettingsType';
import { IPublicPageState } from 'types/PublicPage';
import { ActionPayload } from 'types/redux';
import { ISection } from 'types/Sections';
import {
  ICopyTemplatePayload,
  ITemplate,
  ITemplateData,
  ITemplateDetails,
  ITemplateRequestObj,
} from 'types/Templates';
import { ProfileInfoType } from 'types/userProfile';
import { trackAmplitudeEvent } from 'utils/amplitude/amplitudeTrackingUtlis';
import { getAmplitudeEventName } from 'utils/amplitude/amplitudeUtils';
import { arraySortByDate } from 'utils/arraySortByDate';
import { adaptOldAssignmentsObject } from 'utils/assignmentsHelpers';
import { runAttachmentsLoading } from 'utils/attachmentHelpers';
import { getPdfUrlAndWidth } from 'utils/pdfTemplatesHelpers';
import { getIsPDFDocument } from 'utils/PublicPage/documentTypeChecker';
import { getEditorPageLink } from 'utils/routeHelpers';
import { getRouteForCopiedDocument, prepareNewPdfTemplate, prepareNewTemplate } from 'utils/templateSagasHelpers';

const getStartData = function* () {
  const resources: IResponseOfRequest<any> = yield getResources();
  yield put({
    type: SET_RESOURCES,
    payload: {
      documents: resources.data.documents,
      documentsExecuted: resources.data.documents_executed,
      documentsCount: resources.data.documents_count,
      collections: resources.data.collections,
      collectionsExecuted: resources.data.collections_executed,
      signnowDocumentsSentCurrentMonth: resources.data.signnow_documents_sent_current_month,
    },
  });

  if (resources.data.subscription) {
    yield put(setActiveStripeSubscription(resources.data.subscription));
  }

  const currentUserData = resources.data.user;
  const userPersonalData = currentUserData.personal_data;
  currentUserData.userRole = currentUserData.roles[0].name;

  yield put({ type: SET_USER_PROFILE_DATA, payload: currentUserData });
  yield put({ type: SET_USER_PERSONAL_DATA, payload: userPersonalData });

  const metadataResources: IResponseOfRequest<any> = yield getMetadataResources();
  yield put({
    type: SET_METADATA_RESOURCES,
    payload: {
      states: metadataResources.data.states,
      doctypes: metadataResources.data.documents_types,
      statuses: metadataResources.data.statuses,
      piiList: metadataResources.data.pii_list,
    },
  });

  // TBD in future
  // const sections: IResponseOfRequest<ISection[]> = yield getSectionsList();
  // yield put({ type: SET_SECTIONS, payload: sections.data });
};

// Deprecated. Old documents autoupdate flow.
const getDocumentsCountFromDB = function* ({
  payload: { resourcePath },
}: { type: string; payload: { resourcePath: string } }) {
  const documentsCount: IResponseOfRequest<any> = yield getResourceCount(resourcePath);
  const isDocumentsAutoUpdate: boolean = resourcePath === ENDPOINT.DOCUMENTS;
  const currentCount: number = isDocumentsAutoUpdate
    ? yield select((state) => state.user.documentsCount)
    : yield select((state) => state.user.documentsExecutedCount);

  if (currentCount !== documentsCount?.data) {
    yield put({ type: AUTOREFRESH_PROCESS, payload: true });
    const {
      data,
      count, // eslint-disable-line @typescript-eslint/no-unused-vars
    }: IResponseOfRequest<IDocumentDetails[] & {
      collections: ICollectionDetails[];
      documents: ITemplateDetails[]
    }> = isDocumentsAutoUpdate
      ? yield getDocumentList()
      : yield getDocuments();

    if (isDocumentsAutoUpdate) {
      yield all([
        put({
          type: GET_MULTI_TEMPLATES_SUCCESS,
          payload: { data: arraySortByDate(data.collections) },
        }),
        put({
          type: SET_TEMPLATES,
          payload: arraySortByDate(data.documents),
        }),
        put({
          type: UPDATE_DOCUMENTS_COUNT,
          payload: { count: data.collections.length + data.documents.length },
        }),
      ]);
    } else {
      yield put({
        type: SET_DOCUMENTS,
        payload: arraySortByDate(data),
      });
    }
    yield put({ type: AUTOREFRESH_PROCESS, payload: false });
  }
};

const getUserNotificationCount = function* () {
  const notificationCountResponse: IResponseOfRequest<any> = yield getNotificationCount();
  const currentCount: number = yield select((state) => state.user.notificationsCount);
  if (notificationCountResponse?.status === SUCCESS_CODE && currentCount !== notificationCountResponse?.data) {
    yield put({
      type: SET_NOTIFICATION_COUNT,
      payload: notificationCountResponse?.data || 0,
    });
  }
};

const deleteTemplates = function* ({ payload }: any) {
  let documents: ITemplate[] = yield select((state) => state.user.documents);
  const userInfo: ProfileInfoType = yield select((state: RootStateType) => state.profile.profileInfo);
  const documentsCount: number = yield select((state: RootStateType) => state.user.documentsCount);

  // eslint-disable-next-line
  for (let i in payload) {
    const response: IResponseOfRequest<any> = yield removeTemplate(payload[i]);
    if (response && response.status === 207) {
      toast.error('You can\'t delete this template, because document created from it exists');
      return;
    }
    yield put({ type: UPDATE_DOCUMENTS_COUNT, payload: documentsCount - 1 });
    const deletedDocument: ITemplate | undefined = documents.find((document: ITemplate) => document.id === payload[i]);
    trackAmplitudeEvent({
      eventName: getAmplitudeEventName({ data: deletedDocument, singleFlow: false }).deleted,
      userInfo,
    });
    documents = documents.filter((document) => document.id !== parseInt(payload[i]));
  }

  yield put({ type: SET_TEMPLATES, payload: documents });
};

const copyTemplateSaga = function* ({
  payload: { id, isLightSectionTemplate },
}: ActionPayload<ICopyTemplatePayload>): SagaIterator {
  try {
    const { data: template }: IResponseOfRequest<ITemplateDetails> = yield call(getTemplateDetails, id);

    let createdTemplate: ITemplate | null = null;
    const isPdfDocument = getIsPDFDocument(template.type);
    if (!isPdfDocument) {
      const preparedTemplate: ITemplateData | ITemplateRequestObj = prepareNewTemplate(
        template,
        isLightSectionTemplate,
      );
      if (isLightSectionTemplate) {
        const { data }: IResponseOfRequest<ITemplate> = yield call(
          addTemplateWithSection,
          preparedTemplate as ITemplateData,
        );
        createdTemplate = data;
      } else {
        const { data }: IResponseOfRequest<ITemplate> = yield call(
          addTemplate,
          preparedTemplate as ITemplateRequestObj,
        );
        createdTemplate = data;
      }
    } else {
      const preparedTemplate: ITemplateData | ITemplateRequestObj = prepareNewPdfTemplate(template);
      const { data }: IResponseOfRequest<ITemplate> = yield call(
        addTemplate,
        { ...preparedTemplate, copy_pdf_document_flow: true } as ITemplateRequestObj,
      );
      createdTemplate = data;
    }

    if (createdTemplate) {
      const newTemplateId: number | null = createdTemplate.id;

      const documents: ITemplate[] = yield select((state) => state.user.documents.concat(createdTemplate));
      yield put({ type: SET_TEMPLATES, payload: documents });
      yield call(history.push, `${getRouteForCopiedDocument(createdTemplate.type)}/${newTemplateId}`);
    }
  } catch (e: any) {
    // eslint-disable-next-line no-console
    console.error(e.message);
  }
};

const createSectionAndAddToTemplate = function* ({ payload }: any) {
  const newSection: IResponseOfRequest<ISection> = yield addSection(payload);
  yield put({ type: SET_LAST_CREATED_SECTION, payload: newSection.data });

  const sections: ISection[] = yield select((state) => state.user.sections);
  yield put({ type: SET_SECTIONS, payload: sections.concat(newSection.data) });
};

const updateDocumentList = function* (document: IResponseOfRequest<ITemplate | IDocumentDetails>) {
  yield put({ type: SET_CREATED_DOCUMENT_FROM_PUBLIC, payload: document.data.id });
  const documentsExecuted: IDocumentDetails[] = yield select((state) => state.user.documentsExecuted);
  const updatedDocuments = [document.data, ...documentsExecuted];
  yield put({ type: SET_DOCUMENTS, payload: updatedDocuments });
};

const createTemplate = function* ({
  payload: {
    template,
    withSignNow = false,
  },
}: {
  payload: {
    template: ITemplateRequestObj
    withSignNow?: boolean,
  },
}) {
  const pageSettings: PageSettingsType | null = yield select((state: RootStateType) => (
    state.user.newDocumentDetails?.page_settings
  ));
  const documentsCount: number = yield select((state: RootStateType) => state.user.documentsCount);
  if (pageSettings) {
    // eslint-disable-next-line no-param-reassign
    template.page_settings = pageSettings;
    yield put({ type: CLEAR_PAGE_SETTING_VALUE_FROM_NEW_DOCUMENT });
  }
  const newTemplate: IResponseOfRequest<ITemplate> = yield addTemplate(template);
  const userInfo: ProfileInfoType = yield select((state: RootStateType) => state.profile.profileInfo);
  if (template.is_single) {
    yield updateDocumentList(newTemplate);
  } else {
    const documents: ITemplate[] = yield select((state) => state.user.documents.concat(newTemplate.data));
    yield put({ type: SET_TEMPLATES, payload: documents });
    yield put({ type: UPDATE_DOCUMENTS_COUNT, payload: documentsCount + 1 });
  }

  trackAmplitudeEvent({
    eventName: getAmplitudeEventName({ data: newTemplate.data, singleFlow: template.is_single }).created,
    userInfo,
  });

  if (withSignNow) {
    yield put(updateSignNowDocument({
      id: newTemplate.data.id,
      fileKey: getPdfUrlAndWidth(template.pdf_file_url ?? '')[0],
    }));
  }

  if (newTemplate.data.id) {
    yield toast.success('Document created');
  }
  if (withSignNow) {
    history.push(`${ROUTES.SIGN_NOW}/create`);
  } else {
    switch (newTemplate.data.type) {
      case DOCUMENT_TYPE:
      case PDF_TYPE:
      case FORM_TYPE:
        history.push(getEditorPageLink(
          `${newTemplate.data.id}`,
          newTemplate.data.type,
          template.is_single,
        ));
        break;
      default:
        history.push(ROUTES.DASHBOARD);
    }
  }
};

const createTemplateAndSection = function* ({ payload }: any) {
  const pageSettings: PageSettingsType | null = yield select((state: RootStateType) => (
    state.user.newDocumentDetails?.page_settings
  ));
  const documentsCount: number = yield select((state: RootStateType) => state.user.documentsCount);
  if (pageSettings) {
    // eslint-disable-next-line no-param-reassign
    payload.template.page_settings = pageSettings;
    yield put({ type: CLEAR_PAGE_SETTING_VALUE_FROM_NEW_DOCUMENT });
  }
  const newTemplate: IResponseOfRequest<ITemplate> = yield addTemplateWithSection(payload);
  const userInfo: ProfileInfoType = yield select((state: RootStateType) => state.profile.profileInfo);
  const { totalAttachedFilesCounter }: IPublicPageState = yield select((state: RootStateType) => state.publicPages);

  const isSingleDocument = payload.template.is_single;

  const id = newTemplate.data.id;
  if (isSingleDocument) {
    yield updateDocumentList(newTemplate);
  } else {
    yield put({ type: SET_CREATED_DOCUMENT_FROM_PUBLIC, payload: id });
    const documents: ITemplate[] = yield select((state) => state.user.documents.concat(newTemplate.data));
    yield put({ type: SET_TEMPLATES, payload: documents });
    yield put({ type: UPDATE_DOCUMENTS_COUNT, payload: documentsCount + 1 });

    const newSectionsData = newTemplate.data?.sections;
    if (newSectionsData) {
      const sectionsData: ISection[] = newSectionsData.map((documentSection: any) => (
        documentSection.section
      ));
      const sections: ISection[] = yield select((state) => state.user.sections.concat(sectionsData));
      yield put({ type: SET_SECTIONS, payload: sections });
    }
  }

  trackAmplitudeEvent({
    eventName: getAmplitudeEventName({ data: newTemplate.data, singleFlow: isSingleDocument }).created,
    userInfo,
  });

  if (id) {
    yield toast.success('Document created');
    if (totalAttachedFilesCounter) {
      runAttachmentsLoading(String(id));
    } else {
      const documentRoute = isSingleDocument ? ROUTES.DOCUMENTS_EXECUTED_EDITOR : ROUTES.DOCUMENTS_EDITOR;
      const formRoute = isSingleDocument ? ROUTES.FORM_EXECUTED_EDITOR : ROUTES.FORM_EDITOR;
      const link = payload.template.type === FORM_TYPE ? formRoute : documentRoute;

      history.push(`${link}/${id}`);
    }
  }
};

const getUserTemplateDetails = function* ({ payload }: any) {
  const template: IResponseOfRequest<ITemplateDetails> = yield getTemplateDetails(payload);
  yield put({ type: SET_TEMPLATE_DETAILS, payload: template?.data });
  if (template?.data.assignments) {
    yield put({ type: SET_ALL_ASSIGNMENTS, payload: adaptOldAssignmentsObject(template?.data.assignments) });
  }
};

const editUserTemplate = function* ({ payload }: any) {
  const pageSettings: PageSettingsType = yield select((state: RootStateType) => (
    state.user.templateDetails?.page_settings
  ));

  const currentTemplate = pageSettings
    ? {
      ...payload.template,
      page_settings: pageSettings,
    }
    : payload.template;

  const template: IResponseOfRequest<ITemplate> = yield editTemplate(payload.id, currentTemplate);
  const userInfo: ProfileInfoType = yield select((state: RootStateType) => state.profile.profileInfo);
  const { totalAttachedFilesCounter }: IPublicPageState = yield select((state: RootStateType) => state.publicPages);

  trackAmplitudeEvent({
    eventName: getAmplitudeEventName({ data: template.data, singleFlow: false }).saved,
    userInfo,
  });

  const id = template.data.id;

  yield put({ type: SET_CREATED_DOCUMENT_FROM_PUBLIC, payload: id });

  if (!payload.path) {
    yield put({ type: SET_TEMPLATE_DETAILS, payload: template.data });
  }
  const documents: ITemplate[] = yield select((state) => state.user.documents.map((oldTemplate: any) => {
    if (oldTemplate.id === id) {
      return {
        ...oldTemplate,
        ...template.data,
      };
    }
    return oldTemplate;
  }));
  yield put({ type: SET_TEMPLATES, payload: documents });

  if (id) {
    yield toast.success('Document updated');

    if (totalAttachedFilesCounter) {
      runAttachmentsLoading(String(id));
    } else if (!totalAttachedFilesCounter && payload.path) {
      if (getIsPDFDocument(template.data?.type)) {
        yield clearPdfTemplateData();
      }
      history.push(payload.path);
    }
  }
};

const editSections = function* ({ payload }: any) {
  const editedSections: IResponseOfRequest<ISection[]> = yield editPartialSections(payload);
  const newSections: ISection[] = yield select(
    (state: RootStateType) => state.user.sections.map((oldSection: ISection) => {
      const findedSection = editedSections.data.find((newSection) => oldSection.id === newSection.id);
      if (findedSection) return findedSection;
      return oldSection;
    }),
  );

  yield put({ type: SET_SECTIONS, payload: newSections });
};

const deleteSections = function* ({ payload }: any) {
  let newSections: ISection[] = yield select((state) => state.user.sections);

  // eslint-disable-next-line
  for (let i in payload) {
    yield removeSection(payload[i]);
    newSections = yield newSections.filter((section: any) => section.id !== parseInt(payload[i]));
  }

  yield put({ type: SET_SECTIONS, payload: newSections });
};

const createSection = function* ({ payload }: any) {
  const newSection: IResponseOfRequest<ISection> = yield addSection(payload);
  const sections: ISection[] = yield select((state) => state.user.sections.concat(newSection.data));
  yield put({ type: SET_SECTIONS, payload: sections });
  yield history.push(ROUTES.SECTIONS);
};

const getSection = function* ({ payload }: any) {
  const section: IResponseOfRequest<ISection> = yield getSectionDetails(payload);
  yield put({ type: SET_SECTION_DETAILS, payload: section.data });
};

const saveEditedSection = function* ({ payload }: any) {
  const section: IResponseOfRequest<ISection> = yield editSection(payload.id, payload.section);
  const sections: ISection[] = yield select((state) => state.user.sections.map((s: any) => {
    if (s.id === section.data.id) return section.data;
    return s;
  }));
  yield put({ type: SET_SECTIONS, payload: sections });
  yield history.push(ROUTES.SECTIONS);
};

const getUserDocumentDetails = function* ({ payload }: any) {
  const document: IResponseOfRequest<IDocumentDetails> = yield getDocumentDetails(payload);
  yield put({ type: SET_DOCUMENT_DETAILS, payload: document.data });
  if (document?.data.assignments) {
    yield put({ type: SET_ALL_ASSIGNMENTS, payload: adaptOldAssignmentsObject(document?.data.assignments) });
  }
  if (document.data.is_signnow_document) {
    yield put(setIsSignNowActive());
  }
};

const editUserDocumentDetails = function* ({ payload }: any) {
  const pageSettings: PageSettingsType = yield select((state: RootStateType) => (
    state.user.documentDetails?.page_settings
  ));
  const currentDocument = pageSettings !== null
    ? {
      ...payload.document,
      page_settings: pageSettings,
    }
    : payload.document;

  const document: IResponseOfRequest<IDocumentDetails> = yield editDocumentDetails(
    payload.id,
    currentDocument,
  );

  const { totalAttachedFilesCounter }: IPublicPageState = yield select((state: RootStateType) => state.publicPages);

  const id = document.data.id;
  if (document && document.status === SUCCESS_CODE) {
    yield put({ type: SET_CREATED_DOCUMENT_FROM_PUBLIC, payload: id });
    const userInfo: ProfileInfoType = yield select((state: RootStateType) => state.profile.profileInfo);

    if (payload.completeFlow) {
      trackAmplitudeEvent({
        eventName: getAmplitudeEventName({ data: document.data }).completed,
        userInfo,
      });
    }

    if (document.data?.template?.is_single && !payload.completeFlow) {
      trackAmplitudeEvent({
        eventName: getAmplitudeEventName({ data: document.data, singleFlow: true }).saved,
        userInfo,
      });
    }

    yield toast.success('Document saved');
    if (!payload.completeDocModalWindow) {
      yield put({ type: SET_DOCUMENT_DETAILS, payload: document.data });
    }
  } else {
    return toast.error('An error occurred while saving the document');
  }
  const documentsExecuted: IDocumentDetails[] = yield select((state) => state.user.documentsExecuted);
  const updatedDocuments = documentsExecuted.map((oldDocument: any) => {
    if (oldDocument.id === id) return document.data;
    return oldDocument;
  });
  yield put({ type: SET_DOCUMENTS, payload: updatedDocuments });

  if (id) {
    if (totalAttachedFilesCounter) {
      runAttachmentsLoading(String(id));
    } else if (payload.path) {
      yield put({ type: SET_CREATED_DOCUMENT_FROM_PUBLIC, payload: null });
      history.push(`${payload.path}`);
    }
  }
};

const deleteUserDocuments = function* ({ payload }: any) {
  let newDocumentsExecuted: IDocumentDetails[] = yield select((state) => state.user.documentsExecuted);
  const singleDocuments: IDocumentDetails[] = newDocumentsExecuted.filter(
    (document: IDocumentDetails) => document.template?.is_single,
  );
  const userInfo: ProfileInfoType = yield select((state: RootStateType) => state.profile.profileInfo);
  // eslint-disable-next-line
  for (let i in payload) {
    yield deleteDocumentDetails(payload[i]);
    newDocumentsExecuted = yield newDocumentsExecuted.filter(
      (document: any) => document.id !== parseInt(payload[i]),
    );

    singleDocuments.forEach((document: IDocumentDetails) => {
      if (document.id === payload[i]) {
        trackAmplitudeEvent({
          eventName: getAmplitudeEventName({ data: document, singleFlow: true }).deleted,
          userInfo,
        });
      }
    });
  }
  yield put({ type: SET_DOCUMENTS, payload: newDocumentsExecuted });
};

const createUserDocument = function* ({ payload }: any) {
  const documentExecuted: IResponseOfRequest<IDocumentDetails> = yield createDocument(payload.document);
  yield updateDocumentList(documentExecuted);

  const { totalAttachedFilesCounter }: IPublicPageState = yield select((state: RootStateType) => state.publicPages);
  if (totalAttachedFilesCounter === 0) {
    yield put({ type: SET_CREATED_DOCUMENT_FROM_PUBLIC, payload: 0 });
    const pageUrl = getEditorPageLink(String(documentExecuted.data.id), documentExecuted.data?.type, true);
    yield history.push(pageUrl);
  }
};

export default [
  takeLatest(COPY_TEMPLATE, safe(copyTemplateSaga)),
  takeLatest(DELETE_TEMPLATES, safe(deleteTemplates)),
  takeLatest(GET_START_DATA, safe(getStartData)),
  takeLatest(ADD_SECTION, safe(createSectionAndAddToTemplate)),
  takeLatest(CREATE_TEMPLATE, safe(createTemplate)),
  takeLatest(CREATE_TEMPLATE_AND_SECTION, safe(createTemplateAndSection)),
  takeLatest(GET_TEMPLATE_DETAILS, safe(getUserTemplateDetails)),
  takeLatest(EDIT_TEMPLATE, safe(editUserTemplate)),
  takeLatest(EDIT_SECTIONS, safe(editSections)),
  takeLatest(DELETE_SECTIONS, safe(deleteSections)),
  takeLatest(CREATE_SECTION, safe(createSection)),
  takeLatest(GET_SECTION_DETAILS, safe(getSection)),
  takeLatest(EDIT_SECTION, safe(saveEditedSection)),
  takeLatest(GET_DOCUMENT_DETAILS, safe(getUserDocumentDetails)),
  takeLatest(EDIT_DOCUMENT_DETAILS, safe(editUserDocumentDetails)),
  takeLatest(DELETE_DOCUMENTS, safe(deleteUserDocuments)),
  takeLatest(CREATE_DOCUMENT, safe(createUserDocument)),
  takeLatest(GET_DOCUMENTS_COUNT, safe(getDocumentsCountFromDB, false, true)),
  takeLatest(GET_NOTIFICATION_COUNT, safe(getUserNotificationCount, false, true)),
];

import { useSelector } from 'react-redux';

import { STATUSES } from 'constants/documentStatuses';
import { DOCUMENT_TYPE, PDF_TYPE } from 'constants/general';
import { RootStateType } from 'store/reducers';
import { createCSSString, getHtmlAndCssForCollectionDocuments } from 'utils/fileLinkDownload';
import { getMarginsForPageInInches } from 'utils/PageSettings';
import { getPdfUrlAndWidth } from 'utils/pdfTemplatesHelpers';

const useDownloadCollection = (
  isCollectionPage = false,
  isCollectionExecutedPage = false,
) => {
  const {
    collectionDetails,
    collectionExecutedDetails,
  } = useSelector((state: RootStateType) => state.user);

  const getCollectionRequestObject = async () => {
    const collectionRequestObject = {
      isCollection: true,
      css: await createCSSString(),
      documents: [] as any,
    };
    let updatedDocuments: any[] = [];
    if (isCollectionExecutedPage && collectionExecutedDetails) {
      const collectionDocuments = await getHtmlAndCssForCollectionDocuments(collectionExecutedDetails);

      updatedDocuments = collectionExecutedDetails?.documents.map((documentObject: any, index: number) => {
        const isPdfResource = documentObject.document.type === PDF_TYPE;
        let pageMargins: string | null = null;
        if (documentObject.document.type === DOCUMENT_TYPE) {
          pageMargins = getMarginsForPageInInches(documentObject.document.page_settings);
        }

        return {
          isPdfResource,
          html: collectionDocuments[index].document.html,
          pdfBasePath: isPdfResource ? getPdfUrlAndWidth(documentObject.document.template.pdf_file_url)[0] : null,
          document_id: documentObject.document.id,
          add_log_page: false,
          combine_pdf: false,
          is_completed_document: documentObject.document.status === STATUSES.completed,
          document_name: documentObject.document.name,
          page_margins: pageMargins,
        };
      });
    } else if (isCollectionPage && collectionDetails) {
      const collectionDocuments = await getHtmlAndCssForCollectionDocuments(collectionDetails);

      updatedDocuments = collectionDetails?.templates.map((templateObject: any, index: number) => {
        const isPdfResource = templateObject.template.type === PDF_TYPE;
        let pageMargins: string | null = null;
        if (templateObject.template.type === DOCUMENT_TYPE) {
          pageMargins = getMarginsForPageInInches(templateObject.template.page_settings);
        }

        return {
          isPdfResource,
          html: collectionDocuments[index].document.html,
          pdfBasePath: isPdfResource ? getPdfUrlAndWidth(templateObject.template.pdf_file_url)[0] : null,
          document_id: null,
          add_log_page: false,
          combine_pdf: false,
          is_completed_document: false,
          document_name: templateObject.template.name,
          page_margins: pageMargins,
        };
      });
    }
    collectionRequestObject.documents = updatedDocuments;
    return collectionRequestObject;
  };

  return {
    getCollectionRequestObject,
  };
};

export default useDownloadCollection;
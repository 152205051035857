import {
  DELETE_COMPANY_LOGO,
  DELETE_CUSTOM_DOMAIN,
  GET_COMPANY_BRANDING,
  SET_COMPANY_BRANDING,
  SET_COMPANY_LOGO,
  SET_CUSTOM_DOMAIN,
  SET_CUSTOM_DOMAIN_CREATION_DATE,
  UPDATE_COMPANY_STYLE_SCHEMA,
  UPLOAD_COMPANY_LOGO,
} from 'store/actions/actionTypes';
import { StyleSchemaType } from 'types/BrandingColorSchema';
import { ICompanyBrandingResponse, ICompanyLogoResponse } from 'types/CompanyBranding';

export const getCompanyBranding = () => ({
  type: GET_COMPANY_BRANDING,
});

export const setCompanyBranding = (data: ICompanyBrandingResponse) => ({
  type: SET_COMPANY_BRANDING,
  payload: {
    logoPath: data.logo_path,
    styleJSON: data.style_json,
    customDomain: data.custom_domain,
    domainCreationDate: data.domain_creation_date,
  },
});

export const updateCompanyColorSchema = (
  payload: Record<string, string | StyleSchemaType | null>,
) => ({
  type: UPDATE_COMPANY_STYLE_SCHEMA,
  payload,
});

export const uploadCompanyLogo = (file: File) => ({
  type: UPLOAD_COMPANY_LOGO,
  payload: { file },
});

export const setCompanyLogo = (data: ICompanyLogoResponse) => ({
  type: SET_COMPANY_LOGO,
  payload: {
    logoPath: data.logo_path,
  },
});

export const deleteCompanyLogo = () => ({
  type: DELETE_COMPANY_LOGO,
});

export const setCustomDomainInStore = (customDomain: string | null) => ({
  type: SET_CUSTOM_DOMAIN,
  payload: customDomain,
});

export const setCustomDomainCreationDateInStore = (domainCreationDate: string | null) => ({
  type: SET_CUSTOM_DOMAIN_CREATION_DATE,
  payload: domainCreationDate,
});

export const deleteCustomDomain = () => ({
  type: DELETE_CUSTOM_DOMAIN,
});
import { put, select, takeLatest } from 'redux-saga/effects';

import { SUCCESS_CODE, SUCCESS_CREATE_CODE } from 'constants/generalErrors';
import { SignerFlow } from 'constants/signNow';
import { sendAssignmentsArraySendFlow, sendDocuments } from 'services/api';
import {
  ADD_SIGNNOW_DOC_SENT_CURRENT_MONTH,
  GET_MULTI_TEMPLATES_SUCCESS,
  SEND_DOCUMENTS,
  SET_EXECUTED_DOC_ID,
  SET_UPDATED_SIGNERS,
  UPDATE_SIGNERS_ACCORDING_TO_FIELDS,
} from 'store/actions/actionTypes';
import { RootStateType } from 'store/reducers';
import { safe } from 'store/sagas/errorHandlerSaga';
import { IResponseOfRequest } from 'types/Api';
import { IMultiTemplate } from 'types/MultiTemplate';
import { SendDocumentsReducerType, SignersInfoType } from 'types/SendDocuments';

const sendDocumentsViaEmail = function* () {
  const sendDocumentsObject: SendDocumentsReducerType = yield select((state: RootStateType) => state.sendDocuments);
  const signingOrderFlow: SignerFlow | null = yield select((state: RootStateType) => state.signNow.currentFlow);
  const { isRemindForAllSigners, isRemindDocumentActive } = yield select((state: RootStateType) => state.signNow);
  const signnowDocumentsSentCurrentMonth: number | null = yield select(
    (state: RootStateType) => state.user.signnowDocumentsSentCurrentMonth,
  );

  const response: IResponseOfRequest<IMultiTemplate | string | any> = yield sendDocuments({
    ...sendDocumentsObject,
    ...(isRemindDocumentActive && {
      isRemind: true,
      isRemindForAllSigners,
    }),
    ...(signingOrderFlow && { signingOrderFlow }),
  });

  if (response.status === SUCCESS_CREATE_CODE) {
    const collections: IMultiTemplate[] = yield select((state) => state.user.collections);
    yield put({
      type: GET_MULTI_TEMPLATES_SUCCESS,
      payload: { data: [...collections, response.data] },
    });
  } else if (response.status === SUCCESS_CODE && response.data?.document_id) {
    yield put({
      type: SET_EXECUTED_DOC_ID,
      payload: response.data.document_id,
    });
    if (signnowDocumentsSentCurrentMonth !== null) {
      yield put({ type: ADD_SIGNNOW_DOC_SENT_CURRENT_MONTH });
    }
  }
};

const updateSignersAccordingToFields = function* ({
  payload,
}: {
  payload: {
    signersArray: SignersInfoType[],
    resourceId: number,
    resourceType: string
  }
}) {
  const response: IResponseOfRequest<{ signers: SignersInfoType[] }> = yield sendAssignmentsArraySendFlow({
    signersArray: payload.signersArray,
    resourceId: payload.resourceId,
    resourceType: payload.resourceType,
  });
  if (response && response.status === SUCCESS_CODE) {
    yield put({ type: SET_UPDATED_SIGNERS, payload: response.data.signers });
  }
};

export default [
  takeLatest(SEND_DOCUMENTS, safe(sendDocumentsViaEmail)),
  takeLatest(UPDATE_SIGNERS_ACCORDING_TO_FIELDS, safe(updateSignersAccordingToFields)),
];
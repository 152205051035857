import { useSelector } from 'react-redux';

import { RECIPIENT_ASSIGNMENT } from 'constants/editors';
import { URL_PUBLIC_DOCUMENT } from 'constants/general';
import ROUTES from 'constants/routes';
import { RootStateType } from 'store/reducers';

const useShareLinkRecipientsNames = (publicRoute: string) => {
  const {
    documentPublicLink,
    collectionExecutedPublicLink,
  } = useSelector((state: RootStateType) => state.publicPages);
  const {
    documentsExecuted,
    collectionsExecuted,
  } = useSelector((state: RootStateType) => state.user);
  const { assignments } = useSelector((state: RootStateType) => state.editorSlate);
  let currentAssignments = assignments;
  const recipientsNames: Record<string, string | undefined> = {};

  if (publicRoute === ROUTES.PUBLIC_COLLECTION_EXECUTED) {
    const currentCollection = collectionsExecuted.find(
      (collection: any) => collection.id === collectionExecutedPublicLink.resource_id,
    );
    if (currentCollection) {
      const creator = currentCollection.creator;
      recipientsNames[RECIPIENT_ASSIGNMENT] = `${creator.first_name} ${creator.last_name}`;
    }
  } else {
    if (publicRoute === URL_PUBLIC_DOCUMENT) {
      const currentDoc = documentsExecuted.find((document: any) => document.id === documentPublicLink.resource_id);
      if (currentDoc) {
        currentAssignments = currentDoc.assignments;
      }
    }
    Object.values(currentAssignments).forEach((assignmetnsArray) => {
      assignmetnsArray.forEach((assignment) => {
        recipientsNames[assignment.type] = (assignment.firstName && assignment.lastName)
          && `${assignment.firstName} ${assignment.lastName}`.trim().length
          ? `${assignment.firstName} ${assignment.lastName}`
          : undefined;
      });
    });
  }

  return recipientsNames;
};

export default useShareLinkRecipientsNames;
import { memo, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Form } from 'reactstrap';

import AccountSettingsWrapper from 'components/AccountSettings/AccountSettingsWrapper';
import Button from 'components/Base/Button';
import KebabMenu, { HandlersValueType } from 'components/KebabMenu';
import ConfirmationModal from 'components/Modals/ConfirmationModal';
import ManagementModal from 'components/Modals/UserManagementModal/ManagementModal';
import UserModal from 'components/Modals/UserManagementModal/UserModal';
import MUITableManagement from 'components/MuiTables/MUITableManagement';
import { OK, SAVE } from 'constants/general';
import { setKebabColumnHeading, USER_MANAGEMENT_MENU } from 'constants/KebabMenus';
import { ADMIN_ROLES } from 'constants/roles';
import { SEATS_LIMIT_NOTIFICATION } from 'constants/validation';
import { useRolePermission } from 'hooks/useRolePermission';
import useUserListLoader from 'hooks/useUserListLoader';
import {
  createNewUser,
  deleteUserAction,
  resendInviteToUser,
  updateUserInfo,
} from 'store/actions/userProfile';
import { RootStateType } from 'store/reducers';
import { SelectedRowsType, UserListRowsType } from 'types/Mui';
import {
  IUserManagementFields,
  ModalUserType,
  USER_MANAGEMENT_MODAL_STATUS,
  UserListGetterType,
  UserListStoreType,
} from 'types/userProfile';
import {
  getUserListData,
  setSensitiveInfoAccessHeading,
  userManagementColumns,
} from 'utils/dataColumns';
import { modalUserManagementFieldsGenerator } from 'utils/modalHelpers';
import { capitalizeString } from 'utils/stringsHelpers';
import { modalFormValidation } from 'utils/validation';

import 'scss/components/_managementSettings.scss';

const UserManagement = () => {
  const dispatch = useDispatch();
  const userList: UserListStoreType = useUserListLoader();
  const { permissionUsingFieldMasking } = useRolePermission();
  const { userRole, id: currentUserId } = useSelector((state: RootStateType) => state.profile.profileInfo);
  const { isLoaded, error, isLoading } = useSelector((state: RootStateType) => state.errorLoading);

  const [documentsRows, setDocumentsRows] = useState<UserListRowsType>([]);
  const [isModalVisible, setIsModalVisibility] = useState<boolean>(false);
  const [isDeleteUserModalVisible, setIsDeleteUserModalVisible] = useState<boolean>(false);
  const [rowToDelete, setRowToDelete] = useState<{ id: number }>();
  const [modalStatus, setModalStatus] = useState<USER_MANAGEMENT_MODAL_STATUS>(USER_MANAGEMENT_MODAL_STATUS.EDIT);
  const [currentUser, setCurrentUser] = useState<ModalUserType>(null);
  const [modalFields, setModalFields] = useState<IUserManagementFields[]>([]);

  useEffect(() => {
    const userListData = getUserListData(userList.users, permissionUsingFieldMasking);
    setDocumentsRows(userListData);
  }, [userList]);

  useEffect(() => {
    setModalFields(modalUserManagementFieldsGenerator(currentUser, modalStatus));
  }, [modalStatus]);

  const onClose = () => {
    setCurrentUser(null);
    setModalFields(modalUserManagementFieldsGenerator(null, modalStatus));
    setIsModalVisibility(false);
  };

  useEffect(() => {
    if (isLoaded && error) {
      const modalFieldsWithServerErrors = modalFields.map((field) => {
        if (error.data.errors[field.key]) {
          return {
            ...field,
            errorMessage: error.data.errors[field.key].join(' '),
          };
        }
        return field;
      });
      setModalFields(modalFieldsWithServerErrors);
      return;
    }
    if (isLoaded && !error) {
      onClose();
    }
  }, [isLoaded]);

  const openLimitFullModalWindow = () => {
    setIsModalVisibility(true);
    setModalStatus(USER_MANAGEMENT_MODAL_STATUS.SEATS_LIMIT_FULL);
  };

  const addUserHandler = () => {
    if (userList.totalUsersSeats <= userList.usedSeats) {
      openLimitFullModalWindow();
      return;
    }
    setIsModalVisibility(true);
    setModalStatus(USER_MANAGEMENT_MODAL_STATUS.ADD);
    setCurrentUser({
      email: '',
      first_name: '',
      last_name: '',
    });
  };

  const onEditUser = ({ id }: HandlersValueType) => {
    const userForEditing = userList.users.find((user) => user.id === Number(id));
    if (userForEditing) {
      setCurrentUser(userForEditing);
      setModalStatus(USER_MANAGEMENT_MODAL_STATUS.EDIT);
      setIsModalVisibility(true);
      setModalFields(modalUserManagementFieldsGenerator(userForEditing, USER_MANAGEMENT_MODAL_STATUS.EDIT));
    }
  };

  const onActivateDisableUser = (value: HandlersValueType) => {
    if (userList.totalUsersSeats <= userList.usedSeats && !value.isUserActive) {
      openLimitFullModalWindow();
      return;
    }
    dispatch(updateUserInfo(
      { active: !value.isUserActive, id: Number(value.id) } as UserListGetterType,
      USER_MANAGEMENT_MODAL_STATUS.CHANGE_ACTIVE_STATUS,
    ));
  };

  const onResendInvite = (value: HandlersValueType) => {
    dispatch(resendInviteToUser(Number(value.id)));
  };

  const kebabColActiveUser = (value: HandlersValueType) => (
    <KebabMenu
      value={value}
      menuOptions={USER_MANAGEMENT_MENU}
      methods={{
        onEditUser,
        onActivateDisableUser,
        onResendInvite,
      }}
    />
  );

  const updateSensitiveInfoUserAccess = (value: boolean, userId: number) => {
    const currentUser = userList.users.find((user) => user.id === userId);
    if (currentUser) {
      dispatch(updateUserInfo(
        {
          ...currentUser,
          id: userId,
          sensitive_info_access: !value,
        },
        USER_MANAGEMENT_MODAL_STATUS.EDIT,
      ));
    }
  };

  const checkboxSensitiveInfoAccess = ({ value, userId }: { value: boolean, userId: number }) => (
    <div className="text-center">
      <input
        disabled={!ADMIN_ROLES.includes(userRole)}
        type="checkbox"
        checked={value}
        onChange={() => updateSensitiveInfoUserAccess(value, userId)}
      />
    </div>
  );

  const formValidationHandler = (currentUser: ModalUserType, modalFields: IUserManagementFields[]): boolean => {
    if (!currentUser) return false;
    let isValid = true;
    const newModalFields = modalFields.map((field) => {
      const errorMessage = modalFormValidation({
        ...field,
        value: currentUser[field.key] as string,
      }).join('; ');
      if (errorMessage || field.errorMessage) isValid = false;
      return {
        ...field,
        value: field.value.trim(),
        errorMessage,
      };
    });
    setModalFields(newModalFields);
    return isValid;
  };

  const onSave = () => {
    const isFormValid = formValidationHandler(currentUser, modalFields);
    if (!isFormValid
      && (modalStatus === USER_MANAGEMENT_MODAL_STATUS.EDIT || modalStatus === USER_MANAGEMENT_MODAL_STATUS.ADD)
    ) return;
    switch (modalStatus) {
      case USER_MANAGEMENT_MODAL_STATUS.EDIT:
        dispatch(updateUserInfo(currentUser as UserListGetterType, USER_MANAGEMENT_MODAL_STATUS.EDIT));
        break;
      case USER_MANAGEMENT_MODAL_STATUS.ADD:
        dispatch(createNewUser(currentUser));
        setCurrentUser({
          email: '',
          first_name: '',
          last_name: '',
        });
        break;
      case USER_MANAGEMENT_MODAL_STATUS.SEATS_LIMIT_FULL:
        onClose();
        break;
      default:
        toast.warning('Wrong status');
    }
  };

  const handlerRowDelete = () => {
    setIsDeleteUserModalVisible(false);
    dispatch(deleteUserAction(rowToDelete?.id));
  };

  const onRowsDelete = (rowData: SelectedRowsType) => {
    const userIdArray = rowData?.data?.map(({ dataIndex }: { dataIndex: number }) => (
      { id: Number(documentsRows[dataIndex][0]) }
    )).flat();
    setRowToDelete(userIdArray[0]);
    setIsDeleteUserModalVisible(true);
  };

  let tableColumns = userManagementColumns;
  if (permissionUsingFieldMasking) {
    tableColumns = tableColumns.concat(setSensitiveInfoAccessHeading(checkboxSensitiveInfoAccess));
  }
  tableColumns = tableColumns.concat(setKebabColumnHeading(kebabColActiveUser));

  const modalTitle = modalStatus === USER_MANAGEMENT_MODAL_STATUS.SEATS_LIMIT_FULL
    ? 'Notification'
    : `${capitalizeString(modalStatus)} user`;

  const currentUserIsUserToDelete = currentUserId === rowToDelete?.id;

  return (
    <AccountSettingsWrapper fluidWrapper>
      <div className="management-settings">
        <h2 className="account-settings__title mb-3">User Management</h2>
        <div className="management-controls d-flex justify-content-between align-items-center">
          <Button onClick={addUserHandler} disabled={!ADMIN_ROLES.includes(userRole)}>
            Add user
          </Button>
          <div>
            <span>Users seats: </span>
            <span className="fw-bold">{userList.usedSeats}</span>
            <span>/</span>
            <span className="fw-bold">{userList.totalUsersSeats}</span>
          </div>
        </div>
        <div className="mt-4">
          <MUITableManagement
            tableColumns={tableColumns}
            documentsRows={documentsRows}
            onRowsDelete={onRowsDelete}
            disableSelectableRows={!ADMIN_ROLES.includes(userRole)}
          />
        </div>
      </div>
      <ManagementModal
        title={modalTitle}
        isOpen={isModalVisible}
        onClose={onClose}
        onSave={onSave}
        saveButtonText={modalStatus !== USER_MANAGEMENT_MODAL_STATUS.SEATS_LIMIT_FULL ? SAVE : OK}
        withCancelButton={modalStatus !== USER_MANAGEMENT_MODAL_STATUS.SEATS_LIMIT_FULL}
        disableSaveButton={isLoading}
      >
        {
          modalStatus === USER_MANAGEMENT_MODAL_STATUS.SEATS_LIMIT_FULL
            ? SEATS_LIMIT_NOTIFICATION
            : (
              <Form autoComplete="off">
                <UserModal
                  user={currentUser}
                  onChangeUser={setCurrentUser}
                  fieldsList={modalFields}
                  onChangeFieldsList={setModalFields}
                />
              </Form>
            )
        }
      </ManagementModal>
      <ConfirmationModal
        isDisabled={currentUserIsUserToDelete}
        isOpen={isDeleteUserModalVisible}
        onCancel={() => setIsDeleteUserModalVisible(false)}
        onConfirm={handlerRowDelete}
        cancelButtonText={currentUserIsUserToDelete ? 'Go Back' : 'No'}
        confirmButtonText="Yes"
        description={currentUserIsUserToDelete
          ? (<p>You cannot delete yourself</p>)
          : (
            <>
              <p>You are about to delete a user.</p>
              <p>Are you sure?</p>
            </>
          )}
      />
    </AccountSettingsWrapper>
  );
};

export default memo(UserManagement);
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from 'react';

import ScrollToBottom from 'components/ScrollToBottom';
import ButtonAddOutline from 'components/Sidebar/ButtonAddOutline';
import SortableListContainer from 'components/SortableListContainer';
import arrayMove from 'utils/arrayMove';

export interface ISortIndex {
  oldIndex: number;
  newIndex: number;
}

export interface IOutline {
  elements?: any[];
  createElementHandler?: () => void;
  onSortEndHandler?: (sortedNavList: any[]) => void;
  customButtonTitle?: string;
  disabledAddDocument?: boolean;
}

const Outline: FC<IOutline> = ({
  children,
  elements = [],
  createElementHandler = () => null,
  onSortEndHandler = () => null,
  customButtonTitle = '',
  disabledAddDocument = false,
}) => {
  const scrollableNode: Element | null = document.querySelector('[data-forms-scrollable="forms-scrollable"]');
  const customButtonTitleProperty = customButtonTitle.length && { title: customButtonTitle };

  const onSortEnd = ({ oldIndex, newIndex }: ISortIndex) => {
    const sortedNavList = arrayMove(elements, oldIndex, newIndex);
    onSortEndHandler(sortedNavList);
  };

  const shouldCancelStart = (event: any) => {
    const targetNode = event.target.nodeName.toLowerCase();

    switch (targetNode) {
      case 'svg':
      case 'path':
        return !event.target.getAttribute('data-disabled');
      case 'input':
        return !event.target.disabled;
      case 'li':
      case 'section':
        return false;
      default:
        return true;
    }
  };

  return (
    <div className="outline-sortable-container">
      <SortableListContainer
        onSortEnd={onSortEnd}
        shouldCancelStart={shouldCancelStart}
        helperClass="document-editor__navigation-item-active shadow-sm"
        lockAxis="y"
      >
        {children}
      </SortableListContainer>
      <ScrollToBottom
        scrollableNode={scrollableNode}
      >
        {(scrollToBottom: () => void) => (
          <ButtonAddOutline
            disabled={disabledAddDocument}
            onClick={() => {
              createElementHandler();
              scrollToBottom();
            }}
            {...customButtonTitleProperty}
          />
        )}
      </ScrollToBottom>
    </div>
  );
};

export default Outline;
import { FC, SyntheticEvent } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useSlate } from 'slate-react';

import { SELECT_FIELD_TYPE_CHECKBOX } from 'constants/editors';
import { changeSelectedField } from 'store/actions/editorSlate';
import { RootStateType } from 'store/reducers';
import { IBlockButtonProps } from 'types/Editor';
import { isBlockActive } from 'utils/editorBlockHelpers';
import { removeSlateSelectedFieldError } from 'utils/editorFieldHelpers';
import { toggleSelectField } from 'utils/editorSelectFieldHelpers';

const CheckboxButton: FC<IBlockButtonProps> = ({ format, icon, selectVariant }) => {
  const editor = useSlate();
  const selectedAssignment = useSelector((state: RootStateType) => state.editorSlate.selectedAssignment);
  const active: boolean = isBlockActive(editor, format);
  const dispatch = useDispatch();

  const onMouseDownButton = (event: SyntheticEvent) => {
    event.preventDefault();
    removeSlateSelectedFieldError(editor, format, active, dispatch);
    const selectFieldKey = toggleSelectField(
      editor,
      format,
      selectVariant === SELECT_FIELD_TYPE_CHECKBOX ? [{
        checked: undefined,
        id: Date.now(),
        isSelectOtherOption: undefined,
        label: '',
      }] : [],
      selectVariant,
      selectedAssignment,
    );

    dispatch(changeSelectedField(selectFieldKey));
  };

  return (
    <span
      className={`btn-toolbar-editor ${active && 'text-dark'} horizontal-toolbar-icon`}
      onMouseDown={onMouseDownButton}
      role="button"
      tabIndex={-1}
    >
      {icon}
    </span>
  );
};

export default CheckboxButton;
import { MouseEventHandler, useCallback, useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useStepHandler } from 'components/SignNow/StepManager/StepHandlerContext';
import { SignerFlow, SignerRecord } from 'constants/signNow';
import { addCustomAssignment } from 'store/actions/editorSlate';
import { createCollectionInSignNowFlow, setCurrentSignerFlowToStore, setSignersList } from 'store/actions/signNow';
import { createTemplate } from 'store/actions/userData';
import { getUserListData } from 'store/actions/userProfile';
import { RootStateType } from 'store/reducers';
import { AssignmentsObjectType } from 'types/Editor';
import { SignersInfoType, SignerType } from 'types/SendDocuments';
import { StepManagerOnNextHandlerType } from 'types/signNow/base';
import { IUseSignerFlowReturns, UseSignerFlowHookType } from 'types/signNow/selectSigners';
import { ITemplateRequestObj } from 'types/Templates';
import { clearValidationErrorsInSignersArray, validateSignersArray } from 'utils/sendDocumentsHelpers';
import { getCustomAssignmentsFromSigners } from 'utils/signNow/assignmentsHelper';
import { getSignNowTemplateData } from 'utils/signNow/createDocumentHelper';
import { getNewUserCard } from 'utils/signNow/signNowHookHelpers';

const useSignerFlow: UseSignerFlowHookType = ({
  config,
  defaultItem = SignerFlow.NoOrder,
  onNextHandler = () => null,
}): IUseSignerFlowReturns => {
  const dispatch = useDispatch();
  const { setOnNext, setIsNextButtonDisabled } = useStepHandler();
  const profileInfo = useSelector((state: RootStateType) => state.profile.profileInfo);
  const {
    signers: storeSigners,
    currentFlow,
    files,
    documents,
  } = useSelector((state: RootStateType) => state.signNow);
  const { doctypes } = useSelector((state: RootStateType) => state.user);

  const initialSignerCard: SignersInfoType = useMemo(() => ({
    firstName: profileInfo.first_name,
    lastName: profileInfo.last_name,
    email: profileInfo.email,
    position: 0,
    type: SignerType.MANAGER,
    label: 'Signer(Me)',
  }), [profileInfo.first_name, profileInfo.last_name, profileInfo.email]);

  const [
    currentSignerFlow,
    setCurrentSignerFlow,
  ] = useState<SignerFlow>(currentFlow ?? config[defaultItem].key);

  const currentRecord: SignerRecord = config[currentSignerFlow].record;
  const initialSigners = useMemo(() => (
    storeSigners[currentRecord] ?? [initialSignerCard]
  ), [storeSigners, currentRecord, initialSignerCard]);

  const [signers, setSigners] = useState<SignersInfoType[]>(initialSigners);

  useEffect(() => {
    setIsNextButtonDisabled(!signers.length);
  }, [signers, setIsNextButtonDisabled]);

  useEffect(() => {
    dispatch(getUserListData());
    if (currentFlow !== currentSignerFlow) {
      dispatch(setCurrentSignerFlowToStore(currentSignerFlow));
    }
  }, [dispatch, currentFlow, currentSignerFlow]);

  useEffect(() => {
    dispatch(setSignersList({
      record: currentRecord,
      signers,
    }));
  }, [dispatch, currentRecord, signers]);

  useEffect(() => {
    if (currentSignerFlow === SignerFlow.NoOrder && files.length > 1) {
      setSigners([getNewUserCard({ position: signers.length })]);
    } else if (currentSignerFlow === SignerFlow.JustMe) {
      setSigners([initialSignerCard]);
    } else {
      const cachedSigners: SignersInfoType[] = storeSigners[currentRecord];
      setSigners(cachedSigners.length ? cachedSigners : [initialSignerCard]);
    }
  }, [config, currentSignerFlow, initialSignerCard]);

  const addSignerHandler = useCallback(() => {
    setSigners([...signers, { ...getNewUserCard({ position: signers.length }) }]);
  }, [signers]);

  const onChangeCurrentSignFlow: MouseEventHandler<HTMLButtonElement> = useCallback((event): void => {
    const value: SignerFlow = (event.target as HTMLInputElement).value as SignerFlow;
    setCurrentSignerFlow(value === currentSignerFlow ? config[defaultItem].key : value);
  }, [config, currentSignerFlow, defaultItem]);

  const onNextHandlerWrapper: StepManagerOnNextHandlerType = useCallback(() => {
    if (validateSignersArray(setSigners) && onNextHandler) {
      dispatch(setSignersList({
        record: currentRecord,
        signers: clearValidationErrorsInSignersArray(signers),
      }));
      const assignments: AssignmentsObjectType = getCustomAssignmentsFromSigners(signers);
      dispatch(addCustomAssignment(assignments));

      if (files.length === 1) {
        const file = files[0];
        const templateData: ITemplateRequestObj = getSignNowTemplateData({
          fileName: documents[0].title ?? String(Date.now()),
          assignments,
          doctypeID: doctypes[0].id,
          pdfFileLink: `${file.fileKey}?${file.pdfWidth}`,
        });
        dispatch(createTemplate(
          {
            ...templateData,
            is_signnow_document: true,
            signing_order_flow: currentSignerFlow,
          },
          true,
        ));
      } else if (files.length > 1) {
        dispatch(createCollectionInSignNowFlow(doctypes[0].id, assignments));
      }
      onNextHandler();
    }
  }, [
    dispatch,
    currentSignerFlow,
    onNextHandler,
    currentRecord,
    signers,
    doctypes,
    files,
    documents,
  ]);

  useEffect(() => {
    setOnNext(() => onNextHandlerWrapper);
  }, [setOnNext, onNextHandlerWrapper]);

  return {
    isBulkSend: files.length > 1,
    signers,
    setSigners,
    addSignerHandler,
    currentSignerFlow,
    onChangeCurrentSignFlow,
    onNextHandlerWrapper,
  };
};

export default useSignerFlow;
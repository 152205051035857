/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, FC, memo, useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Col, FormGroup, Input, Label } from 'reactstrap';

import DeleteIcon from '@mui/icons-material/Delete';

import ConnectionIcon from 'components/Base/ConnectionIcon';
import AttachmentFieldProperties from 'components/Editor/EditorDevTools/AttachmentFieldProperties';
import FieldProperties from 'components/Editor/EditorDevTools/FieldProperties';
import SelectFieldOptions from 'components/Editor/EditorDevTools/SelectFieldOptions';
import SelectOptionOtherCheckbox from 'components/Editor/EditorDevTools/SelectOptionOtherCheckbox';
import TodaysDateFieldProperties from 'components/Editor/EditorDevTools/TodaysDateFieldProperties';
import AssignmentsDeletionConfirmationModal from 'components/Modals/AssignmentsSettingsModal/AssignmentsDeletionConfirmationModal';
import ModalConfirmDialog from 'components/Modals/ModalConfirmDialog';
import MondayComFieldTypeChecker from 'components/Modals/MondayComModalWindow/MondayComFieldTypeChecker';
import MondayComModal from 'components/Modals/MondayComModalWindow/MondayComModal';
import { DATE_FORMATS, DEFAULT_DATE_FORMAT } from 'constants/dateField';
import {
  ANSWER_TYPE_KEY,
  ATTACHMENT_DEFAULT_PARAMS,
  ATTACHMENT_FIELD,
  CHECKBOX_FIELD,
  CHECKBOX_FIELD_TYPE,
  DATE_FIELD,
  DATE_MASK_KEY,
  DEFAULT_FIELD_VALUE_LENGTH,
  FIELD_API_INFO_KEY,
  FIELD_ASSIGNMENT_KEY,
  FIELD_GROUP_BY,
  FIELD_GROUPING_VALUE,
  FIELD_NAME_KEY,
  FIELD_REMEMBER_KEY,
  FIELD_REQUIRED_KEY,
  FIELD_SUBTYPE_KEY,
  FIELD_TYPE_KEY,
  FIELD_TYPE_VARIANTS,
  FIELD_VALUE_FONT_OPTIONS,
  FIELD_VALUE_LENGTH,
  GROUP_BY_FILTER_FIELD_KEY,
  GROUP_BY_KEY,
  GROUPED_FIELDS,
  HIDE_LABELS_KEY,
  MAX_LENGTH_KEY,
  MONDAY_COM_INTEGRATION_MODAL,
  NONE_FIELD_VALUE_LENGTH,
  OPAQUE_BACKGROUND_KEY,
  QUESTION_ANSWER_SELECT,
  QUESTION_ANSWER_STRING,
  QUESTION_FIELD,
  READ_ONLY_KEY,
  RECIPIENT_ASSIGNMENT,
  SELECT_FIELD_SELECT_OPTION,
  SELECT_FIELD_TYPE_KEY,
  SELECT_FIELD_TYPE_RADIO_CHECKBOX,
  SELECT_FIELD_TYPE_SELECT,
  SELECT_QUESTION_ANSWER,
  SHORT_NAME_KEY,
  SIGNATURE_VARIATION_TYPE_KEY,
  SIGNING_FIELD,
  SUBTYPE_EMAIL,
  SUBTYPES_APPLICABLE_FIELD_TYPES,
  TEXT_FIELD,
  TEXT_FIELD_MASK_KEY,
} from 'constants/editors';
import {
  ADD_OTHER_OPTION,
  GROUPED_FIELD_MESSAGES,
  TEXT_FIELD_MASK_OPTIONS,
  TEXT_FIELD_MASKS,
} from 'constants/fieldPropertiesTab';
import {
  SIGNATURE_FIELD_DEFAULT_VARIATION,
  SIGNATURE_FIELD_INITIALS_VARIATION,
  SIGNATURE_FIELD_VARIATIONS,
  SIGNATURE_TABS,
} from 'constants/signatures';
import useEditorAssignmentsMenu from 'hooks/EditorAssignmentsMenu/useEditorAssignmentsMenu';
import useMondayComIntegrationInFieldPropsTab from 'hooks/MondayCom/useMondayComIntegrationInFieldPropsTab';
import useDebounce from 'hooks/useDebounce';
import usePIIList from 'hooks/usePIIListSetter';
import { useRolePermission } from 'hooks/useRolePermission';
import useTextFieldMask from 'hooks/useTextFieldMask';
import {
  setAllAssignments,
  setOpenAssignmentsSettingsModal,
  setSelectedAssignment,
  setSelectFieldError,
} from 'store/actions/editorSlate';
import { deletePdfField, setSelectedFieldKey, updateFieldByKey } from 'store/actions/pdfTemplates';
import { RootStateType } from 'store/reducers';
import {
  BlockFormatType,
  ChangedPropertyDataType,
  FieldAssignmetsType,
  FieldGroupByType,
  ICustomElement,
  isBlockFormatType,
  ISubtypesFromFieldsGetter,
  QuestionAnswerType,
  SelectFieldOptionsType,
} from 'types/Editor';
import { FieldTypeOption, IPdfFieldProperties, PDFFieldType, PDFFieldTypesType } from 'types/PdfTemplates';
import { FieldPropertiesType, ISelectFieldType } from 'types/Properties';
import { getActualAssignmentsState, isFieldAssignment, isRecipientAssignment } from 'utils/assignmentsHelpers';
import { createNameConstant } from 'utils/createNameConstant';
import {
  createCustomSubtypeName,
  createKeyArray,
  getCurrentFieldPropertiesToUpdate,
  getFieldIDsToSelect,
  getGroupedFieldKeys,
  getGroupingFilterNameOptions,
  getSubtypesFromFields,
  isFieldGrouped,
  isFieldsGrouped,
  isShownProperty,
} from 'utils/editorFieldHelpers';
import { getDefaultDateMask } from 'utils/editorHelpers';
import { addSelectOptionHelper } from 'utils/editorSelectFieldHelpers';
import { compareSelectFieldsOptions, getPDFOptionsObject } from 'utils/Fields/compareSelectFieldsOptions';
import { getFontSizeValue } from 'utils/Fields/fieldFontSizeHelpers';
import { getLength, onKeyDownMaxLengthField } from 'utils/Fields/fieldMaxLengthHelpers';
import {
  getIsAttachmentField,
  getIsDateField,
  getIsQuestionField,
  getIsSelectField,
} from 'utils/Fields/fieldsTypeChecker';
import { isNotEmptyObject } from 'utils/isEmptyObject';
import { getFieldChangingText, getPropertyOptions } from 'utils/ManagerEditor/propertyTabHelpers';
import { getUnitedOrderedAssignments } from 'utils/signNow/assignmentsHelper';

/**
 * TODO: Investigate, how to connect this component with
 * src/components/Editor/EditorDevTools/RenderFieldProperties.tsx
 * those components are similar and have a lot of identical logic
 */

// TODO: NEED REFACTORING!!! 💥💥💥
const PdfFieldProperties: FC<IPdfFieldProperties> = ({
  pdfTemplateFieldsArray,
  readOnly,
  collectionAssignments = undefined,
}) => {
  const dispatch = useDispatch();
  const PIIList = usePIIList();

  const gropingFilterNameOptions: ISelectFieldType[] = useMemo(() => (
    getGroupingFilterNameOptions(pdfTemplateFieldsArray)
  ), [pdfTemplateFieldsArray]);

  const {
    enableDataReuse,
    permissionUsingFieldMasking,
    permissionUsingGroupFields,
  } = useRolePermission();

  const isSignNow = useSelector((state: RootStateType) => state.signNow.isSignNow);

  const [name, setName] = useState<string>('');
  const [fieldType, setFieldType] = useState<BlockFormatType>(TEXT_FIELD);
  const [fieldAnswerType, setFieldAnswerType] = useState<QuestionAnswerType>(QUESTION_ANSWER_STRING);
  const [selectFieldType, setSelectFieldType] = useState<SelectFieldOptionsType>(SELECT_FIELD_TYPE_SELECT);
  const [fieldShortName, setFieldShortName] = useState<string>('');
  const [fieldSubtype, setFieldSubtype] = useState<string>('');
  const [fieldAssignment, setFieldAssignment] = useState<FieldAssignmetsType>(RECIPIENT_ASSIGNMENT);
  const [helpText, setHelpText] = useState<string>('');
  const [required, setRequired] = useState<boolean>(false);
  const [validationText, setValidationText] = useState<string>('');
  const [options, setOptions] = useState<FieldTypeOption[]>([]);
  const [keysArr, setKeysArr] = useState<number[]>([]);
  const [field, setField] = useState<any>(null);
  const [showConfirmDeletingModal, setShowConfirmDeletingModal] = useState<boolean>(false);
  const [deletedFieldId, setDeletedFieldId] = useState<number>(0);
  const [fieldGroupBy, setFieldGroupBy] = useState<FieldGroupByType | string>('');
  const [filterName, setFilterName] = useState<string>('');
  const [fieldMaxLength, setFieldMaxLength] = useState<number | string>(DEFAULT_FIELD_VALUE_LENGTH);
  const [fieldFontSize, setFieldFontSize] = useState<number | string>('');
  const [dateFieldMask, setDateFieldMask] = useState<string>(DEFAULT_DATE_FORMAT);
  const [signatureFieldVariation, setSignatureFieldVariation] = useState<string>(SIGNATURE_FIELD_DEFAULT_VARIATION);
  const [isSelectFieldOpaqueBackground, setIsSelectFieldOpaqueBackground] = useState<boolean>(false);
  const [areLabelsHidden, setAreLabelsHidden] = useState<boolean>(true);
  const [isReadOnly, setIsReadOnly] = useState<boolean>(false);

  const [confirmChangingAction, setConfirmChangingAction] = useState<() => void>(() => null);
  const [groupedFieldCount, setGroupedFieldCount] = useState<number>(0);
  const [changedData, setChangedData] = useState<ChangedPropertyDataType>({
    value: null,
    method: () => undefined,
    resetMethod: () => undefined,
  });
  const debouncedValue = useDebounce<string | null>(changedData.value, 500);
  const {
    isMondayComButtonAvailable,
    showMondayComModal,
    setShowMondayComModal,
  } = useMondayComIntegrationInFieldPropsTab();

  const { selectedFieldKey } = useSelector((state: RootStateType) => state.pdfTemplates);
  const pdfTemplateFields = useSelector((state: RootStateType) => state.pdfTemplates.pdfTemplateFields);
  const { assignments } = useSelector((state: RootStateType) => state.editorSlate);
  const { sensitive_info_access: sensitiveInfoAccess } = useSelector(
    (state: RootStateType) => state.profile.profileInfo ?? {},
  );
  const isRadioOrCheckboxSelectType: boolean = SELECT_FIELD_TYPE_RADIO_CHECKBOX.includes(selectFieldType);

  useEffect(() => {
    if (pdfTemplateFieldsArray.length) {
      const currentKeys = createKeyArray(pdfTemplateFieldsArray);
      setKeysArr((prevState: number[]) => {
        if (prevState !== currentKeys) {
          return currentKeys;
        }
        return prevState;
      });
    }
  }, [pdfTemplateFieldsArray]);

  useEffect(() => {
    if (createKeyArray(pdfTemplateFieldsArray) !== keysArr) {
      pdfTemplateFieldsArray.forEach((fieldElement: PDFFieldType) => {
        if (!keysArr.includes(fieldElement.key)) {
          setField(fieldElement);
        }
      });
    }
  }, [pdfTemplateFieldsArray, keysArr, dispatch]);

  useEffect(() => {
    if (selectedFieldKey) {
      const currentField = pdfTemplateFieldsArray.find((field: PDFFieldType) => field.key === selectedFieldKey);
      setField(currentField);
    } else {
      const filteredFields = pdfTemplateFieldsArray.filter((field: PDFFieldType) => field.key !== deletedFieldId);
      if (filteredFields[0]?.key) {
        dispatch(setSelectedFieldKey(filteredFields[0].key));
      }
    }
  }, [deletedFieldId, pdfTemplateFieldsArray, selectedFieldKey, dispatch]);

  useEffect(() => {
    if (field) {
      setName(field.fieldName || '');
      setFieldType(field.type || TEXT_FIELD);
      setFieldSubtype((SUBTYPES_APPLICABLE_FIELD_TYPES.includes(field.type) && field.subtype) || '');
      setFieldAssignment(field.assignment || RECIPIENT_ASSIGNMENT);
      setOptions(field.options || []);
      setHelpText(field.helpText || '');
      setValidationText(field.validationText || '');
      setRequired(field.requiredField || false);
      setFieldAnswerType(field.answerType || QUESTION_ANSWER_STRING);
      setSelectFieldType(field.selectFieldType || SELECT_FIELD_TYPE_SELECT);
      setFieldShortName(field.shortName || '');
      setFieldGroupBy(field.groupBy || '');
      setFilterName(field.filterName || '');
      setFieldMaxLength(field.maxLength ?? NONE_FIELD_VALUE_LENGTH);
      setFieldFontSize(field.fontSize || '');
      setDateFieldMask(getDefaultDateMask(field.dateMask));
      setSignatureFieldVariation(field.signatureFieldVariation || SIGNATURE_FIELD_DEFAULT_VARIATION);
      setIsSelectFieldOpaqueBackground(field.isSelectFieldOpaqueBackground || false);
      setAreLabelsHidden(field.areLabelsHidden ?? true);
      setIsReadOnly(field.isReadOnly ?? false);
    }
  }, [field]);

  useEffect(() => () => {
    setField(null);
    setDeletedFieldId(0);
  }, []);

  useEffect(() => {
    if (debouncedValue !== null) {
      changedData.method();
    }
  }, [debouncedValue]);

  const updateFieldHandler = ({
    updatedField,
    withGroupedFieldsUpdating = false,
    updateSelectOptions = false,
  }: {
    updatedField: Partial<ICustomElement | PDFFieldType>,
    withGroupedFieldsUpdating?: boolean;
    updateSelectOptions?: boolean;
  }) => {
    const isSelectField = getIsSelectField(updatedField.type ?? field.type);
    let updatedFields: PDFFieldType[] = [];
    let firstGroupedField: PDFFieldType | null = null;
    let existingGroupedFields: PDFFieldType[] = [];
    if (isSelectField) {
      updatedFields = pdfTemplateFieldsArray.map((localField: any) => {
        if (localField.key === updatedField.key) {
          return {
            ...localField,
            ...updatedField,
          };
        }
        return localField;
      });
      if (isFieldGrouped(updatedField.groupBy)) {
        existingGroupedFields = updatedFields.filter(
          (field) => isFieldsGrouped(field, updatedField) && field.key !== updatedField.key,
        );
        if (existingGroupedFields.length) {
          firstGroupedField = existingGroupedFields[0];
        }
      }
    }

    const areOptionsTheSame = compareSelectFieldsOptions(
      firstGroupedField,
      updatedField as PDFFieldType,
    );

    const keys: number[] = withGroupedFieldsUpdating
      ? getGroupedFieldKeys(
        isSelectField ? updatedFields : pdfTemplateFieldsArray,
        updatedField.key ?? (field.key || 0),
      )
      : [updatedField.key ?? (field.key || 0)];

    pdfTemplateFieldsArray.forEach((pdfField: PDFFieldType) => {
      if (keys.includes(pdfField.key)) {
        const optionsObject = getPDFOptionsObject({
          updateSelectOptions,
          areOptionsTheSame,
          updatedField,
          firstGroupedField,
          pdfField,
        });
        dispatch(updateFieldByKey(
          pdfField.key,
          {
            ...pdfField,
            ...updatedField,
            ...optionsObject,
            ...(firstGroupedField ? { areLabelsHidden: firstGroupedField.areLabelsHidden } : {}),
            coords: pdfField.coords,
            size: pdfField.size,
            key: pdfField.key,
            pageNumber: pdfField.pageNumber,
            position: pdfField.position,
          } as PDFFieldType,
        ));
      }
    });
  };

  const {
    textFieldMask,
    updateTextFieldMask,
    getTextFieldCustomInput,
  } = useTextFieldMask(field, updateFieldHandler);

  const toggleModalWindow = () => {
    setShowConfirmDeletingModal(!showConfirmDeletingModal);
  };

  const openConfirmationWindow = (callbackMethod: () => void, includingCurrent = false, updatedField: any = null) => {
    const isSelectField = getIsSelectField(field.type);
    if (
      GROUPED_FIELDS.includes(fieldType)
      && (isFieldGrouped(isSelectField ? updatedField.groupBy : fieldGroupBy) || includingCurrent)
    ) {
      let fieldCount = getFieldIDsToSelect(field, field.key, Object.values(pdfTemplateFields)).length + (
        includingCurrent ? 1 : 0
      );
      if (isSelectField && updatedField) {
        const updatedFields = Object.values(pdfTemplateFields).map((field: any) => {
          if (field.key === updatedField.key) {
            return updatedField;
          }
          return field;
        });
        fieldCount = getFieldIDsToSelect(updatedField, updatedField.key, updatedFields).length;
      }
      if (fieldCount > 1) {
        setConfirmChangingAction(() => callbackMethod);
        setGroupedFieldCount(fieldCount);
        return;
      }
    }
    callbackMethod();
  };

  const resetChangedData = () => {
    setGroupedFieldCount(0);
    setChangedData({
      value: null,
      method: () => undefined,
      resetMethod: () => undefined,
    });
  };

  const closeChangingModalWindow = () => {
    changedData.resetMethod(); // rollback the field property value
    resetChangedData();
  };

  const updateFieldType = ({ target }: ChangeEvent<HTMLSelectElement>) => {
    const value = target.value as PDFFieldTypesType;

    if (!isBlockFormatType(value)) return;
    const updatedField = {
      ...field,
      filterName: undefined,
      groupBy: undefined,
      subtype: undefined,
      type: value,
      ...(value === ATTACHMENT_FIELD ? { properties: ATTACHMENT_DEFAULT_PARAMS } : {}),
    };

    const showErrorInProperties = field.requiredField && getIsSelectField(value) && !field.options?.length;
    dispatch(setSelectFieldError(showErrorInProperties, field.key));

    setChangedData({
      value,
      method: () => openConfirmationWindow(() => {
        updateFieldHandler({
          updatedField,
          withGroupedFieldsUpdating: true,
        });
        setFieldType(value);
        resetChangedData();
      }, false, updatedField),
      resetMethod: () => setFieldType(field.type),
    });
  };

  const updateAnswerTypeHandler = ({ target }: ChangeEvent<HTMLSelectElement>) => {
    const value = target.value as QuestionAnswerType;
    const selectAnswerTypeProps = value === QUESTION_ANSWER_SELECT ? { options: [] } : {};
    const updatedField = {
      answerType: value,
      value: '',
      ...selectAnswerTypeProps,
    };
    updateFieldHandler({ updatedField });
    dispatch(setSelectFieldError(false, field.key));
  };

  const updateSignatureVariation = (event: ChangeEvent<HTMLSelectElement>): void => {
    const value: string = event.target.value;

    const updatedField = {
      signatureFieldVariation: value,
      signatureTab: value === SIGNATURE_FIELD_INITIALS_VARIATION ? SIGNATURE_TABS.TYPE_TAB : SIGNATURE_TABS.DRAW_TAB,
    };
    updateFieldHandler({ updatedField });
    setSignatureFieldVariation(value);
  };

  const updateSelectOpaqueBackground = (event: ChangeEvent<HTMLInputElement>): void => {
    const value: boolean = event.target.checked;

    const updatedField = {
      isSelectFieldOpaqueBackground: value,
    };
    updateFieldHandler({ updatedField });
    setIsSelectFieldOpaqueBackground(value);
  };

  const updateLabelsVisibility = (event: ChangeEvent<HTMLInputElement>): void => {
    const value: boolean = event.target.checked;

    const updatedField = {
      areLabelsHidden: value,
    };
    updateFieldHandler({ updatedField });
    setAreLabelsHidden(value);
  };

  const updateShortNameHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const updatedField = {
      shortName: value,
    };
    updateFieldHandler({ updatedField });
  };

  const updateFieldSubtype = (
    { target }: ChangeEvent<HTMLSelectElement>,
    isRememberCheckbox: boolean = false,
    isGroupedByFilter: boolean = false,
  ) => {
    const eventValue = target.value;
    const rememberCheckboxValue = isRememberCheckbox ? createCustomSubtypeName(fieldType, field.name) : eventValue;
    const subtypeValue = isRememberCheckbox ? rememberCheckboxValue : eventValue;
    if (SUBTYPES_APPLICABLE_FIELD_TYPES.includes(fieldType) && fieldSubtype !== subtypeValue) {
      const subtypeIdsWithoutCurrent = getSubtypesFromFields(pdfTemplateFieldsArray, field.assignment, subtypeValue)
        .filter((element: ISubtypesFromFieldsGetter) => element.key !== field.key);

      const isNotOneSubtype = subtypeValue.length > 0 && subtypeIdsWithoutCurrent.length > 0;
      const newFieldValue = isNotOneSubtype
        ? subtypeIdsWithoutCurrent[0].value
        : '';
      const requiredField = isNotOneSubtype
        ? subtypeIdsWithoutCurrent[0]?.requiredField
        : (subtypeValue === SUBTYPE_EMAIL && isRecipientAssignment(fieldAssignment)) || required;
      const validationText = subtypeIdsWithoutCurrent[0]?.validationText;
      const groupByValue = field?.groupBy ? field.groupBy : FIELD_GROUPING_VALUE.BY_ASSIGNEE;
      const updatedField = {
        value: newFieldValue,
        subtype: subtypeValue,
        groupBy: isGroupedByFilter ? FIELD_GROUPING_VALUE.BY_FILTER : groupByValue,
        requiredField,
        ...(typeof validationText === 'string' && { validationText }),
      };
      if (requiredField) {
        setRequired(true);
      }
      setFieldSubtype(subtypeValue);
      updateFieldHandler({ updatedField });
    }
  };

  const rememberCheckboxHandler = (event: ChangeEvent<HTMLSelectElement>) => {
    if (!enableDataReuse) return;
    if (field.subtype) {
      const updatedField = {
        subtype: '',
      };
      updateFieldHandler({ updatedField });
      setFieldSubtype('');
    } else {
      updateFieldSubtype(event, true);
    }
  };

  const groupByHandler = (event: ChangeEvent<HTMLSelectElement>) => {
    const groupByValue = event.target.value as FieldGroupByType;

    const filterName: Record<FieldGroupByType, string | undefined> = {
      [FIELD_GROUPING_VALUE.BY_ASSIGNEE]: undefined,
      // [FIELD_GROUPING_VALUE.BY_SECTION]: getFilterNameBySection(editor) ?? '',
      [FIELD_GROUPING_VALUE.BY_SECTION]: undefined, // TODO: Add filter by section for PDF
      [FIELD_GROUPING_VALUE.BY_DOCUMENT]: undefined, // Deprecated
      [FIELD_GROUPING_VALUE.BY_FILTER]: field.filterName,
      [FIELD_GROUPING_VALUE.BY_SUBTYPE]: undefined,
    };

    if (GROUPED_FIELDS.includes(fieldType) && fieldGroupBy !== groupByValue) {
      const updatedField = {
        ...field,
        groupBy: groupByValue,
        filterName: filterName[groupByValue],
      };
      setFieldGroupBy(groupByValue);

      if (getIsSelectField(field.type)) {
        setChangedData({
          value: groupByValue,
          method: () => openConfirmationWindow(() => {
            updateFieldHandler({ updatedField, withGroupedFieldsUpdating: true });
            resetChangedData();
          }, false, updatedField),
          resetMethod: () => setFieldGroupBy(field.groupBy || ''),
        });
      } else {
        updateFieldHandler({ updatedField, withGroupedFieldsUpdating: true });
      }
      if (groupByValue === FIELD_GROUPING_VALUE.BY_FILTER) {
        updateFieldSubtype(event, true, true);
      }
    }
  };

  const updateGroupFilterNameHandler = ({ target }: ChangeEvent<HTMLSelectElement>): void => {
    const value = target.value;
    const updatedField = {
      filterName: value,
    };
    setFilterName(value);
    updateFieldHandler({ updatedField });
  };

  const updateName = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const updatedSubtypeValue: string | null = field.subtype ? createCustomSubtypeName(fieldType, value) : null;
    const updatedField = {
      ...field,
      fieldName: value,
      name: createNameConstant(value.trim()),
      ...(updatedSubtypeValue && { subtype: updatedSubtypeValue }),
    };

    setName(value);
    setChangedData({
      value,
      method: () => openConfirmationWindow(() => {
        updateFieldHandler({
          updatedField,
          withGroupedFieldsUpdating: true,
        });
        resetChangedData();
      }, false, updatedField),
      resetMethod: () => setName(field.fieldName || ''),
    });
  };

  const updateFieldMask = (event: ChangeEvent<HTMLSelectElement>): void => {
    const value: string = event.target.value;

    const updatedField = {
      dateMask: value,
    };

    updateFieldHandler({ updatedField });
    setDateFieldMask(value);
  };

  const updateFieldAssignment = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value as FieldAssignmetsType;
    if (isFieldAssignment(value)) {
      const fieldDataToUpdate = getCurrentFieldPropertiesToUpdate({
        assignmentValue: value,
        filterGroupByValue: fieldGroupBy,
        fieldList: pdfTemplateFieldsArray,
        field: { name: field.name || '', key: field.key, type: fieldType },
      });
      if (isNotEmptyObject(fieldDataToUpdate)) {
        toast.warn(GROUPED_FIELD_MESSAGES.CHECK_SIMILAR);
      }

      const isRequired = (fieldSubtype === SUBTYPE_EMAIL && isRecipientAssignment(value)) || required;
      const updatedField = {
        assignment: value,
        requiredField: isRequired,
        ...fieldDataToUpdate,
      };
      setFieldAssignment(value);
      /**
       * Keeping array with field keys and appropriate assignments actual
       */
      dispatch(setAllAssignments(getActualAssignmentsState(
        assignments,
        field.assignment,
        value,
        field.key,
      )));
      dispatch(setSelectedAssignment(value));
      updateFieldHandler({ updatedField });
    }
  };

  const {
    getAssignmentsMenuComponent,
  } = useEditorAssignmentsMenu(
    fieldAssignment,
    getUnitedOrderedAssignments(assignments),
    updateFieldAssignment,
    !isSignNow,
  );

  const updateHelpText = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const helpTextValue = target.value;
    const updatedField = {
      ...field,
      helpText: helpTextValue,
    };

    setHelpText(helpTextValue);
    setChangedData({
      value: helpTextValue,
      method: () => openConfirmationWindow(() => {
        updateFieldHandler({
          updatedField,
          withGroupedFieldsUpdating: true,
        });
        resetChangedData();
      }, false, updatedField),
      resetMethod: () => setHelpText(field.helpText || ''),
    });
  };

  const updateValidationText = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const targetValue = target.value;
    const updatedField = {
      ...field,
      validationText: targetValue,
    };

    setValidationText(targetValue);
    setChangedData({
      value: targetValue,
      method: () => openConfirmationWindow(() => {
        updateFieldHandler({
          updatedField,
          withGroupedFieldsUpdating: true,
        });
        resetChangedData();
      }, false, updatedField),
      resetMethod: () => setValidationText(field.validationText || ''),
    });
  };

  const updateRequirement = () => {
    const isRequired = (fieldSubtype === SUBTYPE_EMAIL && isRecipientAssignment(fieldAssignment)) || !required;
    const updatedField = {
      ...field,
      requiredField: isRequired,
    };

    openConfirmationWindow(() => {
      setRequired(isRequired);
      updateFieldHandler({
        updatedField,
        withGroupedFieldsUpdating: true,
      });
      setGroupedFieldCount(0);
    }, false, updatedField);
  };

  const updateReadOnly = () => {
    openConfirmationWindow(() => {
      setIsReadOnly((prevState) => {
        const updatedReadOnly = !prevState;
        const updatedField = {
          isReadOnly: updatedReadOnly,
        };
        updateFieldHandler({
          updatedField,
          withGroupedFieldsUpdating: true,
        });
        return updatedReadOnly;
      });
      setGroupedFieldCount(0);
    });
  };

  const updateSelectFieldTypeHandler = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value as SelectFieldOptionsType;
    const updatedField = {
      ...field,
      selectFieldType: value,
    };
    setSelectFieldType(value);

    setChangedData({
      value,
      method: () => openConfirmationWindow(() => {
        updateFieldHandler({
          updatedField,
          withGroupedFieldsUpdating: true,
        });
        resetChangedData();
      }, false, updatedField),
      resetMethod: () => setSelectFieldType(field.selectFieldType || SELECT_FIELD_TYPE_SELECT),
    });
  };

  const addSelectOption = (isSelectOtherOption?: boolean) => {
    const updatedField = addSelectOptionHelper({
      localSetOptions: setOptions,
      options,
      isPdfFlow: true,
      isSelectOtherOption: isSelectOtherOption || false,
    });
    const updatedFieldWithKey = { ...field, ...updatedField };
    openConfirmationWindow(() => {
      updateFieldHandler({
        updatedField: updatedFieldWithKey,
        withGroupedFieldsUpdating: true,
        updateSelectOptions: true,
      });
      setGroupedFieldCount(0);
    }, false, updatedFieldWithKey);
  };

  const uncheckSelectOtherOption = () => {
    const isSelectedOtherOption = options.find((option) => option.isSelectOtherOption && option.checked);
    const newOptions = options.filter((option) => !option.isSelectOtherOption);
    const updatedValue = isNotEmptyObject(isSelectedOtherOption ?? {}) ? { value: '' } : {};
    const updatedField = {
      ...field,
      addedOtherOption: false,
      selectedOtherOption: false,
      options: newOptions,
      ...updatedValue,
    };

    openConfirmationWindow(() => {
      setOptions(newOptions);
      updateFieldHandler({
        updatedField,
        withGroupedFieldsUpdating: true,
      });
      setGroupedFieldCount(0);
    }, false, updatedField);
  };

  const removeOption = (id: number): React.MouseEventHandler<SVGSVGElement> => () => {
    const newOptions = options.filter((el) => el.id !== id);
    const updatedField = {
      ...field,
      options: newOptions,
    };

    openConfirmationWindow(() => {
      setOptions(newOptions);
      updateFieldHandler({
        updatedField,
        withGroupedFieldsUpdating: true,
        updateSelectOptions: true,
      });
      setGroupedFieldCount(0);
    }, false, updatedField);
  };

  const changeOption = (id: number): React.ChangeEventHandler<HTMLInputElement> => (event) => {
    const newOptions = options.map((option) => (
      (option.id === id)
        ? {
          ...option,
          id: option.id,
          label: event.target.value,
          isSelectOtherOption: option.isSelectOtherOption,
          checked: option.checked,
        }
        : option
    ));
    const updatedField = {
      ...field,
      options: newOptions,
    };

    setOptions(newOptions);
    setChangedData({
      value: event.target.value,
      method: () => openConfirmationWindow(() => {
        updateFieldHandler({
          updatedField,
          withGroupedFieldsUpdating: true,
          updateSelectOptions: true,
        });
        resetChangedData();
      }, false, updatedField),
      resetMethod: () => setOptions(field.options || []),
    });
  };

  const calculateFieldLength = (maxLength: number | string) => {
    const updatedField = {
      maxLength,
    };
    setFieldMaxLength(maxLength);

    setChangedData({
      value: String(maxLength),
      method: () => openConfirmationWindow(() => {
        updateFieldHandler({
          updatedField,
          withGroupedFieldsUpdating: true,
        });
        resetChangedData();
      }),
      resetMethod: () => setFieldMaxLength(field.maxLength),
    });
  };

  const maxLengthChangeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    const length: number = getLength(event);
    calculateFieldLength(length);
  };

  const clearMaxLengthHandler = (): void => {
    calculateFieldLength(NONE_FIELD_VALUE_LENGTH);
  };

  const updateFieldFontSize = (fontSize: number | string = '') => {
    setFieldFontSize(fontSize);

    const updatedField = {
      fontSize,
    };
    updateFieldHandler({ updatedField });
  };

  const onChangeFontSizeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const fontSizeValue = getFontSizeValue(event);
    updateFieldFontSize(fontSizeValue);
  };

  const onClearFontSizeHandler = () => {
    updateFieldFontSize();
  };

  const isCheckboxPropertyChecked = (propertyObject: any): boolean => {
    if (propertyObject.type === 'checkbox') {
      switch (propertyObject.key) {
        case FIELD_REQUIRED_KEY:
          return required;
        case OPAQUE_BACKGROUND_KEY:
          return isSelectFieldOpaqueBackground;
        case HIDE_LABELS_KEY:
          return areLabelsHidden;
        case FIELD_REMEMBER_KEY:
          return Boolean(fieldSubtype);
        case READ_ONLY_KEY:
          return isReadOnly;
        default:
          return false;
      }
    }
    return false;
  };

  const PROPERTIES_FIELD: FieldPropertiesType[] = [
    {
      key: FIELD_ASSIGNMENT_KEY,
      label: 'Field Assignment',
      type: 'select',
      value: fieldAssignment,
      options: getUnitedOrderedAssignments(collectionAssignments ?? assignments),
      onChangeHandler: updateFieldAssignment,
      hidden: false,
      button: !isSignNow
        ? {
          text: 'Edit',
          handler: () => dispatch(setOpenAssignmentsSettingsModal(true)),
        }
        : undefined,
    },
    {
      key: FIELD_NAME_KEY,
      label: 'Field Name',
      type: 'text',
      value: name,
      onChangeHandler: updateName,
      hidden: false,
    },
    {
      key: FIELD_API_INFO_KEY,
      label: 'Field Key: name/key',
      type: 'text',
      value: field ? `${field.name}/${field.key}` : '',
      onChangeHandler: () => null,
      hidden: false,
      disabled: true,
    },
    {
      key: MONDAY_COM_INTEGRATION_MODAL,
      label: 'Monday.com integration',
      type: 'button',
      value: 'Field mapping settings',
      onChangeHandler: () => null,
      hidden: !isMondayComButtonAvailable,
      onClickHandler: () => setShowMondayComModal(true),
    },
    {
      key: SHORT_NAME_KEY,
      label: 'Short Name (Optional)',
      type: 'text',
      value: fieldShortName,
      onChangeHandler: updateShortNameHandler,
      hidden: fieldType !== QUESTION_FIELD,
    },
    {
      key: GROUP_BY_KEY,
      label: 'Field Grouping',
      type: 'select',
      value: fieldGroupBy,
      options: FIELD_GROUP_BY,
      onChangeHandler: groupByHandler,
      hidden: !GROUPED_FIELDS.includes(fieldType) || !permissionUsingGroupFields,
    },
    {
      key: GROUP_BY_FILTER_FIELD_KEY,
      label: 'Filter by Field',
      type: 'select',
      value: filterName,
      options: gropingFilterNameOptions,
      onChangeHandler: updateGroupFilterNameHandler,
      hidden: fieldGroupBy !== FIELD_GROUPING_VALUE.BY_FILTER || !permissionUsingGroupFields,
    },
    {
      key: FIELD_TYPE_KEY,
      label: 'Field Type',
      type: 'select',
      value: fieldType,
      options: [
        ...FIELD_TYPE_VARIANTS,
        CHECKBOX_FIELD_TYPE,
      ],
      onChangeHandler: updateFieldType,
      hidden: false,
    },
    {
      key: SIGNATURE_VARIATION_TYPE_KEY,
      label: 'Signature Field Type',
      type: 'select',
      value: signatureFieldVariation,
      options: SIGNATURE_FIELD_VARIATIONS,
      onChangeHandler: updateSignatureVariation,
      hidden: fieldType !== SIGNING_FIELD,
    },
    {
      key: DATE_MASK_KEY,
      label: 'Date Mask',
      type: 'select',
      value: dateFieldMask,
      options: DATE_FORMATS,
      onChangeHandler: updateFieldMask,
      hidden: fieldType !== DATE_FIELD,
    },
    {
      key: READ_ONLY_KEY,
      label: 'Read Only',
      type: 'checkbox',
      checked: isReadOnly,
      onChangeHandler: updateReadOnly,
      hidden: fieldType !== DATE_FIELD,
    },
    {
      key: FIELD_SUBTYPE_KEY,
      label: 'Personal Information (PII)',
      type: 'select',
      value: fieldSubtype,
      options: PIIList,
      onChangeHandler: updateFieldSubtype,
      hidden: false,
    },
    {
      key: FIELD_REMEMBER_KEY,
      label: 'Remember',
      type: 'checkbox',
      checked: Boolean(fieldSubtype),
      onChangeHandler: rememberCheckboxHandler,
      hidden: !SUBTYPES_APPLICABLE_FIELD_TYPES.includes(fieldType) || !enableDataReuse,
    },
    {
      key: TEXT_FIELD_MASK_KEY,
      label: 'Field Masking',
      type: 'select',
      value: textFieldMask,
      options: TEXT_FIELD_MASK_OPTIONS,
      onChangeHandler: updateTextFieldMask,
      hidden: fieldType !== TEXT_FIELD || !permissionUsingFieldMasking || !sensitiveInfoAccess,
    },
    {
      key: ANSWER_TYPE_KEY,
      label: 'Answer Type',
      type: 'select',
      value: fieldAnswerType,
      options: SELECT_QUESTION_ANSWER,
      onChangeHandler: updateAnswerTypeHandler,
      hidden: fieldType !== QUESTION_FIELD,
    },
    {
      key: SELECT_FIELD_TYPE_KEY,
      label: 'Select Field Type',
      type: 'select',
      value: selectFieldType,
      options: SELECT_FIELD_SELECT_OPTION,
      onChangeHandler: updateSelectFieldTypeHandler,
      hidden: !getIsSelectField(fieldType),
    },
    {
      key: MAX_LENGTH_KEY,
      label: 'Max Length',
      type: 'number',
      value: fieldMaxLength,
      onChangeHandler: maxLengthChangeHandler,
      onKeyDown: onKeyDownMaxLengthField,
      hidden: false,
      button: {
        text: 'Clear',
        handler: clearMaxLengthHandler,
      },
      placeholder: 'None',
      numberLimits: {
        minValue: FIELD_VALUE_LENGTH.MIN_LIMIT,
        maxValue: FIELD_VALUE_LENGTH.MAX_LIMIT,
      },
    },
    {
      label: 'Font Size',
      type: 'select',
      options: FIELD_VALUE_FONT_OPTIONS,
      value: fieldFontSize,
      onChangeHandler: onChangeFontSizeHandler,
      hidden: [CHECKBOX_FIELD, SIGNING_FIELD].includes(fieldType),
      button: {
        text: 'Reset',
        handler: onClearFontSizeHandler,
      },
    },
    {
      label: 'Help Text',
      type: 'text',
      placeholder: 'Type some Help text',
      value: helpText,
      onChangeHandler: updateHelpText,
      hidden: false,
    },
    {
      key: HIDE_LABELS_KEY,
      label: 'Hide Labels',
      type: 'checkbox',
      checked: areLabelsHidden,
      onChangeHandler: updateLabelsVisibility,
      hidden: !isRadioOrCheckboxSelectType,
    },
    {
      key: OPAQUE_BACKGROUND_KEY,
      label: 'Opaque Background',
      type: 'checkbox',
      checked: isSelectFieldOpaqueBackground,
      onChangeHandler: updateSelectOpaqueBackground,
      hidden: !isRadioOrCheckboxSelectType,
    },
    {
      key: FIELD_REQUIRED_KEY,
      label: 'Required',
      type: 'checkbox',
      checked: required,
      onChangeHandler: updateRequirement,
      hidden: false,
    },
  ];

  const setDisabledRequiredProperty = (propertyKey: string): boolean => (
    propertyKey === FIELD_REQUIRED_KEY
    && required
    && fieldSubtype === SUBTYPE_EMAIL
    && isRecipientAssignment(fieldAssignment)
  );

  const deleteCurrentField = () => {
    setShowConfirmDeletingModal(false);
    dispatch(deletePdfField(selectedFieldKey));
    dispatch(setSelectFieldError(false, selectedFieldKey));
    dispatch(setSelectedFieldKey(null));
    setField(null);
    setRequired(false);

    setKeysArr((prevState: number[]) => prevState.filter((key) => key !== selectedFieldKey) || []);
    setDeletedFieldId(selectedFieldKey || 0);
    dispatch(setAllAssignments(getActualAssignmentsState(
      assignments,
      field.assignment,
      '',
      field.key,
    )));
  };

  if (!field) {
    return null;
  }

  return (
    <Col className="position-relative">
      {
        (isFieldGrouped(fieldGroupBy))
          ? <ConnectionIcon />
          : null
      }
      {PROPERTIES_FIELD.map((el: FieldPropertiesType, index: number) => {
        if (isShownProperty(fieldType, el.hidden, el.key || '')) {
          const propertyKey = `fieldPropertiesWrapper_${index}`;
          if (el.key === FIELD_ASSIGNMENT_KEY) {
            return (
              <div key={propertyKey}>
                {getAssignmentsMenuComponent()}
              </div>
            );
          }
          return (
            <div key={propertyKey}>
              <FieldProperties
                key={el.label}
                label={el.label}
                type={el.type}
                value={el.value}
                placeholder={el.placeholder}
                onChangeHandler={el.onChangeHandler}
                onKeyDown={el.onKeyDown}
                onClickHandler={el.onClickHandler}
                options={getPropertyOptions(el.key, el.options, field.type)}
                checked={isCheckboxPropertyChecked(el)}
                disabled={el.disabled || setDisabledRequiredProperty(el.key || '') || readOnly}
                button={el.button}
                numberLimits={el.numberLimits}
              />
              {
                (el.key === FIELD_REQUIRED_KEY && required) ? (
                  <FormGroup>
                    <Label for="validation_text">Validation text</Label>
                    <Input
                      type="textarea"
                      name="validation_text"
                      value={validationText}
                      onChange={updateValidationText}
                      placeholder="Type a custom validation text"
                    />
                  </FormGroup>
                ) : null
              }
              {
                (el.key === TEXT_FIELD_MASK_KEY && textFieldMask === TEXT_FIELD_MASKS.CUSTOM_TEXT) && (
                  getTextFieldCustomInput()
                )
              }
              {
                el.key === DATE_MASK_KEY && getIsDateField(fieldType)
                && (
                  <TodaysDateFieldProperties
                    field={field}
                    openConfirmationWindow={openConfirmationWindow}
                    updateField={updateFieldHandler}
                    setGroupedFieldCount={setGroupedFieldCount}
                  />
                )
              }
            </div>
          );
        }
        return null;
      })}
      {
        (
          (getIsSelectField(fieldType) && !readOnly)
          || (getIsQuestionField(fieldType) && fieldAnswerType === QUESTION_ANSWER_SELECT)
        ) && (
          <>
            <SelectFieldOptions
              isRequiredField={required}
              options={options}
              changeOptionHandler={changeOption}
              removeOptionHandler={removeOption}
              addOptionHandler={addSelectOption}
              isPdfEditor
            />
            {
              getIsSelectField(fieldType) && options.length >= 1 && (
                <SelectOptionOtherCheckbox
                  type="checkbox"
                  label={ADD_OTHER_OPTION}
                  checked={field.addedOtherOption ?? false}
                  onChangeHandler={
                    field.addedOtherOption ? uncheckSelectOtherOption : () => addSelectOption(true)
                  }
                />
              )
            }
          </>
        )
      }
      {
        getIsAttachmentField(fieldType) && (
          <AttachmentFieldProperties field={field} updateField={updateFieldHandler} />
        )
      }
      {!readOnly && (
        <div className="mt-4 d-flex justify-content-center">
          <DeleteIcon className="close cursor-pointer" onClick={toggleModalWindow} />
        </div>
      )}
      <ModalConfirmDialog
        showDialog={showConfirmDeletingModal}
        onClose={toggleModalWindow}
        onConfirm={deleteCurrentField}
      />
      <ModalConfirmDialog
        showDialog={groupedFieldCount > 1}
        onClose={closeChangingModalWindow}
        onConfirm={confirmChangingAction}
        messageText={getFieldChangingText(field, groupedFieldCount, true)}
      />
      <MondayComModal
        isFieldPropertiesTab
        showMondayComModal={showMondayComModal}
        setShowMondayComModal={setShowMondayComModal}
      />
      <MondayComFieldTypeChecker updateField={updateFieldHandler} />
      <AssignmentsDeletionConfirmationModal pdfFields={pdfTemplateFieldsArray} />
    </Col>
  );
};

export default memo(PdfFieldProperties);
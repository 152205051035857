import { FC, useCallback } from 'react';

import { DeleteOutline as DeleteIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { Editor as SlateEditor, Element as SlateElement, Transforms } from 'slate';
import { ReactEditor, RenderElementProps, useSlate } from 'slate-react';

import ElementSortButton from 'components/FormConstructor/Buttons/ElementSortButton';
import Tooltip from 'components/Tooltip';
import { PARAGRAPH, PDF_TEXT_ALIGN } from 'constants/editors';

interface IRenderElementProps extends RenderElementProps {
  readOnly?: boolean;
}

const SortableParagraph: FC<IRenderElementProps> = ({
  attributes,
  element,
  children,
  readOnly = false,
}) => {
  const editor = useSlate();
  const paragraphKey = (element.type === PARAGRAPH && element.key)
    ? {
      'data-block-key': element.key,
      'data-empty': element.children?.length === 1 && element.children[0].text?.trim() === '',
    }
    : {};

  const isTextNode = element.children?.length && element.children[0].text !== undefined;

  const fontFamily: string = element['font-type'] ? element['font-type'] : 'inherit';
  const textAlign = element.textAlign ? PDF_TEXT_ALIGN[element.textAlign] : 'inherit';
  const margin = element.margin || 0;
  const textIndent = element.textIndent || 0;
  const textStyles = {
    fontFamily: `${fontFamily}`,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    textAlign: `${textAlign}` as any,
    marginLeft: `${margin}px`,
    textIndent: `${textIndent}px`,
  };

  const styledElement = {
    ...element,
  };
  if (styledElement.listStyle !== undefined) {
    styledElement.liststyle = styledElement.listStyle;
    delete styledElement.listStyle;
  }
  if (styledElement.textAlign) {
    delete styledElement.textAlign;
  }
  if ('textIndent' in styledElement) {
    delete styledElement.textIndent;
  }
  if ('margin' in styledElement) {
    delete styledElement.margin;
  }

  const elementPath = !readOnly ? ReactEditor.findPath(editor, element) : [];
  const firstLevel = elementPath.length === 2;

  const onDeleteParagraphHandler = useCallback(() => {
    if (!element) return;

    const fieldPath = ReactEditor.findPath(editor, element);
    Transforms.select(editor, SlateEditor.start(editor, []));
    editor.selection = null;
    Transforms.removeNodes(editor, {
      at: fieldPath,
      match: (node) => SlateElement.isElement(node) && node.type === PARAGRAPH,
    });
  }, [editor, element]);

  return (
    <div className="form-paragraph-field">
      {
        isTextNode && (
          <div
            className="form-section__field-buttons form-paragraph-field__buttons"
            contentEditable={false}
            style={{ userSelect: 'none' }}
          >
            {
              !readOnly && (
                <Tooltip title="Delete">
                  <IconButton aria-label="Delete" size="small" className="p-1" onClick={onDeleteParagraphHandler}>
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )
            }
          </div>
        )
      }
      <div {...attributes} {...styledElement} {...paragraphKey} data-disabled={readOnly}>
        {
          !readOnly && firstLevel && (
            <div className="form-section__sort-field-button position-relative">
              <ElementSortButton className="position-absolute end-100 px-2 py-2" />
            </div>
          )
        }
        {
          isTextNode
            ? <div className="py-2" style={textStyles}>{children}</div>
            : children
        }
      </div>
    </div>
  );
};

export default SortableParagraph;
import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import AccountSettingsWrapper from 'components/AccountSettings/AccountSettingsWrapper';
import CustomDomain from 'components/AccountSettings/Branding/CustomDomain';
import LogoInput from 'components/AccountSettings/Branding/LogoInput';
import SettingsLayout from 'components/AccountSettings/SettingsLayout';
import Button from 'components/Base/Button';
import { DEFAULT_COLOR_SCHEMA } from 'constants/brandingColorSchema';
import { RESET, UPDATE } from 'constants/general';
import { ADMIN_ROLES } from 'constants/roles';
import { PROFILE_SECTIONS } from 'constants/UserMenu';
import { useRolePermission } from 'hooks/useRolePermission';
import useStyleSchemaInputs from 'hooks/useStyleSchemaInputs';
import { getCompanyBranding, updateCompanyColorSchema } from 'store/actions/companyBranding';
import { RootStateType } from 'store/reducers';
import { StyleSchemaType, UseStyleSchemaInputsReturnsType } from 'types/BrandingColorSchema';
import { ICompanyBranding } from 'types/CompanyBranding';
import { hidehttpsDomainPart, validateDomain } from 'utils/CompanyBranding/customDomain';

import 'cropperjs/dist/cropper.css';

const CompanyBranding = () => {
  const dispatch = useDispatch();
  const { permissionUsingCustomDomain } = useRolePermission();
  const {
    logoPath,
    styleJSON,
    customDomain,
    domainCreationDate,
  }: ICompanyBranding = useSelector((state: RootStateType) => state.companyBranding);
  const { userRole } = useSelector((state: RootStateType) => state.profile.profileInfo ?? {});

  useEffect(() => {
    if (!logoPath || !styleJSON || !customDomain) {
      dispatch(getCompanyBranding());
    }
  }, []);

  const [
    colorSchema,
    inputs,
  ]: UseStyleSchemaInputsReturnsType = useStyleSchemaInputs(styleJSON ?? DEFAULT_COLOR_SCHEMA);

  const customDomainError = validateDomain(customDomain, domainCreationDate);

  const onClickUpdateHandler = async () => {
    const payload: Record<string, StyleSchemaType | string | null> = { style_json: colorSchema };
    if (!customDomainError) {
      payload.custom_domain = customDomain;
    }
    dispatch(updateCompanyColorSchema(payload));
  };

  const onClickResetHandler = async () => {
    dispatch(updateCompanyColorSchema({ style_json: DEFAULT_COLOR_SCHEMA }));
  };

  return (
    <AccountSettingsWrapper>
      <SettingsLayout title="Company Logo">
        <LogoInput logoPath={logoPath} />
      </SettingsLayout>
      <SettingsLayout title="Color Schema">
        {inputs.map((Input: JSX.Element | null) => Input || null)}
      </SettingsLayout>
      {
        permissionUsingCustomDomain && ADMIN_ROLES.includes(userRole) && (
          <SettingsLayout title={PROFILE_SECTIONS.HOST_MAPPING}>
            <CustomDomain
              customDomain={hidehttpsDomainPart(customDomain)}
              customDomainError={customDomainError}
            />
          </SettingsLayout>
        )
      }
      <Button
        classes="me-3"
        onClick={onClickUpdateHandler}
        disabled={false}
      >
        {UPDATE}
      </Button>
      <Button classes="outline" onClick={onClickResetHandler}>
        {RESET}
      </Button>
    </AccountSettingsWrapper>
  );
};

export default CompanyBranding;

import { ChangeEvent, ChangeEventHandler, FC, useEffect, useState } from 'react';

import {
  GetCity,
  GetCountries,
  GetState,
} from 'react-country-state-city';
import { useDispatch, useSelector } from 'react-redux';

import AccountSettings from 'components/AccountSettings/AccountSettings';
import AccountSettingsWrapper from 'components/AccountSettings/AccountSettingsWrapper';
import {
  DEFAULT_ORGANIZATION_INFO,
  HOUSTON_CITY_ID,
  INITIAL_FORM_ERRORS,
  ORGANIZATION_INFO_FIELDS_NAMES,
  TEXAS_STATE_ID,
  UNITED_STATES_COUNTRY_ID,
} from 'constants/accountSettings';
import { PROFILE_SECTIONS } from 'constants/UserMenu';
import { CHANGE_ORGANIZATION_TITLES } from 'constants/validation';
import { useRolePermission } from 'hooks/useRolePermission';
import { getUserCompanyData, updateUserCompanyData } from 'store/actions/userProfile';
import { RootStateType } from 'store/reducers';
import {
  CityType,
  CountryType,
  ISettingsField,
  OrganizationInfoType,
  StateType,
} from 'types/AccountSettings';
import { IErrorField } from 'types/validation';
import { createArrayForValidation, validationForm } from 'utils/validation';

const Organization: FC = () => {
  const { banChangeOrganization } = useRolePermission();
  const dispatch = useDispatch();
  const companyData = useSelector((state: RootStateType) => state.profile.companyData);
  const { data: errorsData } = useSelector((state: RootStateType) => state.errorLoading?.error);

  const [countryid, setCountryid] = useState<number>(0);
  const [countriesList, setCountriesList] = useState<CountryType[]>([]);
  const [stateList, setStateList] = useState<StateType[]>([]);
  const [cityList, setCityList] = useState<CityType[]>([]);
  const [formErrors, setFormErrors] = useState<IErrorField>(INITIAL_FORM_ERRORS);
  const [organizationInfo, setOrganizationInfo] = useState<OrganizationInfoType>(DEFAULT_ORGANIZATION_INFO);

  useEffect(() => {
    if (errorsData?.errors && errorsData.errors.company_name) {
      setFormErrors((prevState) => ({
        ...prevState,
        name: errorsData.errors.company_name,
      }));
    }
  }, [errorsData?.errors]);

  const setDefaultOrganizationInfo = () => {
    setOrganizationInfo({
      name: companyData.name,
      address: companyData.address,
      postal_code: companyData.postal_code,
      country: companyData.country,
      state_name: companyData.state_name,
      city: companyData.city,
    });
  };

  useEffect(() => {
    GetCountries().then((countries: CountryType[]) => {
      setCountriesList(countries);
    });
  }, []);

  useEffect(() => {
    if (!companyData) {
      dispatch(getUserCompanyData());
    } else {
      setDefaultOrganizationInfo();
    }
  }, [companyData]);

  useEffect(() => {
    if (countriesList.length && companyData) {
      if (!companyData.country) {
        const usCountry = countriesList.find((country) => String(country.id) === UNITED_STATES_COUNTRY_ID);
        if (usCountry) {
          setOrganizationInfo((prevState) => ({ ...prevState, country: usCountry.name }));
          setCountryid(usCountry.id);
          GetState(usCountry.id).then((states: StateType[]) => {
            setStateList(states);
          });
        }
      }
      if (!companyData.state_name && stateList) {
        if (companyData.state) {
          const existingState = stateList.find((state) => state.name === companyData.state.name);
          if (existingState) {
            setOrganizationInfo((prevState) => ({ ...prevState, state_name: existingState.name }));
            GetCity(countryid, existingState.id).then((cities: CityType[]) => {
              setCityList(cities);
            });
          }
        } else {
          const texasState = stateList.find((state) => state.id === TEXAS_STATE_ID);
          if (texasState) {
            setOrganizationInfo((prevState) => ({ ...prevState, state_name: texasState.name }));
            GetCity(countryid, texasState.id).then((cities: CityType[]) => {
              setCityList(cities);
            });
          }
        }
      }
      if (!companyData.city && cityList) {
        const currentCity: CityType | undefined = cityList.find((city: CityType) => city.id === HOUSTON_CITY_ID);
        if (currentCity) {
          setOrganizationInfo((prevState) => ({ ...prevState, city: currentCity.name }));
        }
      }
      if (!companyData.postal_code && companyData.zip) {
        setOrganizationInfo((prevState) => ({ ...prevState, postal_code: String(companyData.zip) }));
      }
    }
  }, [
    countriesList.length,
    stateList.length,
    cityList.length,
    companyData,
    countryid,
  ]);

  const validateForm = (organizationInfo: OrganizationInfoType) => {
    const arrayForValidation = createArrayForValidation(organizationInfo, CHANGE_ORGANIZATION_TITLES);
    const validateResult = validationForm(arrayForValidation);
    setFormErrors(validateResult.validationFields);
    return validateResult;
  };

  const handleChangeData: ChangeEventHandler<HTMLInputElement | HTMLSelectElement> = (event) => {
    const targetName = event.target.name;
    const updatedOrganizationInfoValue = { ...organizationInfo, [targetName]: event.target?.value };
    setOrganizationInfo(updatedOrganizationInfoValue);
    validateForm(updatedOrganizationInfoValue);
  };

  const updateCompanyData = () => {
    const formValidate = validateForm(organizationInfo);
    if (formValidate.isError) {
      return false;
    }

    const trimmedOrganizationInfo = Object.entries(organizationInfo).reduce((accumulator, [key, value]) => {
      if (typeof value === 'string') {
        return {
          ...accumulator,
          [key]: value.trim(),
        };
      }
      return {
        ...accumulator,
        [key]: value,
      };
    }, {});

    const updatedCompany = { ...trimmedOrganizationInfo };
    dispatch(updateUserCompanyData(updatedCompany));
    return true;
  };

  const resetCompanyData = () => {
    setDefaultOrganizationInfo();
    setFormErrors(INITIAL_FORM_ERRORS);
  };

  const organizationData: ISettingsField[] = [
    {
      label: CHANGE_ORGANIZATION_TITLES.name,
      value: organizationInfo.name,
      name: ORGANIZATION_INFO_FIELDS_NAMES.NAME,
      fieldError: formErrors.name,
    },
    {
      label: CHANGE_ORGANIZATION_TITLES.country,
      value: organizationInfo.country,
      name: ORGANIZATION_INFO_FIELDS_NAMES.COUNTRY,
      fieldError: formErrors.country,
      options: countriesList.map((country: CountryType) => ({
        id: country.id.toString(),
        name: country.name,
      })),
      onChangeHandler: (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const currentCountry: CountryType | undefined = countriesList.find(
          (country: CountryType) => country.name === event.target.value,
        );
        if (currentCountry) {
          setCountryid(currentCountry.id);
          GetState(currentCountry.id).then((states: StateType[]) => {
            setStateList(states);
          });
          handleChangeData(event);
        }
      },
    },
    {
      label: CHANGE_ORGANIZATION_TITLES.state,
      value: organizationInfo.state_name,
      name: ORGANIZATION_INFO_FIELDS_NAMES.STATE_NAME,
      fieldError: formErrors.state_name,
      options: stateList.map((state: StateType) => ({
        id: state.id.toString(),
        name: state.name,
      })),
      onChangeHandler: (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const currentState: StateType | undefined = stateList.find(
          (state: StateType) => state.name === event.target.value,
        );
        if (currentState) {
          GetCity(countryid, currentState.id).then((cities: CityType[]) => {
            setCityList(cities);
          });
          handleChangeData(event);
        }
      },
    },
    {
      label: CHANGE_ORGANIZATION_TITLES.city,
      value: organizationInfo.city,
      name: ORGANIZATION_INFO_FIELDS_NAMES.CITY,
      fieldError: formErrors.city,
      options: cityList.map((city: CityType) => ({
        id: city.id.toString(),
        name: city.name,
      })),
      onChangeHandler: (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const currentCity: CityType | undefined = cityList.find(
          (city: CityType) => city.name === event.target.value,
        );
        if (currentCity) {
          handleChangeData(event);
        }
      },
    },
    {
      label: CHANGE_ORGANIZATION_TITLES.address,
      value: organizationInfo.address,
      name: ORGANIZATION_INFO_FIELDS_NAMES.ADDRESS,
      fieldError: formErrors.address,
    },
    {
      label: CHANGE_ORGANIZATION_TITLES.postalCode,
      value: organizationInfo.postal_code,
      name: ORGANIZATION_INFO_FIELDS_NAMES.POSTAL_CODE,
      fieldError: formErrors.postal_code,
    },
  ];

  return (
    <AccountSettingsWrapper>
      <AccountSettings
        fields={organizationData}
        title={PROFILE_SECTIONS.ORGANIZATION}
        canEditFields={!banChangeOrganization}
        isEditable={errorsData?.errors?.company_name}
        updateClickHandler={updateCompanyData}
        cancelClickHandler={resetCompanyData}
        handleChangeData={(event) => handleChangeData(event)}
      />
    </AccountSettingsWrapper>
  );
};

export default Organization;
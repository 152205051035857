import { FC, useState } from 'react';

import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { DEFAULT_MAX_DOCUMENTS_TO_DOWNLOAD } from 'constants/muiTable';
import moment from 'moment';
import { DisplayData } from 'mui-datatables';

import Button from 'components/Base/Button';
import SimpleSpinner from 'components/Base/SimpleSpinner';
import { SUCCESS } from 'constants/api';
import { MOMENT_DATE_FORMAT } from 'constants/dateField';
import useCSVData from 'hooks/useCSVData';
import { batchDownloadExecutedDocuments } from 'services/api';
import { apiErrorHandler } from 'services/apiErrorHandler';
import { RootStateType } from 'store/reducers';
import { IDocumentsIdsArray, SelectedRowsType } from 'types/Mui';
import { ICollectionDetails, ICollectionTemplate } from 'types/MultiTemplate';
import { createDocsIdsArray } from 'utils/sendDocumentsHelpers';

interface IBatchDownloadExecutedDocumentsButton {
  selectedRows: SelectedRowsType;
  displayData: DisplayData;
  isCollectionPage?: boolean;
}

const BatchDownloadExecutedDocumentsButton: FC<IBatchDownloadExecutedDocumentsButton> = ({
  selectedRows,
  displayData,
  isCollectionPage = false,
}) => {
  const { collectionsExecuted } = useSelector((state: RootStateType) => state.user);
  const { downloadAttachmentsZIP } = useCSVData();

  const [disableButton, setDisableButton] = useState<boolean>(false);

  const handlerDownloadDocuments = async () => {
    setDisableButton(true);
    const documentsIds = createDocsIdsArray(selectedRows, displayData);
    if (documentsIds.length > DEFAULT_MAX_DOCUMENTS_TO_DOWNLOAD) {
      setDisableButton(false);
      toast.info(`You can't download more than ${DEFAULT_MAX_DOCUMENTS_TO_DOWNLOAD} documents.`);
      return;
    }

    const docsInCollectionIds:IDocumentsIdsArray[] = [];

    if (isCollectionPage) {
      documentsIds.forEach((item) => {
        const currentCollection = collectionsExecuted.find(
          (collection: ICollectionDetails) => collection.id === item.id,
        );
        currentCollection?.documents.forEach((docObject: ICollectionTemplate) => {
          const document = docObject.document;
          if (document) {
            docsInCollectionIds.push({ id: document.id, type: document.type });
          }
        });
      });
    }
    const filesLinksResp = await apiErrorHandler(batchDownloadExecutedDocuments, documentsIds);
    if (filesLinksResp.code === SUCCESS) {
      downloadAttachmentsZIP(
        filesLinksResp.links_array,
        `Archive_${moment(Date.now()).format(MOMENT_DATE_FORMAT)}.zip`,
      );
    }
    setDisableButton(false);
  };

  return (
    <div className="d-inline-block me-2">
      <Button
        classes="mui-table-button min-width-200"
        onClick={handlerDownloadDocuments}
        disabled={disableButton}
      >
        {disableButton ? <SimpleSpinner /> : 'Download'}
      </Button>
    </div>
  );
};

export default BatchDownloadExecutedDocumentsButton;
import moment from 'moment';

import { WEBSITE_URL_REGEX_SECURE } from 'constants/validation';

export const validateDomain = (
  domain: string | null,
  domainCreationDate: string | null,
) => {
  if (domainCreationDate && (moment(domainCreationDate).diff(moment(Date.now()), 'days') >= 0)) {
    return 'You cannot change your subdomain more than once in 24 hours.';
  }
  if (!domain || WEBSITE_URL_REGEX_SECURE.test(domain)) {
    return '';
  }
  return 'Domain is invalid';
};

export const hidehttpsDomainPart = (domain: string | null) => {
  if (domain) {
    if (domain.startsWith('https://')) {
      return domain.substring(8);
    }
    if (domain.startsWith('http://')) {
      return domain.substring(7);
    }
    return domain;
  }
  return null;
};
/* eslint-disable @typescript-eslint/no-explicit-any */
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { Descendant } from 'slate';

import { STATUSES } from 'constants/documentStatuses';
import { COLLECTION_TYPE, URL_PUBLIC_TEMPLATE } from 'constants/general';
import { PROVIDE_REFERENCE_NUMBER } from 'constants/pageSettings';
import { ShareViewModes } from 'constants/shareViewModes';
import {
  editPublicDocument,
  getPublicDocument,
  getPublicMultiTemplate,
  getPublicTemplate,
  publicPageRequestHandler,
  saveDocumentPublicLink,
  saveMultiTemplatePublicLink,
  saveTemplatePublicLink,
} from 'services/api';
import {
  CREATE_DOCUMENT_PUBLIC_LINK,
  CREATE_MULTI_TEMPLATE_PUBLIC_LINK,
  CREATE_TEMPLATE_PUBLIC_LINK,
  GET_PUBLIC_DOCUMENT,
  GET_PUBLIC_MULTI_TEMPLATE,
  GET_PUBLIC_PAGE_DATA,
  GET_PUBLIC_TEMPLATE,
  SAVE_PUBLIC_DOCUMENT,
  SET_DOCUMENT_PUBLIC_LINK,
  SET_DOCUMENTS,
  SET_MULTI_TEMPLATE_PUBLIC_LINK,
  SET_PUBLIC_DOCUMENT,
  SET_PUBLIC_MULTI_TEMPLATE,
  SET_PUBLIC_TEMPLATE,
  SET_TEMPLATE_PUBLIC_LINK,
  UPDATE_PUBLIC_PAGE_DATA,
  UPDATE_PUBLIC_PAGE_DATA_SAGA,
  UPDATE_PUBLIC_PAGE_DOCUMENTS,
  UPDATE_PUBLIC_PAGE_DOCUMENTS_SAGA,
  UPDATE_PUBLIC_PAGE_PII_DATA_IN_ALL_DOCUMENTS_SAGA,
} from 'store/actions/actionTypes';
import { setCompanyBranding } from 'store/actions/companyBranding';
import {
  setDocumentUUID,
  setHistoryLogCurrentDocument,
  setPublicPageData,
  setPublicPageStructure,
  setShowSMSValidationModal,
} from 'store/actions/publicPages';
import { RootStateType } from 'store/reducers';
import { safe } from 'store/sagas/errorHandlerSaga';
import { IResponseOfRequest } from 'types/Api';
import { ICollectionExecutedDetails, ICollectionExecutedDocument } from 'types/CollectionExecuted';
import { IDocumentDetails } from 'types/Documents';
import { HISTORY_ACTIONS } from 'types/DocumentsHistory';
import { ICollectionDetails, ICollectionTemplate } from 'types/MultiTemplate';
import { PDFFieldTypeObject } from 'types/PdfTemplates';
import {
  AggregatedFieldsStructureType,
  IPublicPageDataPayloadGetter,
  IPublicPageDocumentStructure,
  IPublicPageFormBuilderFields,
  IPublicPageGeneralStructure,
  PublicPageDataType,
  UpdatePublicPageFieldsEntriesType,
} from 'types/PublicPage';
import { Action } from 'types/redux';
import { ITemplateDetails } from 'types/Templates';
import { setUserLocationToLocalStorage } from 'utils/amplitude/amplituteServices';
import { getUTCDate } from 'utils/documentsHistory';
import {
  getIsCollectionFormBuilder,
  getIsCollectionWithDocumentAndFormBuilderView,
  getIsFormBuilder,
  getIsPDFDocument,
} from 'utils/PublicPage/documentTypeChecker';
import {
  getUpdatedCollectionWithFormBuilder,
  getUpdatedTemplateForFormBuilder,
  getUpdatedTemplatesForFormBuilder,
} from 'utils/PublicPage/formBuilderHelpers';
import getGeneralDocumentStructure from 'utils/PublicPage/getGeneralDocumentStructure';
import getGroupedFieldsEntriesData from 'utils/PublicPage/getGroupedFieldsEntriesData';
import updateData from 'utils/PublicPage/updateData';
import { updatePublicPageFields } from 'utils/PublicPage/updatePublicPageStoreHelper';
import { isCollectionExecutedType, isCollectionType, isDocumentType, isTemplateType } from 'utils/typeChecker';

const getPublicPageDataSaga = function* ({
  payload: { url, type, id, salt, decodedAssignment },
}: Action<IPublicPageDataPayloadGetter>) {
  const { data }: IResponseOfRequest<PublicPageDataType> = yield call(publicPageRequestHandler, url);
  if (data.auth && data.auth.login) {
    yield put(setHistoryLogCurrentDocument({
      target: HISTORY_ACTIONS.PASSWORDLESS_AUTH,
      value: getUTCDate(),
    }));
  }
  if (data.branding) {
    yield put(setCompanyBranding(data.branding));
  }
  const structure = getGeneralDocumentStructure({
    type,
    id,
    data,
    salt,
    decodedAssignment,
  });

  const isTemplate = structure.main.pageType === URL_PUBLIC_TEMPLATE;
  if (data.document_uuid) {
    yield put(setDocumentUUID(data.document_uuid));
  } else if (isTemplate && data.page_settings && data.page_settings[PROVIDE_REFERENCE_NUMBER]) {
    yield put(setDocumentUUID(crypto.randomUUID()));
  }

  if (data.sms_is_sent_to_document_creator) {
    yield put(setShowSMSValidationModal(true, data.sms_is_sent_to_phone_number ?? '', true));
  }

  yield put(setPublicPageData(data));
  yield put(setPublicPageStructure(structure));
  yield setUserLocationToLocalStorage();
};

const updatePublicPageDataSaga = function* ({
  payload: {
    content,
    id,
  },
}: Action<{ content: Descendant[], id: number }>) {
  const data: PublicPageDataType = yield select((state: RootStateType) => state.publicPages.data);
  const formBuilderFields: IPublicPageFormBuilderFields = yield select((state: RootStateType) => (
    state.publicPages.structure.formBuilderFields
  ));
  const pdfFields: PDFFieldTypeObject = yield select((state: RootStateType) => state.pdfTemplates.pdfTemplateFields);

  if (isTemplateType(data) && getIsPDFDocument(data.type) && getIsFormBuilder(data.view_mode)) {
    const updatedTemplate = getUpdatedTemplateForFormBuilder(data, formBuilderFields);
    yield put(setPublicPageData(updatedTemplate));
    return;
  }

  if (isCollectionType(data) && getIsCollectionFormBuilder(data)) {
    const updatedTemplates: ICollectionTemplate[] = getUpdatedTemplatesForFormBuilder(data, formBuilderFields);

    const updatedData: ICollectionDetails = {
      ...data,
      templates: updatedTemplates,
    };
    yield put(setPublicPageData(updatedData));
    return;
  }

  if (
    isCollectionType(data) && getIsCollectionWithDocumentAndFormBuilderView(data.type, data.view_mode as ShareViewModes)
  ) {
    const updatedCollection: ICollectionDetails = getUpdatedCollectionWithFormBuilder(data, formBuilderFields);

    yield put(setPublicPageData(updatedCollection));
    return;
  }

  if (isCollectionType(data)) {
    const updatedTemplate: ICollectionTemplate[] = data.templates?.map((collectionTemplate: ICollectionTemplate) => {
      if (collectionTemplate.template_id === id && isTemplateType(collectionTemplate.template)) {
        const currentTemplate: ICollectionTemplate = {
          ...collectionTemplate,
          template: updateData(collectionTemplate.template, content, pdfFields) as ITemplateDetails,
        };
        return currentTemplate;
      }
      return collectionTemplate;
    }) || [];

    const updatedData: ICollectionDetails = {
      ...data,
      templates: updatedTemplate,
    };

    yield put(setPublicPageData(updatedData));
    return;
  }

  if (isCollectionExecutedType(data)) {
    const updatedDocuments: ICollectionExecutedDocument[] = data.documents?.map((collectionDocument) => {
      if (collectionDocument.document_id === id && isDocumentType(collectionDocument.document)) {
        const currentDocument: ICollectionExecutedDocument = {
          ...collectionDocument,
          document: updateData(collectionDocument.document, content, pdfFields) as IDocumentDetails,
        };
        return currentDocument;
      }
      return collectionDocument;
    }) || [];

    const updatedData: ICollectionExecutedDetails = {
      ...data,
      documents: updatedDocuments,
    };

    yield put(setPublicPageData(updatedData));
    return;
  }

  yield put(setPublicPageData(updateData(data, content, pdfFields)));
};

const updatePublicPageDocumentsSaga = function* ({ payload: currentDocument }: Action<IPublicPageDocumentStructure>) {
  const structure: IPublicPageGeneralStructure = yield select((state: RootStateType) => state.publicPages.structure);
  const updatedStructure: IPublicPageGeneralStructure = {
    ...structure,
    documents: structure.documents.map((document) => {
      if (Number(document.id) === Number(currentDocument.id)) {
        return currentDocument;
      }
      if (document.documentType === COLLECTION_TYPE && document.viewMode === ShareViewModes.SHARE_MODE_FORM_BUILDER) {
        // const { subtypes, groupedFields } = structure;
        // const entries: IUpdatePublicPageFieldsEntries = getGropedFieldsAndSubtypesEntriesData(
        //   subtypes,
        //   groupedFields,
        // );
        // const keys = Object.keys(entries).map(Number);
        // const updatedSlides = document.slides.map((section) => ({
        //   ...section,
        //   fields: getUpdateFieldsFromEntries(section.fields || [], keys, entries),
        // }));
        // return {
        //   ...document,
        //   slides: updatedSlides,
        // };
        return document;
      }
      return document;
    }),
  };
  yield put({ type: UPDATE_PUBLIC_PAGE_DOCUMENTS, payload: updatedStructure });
};

const updatePublicPagePIIDataInAllDocumentsSaga = function* () {
  const data: PublicPageDataType | null = yield select((state: RootStateType) => state.publicPages.data);
  const groupedFieldsStructure: AggregatedFieldsStructureType[] = yield select((state: RootStateType) => (
    state.publicPages.structure.groupedFieldsStructure
  ));

  const entries: UpdatePublicPageFieldsEntriesType = getGroupedFieldsEntriesData(groupedFieldsStructure);

  const newData = updatePublicPageFields(data, entries);
  yield put({ type: UPDATE_PUBLIC_PAGE_DATA, payload: newData });
};

// Public templates
const getTemplateForPublic = function* ({ payload }: any) {
  const { data }: IResponseOfRequest<ITemplateDetails> = yield getPublicTemplate(payload);
  yield put({ type: SET_PUBLIC_TEMPLATE, payload: data });
};

const createTemplatePublicLink = function* ({ payload }: any) {
  const link: IResponseOfRequest<string> = yield saveTemplatePublicLink(payload);
  yield put({ type: SET_TEMPLATE_PUBLIC_LINK, payload: link.data });
};

// Public documents
const getDocumentForPublic = function* ({ payload }: any) {
  const document: IResponseOfRequest<IDocumentDetails> = yield getPublicDocument(payload);
  yield put({ type: SET_PUBLIC_DOCUMENT, payload: document.data });
};

const createDocumentPublicLink = function* ({ payload }: any) {
  const link: IResponseOfRequest<string> = yield saveDocumentPublicLink(payload);
  yield put({ type: SET_DOCUMENT_PUBLIC_LINK, payload: link.data });
  const documentsExecuted: IDocumentDetails[] = yield select((state) => state.user.documentsExecuted);
  const updatedDocuments = documentsExecuted.map((document: IDocumentDetails) => {
    // TODO to avoid parseInt we need to describe the payload variable type
    if (document.id === parseInt(payload.document_id)) {
      const newStatus = document.status !== STATUSES.completed ? STATUSES.waiting : document.status;
      const updatedStatus = document.status === STATUSES.notSubmitted ? STATUSES.notSubmitted : newStatus;
      return {
        ...document,
        status: updatedStatus,
      };
    }
    return document;
  });
  yield put({ type: SET_DOCUMENTS, payload: updatedDocuments });
};

const savePublicDocument = function* ({ payload }: any) {
  yield editPublicDocument(payload);
};

// Public multi templates
const getPublicMultiTemplateSaga = function* ({ payload }: any) {
  const multiTemplate: IResponseOfRequest<ICollectionDetails> = yield getPublicMultiTemplate(payload);
  yield put({ type: SET_PUBLIC_MULTI_TEMPLATE, payload: multiTemplate.data });
};

const createMultiTemplatePublicLink = function* ({ payload }: any) {
  const link: IResponseOfRequest<string> = yield saveMultiTemplatePublicLink(payload);
  yield put({ type: SET_MULTI_TEMPLATE_PUBLIC_LINK, payload: link.data });
};

export default [
  takeLatest(GET_PUBLIC_PAGE_DATA, safe(getPublicPageDataSaga)),
  takeLatest(UPDATE_PUBLIC_PAGE_DATA_SAGA, safe(updatePublicPageDataSaga)),
  takeLatest(UPDATE_PUBLIC_PAGE_DOCUMENTS_SAGA, safe(updatePublicPageDocumentsSaga)),
  takeLatest(UPDATE_PUBLIC_PAGE_PII_DATA_IN_ALL_DOCUMENTS_SAGA, safe(updatePublicPagePIIDataInAllDocumentsSaga)),
  takeLatest(GET_PUBLIC_TEMPLATE, safe(getTemplateForPublic)),
  takeLatest(CREATE_TEMPLATE_PUBLIC_LINK, safe(createTemplatePublicLink)),
  takeLatest(GET_PUBLIC_DOCUMENT, safe(getDocumentForPublic)),
  takeLatest(CREATE_DOCUMENT_PUBLIC_LINK, safe(createDocumentPublicLink)),
  takeLatest(SAVE_PUBLIC_DOCUMENT, safe(savePublicDocument)),
  takeLatest(CREATE_MULTI_TEMPLATE_PUBLIC_LINK, safe(createMultiTemplatePublicLink)),
  takeLatest(GET_PUBLIC_MULTI_TEMPLATE, safe(getPublicMultiTemplateSaga)),
];
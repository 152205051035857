import {
  SET_SELECTED_TABLE_ROW,
} from 'store/actions/actionTypes';
import { Action, IMuiTableStateStore } from 'types/redux';

const initialState: IMuiTableStateStore = {
  selectedTableRow: null,
};

const muiTableState = (
  state: IMuiTableStateStore = initialState,
  { type, payload }: Action = { type: '', payload: null },
): IMuiTableStateStore => {
  switch (type) {
    case SET_SELECTED_TABLE_ROW:
      return {
        ...state,
        selectedTableRow: payload,
      };
    default:
      return state;
  }
};

export default muiTableState;
import {
  SET_COMPANY_BRANDING,
  SET_COMPANY_LOGO,
  SET_COMPANY_STYLE_SCHEMA,
  SET_CUSTOM_DOMAIN,
  SET_CUSTOM_DOMAIN_CREATION_DATE,
} from 'store/actions/actionTypes';
import { ICompanyBranding } from 'types/CompanyBranding';
import { Action } from 'types/redux';

const initialState: ICompanyBranding = {
  logoPath: null,
  styleJSON: null,
  customDomain: null,
  domainCreationDate: null,
};

// eslint-disable-next-line default-param-last
const companyBranding = (state: ICompanyBranding = initialState, { type, payload }: Action): ICompanyBranding => {
  switch (type) {
    case SET_COMPANY_BRANDING:
      return {
        ...state,
        ...payload,
      };
    case SET_COMPANY_LOGO:
      return {
        ...state,
        logoPath: payload.logoPath,
      };
    case SET_COMPANY_STYLE_SCHEMA:
      return {
        ...state,
        styleJSON: payload.styleJSON,
      };
    case SET_CUSTOM_DOMAIN:
      return {
        ...state,
        customDomain: payload,
      };
    case SET_CUSTOM_DOMAIN_CREATION_DATE:
      return {
        ...state,
        domainCreationDate: payload,
      };
    default:
      return state;
  }
};

export default companyBranding;
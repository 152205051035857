import { ChangeEvent, FC } from 'react';

import { useDispatch } from 'react-redux';
import { Form, FormFeedback, Input } from 'reactstrap';

import CustomDomainHelpText from 'components/AccountSettings/Branding/CustomDomainHelpText';
import Button from 'components/Base/Button';
import {
  CUSTOM_DOMAIN_FIELD_NAME,
  CUSTOM_DOMAIN_PLACEHOLDER,
} from 'constants/accountSettings';
import { DELETE } from 'constants/general';
import { PROFILE_SECTIONS } from 'constants/UserMenu';
import { deleteCustomDomain, setCustomDomainInStore } from 'store/actions/companyBranding';
import { ICustomDomain } from 'types/CompanyBranding';

const CustomDomain: FC<ICustomDomain> = ({
  customDomain,
  customDomainError,
}) => {
  const dispatch = useDispatch();

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setCustomDomainInStore(event.target.value));
  };

  const onDeleteHandler = () => {
    dispatch(deleteCustomDomain());
  };

  return (
    <Form>
      <div key={CUSTOM_DOMAIN_FIELD_NAME} className="row mb-3 pb-3 d-flex align-items-center account-settings__row">
        <label className="col-12 col-lg-3 account-settings__label" htmlFor={CUSTOM_DOMAIN_FIELD_NAME}>
          {PROFILE_SECTIONS.HOST_MAPPING}
        </label>
        <div className="col-12 col-lg">
          <Input
            id={CUSTOM_DOMAIN_FIELD_NAME}
            className="account-settings__text account-settings__input"
            value={customDomain ?? ''}
            placeholder={CUSTOM_DOMAIN_PLACEHOLDER}
            type="text"
            name={CUSTOM_DOMAIN_FIELD_NAME}
            disabled={false}
            onChange={(event) => onChangeHandler(event)}
            required
            invalid={!!customDomainError}
          />
          <FormFeedback>{customDomainError}</FormFeedback>
          <Button
            classes="mt-3"
            onClick={onDeleteHandler}
            disabled={!customDomain}
          >
            {DELETE}
          </Button>
          <CustomDomainHelpText subdomain={customDomain} />
        </div>
      </div>
    </Form>
  );
};

export default CustomDomain;
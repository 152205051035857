import { useCallback, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useDropzone } from 'react-dropzone';

import Button from 'components/Base/Button';
import SimpleSpinner from 'components/Base/SimpleSpinner';
import { SUCCESS } from 'constants/api';
import ROUTES from 'constants/routes';
import { MAX_TEMPLATES_COUNT_REACHED } from 'constants/signNow';
import { uploadFile } from 'services/api/signNow';
import { apiErrorHandler } from 'services/apiErrorHandler';
import {
  setFileUrl,
  setSignNowDocumentInfo,
} from 'store/actions/signNow';
import { RootStateType } from 'store/reducers';
import { IUploadFileResponse } from 'types/signNow/store';
import { generateNumber } from 'utils/editorFieldHelpers';

import 'scss/components/Dropzone/dashboardDropzone.scss';

const DashboardDropzone = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const profileInfo = useSelector((state: RootStateType) => state.profile.profileInfo);
  const { signnowDocumentsSentCurrentMonth } = useSelector((state: RootStateType) => state.user);

  const denyToCreateSignNowDocument = useMemo(() => (
    signnowDocumentsSentCurrentMonth !== null
    && profileInfo.company.settings.max_templates
    && signnowDocumentsSentCurrentMonth >= profileInfo.company.settings.max_templates
  ), [profileInfo, signnowDocumentsSentCurrentMonth]);

  const handlerOnClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (denyToCreateSignNowDocument) {
      event.preventDefault();
      event.stopPropagation();
      toast.info(MAX_TEMPLATES_COUNT_REACHED);
    }
  };

  const uploadSignNowDocuments = async (
    acceptedFiles: File[],
    index: number = 0,
  ) => {
    const currentFile = acceptedFiles[index];
    // Used random number in order to avoid timestamps duplicates.
    const timeStamp = generateNumber('', 12);
    const formData = new FormData();
    const fileName: string = currentFile.name;

    formData.append('file', currentFile);
    formData.append('fieldId', 'file_path');
    formData.append('timeStamp', `${timeStamp}`);

    const response = await apiErrorHandler<IUploadFileResponse, FormData>(uploadFile, formData);

    if (response.code === SUCCESS) {
      const { data } = response;
      dispatch(setFileUrl({
        fileKey: data.file_key,
        fileUrl: data.full_link,
        pdfWidth: data.pdf_width,
      }));
      // if (data.pdf_form_fields.length) {
      //   dispatch(setSignNowPdfFormFields(data.pdf_form_fields));
      // }
      dispatch(setSignNowDocumentInfo({
        id: null,
        title: fileName.slice(0, fileName.lastIndexOf('.')),
        fileKey: data.file_key,
        isError: false,
      }));
      if (index === acceptedFiles.length - 1) {
        setIsLoading(false);
        history.push(ROUTES.SIGN_NOW);
      } else {
        uploadSignNowDocuments(acceptedFiles, index + 1);
      }
    }
  };

  const handleUpload = useCallback(async (acceptedFiles: File[]) => {
    if (denyToCreateSignNowDocument) {
      toast.info(MAX_TEMPLATES_COUNT_REACHED);
      return;
    }
    setIsLoading(true);
    uploadSignNowDocuments(acceptedFiles);
  }, [dispatch, history]);

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop: handleUpload,
    maxFiles: 10,
    multiple: true,
    maxSize: 20480 * 1000,
    accept: {
      'application/pdf': ['.pdf'],
      // Word documents are disabled for now.
      // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      // 'application/msword': ['.doc'],
    },
    disabled: isLoading,
  });

  return (
    <section className="container">
      <div {...getRootProps({
        className: 'dashboard-dropzone',
        onClick: denyToCreateSignNowDocument ? (event) => handlerOnClick(event) : () => null,
      })}
      >
        <input
          {...getInputProps({
            multiple: false,
          })}
        />
        {
          isLoading
            ? <SimpleSpinner />
            : (
              <>
                <h3>Sign now by dropping files here</h3>
                <Button onClick={() => undefined}>
                  Upload
                </Button>
              </>
            )
        }
      </div>
      {
        fileRejections.length
          ? (
            <ul className="dashboard-dropzone__errors-list">
              {
                fileRejections.map(({ file, errors }) => (
                  <li className="dashboard-dropzone__error-file-name" key={file.name}>
                    {file.name}:
                    <ul className="dashboard-dropzone__errors-message-list">
                      {
                        errors.map(({ message }) => (
                          <li key={file.name + message}>{message}</li>
                        ))
                      }
                    </ul>
                  </li>
                ))
              }
            </ul>
          )
          : null
      }

    </section>
  );
};

export default DashboardDropzone;
import { FC } from 'react';

import { useSelector } from 'react-redux';

import DocumentNavigator from 'components/DocumentNavigator';
import EditorContainer from 'components/Editor/EditorContainer';
import PdfEditor from 'components/PdfEditor/PdfEditor';
import PdfFieldsGeneralWrapper from 'components/PdfEditor/PdfFieldsSidebar/PdfFieldsGeneralWrapper';
import SetSignersAndDocumentTitleHeader from 'components/SignNow/SetSignersAndDocumentTitleHeader';
import useCollectionSlidersInSignNow from 'hooks/CollectionSliders/useCollectionSlidersInSignNow';
import { useSetFields } from 'hooks/signNow';
import { RootStateType } from 'store/reducers';
import { IStepManagerChildComponentProps } from 'types/signNow/base';

import 'scss/components/Editor/_editor.scss';

const SetFieldsAndEditor: FC<IStepManagerChildComponentProps> = ({
  onNextHandler,
}) => {
  useSetFields({ onNextHandler });
  const { documents } = useSelector((state: RootStateType) => state.signNow);
  const {
    currentPdfTemplateKey,
    getCollectionSlidersComponent,
  } = useCollectionSlidersInSignNow(documents);

  return (
    <>
      <SetSignersAndDocumentTitleHeader documentKey={currentPdfTemplateKey} />
      <EditorContainer>
        <DocumentNavigator panelTitle="">
          <PdfFieldsGeneralWrapper />
        </DocumentNavigator>
        <PdfEditor visibleEditorDevTools />
      </EditorContainer>
      {
        documents && documents.length > 1 && (
          getCollectionSlidersComponent()
        )
      }
    </>
  );
};

export default SetFieldsAndEditor;
import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import Logo from 'components/Intro/Logo';
import { PASSWORDLESS_EMAIL_SENT_MODAL_TEXT } from 'constants/mondayComIntegration';
import useStyleSchema, { CustomStylesEnum } from 'hooks/useStylesFromSchema';
import { setShowEmailHasBeenSentModal } from 'store/actions/publicPages';
import { RootStateType } from 'store/reducers';
import { IPublicPageState } from 'types/PublicPage';

import classes from 'scss/components/Modal/ConfirmationModal.module.scss';

const RememberPIIEmailHasBeenSentModal = () => {
  const dispatch = useDispatch();
  const {
    showEmailHasBeenSentModal,
  }: IPublicPageState = useSelector((state: RootStateType) => state.publicPages);

  const styleSchema = useStyleSchema<
    CustomStylesEnum.button
    | CustomStylesEnum.outlinedBorderButton
    | CustomStylesEnum.title
    | CustomStylesEnum.description
  >([
    CustomStylesEnum.button,
    CustomStylesEnum.outlinedBorderButton,
    CustomStylesEnum.title,
    CustomStylesEnum.description,
  ]);

  useEffect(() => () => {
    dispatch(setShowEmailHasBeenSentModal(false));
  }, [dispatch]);

  return (
    <Modal
      centered
      isOpen={showEmailHasBeenSentModal}
      modalClassName="restore-data-modal"
    >
      <ModalHeader>
        <span className={classes.Logo}>
          <Logo />
        </span>
      </ModalHeader>
      <ModalBody>
        <div className={classes.Body}>
          <div
            className="text-center"
            style={styleSchema.description}
          >
            <p>
              {PASSWORDLESS_EMAIL_SENT_MODAL_TEXT}
            </p>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default RememberPIIEmailHasBeenSentModal;
import { FC } from 'react';

import { Editor } from '@tinymce/tinymce-react';

interface IDescriptionEditor {
  isReadOnly: boolean,
  onChange?: ((value: string) => void) | undefined;
  value: string
}

const DescriptionEditor: FC<IDescriptionEditor> = ({
  isReadOnly,
  onChange,
  value,
}) => (
  (!isReadOnly || (isReadOnly && value?.length))
    ? (
      <Editor
        tinymceScriptSrc={`${process.env.PUBLIC_URL}/tinymce/tinymce.min.js`}
        onInit={(evt: any, editor: any) => editor.focus()}
        onEditorChange={(newValue: string) => {
          if (!isReadOnly && onChange) {
            onChange(newValue);
          }
        }}
        disabled={isReadOnly}
        value={value || ''}
        init={{
          license_key: 'gpl',
          height: 200,
          menubar: false,
          plugins: [
            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
            'anchor', 'searchreplace', 'visualblocks', 'insertdatetime',
          ],
          toolbar: 'bold italic forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | image',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        }}
      />
    )
    : null
);

export default DescriptionEditor;
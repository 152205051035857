import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';

import {
  deleteCompanyLogo,
  deleteCustomDomain,
  getCompanyBranding,
  postCompanyLogo,
  postCompanyStyleSchema,
} from 'services/api/companyBranding';
import {
  DELETE_COMPANY_LOGO,
  DELETE_CUSTOM_DOMAIN,
  GET_COMPANY_BRANDING,
  SET_COMPANY_STYLE_SCHEMA,
  UPDATE_COMPANY_STYLE_SCHEMA,
  UPLOAD_COMPANY_LOGO,
} from 'store/actions/actionTypes';
import {
  setCompanyBranding,
  setCompanyLogo,
  setCustomDomainCreationDateInStore,
  setCustomDomainInStore,
} from 'store/actions/companyBranding';
import { safe } from 'store/sagas/errorHandlerSaga';
import { IResponseOfRequest } from 'types/Api';
import { PostStyleSchemaType } from 'types/BrandingColorSchema';
import {
  ICompanyBrandingResponse,
  ICompanyLogoResponse,
  ICustomDomainDeletionResponse,
  IFileUploadActionPayload,
} from 'types/CompanyBranding';
import { Action } from 'types/redux';

const getCompanyBrandingSaga = function* () {
  const { data }: IResponseOfRequest<ICompanyBrandingResponse> = yield call(getCompanyBranding);
  yield put(setCompanyBranding(data));
};

const updateCompanyStyleSchemaSaga = function* ({ payload }: Action<PostStyleSchemaType>) {
  const { data }: IResponseOfRequest<PostStyleSchemaType> = yield call(postCompanyStyleSchema, payload);
  yield put({ type: SET_COMPANY_STYLE_SCHEMA, payload: { styleJSON: data.style_json } });
  if (data.custom_domain) {
    yield put(setCustomDomainInStore(data.custom_domain));
    yield put(setCustomDomainCreationDateInStore(data.domain_creation_date));
  }
  yield toast.success('The Color Scheme has been updated successfully.');
};

const uploadCompanyLogoSaga = function* ({ payload }: Action<IFileUploadActionPayload>) {
  const { data }: IResponseOfRequest<ICompanyLogoResponse> = yield call(postCompanyLogo, payload.file);
  yield put(setCompanyLogo(data));
  yield toast.success('The Company Logo has been updated successfully.');
};

const deleteCompanyLogoSaga = function* () {
  yield call(deleteCompanyLogo);
  yield put(setCompanyLogo({ logo_path: null }));
  yield toast.success('The Company Logo has been deleted successfully.');
};

const deleteCustomDomainSaga = function* () {
  yield call(deleteCustomDomain);
  const { data }: IResponseOfRequest<ICustomDomainDeletionResponse> = yield call(deleteCustomDomain);
  yield put(setCustomDomainInStore(null));
  yield put(setCustomDomainCreationDateInStore(null));
  if (data.main_domain) {
    window.location.replace(`${data.main_domain}${window.location.pathname}`);
  }
};

export default [
  takeLatest(GET_COMPANY_BRANDING, safe(getCompanyBrandingSaga)),
  takeLatest(UPDATE_COMPANY_STYLE_SCHEMA, safe(updateCompanyStyleSchemaSaga)),
  takeLatest(UPLOAD_COMPANY_LOGO, safe(uploadCompanyLogoSaga)),
  takeLatest(DELETE_COMPANY_LOGO, safe(deleteCompanyLogoSaga)),
  takeLatest(DELETE_CUSTOM_DOMAIN, safe(deleteCustomDomainSaga)),
];
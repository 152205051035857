import { Dispatch, FC, SetStateAction } from 'react';

import { Modal, ModalBody, ModalHeader, Nav, NavItem } from 'reactstrap';

import moment from 'moment';

import DocumentInfoTab from 'components/Modals/DocumentInfoModalWindow/DocumentInfoTab';
import DocumentVersionsTab from 'components/Modals/DocumentInfoModalWindow/DocumentVersionsTab';
import { COLLECTION_EXECUTED_TYPE } from 'constants/general';
import { SignerFlow } from 'constants/signNow';
import useDocumentVersionsDownloader, { DOCUMENT_INFO_TAB_ID } from 'hooks/useDocumentInfoAndVersionsModal';
import { AssignmentsObjectType } from 'types/Editor';
import { PublicPageDataType } from 'types/PublicPage';
import { SignersInfoType } from 'types/SendDocuments';
import { ITab } from 'types/Tabs';
import { getSignersFromAssignments } from 'utils/sendDocumentsHelpers';

import 'scss/components/TabNavigation/TabNavigation.scss';

export type DocumentInfoRowType = {
  title: string;
  value: string | string[];
  isHidden?: boolean;
}

export type DocumentVersionRowType = {
  key: string;
  last_modified: string;
}

interface IDocumentInfoModal {
  showDocInfoModal: boolean;
  setShowDocInfoModal: Dispatch<SetStateAction<boolean>>;
}

const getSignersInfoFromAssignments = (
  currentDocument: PublicPageDataType | null,
  docType: string,
): string[] => {
  if (!currentDocument) return [];
  if (docType === COLLECTION_EXECUTED_TYPE) {
    return [
      `Signer 1 Name: ${currentDocument.creator?.first_name} ${currentDocument.creator?.last_name}`,
      `Signer 1 Email: ${currentDocument.creator?.email}`,
    ];
  }

  const assignments: AssignmentsObjectType | undefined = currentDocument.assignments;
  if (!assignments) return [];

  const orderedAssignments: SignersInfoType[] = getSignersFromAssignments(assignments);
  const assigmentInfo: string[] = [];

  orderedAssignments.forEach(({ firstName, lastName, email, position }, index) => {
    const signerPosition = (position ?? index) + 1;
    const fullName = `${firstName} ${lastName}`;
    assigmentInfo.push(`Signer ${signerPosition} Name: ${fullName.trim().length ? fullName : 'None'}`);
    assigmentInfo.push(`Signer ${signerPosition} Email: ${email.trim().length ? email : 'None'}`);
  });

  return assigmentInfo;
};

const DocumentInfoModal: FC<IDocumentInfoModal> = ({
  showDocInfoModal,
  setShowDocInfoModal,
}) => {
  const {
    currentDocument,
    doctype,
    modalTabs,
    docVersions,
    activeTab,
    setActiveTab,
  } = useDocumentVersionsDownloader();

  const signers: string[] = getSignersInfoFromAssignments(currentDocument, doctype);

  const documentDetailsInfo: DocumentInfoRowType[] = currentDocument ? [
    {
      title: 'Creation Date:',
      value: moment(currentDocument.creation_date).format('LL'),
    },
    {
      title: 'Modification Date:',
      value: moment(currentDocument.modification_date).format('LL'),
    },
    {
      title: 'Creator Name:',
      value: `${currentDocument.creator?.first_name} ${currentDocument.creator?.last_name}`,
    },
    {
      title: 'Last Modified By:',
      value: `${currentDocument.last_modified_by?.first_name} ${currentDocument.last_modified_by?.last_name}`,
    },
    {
      title: 'Category:',
      value: currentDocument.doctype?.name || '',
    },
    {
      title: 'Type:',
      value: `${doctype.charAt(0).toUpperCase()}${doctype.slice(1)}`,
    },
    {
      title: 'Signers:',
      value: signers,
      isHidden: !signers.length,
    },
    {
      title: 'Signing Options:',
      value: currentDocument.signing_order_flow ?? SignerFlow.NoOrder,
      isHidden: !signers.length,
    },
  ] : [];

  return (
    <Modal
      centered
      isOpen={showDocInfoModal}
      contentClassName="mx-auto"
    >
      <ModalHeader className="modal-header border-0" toggle={() => setShowDocInfoModal(false)}>
        <Nav className="tab-navigation border-0">
          {
            modalTabs.map((tab: ITab) => (
              <NavItem
                key={tab.id}
                className={`tab-navigation__tab ${activeTab === tab.id ? 'tab-navigation__tab--active' : ''}`}
                onClick={() => setActiveTab(tab.id)}
              >
                <span className="ps-1">{tab.label}</span>
              </NavItem>
            ))
          }
        </Nav>
      </ModalHeader>
      <ModalBody className="px-0">
        {
          activeTab === DOCUMENT_INFO_TAB_ID
            ? (<DocumentInfoTab documentDetailsInfo={documentDetailsInfo} />)
            : (<DocumentVersionsTab docVersions={docVersions?.versions ?? []} />)
        }
      </ModalBody>
    </Modal>
  );
};

export default DocumentInfoModal;
import mainLogo from 'svg/main-logo-pink-white.png';

const MainCompanyLogo = () => (
  <div className="wrapper-company-logo">
    <img
      className="main-company-logo"
      src={mainLogo}
      alt="Company Logo"
    />
  </div>
);

export default MainCompanyLogo;
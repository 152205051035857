import { Dispatch as ReactDispatch, useEffect, useState } from 'react';

import { CURRENT_PAGE_BY_DEFAULT, ROWS_PER_PAGE_BY_DEFAULT } from 'constants/muiTable';
import { MUIDataTableColumn } from 'mui-datatables';

import { QueryParameters } from 'constants/queryParametersManager';
import { DocumentStatusType } from 'types/Documents';
import {
  getCurrentActiveStatusesByQueryParam,
  getCurrentTabByStatuses,
  replaceQueryParam,
} from 'utils/QueryParameters/documentsTabsQueryParameters ';

type UseQueryParametersManagerType = ({
  setActiveStatuses,
  activeStatuses,
}: {
  activeStatuses?: DocumentStatusType,
  setActiveStatuses?: ReactDispatch<React.SetStateAction<string[]>>,
}) => {
  urlQueryParams: URLSearchParams,
  onChangeTableFilterHandler: (changedColumn: string | MUIDataTableColumn | null, value: string) => void,
  rowsPerPage: number,
  setRowsPerPage: ReactDispatch<React.SetStateAction<number>>,
  currentPage: number,
  setCurrentPage: ReactDispatch<React.SetStateAction<number>>,
  existingPageFromQueryParam: number,
};

const useQueryParametersManager: UseQueryParametersManagerType = ({
  activeStatuses,
  setActiveStatuses,
}) => {
  const [localCurrentTab, setLocalCurrentTab] = useState<string | null>(null);

  const urlParams = new URLSearchParams(window.location.search);
  const previousRowsPerPageQueryParam = urlParams.get(QueryParameters.ROWS_PER_PAGE);
  const previousCurrentPageQueryParam = urlParams.get(QueryParameters.CURRENT_PAGE);

  const [rowsPerPage, setRowsPerPage] = useState<number>(
    previousRowsPerPageQueryParam ? Number(previousRowsPerPageQueryParam) : ROWS_PER_PAGE_BY_DEFAULT,
  );
  const [currentPage, setCurrentPage] = useState<number>(
    previousCurrentPageQueryParam ? Number(previousCurrentPageQueryParam) : CURRENT_PAGE_BY_DEFAULT,
  );
  const previousTabQueryParam = urlParams.get(QueryParameters.TAB);
  const currentTab = getCurrentTabByStatuses(activeStatuses).toLowerCase().replaceAll(' ', '-');

  useEffect(() => {
    if (!previousRowsPerPageQueryParam) {
      urlParams.append(QueryParameters.ROWS_PER_PAGE, String(rowsPerPage));
    } else if (previousRowsPerPageQueryParam !== String(rowsPerPage)) {
      urlParams.set(QueryParameters.ROWS_PER_PAGE, String(rowsPerPage));
      setRowsPerPage(rowsPerPage);
    }
    replaceQueryParam(urlParams);
    if (!previousCurrentPageQueryParam) {
      urlParams.append(QueryParameters.CURRENT_PAGE, String(currentPage));
    } else if (previousCurrentPageQueryParam !== String(currentPage)) {
      urlParams.set(QueryParameters.CURRENT_PAGE, String(currentPage));
      setCurrentPage(currentPage);
    }
    replaceQueryParam(urlParams);
  }, [rowsPerPage, currentPage]);

  useEffect(() => {
    if (setActiveStatuses && previousTabQueryParam && !activeStatuses?.length && !localCurrentTab) {
      setActiveStatuses(getCurrentActiveStatusesByQueryParam(previousTabQueryParam));
    } else if (!previousTabQueryParam) {
      urlParams.append(QueryParameters.TAB, currentTab);
      replaceQueryParam(urlParams);
    } else if (previousTabQueryParam !== currentTab) {
      urlParams.set(QueryParameters.TAB, currentTab);
      replaceQueryParam(urlParams);
    }
    setLocalCurrentTab(currentTab);
  }, [activeStatuses]);

  const onChangeTableFilterHandler = (changedColumn: string | MUIDataTableColumn | null, value: string) => {
    if (value) {
      urlParams.set(changedColumn as string, value);
    } else {
      urlParams.delete(changedColumn as string);
    }
    replaceQueryParam(urlParams);
  };

  return {
    urlQueryParams: urlParams,
    onChangeTableFilterHandler,
    rowsPerPage,
    setRowsPerPage,
    currentPage,
    setCurrentPage,
    existingPageFromQueryParam: Number(previousCurrentPageQueryParam),
  };
};

export default useQueryParametersManager;
import {
  COOKIES_EXPIRATION_TIME_IN_MONTHS,
  LOCAL_USER_IP_LABEL,
  UNICORNS_ACCEPTANCE_TERMS_OF_USE,
} from 'constants/general';
import { ILoginResponse } from 'types/Auth';
import { setUserLocationToLocalStorage } from 'utils/amplitude/amplituteServices';
import { ACCESS_TOKEN, REFRESH_TOKEN } from 'utils/axiosInstance';

const localStorageItem = 'UNICORN_FORMS';

export const setAuthInStorage = async (loginObj: ILoginResponse) => {
  localStorage.setItem(ACCESS_TOKEN, loginObj.access_token);
  localStorage.setItem(REFRESH_TOKEN, loginObj.refresh_token);
  localStorage.setItem(localStorageItem, 'true');
  await setUserLocationToLocalStorage();
};

export const getAuthInStorage = () => {
  const item = localStorage.getItem(localStorageItem);
  return item && JSON.parse(item);
};

export const deleteAuthInStorage = () => {
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);
  localStorage.removeItem(localStorageItem);
  localStorage.removeItem(LOCAL_USER_IP_LABEL);
};

export const createAccepteranceTermsCookies = (currentDate: Date) => (
  `${UNICORNS_ACCEPTANCE_TERMS_OF_USE}=True; path=/; expires=${
    new Date(currentDate.setMonth(currentDate.getMonth() + COOKIES_EXPIRATION_TIME_IN_MONTHS))
  }`
);

export const setTokensToLocalStorage = (responseData: ILoginResponse) => {
  localStorage.setItem(ACCESS_TOKEN, responseData.access_token);
  localStorage.setItem(REFRESH_TOKEN, responseData.refresh_token);
};

export const getUniqueTabId = () => {
  const idFromStorage = sessionStorage.getItem('UNIQUE_TAB_ID_KEY');
  if (idFromStorage) {
    return Number(idFromStorage);
  }
  const tabId = Math.floor(Math.random() * 1000000000); // generate unique UUID
  sessionStorage.setItem('UNIQUE_TAB_ID_KEY', String(tabId));
  return Number(tabId);
};
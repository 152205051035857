import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Descendant, Element as SlateElement } from 'slate';

import { PUBLIC_PAGE_STATUS } from 'constants/publicPage';
import { ShareViewModes } from 'constants/shareViewModes';
import { changeFieldInFocusStatus, setPublicFormValidation } from 'store/actions/editorSlate';
import { setCurrentDocumentActiveTab, setPublicPageCurrentSlideIndex } from 'store/actions/publicPages';
import { RootStateType } from 'store/reducers';
import { PDFFieldType } from 'types/PdfTemplates';
import { IPublicPageDocumentStructure, IPublicPageState, PublicPageDataType } from 'types/PublicPage';
import { ITemplateSection } from 'types/redux';
import { IErrorField, IValidated } from 'types/validation';
import { getIsAttachmentField } from 'utils/Fields/fieldsTypeChecker';
import { getIsCollectionFormBuilder, getIsPDFDocument } from 'utils/PublicPage/documentTypeChecker';
import formSwiperAutoScroll from 'utils/PublicPage/swiperAutoScroll';
import { getCurrentFields, validatePublicForm } from 'utils/validation';

const COORDINATE_SPLITE_SYMBOL = ':';

const useSliderValidation = (
  isFormsView: boolean,
  content: Descendant[],
) => {
  const dispatch = useDispatch();
  const { pdfTemplateFields } = useSelector((state: RootStateType) => state.pdfTemplates);
  const data: PublicPageDataType = useSelector((state: RootStateType) => state.publicPages.data);
  const { main } = useSelector((state: RootStateType) => state.publicPages.structure);
  const {
    attachmentFields,
    currentSlideIndex,
    documentType,
    slides,
    totalIndexSlides,
    activeEditorTab,
    viewMode,
  }: IPublicPageDocumentStructure = useSelector((state: RootStateType) => state.publicPages.currentDocument);
  const {
    attachments,
  }: IPublicPageState = useSelector((state: RootStateType) => state.publicPages);

  const updateValidationDateOnStore = (validationResults: IValidated) => {
    dispatch(setPublicFormValidation(validationResults));
  };

  const scrollToDOMElementByFieldKey = (key: string): void => {
    const field = document.querySelector(`[data-field-key="${key}"][data-active-tab="${activeEditorTab}"]`);
    if (field) {
      field.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    }
  };

  const currentSlideValidation = (index: number): IValidated => {
    const currentSection: ITemplateSection = slides[index - 1];

    const currentFields: PDFFieldType[] | Partial<SlateElement>[] = getCurrentFields({
      currentSection,
      content,
      pdfTemplateFields,
      currentDocAssignment: main.mainAssignment,
      isPDFDocument: getIsPDFDocument(documentType),
      isFormBuilder: getIsCollectionFormBuilder(data) || [
        ShareViewModes.SHARE_MODE_DOCUMENT_AND_FORM_BUILDER,
        ShareViewModes.SHARE_MODE_FORM_BUILDER,
      ].includes(viewMode as ShareViewModes ?? ''),
      isDocumentAndFormBuilder: viewMode === ShareViewModes.SHARE_MODE_DOCUMENT_AND_FORM_BUILDER,
    });

    const updatedFields: (PDFFieldType | Partial<SlateElement>)[] = currentFields.map((field) => {
      if (getIsAttachmentField(field.type)) {
        const properties = field.properties && {
          ...field.properties,
          count: field.properties
            ? (field?.properties?.count || 0) + (attachments[field.key || 0]?.length || 0)
            : 0,
        };

        const newField: PDFFieldType | Partial<SlateElement> = {
          ...field,
          properties,
        };
        return newField;
      }
      return field;
    });
    return validatePublicForm(updatedFields);
  };

  const allDocumentValidation = (): IValidated => {
    const totalResult: IValidated = {
      validationFields: {},
      fieldsPositions: {},
      isError: false,
    };
    for (let i = 1; i <= totalIndexSlides; i++) {
      const validationResult: IValidated = currentSlideValidation(i);
      totalResult.validationFields = {
        ...totalResult.validationFields,
        ...validationResult.validationFields,
      };
      if (validationResult.fieldsPositions) {
        const correctPositions = Object.fromEntries(
          Object
            .entries(validationResult.fieldsPositions)
            .map(([key, position]) => [key, `${i}${COORDINATE_SPLITE_SYMBOL}${position}`]),
        );

        totalResult.fieldsPositions = {
          ...totalResult.fieldsPositions,
          ...correctPositions,
        };
      }
      if (validationResult.isError) {
        totalResult.isError = validationResult.isError;
      }
    }
    return totalResult;
  };

  const validateAllDocumentAndUpdateStore = (): IValidated => {
    const validationDocumentResult: IValidated = allDocumentValidation();
    updateValidationDateOnStore(validationDocumentResult);
    return validationDocumentResult;
  };

  const getFirstFieldKeyWithError = (
    positions: Record<string, string> | undefined,
    fields: IErrorField,
  ): string | null => {
    if (positions) {
      const fieldsWithErrors = Object
        .entries(fields)
        .filter(([, value]) => value)
        .map(([key]) => key);
      const fieldKeysSortedByPosition = Object
        .entries(positions)
        .sort((a, b) => Number(a[1]) - Number(b[1]))
        .filter(([key]) => fieldsWithErrors.includes(key));
      if (fieldKeysSortedByPosition.length) {
        toast.warning(`${fieldsWithErrors.length} required fields remaining`);
        return fieldKeysSortedByPosition[0][0] ?? null;
      }
    }
    return null;
  };

  const changeCurrentSlideIndex = (): void => {
    const validationSlideResult = currentSlideValidation(currentSlideIndex);
    updateValidationDateOnStore(validationSlideResult);

    const key = getFirstFieldKeyWithError(
      validationSlideResult.fieldsPositions,
      validationSlideResult.validationFields,
    );
    if (key) {
      scrollToDOMElementByFieldKey(key);
    }

    if (validationSlideResult.isError) return;

    dispatch(setPublicPageCurrentSlideIndex(currentSlideIndex + 1));
    formSwiperAutoScroll();
  };

  const validateAndSaveDocument = (
    callback: (status: PUBLIC_PAGE_STATUS) => void,
  ): void => {
    const validationResult = validateAllDocumentAndUpdateStore();

    const key = getFirstFieldKeyWithError(
      validationResult.fieldsPositions,
      validationResult.validationFields,
    );
    if (key) {
      scrollToDOMElementByFieldKey(key);
    }

    if (validationResult.isError) return;

    dispatch(changeFieldInFocusStatus(false));
    callback(PUBLIC_PAGE_STATUS.DONE);
  };

  const validateFieldsWithoutAttachmentsAndChangeTabIndex = (tabIndex: number): void => {
    const validationResult = validateAllDocumentAndUpdateStore();
    const attachmentFieldsKeys = new Set(attachmentFields.map((field: Partial<SlateElement>) => Number(field.key)));

    const fieldsWithErrorsWithoutAttachments = Object
      .entries(validationResult.validationFields)
      .filter(([key, value]) => !attachmentFieldsKeys.has(Number(key)) && value)
      .map(([key]) => key);

    if (fieldsWithErrorsWithoutAttachments.length && validationResult.fieldsPositions) {
      const cachedPositions = Object
        .entries(validationResult.fieldsPositions)
        .map(([key, value]) => {
          const [section, position] = value.split(COORDINATE_SPLITE_SYMBOL).map(Number);
          return { key, section, position };
        });

      const positions = cachedPositions
        .sort((a, b) => a.section - b.section || a.position - b.position)
        .filter(({ key }) => fieldsWithErrorsWithoutAttachments.includes(key));

      toast.warning(`${fieldsWithErrorsWithoutAttachments.length} required fields remaining`);

      const firstFieldKey = positions[0]?.key;
      scrollToDOMElementByFieldKey(firstFieldKey);
      return;
    }

    dispatch(setCurrentDocumentActiveTab(tabIndex));
  };

  return {
    changeCurrentSlideIndex,
    validateAndSaveDocument,
    validateFieldsWithoutAttachmentsAndChangeTabIndex,
  };
};

export default useSliderValidation;
import { useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useStepHandler } from 'components/SignNow/StepManager/StepHandlerContext';
import { COLLECTION_EXECUTED_TYPE, PDF_TYPE } from 'constants/general';
import ROUTES from 'constants/routes';
import { ShareViewModes } from 'constants/shareViewModes';
import { setPdfPartOfMultiTemplate } from 'store/actions/collections';
import { getCollectionExecutedDetails, updateCollectionExecutedDetails } from 'store/actions/collectionsExecuted';
import {
  setAllPdfFieldsAndInfo,
  setPdfTemplateFileLink,
  setPdfTemplateKey,
  setPdfTemplateWidth,
} from 'store/actions/pdfTemplates';
import { setLinkViewMode, setOnlyCollection, setSendDocumentsInfo, setSigners } from 'store/actions/sendDocuments';
import { updateSignNowDocument } from 'store/actions/signNow';
import { editDocumentDetails, getDocumentDetails } from 'store/actions/userData';
import { RootStateType } from 'store/reducers';
import { IDocumentDetails } from 'types/Documents';
import { IUseSetFields, UseSetFieldsHookType } from 'types/signNow/base';
import { trimHTMLValue } from 'utils/descriptionHelper';
import { isNotEmptyObject } from 'utils/isEmptyObject';
import { createFieldsFromPdfFormfields, getPdfUrlAndWidth } from 'utils/pdfTemplatesHelpers';
import { getSignersFromAssignments } from 'utils/sendDocumentsHelpers';

const useSetFields: UseSetFieldsHookType = ({
  onNextHandler = () => null,
}): IUseSetFields => {
  const dispatch = useDispatch();
  const { setOnNext, isNextButtonDisabled, setIsNextButtonDisabled } = useStepHandler();
  const {
    documents,
    files,
    pdfFormFields,
    currentSigner,
    isCollection,
    collectionId,
  } = useSelector((state: RootStateType) => state.signNow);
  const document = documents[0];
  const { isSelectFieldError } = useSelector((state: RootStateType) => state.editorSlate);
  const { documentDetails, collectionExecutedDetails } = useSelector((state: RootStateType) => state.user);
  const {
    pdfTemplateFields,
    pdfTemplateKey,
    pdfTemplateInfo: { templateDescription },
  } = useSelector((state: RootStateType) => state.pdfTemplates);
  const { assignments } = useSelector((state: RootStateType) => state.editorSlate);

  const pdfPartsOfMultiTemplate = useSelector(
    (state: RootStateType) => state.multiTemplates.pdfPartsOfMultiTemplate ?? {},
  );

  useEffect((): void => {
    if (isNotEmptyObject(isSelectFieldError)) {
      const isError: boolean = Object.values(isSelectFieldError).some((item: boolean) => item);
      setIsNextButtonDisabled(isError);
    }
  }, [isSelectFieldError, setIsNextButtonDisabled]);

  useEffect(() => {
    if (isCollection && collectionId) {
      dispatch(getCollectionExecutedDetails(Number(collectionId)));
    }
  }, [dispatch, isCollection, collectionId]);

  useEffect((): void => {
    if (!isCollection) {
      if (document?.id) {
        dispatch(getDocumentDetails(document.id));
      }
    }
  }, [dispatch, document?.id, isCollection]);

  useEffect((): void => {
    if (!documentDetails) return undefined;
    const file = files ? files[0] : null;
    if (file) {
      dispatch(setPdfTemplateKey(file.fileKey));
      dispatch(setPdfTemplateWidth(file.pdfWidth));
      dispatch(setPdfTemplateFileLink(file.fileUrl));
    } else {
      const [pdfFileUrl, pdfFileWidth] = getPdfUrlAndWidth(documentDetails.template?.pdf_file_url);
      dispatch(setPdfTemplateKey(pdfFileUrl));
      dispatch(setPdfTemplateWidth(parseInt(pdfFileWidth)));
      dispatch(setPdfTemplateFileLink(documentDetails.pdf_full_link));
    }
    dispatch(setAllPdfFieldsAndInfo({
      fields: documentDetails.content_json,
      info: {
        templateName: documentDetails.name,
        templateTitle: documentDetails.document_title,
        templateDescription: documentDetails.description,
      },
    }));
    if (pdfFormFields && pdfFormFields.length) {
      createFieldsFromPdfFormfields(pdfFormFields, dispatch, currentSigner);
    }
    dispatch(updateSignNowDocument({
      id: documentDetails.id,
      title: documentDetails.name,
      fileKey: getPdfUrlAndWidth(documentDetails.template?.pdf_file_url)[0],
    }));
  }, [dispatch, documentDetails, files]);

  useEffect(() => {
    if (!collectionExecutedDetails) return;
    collectionExecutedDetails.documents.forEach((documentData: any, index: number) => {
      const document = documentData.document;
      dispatch(setPdfPartOfMultiTemplate(document.template, document.content_json));
      if (index === 0) {
        const [pdfFileUrl, pdfFileWidth] = getPdfUrlAndWidth(document.template.pdf_file_url);
        dispatch(setPdfTemplateKey(pdfFileUrl));
        dispatch(setPdfTemplateWidth(parseInt(pdfFileWidth)));
        dispatch(setPdfTemplateFileLink(document.template.pdf_full_link));
        dispatch(setAllPdfFieldsAndInfo({
          fields: document.content_json,
          info: {
            templateName: document.name,
            templateTitle: document.document_title,
            templateDescription: document.description,
          },
        }));
      }
    });
  }, [dispatch, collectionExecutedDetails]);

  const onNextHandlerWrapper = useCallback((): void => {
    if (isNextButtonDisabled) return undefined;

    const signers = getSignersFromAssignments(assignments);
    dispatch(setSigners(signers));
    if (documents.length === 1) {
      if (!document?.title || document?.isError) {
        dispatch(updateSignNowDocument({
          isError: true,
          fileKey: getPdfUrlAndWidth(documentDetails.template?.pdf_file_url)[0],
        }));
        return undefined;
      }

      const documentTitle = document?.title.trim();
      const editedPdfDocument: Partial<IDocumentDetails> = {
        name: documentTitle,
        document_title: documentTitle,
        description: trimHTMLValue(templateDescription),
        doctype_id: documentDetails.doctype.id,
        state_id: documentDetails.state.id,
        content_json: pdfTemplateFields,
        type: PDF_TYPE,
        assignments,
      };
      dispatch(setLinkViewMode(ShareViewModes.SHARE_MODE_DOCUMENT));
      dispatch(setSendDocumentsInfo(
        [{
          id: Number(document?.id),
          type: PDF_TYPE,
          position: 0,
          name: documentTitle,
        }],
        ROUTES.DASHBOARD,
        PDF_TYPE,
      ));

      dispatch(editDocumentDetails({
        id: document?.id,
        document: editedPdfDocument,
        completeFlow: false,
      }));
    } else if (documents.length > 1) {
      documents.forEach((document) => {
        if (!document.title || document.isError) {
          dispatch(updateSignNowDocument({
            isError: true,
            fileKey: document.fileKey,
          }));
          return undefined;
        }
      });

      dispatch(setSendDocumentsInfo(
        documents.map((document, index) => ({
          id: Number(document.id),
          type: PDF_TYPE,
          position: index,
          name: document.title?.trim(),
        })),
        ROUTES.DASHBOARD,
        PDF_TYPE,
        false,
        true,
      ));
      dispatch(setOnlyCollection({
        id: collectionExecutedDetails.id,
        type: COLLECTION_EXECUTED_TYPE,
      }));

      const updatedCollectionExecuted = {
        ...collectionExecutedDetails,
        documents: collectionExecutedDetails.documents.map((documentObject: any) => {
          const currentPdfPartOfCollection = pdfPartsOfMultiTemplate[documentObject.document.template_id];
          let pdfFields = currentPdfPartOfCollection.pdfTemplateFields;
          if (currentPdfPartOfCollection.pdfTemplateKey === pdfTemplateKey) {
            pdfFields = pdfTemplateFields;
          }
          const currentDoc = documents.find((doc) => currentPdfPartOfCollection.pdfTemplateKey === doc.fileKey);
          return {
            ...documentObject,
            document: {
              ...documentObject.document,
              content_json: pdfFields,
              name: currentDoc?.title,
            },
          };
        }),
      };
      dispatch(updateCollectionExecutedDetails(updatedCollectionExecuted));
    }

    onNextHandler();
  }, [
    pdfPartsOfMultiTemplate,
    pdfTemplateKey,
    documents,
    collectionExecutedDetails,
    assignments,
    dispatch,
    documentDetails,
    document?.id,
    isNextButtonDisabled,
    onNextHandler,
    pdfTemplateFields,
    templateDescription,
    document?.title,
    document?.isError,
  ]);

  useEffect(() => {
    setOnNext(() => onNextHandlerWrapper);
  }, [setOnNext, onNextHandlerWrapper]);

  return {
    isNextButtonDisabled,
    onNextHandlerWrapper,
  };
};

export default useSetFields;
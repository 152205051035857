import ENDPOINT from 'constants/endpoints';
import { IResponseOfRequest } from 'types/Api';
import { axiosInstance } from 'utils/axiosInstance';

export const sendUniqueSMSCodeToUser = (
  data: {
    user_email?: string,
    user_phone_number?: string,
    is_monday_com_subitem?: boolean,
    monday_com_board?: string,
    resource_id?: number;
    is_collection?: boolean;
    send_sms_unique_code?: boolean;
  },
): Promise<IResponseOfRequest<any>> => (
  axiosInstance.post(ENDPOINT.SMS_VALIDATION_SMS_UNIQUE_CODE, data)
);

export const sendCodeForVerification = (
  data: {
    user_email?: string,
    company_name?: string,
    user_phone_number?: string,
    unique_code: string,
    start_link: string,
    document_uuid: string,
    send_sms_unique_code?: boolean;
  },
): Promise<IResponseOfRequest<any>> => (
  axiosInstance.post(ENDPOINT.SMS_VALIDATION_CODE_VERIFICATION, data)
);
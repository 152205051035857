import { SignerFlow } from 'constants/signNow';
import {
  CLEAR_FULL_SIGN_NOW_STORE,
  CLEAR_SIGN_NOW_STORE,
  CREATE_COLLECTION_IN_SIGN_NOW_FLOW,
  REMOVE_SIGN_NOW_FILE,
  SET_COLLECTION_ID_IN_SIGN_NOW,
  SET_CURRENT_SIGNER,
  SET_CURRENT_SIGNER_FLOW_TO_STORE,
  SET_IS_COLLECTION_IN_SIGN_NOW,
  SET_IS_REMIND_DOCUMENT_ACTIVE,
  SET_IS_REMIND_FOR_ALL_SIGNERS,
  SET_IS_SIGN_NOW_ACTIVE,
  SET_PDF_TEMPLATE_ID_IN_SIGN_NOW,
  SET_SIGN_NOW_DOCUMENT_INFO,
  SET_SIGN_NOW_FILE_URL,
  SET_SIGN_NOW_PDF_FORM_FIELDS,
  SET_SIGNERS_LIST,
  UPDATE_SIGN_NOW_DOCUMENT,
} from 'store/actions/actionTypes';
import { AssignmentsObjectType } from 'types/Editor';
import { PDFFieldType } from 'types/PdfTemplates';
import { ActionPayload } from 'types/redux';
import {
  ICreateCollectionInSignNowFlow,
  IPDFFileInfo,
  ISignerListActionProps,
  ISignNowDocument,
} from 'types/signNow/store';

export const setFileUrl = ({
  fileUrl,
  fileKey,
  pdfWidth,
}: IPDFFileInfo): ActionPayload<IPDFFileInfo> => ({
  type: SET_SIGN_NOW_FILE_URL,
  payload: {
    fileUrl,
    fileKey,
    pdfWidth,
  },
});

export const setSignNowPdfFormFields = (pdfFormFields: PDFFieldType[]): ActionPayload<PDFFieldType[]> => ({
  type: SET_SIGN_NOW_PDF_FORM_FIELDS,
  payload: pdfFormFields,
});

export const removeSignNowFile = (fileKey: string) => ({
  type: REMOVE_SIGN_NOW_FILE,
  payload: fileKey,
});

export const setSignersList = ({ record, signers }: ISignerListActionProps): ActionPayload<ISignerListActionProps> => ({
  type: SET_SIGNERS_LIST,
  payload: {
    record,
    signers,
  },
});

export const setCurrentSignerFlowToStore = (currentSignerFlow: SignerFlow): ActionPayload<SignerFlow> => ({
  type: SET_CURRENT_SIGNER_FLOW_TO_STORE,
  payload: currentSignerFlow,
});

export const setCurrentSigner = (
  currentSigner: string,
): ActionPayload<string> => ({
  type: SET_CURRENT_SIGNER,
  payload: currentSigner,
});

export const setIsSignNowActive = () => ({
  type: SET_IS_SIGN_NOW_ACTIVE,
});

export const clearSignNowStore = (isClearFullStore: boolean = true): ActionPayload<boolean> => ({
  type: CLEAR_SIGN_NOW_STORE,
  payload: isClearFullStore,
});

export const clearFullSignNowStore = () => ({
  type: CLEAR_FULL_SIGN_NOW_STORE,
});

export const setIsRemindDocumentActive = () => ({
  type: SET_IS_REMIND_DOCUMENT_ACTIVE,
});

export const setIsRemindForAllSigners = (payload: boolean): ActionPayload<boolean> => ({
  type: SET_IS_REMIND_FOR_ALL_SIGNERS,
  payload,
});

export const setSignNowDocumentInfo = (
  documentInfo: Partial<ISignNowDocument>,
): ActionPayload<Partial<ISignNowDocument>> => ({
  type: SET_SIGN_NOW_DOCUMENT_INFO,
  payload: documentInfo,
});

export const updateSignNowDocument = (
  documentInfo: Partial<ISignNowDocument>,
): ActionPayload<Partial<ISignNowDocument>> => ({
  type: UPDATE_SIGN_NOW_DOCUMENT,
  payload: documentInfo,
});

export const createCollectionInSignNowFlow = (
  doctypeId: number,
  assignments: AssignmentsObjectType,
): ActionPayload<ICreateCollectionInSignNowFlow> => ({
  type: CREATE_COLLECTION_IN_SIGN_NOW_FLOW,
  payload: {
    doctypeId,
    assignments,
  },
});

export const setIsCollectionInSignNow = (payload: boolean): ActionPayload<boolean> => ({
  type: SET_IS_COLLECTION_IN_SIGN_NOW,
  payload,
});

export const setCollectionIdInSignNow = (collectionId: number | null): ActionPayload<number | null> => ({
  type: SET_COLLECTION_ID_IN_SIGN_NOW,
  payload: collectionId,
});

export const setPdfTemplateIdInSignNow = (
  fileKey: string,
  pdfTemplateId: number,
): ActionPayload<{ fileKey: string; pdfTemplateId: number; }> => ({
  type: SET_PDF_TEMPLATE_ID_IN_SIGN_NOW,
  payload: {
    fileKey,
    pdfTemplateId,
  },
});
import { FC, memo, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import { Transforms } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';

import cn from 'classnames';
import NavigationArrows from 'components/Editor/components/DocumentNavigationArrows/NavigationArrows';
import SlateInputField from 'components/Editor/components/Fields/SlateInputField';
import FieldWrapper from 'components/Editor/components/FieldWrapper';
import { IRenderElementPropsWithValidation } from 'components/Editor/components/RenderElement';
import SlateFieldFiller from 'components/Editor/components/SlateFieldFiller';
import FieldToolbar from 'components/Editor/components/Toolbars/FieldToolbar';
import { MAX_FIELD_VALUE_LENGTH } from 'constants/editors';
import { TEXT_FIELD_MASKS } from 'constants/fieldPropertiesTab';
import { DOCUMENT_TAB_ID } from 'constants/tabs';
import useFieldsHandler from 'hooks/useFieldsHandler';
import { useFormFields } from 'hooks/useFormFields';
import useSlateFieldHandlers from 'hooks/useSlateFieldHandlers';
import { RootStateType } from 'store/reducers';
import { createFieldDataAttributes } from 'utils/editorCopyPasteHelpers';
import {
  getCurrentDate,
  getGroupedFieldKeys,
  getSelectedFieldClass,
  isFieldHasSubtype,
} from 'utils/editorFieldHelpers';
import { getFieldFontStyles } from 'utils/Fields/fieldFontSizeHelpers';
import { updateFieldNameAndProps } from 'utils/updateNode';
import { validateFieldHelper } from 'utils/validation';

const InputEditable: FC<IRenderElementPropsWithValidation> = ({
  attributes,
  children,
  element,
  readOnlyMode = false,
  isError = false,
  validationErrorText = null,
  filledInFields = undefined,
  isPublicPage = undefined,
  editorRef,
}) => {
  const editor = useSlateStatic();
  const dispatch = useDispatch();
  const { selectedField, selectedTableQuestionKey } = useSelector((state: RootStateType) => state.editorSlate);
  const {
    sensitive_info_access: sensitiveInfoAccess,
  } = useSelector((state: RootStateType) => state.profile.profileInfo ?? {});

  const [showToolbar, setShowToolbar] = useState<boolean>(false);
  const existingFieldMask = Boolean(element.textFieldMask) && element.textFieldMask !== 'none';
  const [hideValue, setHideValue] = useState<boolean>(existingFieldMask);

  const { updateFieldsHandler } = useFieldsHandler({ editor });

  const {
    clickFieldHandler,
    hoverFieldHandler,
    leaveFieldHandler,
  } = useSlateFieldHandlers({
    selectedField,
    selectedTableQuestionKey,
    element,
    editor,
    isReadOnly: readOnlyMode,
    callback: setShowToolbar,
  });

  const forms = useFormFields(true, false);

  // TODO: Check: Why do we update today date in input?
  useEffect(() => {
    if (editor.publicPage && element.isTodaysDate && !element.value) {
      const todayValue = getCurrentDate();
      if (element.value !== todayValue) {
        const path = ReactEditor.findPath(editor, element);
        Transforms.setNodes(editor, { value: todayValue, isTodaysDate: false }, { at: path });
      }
    }
  }, [element, editor]);

  useEffect(() => {
    updateFieldNameAndProps(editor, element);
  }, [element.children]);

  const onChangeTextField = (updatedValue: string) => {
    if (!readOnlyMode) {
      let newValue: string = updatedValue;

      validateFieldHelper({
        field: element,
        prevValue: element.value ?? '',
        value: newValue,
        dispatch,
      });

      if (element.maxLength && (newValue.length > Number(element.maxLength))) {
        const maxLength: number = Math.min(Number(element.maxLength), MAX_FIELD_VALUE_LENGTH);
        newValue = newValue.slice(0, maxLength);
      }

      updateFieldsHandler(element, { value: newValue });
    }
  };

  const hasSubtype = !editor.publicPage && isFieldHasSubtype(element.subtype);
  const idsToSelect = getGroupedFieldKeys(forms || [], selectedField ?? 0);

  return (
    <>
      <span
        className={cn(
          'custom-subtype-highlight inline-field-wrapper white-space-inline-field-wrapper',
          getSelectedFieldClass(idsToSelect, element.key, hasSubtype),
          {
            'hidden-field-in-pdf': element.textFieldMask === TEXT_FIELD_MASKS.HIDDEN,
            'blue-bg': showToolbar,
          },
        )}
        {...(createFieldDataAttributes({
          field: element,
          attributes,
          activeTab: DOCUMENT_TAB_ID,
        }))}
        onClick={clickFieldHandler}
        onFocus={hoverFieldHandler}
        onMouseOver={hoverFieldHandler}
        onBlur={leaveFieldHandler}
        onMouseLeave={leaveFieldHandler}
        role="textbox"
        tabIndex={-1}
        style={getFieldFontStyles(children, element.fontSize)}
      >
        <NavigationArrows field={element} isPublicPage={isPublicPage} filledInFields={filledInFields}>
          <>
            <SlateFieldFiller inline />
            <FieldWrapper
              assignment={element.assignment}
              fieldChildren={children}
              labelConfiguration={element.labelConfiguration}
              isReadOnly={readOnlyMode}
              element={element}
              isPublicPage={isPublicPage}
            >
              <SlateInputField
                name={element.name ?? ''}
                initValue={element.value ?? ''}
                onChangeInitValue={onChangeTextField}
                maxLength={element.maxLength ?? ''}
                isReadOnly={readOnlyMode}
                isError={isError}
                validationErrorText={validationErrorText}
                editorRef={editorRef}
                hideValue={hideValue}
                fieldMaskType={element.textFieldMask}
              />
              {showToolbar && <FieldToolbar fieldKey={element.key} />}
            </FieldWrapper>
          </>
        </NavigationArrows>
      </span>
      {
        existingFieldMask && sensitiveInfoAccess && (
          <IconButton
            onClick={() => setHideValue((prevState) => !prevState)}
            edge="end"
            className="p-0 show-hide-masked-value-icon"
          >
            {hideValue ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        )
      }
    </>
  );
};

export default memo(InputEditable);
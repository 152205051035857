import ENDPOINT from 'constants/endpoints';
import {
  URL_MANAGER_DOCUMENT,
  URL_MANAGER_TEMPLATE,
  URL_PUBLIC_COLLECTION,
  URL_PUBLIC_DOCUMENT,
  URL_PUBLIC_TEMPLATE,
} from 'constants/general';
import ROUTES from 'constants/routes';

interface IPublicPageRequestURL {
  type: string;
  url: string;
  authToken: string | null;
  targetBoard: string | null;
  assignment?: string;
  startLink?: string;
}

const getPublicPageRequestURL = ({
  type,
  url,
  authToken,
  targetBoard,
  assignment,
  startLink,
}: IPublicPageRequestURL): string => {
  switch (type) {
    case URL_PUBLIC_DOCUMENT:
    case URL_PUBLIC_COLLECTION:
    case ROUTES.PUBLIC_COLLECTION_EXECUTED:
    case URL_PUBLIC_TEMPLATE:
      return `${url}?type=public${
        targetBoard ? `&board=${window.atob(targetBoard)}` : ''
      }${
        authToken ? `&auth-token=${authToken}` : ''
      }${
        assignment ? `&assignment=${assignment}` : ''
      }${
        startLink ? `&start-link=${startLink}` : ''
      }`;
    case URL_MANAGER_TEMPLATE:
      return url.replace(`/${URL_MANAGER_TEMPLATE}`, ENDPOINT.DOCUMENT);
    case URL_MANAGER_DOCUMENT:
      return url.replace(`/${URL_MANAGER_DOCUMENT}`, ENDPOINT.DOCUMENT_EXECUTED);
    default:
      throw new Error('Wrong page URL.');
  }
};

export default getPublicPageRequestURL;
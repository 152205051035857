import { ChangeEventHandler, Dispatch, FC, SetStateAction, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';

import Button from 'components/Base/Button';
import ChangeStatusButton from 'components/EditorHeader/ChangeStatusButton';
import EditorHeaderDropdown from 'components/EditorHeader/EditorHeaderDropdown';
import HeaderBackButton from 'components/EditorHeader/HeaderBackButton';
import HeaderDocumentName from 'components/EditorHeader/HeaderDocumentName';
import DocumentDescriptionModal from 'components/Modals/DocumentDescription';
import { combinePdfOptionWarning } from 'constants/documentsNavigation';
import { STATUSES } from 'constants/documentStatuses';
import { RedirectTypeEnum, REMIND, SAVE, SEND } from 'constants/general';
import { COMBINE_ATTACHMENTS_IN_DOWNLOADED_PDF } from 'constants/pageSettings';
import ROUTES from 'constants/routes';
import { ShareViewModes } from 'constants/shareViewModes';
import { useIsFreeTier } from 'hooks/payment/stripe';
import useDisableSendButton from 'hooks/ShareLinkRecipientsNames/useDisableSendButton';
import { useRolePermission } from 'hooks/useRolePermission';
import { getLinkForPdfRenderer } from 'services/api';
import { apiErrorHandler } from 'services/apiErrorHandler';
import { setPdfFileLink } from 'store/actions/editorSlate';
import { setLinkViewMode, setSigners } from 'store/actions/sendDocuments';
import { setCurrentSignerFlowToStore, setIsRemindDocumentActive } from 'store/actions/signNow';
import { RootStateType } from 'store/reducers';
import DescriptionIcon from 'svg/DescriptionIcon';
import PreviewIcon from 'svg/PreviewIcon';
import { ITemplateForMultiTemplate } from 'types/MultiTemplate';
import { PageSettingsType } from 'types/PageSettingsType';
import { MatchParams } from 'types/Route';
import { doesDocumentIncludeDocAttachments } from 'utils/documentsHelper';
import { createCSSString } from 'utils/fileLinkDownload';
import { getMarginsForPageInInches, getPageSettings } from 'utils/PageSettings';
import { createDataForSending, getSignersFromAssignments } from 'utils/sendDocumentsHelpers';

interface IEditorHeader {
  showPreviewButton?: boolean;
  documentName?: string;
  errorText?: string | null;
  readOnly?: boolean;
  onChangeDocumentName?: ChangeEventHandler<HTMLInputElement>;
  description?: string;
  setDescription?: Dispatch<SetStateAction<string>> | ((description: string) => void);
  handlerShareLinkModal?: () => void;
  saveDocument: (redirectType?: RedirectTypeEnum, redirectPath?: string) => void;
  showShareLinkModal?: boolean | undefined;
  isShareButtonAvailable?: boolean;
  previewMode?: boolean;
  setPreviewMode?: Dispatch<SetStateAction<boolean>>;
  openManagerPageHandler?: () => void;
  documentsInCollection?: Partial<ITemplateForMultiTemplate>[];
  isPdfDocument?: boolean;
  statusObject?: {
    statuses: string[];
    selectedStatus: string;
    onSelectStatus: (status: string) => void;
  };
  showDocumentHistoryButton?: boolean;
  isFormBuilderAvailable?: boolean;
  isFormBuilderOpened?: boolean;
  onOpenFormBuilder?: (isFormBuilderOpened: boolean) => void;
  isCollectionPage?: boolean;
  isCollectionExecutedPage?: boolean;
}

const EditorHeader: FC<IEditorHeader> = ({
  documentName = '',
  onChangeDocumentName = () => null,
  errorText = null,
  readOnly = false,
  description = '',
  setDescription = () => null,
  handlerShareLinkModal = () => null,
  showPreviewButton = true,
  saveDocument,
  showShareLinkModal = undefined,
  isShareButtonAvailable = true,
  previewMode = false,
  setPreviewMode = () => null,
  openManagerPageHandler = undefined,
  documentsInCollection = [],
  isPdfDocument = false,
  statusObject = undefined,
  showDocumentHistoryButton = false,
  isFormBuilderAvailable = false,
  isFormBuilderOpened = false,
  onOpenFormBuilder = () => null,
  isCollectionPage = false,
  isCollectionExecutedPage = false,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { id }: MatchParams = useParams();
  const { permissionUsingShareLink, permissionUsingSignNow } = useRolePermission();
  const { isSelectFieldError } = useSelector((state: RootStateType) => state.editorSlate);
  const { pdfTemplateLink } = useSelector((state: RootStateType) => state.pdfTemplates);
  const { documentDetails } = useSelector((state: RootStateType) => state.user);
  const { assignments } = useSelector((state: RootStateType) => state.editorSlate);
  const pageSettings: PageSettingsType | null = useSelector((state: RootStateType) => getPageSettings(state));
  const combineAttachmentsInPdf = pageSettings ? Boolean(pageSettings[COMBINE_ATTACHMENTS_IN_DOWNLOADED_PDF]) : false;

  const sectionPadding = getMarginsForPageInInches(pageSettings);

  const isFreeTierSubscription = useIsFreeTier();

  const [showDescriptionModal, setShowDescriptionModal] = useState<boolean>(false);
  const [isCombinePdfOptionEnabled, setIsCombinePdfOptionEnabled] = useState<boolean>(combineAttachmentsInPdf);

  const isSignNowDocument: boolean = documentDetails?.is_signnow_document && permissionUsingSignNow;

  const disableSendButton = useDisableSendButton();

  const combinePdfOptionToggler = (): void => {
    setIsCombinePdfOptionEnabled((prev: boolean) => !prev);
  };

  const disableSaveButton = (
    isSelectFieldError && Object.values(isSelectFieldError).find((el) => el)
  ) || (isPdfDocument && !pdfTemplateLink);

  const handlerSendDocuments = () => {
    createDataForSending(
      documentName,
      Number(id),
      dispatch,
      history.location.pathname,
      documentsInCollection,
      statusObject?.selectedStatus === STATUSES.completed,
    );
    if (isSignNowDocument) {
      dispatch(setIsRemindDocumentActive());
      dispatch(setSigners(getSignersFromAssignments(assignments)));
      dispatch(setLinkViewMode(ShareViewModes.SHARE_MODE_DOCUMENT));
      dispatch(setCurrentSignerFlowToStore(documentDetails.signing_order_flow));
      history.push(`${ROUTES.SIGN_NOW}/${id}`);
      return null;
    }
    saveDocument(RedirectTypeEnum.Send, ROUTES.SEND_DOCUMENTS);
  };

  const handlerPreviewButton = async () => {
    setPreviewMode((prev) => !prev);

    if (!previewMode) {
      if (isCombinePdfOptionEnabled && doesDocumentIncludeDocAttachments(documentDetails)) {
        toast.warn(combinePdfOptionWarning);
      }

      const editorHtmlElement = document.querySelector('[data-html-source="true"]');

      let editorHtmlString = '';
      if (editorHtmlElement) {
        const editorHtmlElementClone = editorHtmlElement?.cloneNode(true);
        (editorHtmlElementClone as any).style.padding = 0;
        editorHtmlString = (editorHtmlElementClone as any)?.outerHTML;
      }

      const globalCssString = await createCSSString();
      const fileLinkResponse = await apiErrorHandler(getLinkForPdfRenderer, {
        pdf: true,
        html: editorHtmlString,
        css: globalCssString,
        combinePDF: isCombinePdfOptionEnabled,
        executedDocId: id,
        addLogPage: false,
        pageMargins: sectionPadding,
      });
      if (fileLinkResponse) {
        dispatch(setPdfFileLink(fileLinkResponse.data.path));
      }
    }
  };

  const textFormBuilderButton = isPdfDocument ? 'Document' : 'Collection';

  return (
    <div className="p-2 editor-header">
      <div className="col-6 col-xxl-4 d-flex justify-content-start">
        <HeaderBackButton />
        <ChangeStatusButton
          statusObject={statusObject}
          disabledButton={disableSaveButton}
        />
      </div>
      <div className="col col-md-8 mx-auto pt-2 order-3 order-xxl-2 col-xxl-3">
        <HeaderDocumentName
          documentName={documentName}
          onChangeDocumentName={onChangeDocumentName}
          readOnly={readOnly}
          errorText={errorText}
        />
      </div>
      <div className="col-6 order-2 order-xxl-3 col-xxl-4 editor-header-endblock">
        {
          !isFreeTierSubscription && (
            <Button
              classes="button-simple mobile-icon-button flex-shrink-0"
              variant="text"
              size="small"
              disabled={readOnly}
              startIcon={<DescriptionIcon />}
              onClick={() => setShowDescriptionModal(true)}
            >
              <span className="d-none d-xl-inline">Description</span>
            </Button>
          )
        }
        {isFormBuilderAvailable && (
          <Button
            classes="button-simple mobile-icon-button flex-shrink-0"
            onClick={() => onOpenFormBuilder(!isFormBuilderOpened)}
            variant="text"
            size="small"
            startIcon={isFormBuilderOpened ? <InsertDriveFileOutlinedIcon /> : <ArticleOutlinedIcon />}
          >
            <span className="d-none d-xl-inline">{isFormBuilderOpened ? textFormBuilderButton : 'Form Builder'}</span>
          </Button>
        )}
        {
          showPreviewButton
            ? (
              <Button
                classes="button-simple mobile-icon-button flex-shrink-0"
                variant="text"
                size="small"
                startIcon={<PreviewIcon />}
                onClick={handlerPreviewButton}
              >
                <span className="d-none d-xl-inline">{previewMode ? 'Editor' : 'Preview'}</span>
              </Button>
            )
            : null
        }
        <EditorHeaderDropdown
          saveDocument={saveDocument}
          disableSaveButton={disableSaveButton}
          isShareButtonAvailable={isShareButtonAvailable && permissionUsingShareLink}
          showShareLinkModal={showShareLinkModal}
          handlerShareLinkModal={handlerShareLinkModal}
          openManagerPageHandler={openManagerPageHandler}
          isReadOnlyMode={readOnly}
          showDocumentInformation={!!id}
          showDocumentHistoryButton={showDocumentHistoryButton}
          isPreviewMode={previewMode}
          isCombinePdfOptionEnabled={isCombinePdfOptionEnabled}
          combinePdfOptionToggler={combinePdfOptionToggler}
          isCollectionPage={isCollectionPage}
          isCollectionExecutedPage={isCollectionExecutedPage}
        />
        <div>
          {
            id && !isCollectionExecutedPage && (
              <Button
                classes="button-simple button-contained-pink"
                variant="contained"
                size="small"
                onClick={handlerSendDocuments}
                disabled={disableSaveButton || (disableSendButton && !isSignNowDocument)}
              >
                {isSignNowDocument ? REMIND : SEND}
              </Button>
            )
          }
        </div>
        <div>
          <Button
            classes="button-simple button-outlined-pink"
            variant="outlined"
            size="small"
            onClick={saveDocument}
            disabled={disableSaveButton || readOnly}
          >
            {SAVE}
          </Button>
        </div>
      </div>
      <DocumentDescriptionModal
        showDescriptionModal={showDescriptionModal}
        setShowDescriptionModal={setShowDescriptionModal}
        description={description}
        setDescription={setDescription}
        readOnlyMode={readOnly}
      />
    </div>
  );
};

export default EditorHeader;
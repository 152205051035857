import { SignerFlow, SignerRecord } from 'constants/signNow';
import {
  CLEAR_FULL_SIGN_NOW_STORE,
  CLEAR_SIGN_NOW_FILE_FROM_STORE,
  CLEAR_SIGN_NOW_STORE,
  SET_COLLECTION_ID_IN_SIGN_NOW,
  SET_CURRENT_SIGNER,
  SET_CURRENT_SIGNER_FLOW_TO_STORE,
  SET_IS_COLLECTION_IN_SIGN_NOW,
  SET_IS_REMIND_DOCUMENT_ACTIVE,
  SET_IS_REMIND_FOR_ALL_SIGNERS,
  SET_IS_SIGN_NOW_ACTIVE,
  SET_PDF_TEMPLATE_ID_IN_SIGN_NOW,
  SET_SIGN_NOW_DOCUMENT_INFO,
  SET_SIGN_NOW_FILE_URL,
  SET_SIGN_NOW_PDF_FORM_FIELDS,
  SET_SIGNERS_LIST,
  UPDATE_SIGN_NOW_DOCUMENT,
} from 'store/actions/actionTypes';
import { Action } from 'types/redux';
import { SignersInfoType } from 'types/SendDocuments';
import { ISignNowStore } from 'types/signNow/store';

const initialState: ISignNowStore = {
  files: [],
  currentFlow: null,
  signers: {
    [SignerRecord.One]: [],
    [SignerRecord.Many]: [],
  },
  currentSigner: null,
  isSignNow: false,
  isRemindDocumentActive: false,
  isRemindForAllSigners: false,
  documents: [],
  pdfFormFields: null,
  isCollection: false,
  collectionId: null,
};

const signNow = (
  state: ISignNowStore = initialState,
  { type, payload }: Action = { type: '', payload: null },
): ISignNowStore => {
  switch (type) {
    case SET_SIGN_NOW_FILE_URL:
      return {
        ...state,
        files: [...state.files, payload],
      };
    case SET_PDF_TEMPLATE_ID_IN_SIGN_NOW:
      return {
        ...state,
        files: state.files.map((file) => {
          if (file.fileKey === payload.fileKey) {
            return {
              ...file,
              pdfTemplateId: payload.pdfTemplateId,
            };
          }
          return file;
        }),
      };
    case SET_SIGN_NOW_PDF_FORM_FIELDS:
      return {
        ...state,
        pdfFormFields: payload,
      };
    case CLEAR_SIGN_NOW_FILE_FROM_STORE:
      return {
        ...state,
        files: state.files.filter((file) => file.fileKey !== payload),
        documents: state.documents.filter((document) => document.fileKey !== payload),
      };
    case SET_CURRENT_SIGNER_FLOW_TO_STORE:
      return {
        ...state,
        currentFlow: payload as SignerFlow,
      };
    case SET_SIGN_NOW_DOCUMENT_INFO:
      return {
        ...state,
        documents: [...state.documents, payload],
      };
    case UPDATE_SIGN_NOW_DOCUMENT:
      return {
        ...state,
        documents: state.documents.map((document) => {
          if (document.fileKey === payload.fileKey) {
            return {
              ...document,
              ...payload,
            };
          }
          return document;
        }),
      };
    case SET_SIGNERS_LIST:
      return {
        ...state,
        signers: {
          ...(state.signers && { ...state.signers }),
          [payload.record as SignerRecord]: payload.signers as SignersInfoType[],
        },
      };
    case SET_CURRENT_SIGNER:
      return {
        ...state,
        currentSigner: payload,
      };
    case SET_IS_SIGN_NOW_ACTIVE:
      return {
        ...state,
        isSignNow: true,
      };
    case SET_IS_REMIND_DOCUMENT_ACTIVE:
      return {
        ...state,
        isRemindDocumentActive: true,
      };
    case SET_IS_REMIND_FOR_ALL_SIGNERS:
      return {
        ...state,
        isRemindForAllSigners: payload,
      };
    case CLEAR_SIGN_NOW_STORE: {
      const isClearFullStore = payload;
      const isRemindDocumentActive = state.isRemindDocumentActive;
      const pdfFormFields = state.pdfFormFields;
      return {
        ...initialState,
        documents: state.documents,
        files: state.files,
        isCollection: state.isCollection,
        collectionId: state.collectionId,
        ...(!isClearFullStore && { isRemindDocumentActive }),
        ...(pdfFormFields && pdfFormFields.length && { pdfFormFields }),
      };
    }
    case CLEAR_FULL_SIGN_NOW_STORE:
      return {
        ...state,
        ...initialState,
      };
    case SET_IS_COLLECTION_IN_SIGN_NOW:
      return {
        ...state,
        isCollection: payload,
      };
    case SET_COLLECTION_ID_IN_SIGN_NOW:
      return {
        ...state,
        collectionId: payload,
      };
    default:
      return state;
  }
};

export default signNow;
import PdfFieldsSidebar from 'components/PdfEditor/PdfFieldsSidebar/PdfFieldsSidebar';
import { SIGN_NOW_MOST_COMMON_FIELDS, SIGN_NOW_SIGNATURE_FIELDS } from 'constants/editors';

const PdfFieldsGeneralWrapper = () => (
  <div>
    <div className="editor-navigator-title">
      Signature Fields
    </div>
    <PdfFieldsSidebar hasDescription={false} fields={SIGN_NOW_SIGNATURE_FIELDS} />
    <div className="mt-4 editor-navigator-title">
      Most Common Fields
    </div>
    <PdfFieldsSidebar hasDescription={false} fields={SIGN_NOW_MOST_COMMON_FIELDS} />
    <div className="mt-4 editor-navigator-title">
      Standard Fields
    </div>
    <PdfFieldsSidebar />
  </div>
);

export default PdfFieldsGeneralWrapper;
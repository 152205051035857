/* eslint-disable */
import { FC, useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { SortableContainer, SortableElement, SortEnd, SortEvent, SortStart } from 'react-sortable-hoc';

import { RenderElementProps, useSlate } from 'slate-react';

import TitleAndDescription from 'components/Editor/FormView/TitleAndDescription';
import ManageSectionButtons from 'components/FormConstructor/Buttons/FormSectionButtons';
import { FORM_ACTIVE_SECTION_CLASS } from 'constants/formEditor';
import { SECTIONS_TITLE_MAX_LEN } from 'constants/validation';
import useSortFields from 'hooks/managerForm/useSortFields';
import { changeSelectedSection, setActiveHoveredSectionKey } from 'store/actions/editorSlate';
import {
  sortFormDocumentSections,
  updateTemplateSectionDescription,
  updateTemplateSectionName,
} from 'store/actions/template';
import { RootStateType } from 'store/reducers';
import { ITemplateSection } from 'types/redux';
import { shouldCancelStartOfSorting } from 'utils/arrayMove';
import { removeDraggableParagraphStyles, setDraggableParagraphStyles } from 'utils/ManagerEditor/formEditorHelpers';
import { getSectionNameErrorText, isSectionNameHasError } from 'utils/validation';

interface IRenderElementProps extends RenderElementProps {
  readOnly?: boolean;
  showButtons?: boolean;
}

const SortableContent = SortableContainer(({ children }: { children: JSX.Element }) => children);
const SortableChildren = SortableElement(({ children }: { children: JSX.Element }) => children);

const FormSectionEditable: FC<IRenderElementProps> = ({
  attributes,
  children,
  element,
  readOnly = false,
  showButtons = true,
}) => {
  const dispatch = useDispatch();
  const editor = useSlate();
  const { selectedSection, activeHoveredSectionKey } = useSelector((state: RootStateType) => state.editorSlate);
  const sectionsInfo: ITemplateSection[] = useSelector((state: RootStateType) => state.template.sections);
  /*
  Note: Current section object is on one step behind in order to descrease amount of rerenders,
  this is because we change global state by using onBlur action instead of changing it on every type in input etc.
  */
  const currentSection = sectionsInfo.find((el) => el.key === element.key);

  const onSortEndHandler = useSortFields();

  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [nameError, setNameError] = useState<boolean>(false);
  const [isActiveSection, setActiveSection] = useState<boolean>(false);
  const [isDescriptionEditable, setDescriptionEditable] = useState<boolean>(false);
  const sectionKey = element.key || 0;

  useEffect(() => {
    if (currentSection) {
      setName(currentSection.name);
      setDescription(currentSection.description);
    }
  }, [currentSection]);

  useEffect(() => {
    setActiveSection(selectedSection === sectionKey);
    if (selectedSection !== sectionKey) {
      setDescriptionEditable(false);
    }
  }, [selectedSection]);

  const changeSectionName: React.ChangeEventHandler<HTMLInputElement> = ({ target }): void => {
    if (name === target.value) return;
    setName(target.value);
  };

  const updateSectionName = (): void => {
    setNameError(isSectionNameHasError(name || ''));
    dispatch(updateTemplateSectionName(sectionKey, name));
  };

  const changeSectionDescription = (value: string) => {
    setDescription(value);
  };

  const updateSectionDescription = (): void => {
    setDescriptionEditable(false);
    dispatch(updateTemplateSectionDescription(sectionKey, description));
  };

  const activateSectionEditor = (sectionKey: number) => {
    if (selectedSection !== sectionKey) {
      dispatch(changeSelectedSection(sectionKey));
    }
  };

  const onActivateDescriptionEditorHandler = (sectionId: number) => {
    setDescriptionEditable(true);
    activateSectionEditor(sectionId);
  };

  const onSortFieldStart = (sort: SortStart) => {
    setDraggableParagraphStyles(sort.node);
  };

  const onSortFieldEnd = ({ oldIndex, newIndex }: SortEnd) => {
    editor.selection = null;
    if (editor.children) {
      onSortEndHandler({
        oldIndex,
        newIndex,
        allSections: editor.children,
        sectionKey,
      });
      dispatch(sortFormDocumentSections(true));
    }
    removeDraggableParagraphStyles();
  };

  const shouldCancelSorting = (event: SortEvent) => shouldCancelStartOfSorting(event, readOnly);

  const sectionNodeId = `formSection_${sectionKey}`;

  const setHoveredSectionKey = useCallback((key: number, activeHoveredSectionKey: number | null) => {
    if (activeHoveredSectionKey !== key) {
      dispatch(setActiveHoveredSectionKey(key));
    }
  }, [dispatch, sectionKey]);

  return (
    <section
      key={sectionNodeId}
      id={sectionNodeId}
      className={`my-3 form-section ${isActiveSection ? FORM_ACTIVE_SECTION_CLASS : ''}`}
      onClick={() => activateSectionEditor(sectionKey)}
      onMouseEnter={() => setHoveredSectionKey(sectionKey, activeHoveredSectionKey)}
      role="contentinfo"
      data-form-wrapper="true"
      {...attributes}
    >
      <div
        contentEditable={false}
        className="user-select-none"
      >
        {!readOnly && showButtons && <ManageSectionButtons sectionKey={sectionKey} />}
        <TitleAndDescription
          changeFormDescription={changeSectionDescription}
          changeFormTitle={changeSectionName}
          updateSectionName={updateSectionName}
          description={description}
          errorMessage={getSectionNameErrorText(name)}
          isError={nameError}
          isReadOnlyMode={readOnly}
          title={name}
          titleMaxLength={SECTIONS_TITLE_MAX_LEN}
          titlePlaceholder="Section name is here..."
          isDescriptionEditable={isDescriptionEditable}
          onActivateDescriptionEditor={() => onActivateDescriptionEditorHandler(sectionKey)}
          updateSectionDescription={updateSectionDescription}
        />
      </div>
      <SortableContent
        onSortStart={onSortFieldStart}
        onSortEnd={onSortFieldEnd}
        shouldCancelStart={shouldCancelSorting}
        hideSortableGhost={false}
        lockAxis="y"
        distance={1}
        helperClass="d-block form-section__field-ordering-active"
        useDragHandle
        getContainer={() => {
          const node: HTMLElement | null = document.getElementById(sectionNodeId);
          return node || document.body;
        }}
      >
        {
          children.map((child: JSX.Element, index: number) => (
            <SortableChildren index={index} key={`SortableChildren_${child.key}`}>
              {child}
            </SortableChildren>
          ))
        }
      </SortableContent>
    </section>
  );
};

export default SortableElement(FormSectionEditable);
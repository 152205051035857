import { useDispatch } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';

import Button from 'components/Base/Button';
import Logo from 'components/Intro/Logo';
import useFirstLogin from 'hooks/FirstLogin/useFirstLogin';
import useStyleSchema, { CustomStylesEnum } from 'hooks/useStylesFromSchema';
import { setFirstLoginParameter } from 'store/actions/userProfile';

import classes from 'scss/components/Intro/Intro.module.scss';

const FirstLoginModal = () => {
  const dispatch = useDispatch();
  const {
    isFirstLogin,
    stripePaidLink,
    stripeTrialDaysLeft,
  } = useFirstLogin();
  const styleSchema = useStyleSchema<CustomStylesEnum.title | CustomStylesEnum.description | CustomStylesEnum.button>(
    [CustomStylesEnum.title, CustomStylesEnum.description, CustomStylesEnum.button],
  );

  return (
    <Modal centered isOpen={isFirstLogin} contentClassName="document-info mx-auto">
      <ModalBody className="page-settings-modal">
        <div>
          <section className={`${classes.Intro}`}>
            <Logo />
            <h1
              className="title"
              style={styleSchema.title}
            >
              Welcome to UnicornForms!
            </h1>
            <div className="mx-4" style={{ maxWidth: '400px', fontSize: '14px' }}>
              <div className="mb-3">
                Howdy!👋
              </div>
              <div className="mb-3">
                We&apos;re delighted you&apos;re here. We built UnicornForms to help you stay organized,
                automate your processes and streamline your e-signing needs big or small.
              </div>
              {
                stripePaidLink && (
                  <div className="mb-3">
                    You are on a {stripeTrialDaysLeft <= 0 ? 'less than day' : `${stripeTrialDaysLeft} days`} trial,
                    please update your payment method&nbsp;
                    <a
                      style={{ textDecoration: 'none' }}
                      href={stripePaidLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      here
                    </a>.
                    You will not be charged until your trial ends.
                  </div>
                )
              }
              <div className="mb-3">
                To learn more about our product, feel free to ask our help bot in the lower right any questions.
                You can also check out our&nbsp;
                <a
                  style={{ textDecoration: 'none' }}
                  href="https://help.unicornforms.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  Knowledge Base
                </a>
              </div>
            </div>
            <div className="text-center">
              <Button
                classes={classes.PublicButton}
                onClick={() => dispatch(setFirstLoginParameter(false))}
                style={styleSchema.button}
              >
                Start Signing Now!
              </Button>
            </div>
          </section>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default FirstLoginModal;
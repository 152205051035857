import { toast } from 'react-toastify';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { GROUP_MANAGEMENT_USER_ACTIONS } from 'constants/general';
import { SUCCESS_CODE, SUCCESS_CREATE_CODE } from 'constants/generalErrors';
import {
  changePassword,
  getMyCompany,
  getMyProfile,
  updateMyCompany,
  updateMyDetails,
} from 'services/api';
import { generateRefferalCode, updateRefferalCode } from 'services/api/referralCodes';
import {
  createGroup,
  deleteGroup,
  getGroup,
  getGroups,
  updateGroup,
} from 'services/api/userGroups';
import {
  createUser,
  deleteUser,
  getUsers,
  resendInviteToUserApi,
  updateUser,
} from 'services/api/users';
import {
  CHANGE_REFERRAL_CODE,
  CHANGE_USER_PASSWORD,
  CREATE_GROUP,
  CREATE_NEW_USER,
  DELETE_GROUP,
  DELETE_GROUPS_BATCH,
  DELETE_USER,
  GET_GROUP,
  GET_GROUPS,
  GET_REFERRAL_CODE,
  GET_USER_COMPANY_DATA,
  GET_USER_LIST_DATA,
  GET_USER_PROFILE_DATA,
  RESEND_INVITE_TO_USER,
  SET_LOADED,
  SET_REFERRAL_CODE,
  SET_USER_COMPANY_DATA,
  SET_USER_LIST_DATA,
  SET_USER_LIST_DATA_LOADER,
  SET_USER_PERSONAL_DATA,
  SET_USER_PROFILE_DATA,
  UPDATE_GROUP,
  UPDATE_GROUP_MEMBER,
  UPDATE_USER_COMPANY_DATA,
  UPDATE_USER_INFO,
  UPDATE_USER_PROFILE_DATA,
} from 'store/actions/actionTypes';
import { setGroup, setGroups } from 'store/actions/userProfile';
import { RootStateType } from 'store/reducers';
import { safe } from 'store/sagas/errorHandlerSaga';
import { IResponseOfRequest } from 'types/Api';
import { ICompany, IUserDetail } from 'types/Auth';
import { Action } from 'types/redux';
import {
  GroupType,
  ModalUserType,
  UpdateUserInfoType,
  USER_MANAGEMENT_MODAL_STATUS,
  UserListGetterType,
  UserListStoreType,
  UsersGetterType,
} from 'types/userProfile';

const getUserProfileData = function* () {
  const userProfile: IUserDetail = yield getMyProfile();
  const userPersonalData = userProfile.personal_data;
  userProfile.userRole = userProfile.roles[0].name;

  yield put({ type: SET_USER_PROFILE_DATA, payload: userProfile });
  yield put({ type: SET_USER_PERSONAL_DATA, payload: userPersonalData });
};

const updateUserProfileData = function* ({ payload }: any) {
  const userProfile: IResponseOfRequest<IUserDetail> = yield updateMyDetails(payload);
  const userPersonalData = userProfile.data;
  userPersonalData.userRole = userPersonalData.roles[0].name;
  yield put({ type: SET_USER_PROFILE_DATA, payload: userPersonalData });
};

const changeUserPassword = function* ({ payload }: any) {
  yield changePassword(payload);
  toast.success('Password has been changed');
};

const getUserCompanyData = function* () {
  const userCompanyData: ICompany = yield getMyCompany();
  yield put({ type: SET_USER_COMPANY_DATA, payload: userCompanyData });
};

const updateUserCompanyData = function* ({ payload }: any) {
  const updatedCompanyData: IResponseOfRequest<ICompany> = yield updateMyCompany(payload);
  yield put({ type: SET_USER_COMPANY_DATA, payload: updatedCompanyData.data });
};

const getUserListData = function* () {
  const isUserListWasLoaded: boolean = yield select((
    { profile }: RootStateType,
  ) => profile.userList?.loading?.wasLoaded) ?? false;

  if (!isUserListWasLoaded) {
    const users: UsersGetterType = yield call(getUsers);
    const usedSeats = users.data.user_list.reduce((total, value) => (value.active ? total + 1 : total), 0);

    yield put({
      type: SET_USER_LIST_DATA,
      payload: {
        users: users.data.user_list,
        totalUsersSeats: users.data.users_seats,
        usedSeats,
      },
    });
    yield put({
      type: SET_USER_LIST_DATA_LOADER,
      payload: {
        wasLoaded: true,
      },
    });
  }
};

const createNewUser = function* ({ payload }: Action<ModalUserType>) {
  yield put({ type: SET_LOADED, payload: false });
  const newUserData: IResponseOfRequest<UserListGetterType> = yield createUser(payload);
  if (newUserData && newUserData.status === SUCCESS_CREATE_CODE) {
    const { users, usedSeats }: UserListStoreType = yield select((state: RootStateType) => state.profile.userList);
    yield put({
      type: SET_USER_LIST_DATA,
      payload: {
        users: [...users, newUserData.data],
        usedSeats: usedSeats + 1,
      },
    });
    yield put({ type: SET_LOADED, payload: true });
  }
};

const updateUserInfo = function* ({ payload }: Action<UpdateUserInfoType>) {
  yield put({ type: SET_LOADED, payload: false });
  const updatedUser: IResponseOfRequest<UserListGetterType> = yield updateUser(
    payload.fieldsToUpdate.id,
    payload.fieldsToUpdate,
  );
  if (updatedUser && updatedUser.status === SUCCESS_CODE) {
    const { users, usedSeats }: UserListStoreType = yield select((state: RootStateType) => state.profile.userList);
    const userList = users.map((user) => {
      if (user.id === updatedUser.data.id) {
        return updatedUser.data;
      }
      return user;
    });
    const updateUsedSeats = updatedUser.data.active ? usedSeats + 1 : usedSeats - 1;
    yield put({
      type: SET_USER_LIST_DATA,
      payload: {
        users: userList,
        usedSeats: payload.status === USER_MANAGEMENT_MODAL_STATUS.CHANGE_ACTIVE_STATUS
          ? updateUsedSeats
          : usedSeats,
      },
    });
    yield put({ type: SET_LOADED, payload: true });
  }
};

const sendInviteToUser = function* ({ payload }: Action<number>) {
  const resendInviteResponse: IResponseOfRequest<{ message: string }> = yield resendInviteToUserApi(payload);
  if (resendInviteResponse && resendInviteResponse.status === SUCCESS_CODE) {
    toast.info(resendInviteResponse.data.message);
  }
};

const deleteUserSaga = function* ({ payload }: Action<number>) {
  const deleteUserResponse: IResponseOfRequest<{ message: string }> = yield deleteUser(payload);
  if (deleteUserResponse && deleteUserResponse.status === SUCCESS_CODE) {
    const { users, usedSeats }: UserListStoreType = yield select((state: RootStateType) => state.profile.userList);
    const userToDelete: UserListGetterType | undefined = users.find((user) => user.id === payload);
    const filteredUsers: UserListGetterType[] = users.filter((user) => user.id !== payload);
    yield put({
      type: SET_USER_LIST_DATA,
      payload: {
        users: filteredUsers,
        usedSeats: userToDelete?.active ? usedSeats - 1 : usedSeats,
      },
    });
  }
};

const getGroupsSaga = function* () {
  const { data: groups }: IResponseOfRequest<GroupType[]> = yield call(getGroups);
  yield put(setGroups(groups));
};

const deleteGroupSaga = function* (
  { payload: { groupId } }:
    { payload: { groupId: string } },
) {
  if (groupId) {
    yield deleteGroup(groupId);
    yield put({
      type: GET_GROUPS,
    });
  }
};

const createGroupSaga = function* (
  { payload: { newGroup } }:
    { payload: { newGroup: { name: string } } },
) {
  yield createGroup(newGroup);

  yield put({
    type: GET_GROUPS,
  });
};

const updateGroupSaga = function* (
  { payload: { fieldsToUpdate, groupId } }:
    { payload: { fieldsToUpdate: { name: string }, groupId: string } },
) {
  yield updateGroup(fieldsToUpdate, groupId);

  yield put({
    type: GET_GROUPS,
  });
};

const deleteGroupsBatchSaga = function* (
  { payload: { groupIdsToDelete } }:
    { payload: { groupIdsToDelete: string[] } },
) {
  for (const groupId of groupIdsToDelete) {
    yield deleteGroup(groupId);
  }

  yield put({
    type: GET_GROUPS,
  });
};

const getGroupSaga = function* (
  { payload: { groupId } }:
    { payload: { groupId: string } },
) {
  const { data: group }: IResponseOfRequest<GroupType> = yield call(getGroup, groupId);
  yield put(setGroup(group));
};

const updateGroupMemberSaga = function* (
  { payload: { userId, groupId, action } }:
    { payload: { userId: string; groupId: string; action: GROUP_MANAGEMENT_USER_ACTIONS; } },
) {
  yield updateGroup({ userId, action }, groupId);

  yield call(getGroupSaga, { payload: { groupId } });
};

const getRefferalCode = function* () {
  const referralCodeResponse: IResponseOfRequest<{ referral_code: string }> = yield generateRefferalCode();
  if (referralCodeResponse && referralCodeResponse.status === SUCCESS_CODE) {
    yield put({ type: SET_REFERRAL_CODE, payload: referralCodeResponse.data.referral_code });
  }
};

const changeRefferalCode = function* ({ payload }: { payload: string }) {
  const updateResponse: IResponseOfRequest<{
    referral_code: string,
    referral_code_error?: string,
  }> = yield updateRefferalCode({ referral_code: payload });
  if (updateResponse && updateResponse.status === SUCCESS_CODE) {
    yield put({ type: SET_REFERRAL_CODE, payload: updateResponse.data.referral_code });
    toast.success('Referral link has been updated.');
  }
};

export default [
  takeLatest(GET_USER_PROFILE_DATA, safe(getUserProfileData)),
  takeLatest(UPDATE_USER_PROFILE_DATA, safe(updateUserProfileData)),
  takeLatest(CHANGE_USER_PASSWORD, safe(changeUserPassword)),
  takeLatest(GET_USER_COMPANY_DATA, safe(getUserCompanyData)),
  takeLatest(UPDATE_USER_COMPANY_DATA, safe(updateUserCompanyData)),
  takeLatest(GET_USER_LIST_DATA, safe(getUserListData)),
  takeLatest(CREATE_NEW_USER, safe(createNewUser)),
  takeLatest(UPDATE_USER_INFO, safe(updateUserInfo)),
  takeLatest(RESEND_INVITE_TO_USER, safe(sendInviteToUser)),
  takeLatest(DELETE_USER, safe(deleteUserSaga)),
  takeLatest(GET_GROUPS, safe(getGroupsSaga)),
  takeLatest(DELETE_GROUP, safe(deleteGroupSaga)),
  takeLatest(CREATE_GROUP, safe(createGroupSaga)),
  takeLatest(UPDATE_GROUP, safe(updateGroupSaga)),
  takeLatest(DELETE_GROUPS_BATCH, safe(deleteGroupsBatchSaga)),
  takeLatest(GET_GROUP, safe(getGroupSaga)),
  takeLatest(UPDATE_GROUP_MEMBER, safe(updateGroupMemberSaga)),
  takeLatest(GET_REFERRAL_CODE, safe(getRefferalCode)),
  takeLatest(CHANGE_REFERRAL_CODE, safe(changeRefferalCode)),
];

import { MouseEventHandler, useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { DOCUMENT_TYPE } from 'constants/general';
import { SignerFlow } from 'constants/signNow';
import { setSignerFlow } from 'store/actions/sendDocuments';
import { RootStateType } from 'store/reducers';

const useSignerFlowInSendFlow = () => {
  const dispatch = useDispatch();
  const {
    signingOrderFlow,
    onlyCollection,
    resourceType,
    documentsInfo,
  } = useSelector((state: RootStateType) => state.sendDocuments);

  const changeSignerFlowHandler: MouseEventHandler<HTMLButtonElement> = useCallback((event): void => {
    const value: SignerFlow = (event.target as HTMLInputElement).value as SignerFlow;
    if (value === signingOrderFlow) {
      dispatch(setSignerFlow(SignerFlow.NoOrder));
    } else {
      dispatch(setSignerFlow(value));
    }
  }, [signingOrderFlow]);

  return {
    onlyCollection,
    signingOrderFlow,
    changeSignerFlowHandler,
    addBulkSendFlow: documentsInfo.length === 1 && resourceType === DOCUMENT_TYPE,
  };
};

export default useSignerFlowInSendFlow;
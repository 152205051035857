import { Editor as SlateEditor, Element as SlateElement, Range as SlateRange, Transforms } from 'slate';

import { RECIPIENT_ASSIGNMENT } from 'constants/editors';
import { BlockFormatType, isBlockFormatType } from 'types/Editor';

import { isBlockActive } from './editorBlockHelpers';
import { createField, findAndChangeDuplicatedFieldKeys, isNodeTextNotEmpty } from './editorFieldHelpers';

export const toggleTextField = (
  editor: SlateEditor,
  format: BlockFormatType,
  assignment: string = RECIPIENT_ASSIGNMENT,
): number | null => {
  const { selection, forms } = editor;

  Transforms.unwrapNodes(editor, {
    match: (node) => (
      !SlateEditor.isEditor(node)
      && SlateElement.isElement(node)
      && isBlockFormatType(node.type)
      && node.type !== format
    ),
  });

  const isActive = isBlockActive(editor, format);

  if (isActive) {
    Transforms.unwrapNodes(editor, {
      match: (node) => (
        !SlateEditor.isEditor(node)
        && SlateElement.isElement(node)
        && node.type === format
      ),
    });
    return null;
  }

  const isCollapsed = selection && SlateRange.isCollapsed(selection);
  const textField: SlateElement = createField(
    format,
    isCollapsed,
    forms?.length || 0,
    assignment,
  );
  const fieldKey = textField?.key || null;

  if (isCollapsed) {
    Transforms.insertNodes(editor, textField, { match: (node) => isNodeTextNotEmpty(node) });
    findAndChangeDuplicatedFieldKeys(fieldKey || 0, editor);
  } else {
    Transforms.wrapNodes(editor, textField, { split: true });
    findAndChangeDuplicatedFieldKeys(fieldKey || 0, editor);
    Transforms.collapse(editor, { edge: 'end' });
  }

  return fieldKey;
};

export default toggleTextField;
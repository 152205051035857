import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import ROUTES from 'constants/routes';
import { RootStateType } from 'store/reducers';
import { AssignmentsMainParts } from 'types/Editor';

const useDisableSendButton = () => {
  const location = useLocation();
  const { assignments } = useSelector((state: RootStateType) => state.editorSlate);

  const unitedArray = [
    ...assignments[AssignmentsMainParts.MANAGERS],
    ...assignments[AssignmentsMainParts.RECIPIENTS],
  ];

  if (
    [
      location.pathname.startsWith(ROUTES.DOCUMENTS_EXECUTED_EDITOR),
      location.pathname.startsWith(ROUTES.PDF_EXECUTED_EDITOR),
      location.pathname.startsWith(ROUTES.FORM_EXECUTED_EDITOR)].some((item: boolean) => item)
    && !unitedArray.every((item) => item.email && Boolean(item.email.length))
  ) {
    return true;
  }
  return false;
};

export default useDisableSendButton;
import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { SortableElement } from 'react-sortable-hoc';
import { FormFeedback, Input } from 'reactstrap';

import Check from '@mui/icons-material/Check';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RemoveCircleOutline from '@mui/icons-material/RemoveCircleOutline';
import Settings from '@mui/icons-material/Settings';

import ModalConfirmDialog from 'components/Modals/ModalConfirmDialog';
import Tooltip from 'components/Tooltip';
import { FORM_BUILDER } from 'constants/formBuilder';
import { SECTIONS_TITLE_MAX_LEN } from 'constants/validation';
import { changeSelectedSection } from 'store/actions/editorSlate';
import { clearOutlineActiveSectionKey, setOutlineActiveSectionKey } from 'store/actions/formBuilder';
import { deleteTemplateSection, updateTemplateSectionName } from 'store/actions/template';
import { updateFormBuilderSection } from 'store/actions/userData';
import { RootStateType } from 'store/reducers';
import DragHandleIcon from 'svg/DragHandleIcon';
import { ICustomElement } from 'types/Editor';
import { FormBuilderType } from 'types/FormBuilder';
import { getText } from 'utils/modalHelpers';
import { getSectionNameErrorText, isSectionNameHasError } from 'utils/validation';

import 'style/_outline.scss';

interface ISortableItemProps {
  value: string;
  id: number;
  sectionLength: number;
  isFormBuilder?: boolean;
  formBuilderType?: FormBuilderType;
  formBuilderSectionFields?: Partial<ICustomElement>[];
}

const SortableItem = ({
  value,
  id,
  sectionLength,
  isFormBuilder = false,
  formBuilderType = FORM_BUILDER.COLLECTION,
  formBuilderSectionFields = [],
}: ISortableItemProps) => {
  const dispatch = useDispatch();
  const { isDragInProcess, outlineActiveSectionKey } = useSelector((state: RootStateType) => state.formBuilder);

  const [sectionName, setSectionName] = useState('');
  const [editable, setEditable] = useState<boolean>(!value);
  const [isError, setIsError] = useState<boolean>(false);
  const [isConfirmModalOpened, setIsConfirmModalOpened] = useState<boolean>(false);
  const [showDeleteButton, setShowDeleteButton] = useState<boolean>(true);
  const [isHover, setIsHover] = useState<boolean>(false);
  const [showFieldsInPdfSection, setShowFieldsInPdfSection] = useState<boolean>(false);

  useEffect(() => {
    const hasError = isSectionNameHasError(value);
    setIsError(hasError);
    setSectionName(value.trim());
    setEditable(hasError);
  }, [value]);

  useEffect(() => {
    setShowDeleteButton(sectionLength > 1);
  }, [sectionLength]);

  const changeNameHandler: React.ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    setIsError(isSectionNameHasError(target.value));
    setSectionName(target.value);
  };

  const editSectionName = () => {
    setEditable(true);
  };

  const saveSectionName = () => {
    if (isFormBuilder) {
      dispatch(updateFormBuilderSection({
        formBuilder: { name: sectionName },
        key: id,
        formBuilderType,
      }));
    } else {
      dispatch(updateTemplateSectionName(id, sectionName));
    }
    setEditable(isSectionNameHasError(sectionName));
  };

  const deleteSection = (): void => {
    dispatch(changeSelectedSection(null));
    dispatch(deleteTemplateSection(id));
  };

  const handleModalVisibility = (): void => {
    setIsConfirmModalOpened((prevState: boolean) => !prevState);
  };

  const settingsClassNames = 'light-section-outline-icon settings-icon close';

  const onMouseEnter = () => {
    if (isDragInProcess) {
      setIsHover(true);
      dispatch(setOutlineActiveSectionKey(id));
    }
  };

  const onMouseLeave = () => {
    if (outlineActiveSectionKey) {
      setIsHover(false);
      dispatch(clearOutlineActiveSectionKey());
    }
  };

  const onSectionClickHandler = () => {
    const currentSlateSection = document.querySelector(`[data-section-key="${id}"]`);
    if (currentSlateSection) {
      currentSlateSection.scrollIntoView({ behavior: 'smooth' });
    }
    const currentFormSection = document.getElementById(`formSection_${id}`);
    if (currentFormSection) {
      currentFormSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <li className={`light-section-outline-li${isHover ? ' hover' : ''}`}>
        <label className="light-section-outline-label">
          <span><DragHandleIcon disabled={editable} /></span>
          <span className="d-block w-100">
            {
              editable
                ? (
                  <Input
                    type="text"
                    maxLength={SECTIONS_TITLE_MAX_LEN}
                    className="light-section-outline-input text-truncate p-1"
                    value={sectionName}
                    invalid={isError}
                    disabled={!editable}
                    readOnly={!editable}
                    onChange={changeNameHandler}
                    onBlur={saveSectionName}
                  />
                )
                : (
                  <Tooltip title={sectionName} placement="right">
                    <span
                      role="listitem"
                      onMouseEnter={onMouseEnter}
                      onMouseLeave={onMouseLeave}
                      onClick={onSectionClickHandler}
                      className="light-section-outline-text form-control text-truncate p-1"
                    >
                      {sectionName}
                    </span>
                  </Tooltip>
                )
            }
            <FormFeedback className="mt-0">
              <small>{getSectionNameErrorText(sectionName)}</small>
            </FormFeedback>
          </span>
          <div>
            {
              editable
                ? <Check className={settingsClassNames} type="button" onClick={saveSectionName} />
                : <Settings className={settingsClassNames} type="button" onClick={editSectionName} />
            }
            {
              isFormBuilder
                ? (
                  <ExpandMoreIcon
                    className="pdf-expand-more-icon"
                    type="button"
                    onClick={() => setShowFieldsInPdfSection((prevState) => !prevState)}
                    style={showFieldsInPdfSection ? { transform: 'rotate(0.5turn)' } : {}}
                  />
                )
                : null
            }
            {
              showDeleteButton && !isFormBuilder && (
                <RemoveCircleOutline
                  className="light-section-outline-icon delete-icon close"
                  onClick={handleModalVisibility}
                />
              )
            }
          </div>
        </label>
        {
          isFormBuilder && showFieldsInPdfSection && formBuilderSectionFields.map((field) => (
            <div key={field.key} className="ms-4">{field.fieldName ?? field.name}</div>
          ))
        }
      </li>
      <ModalConfirmDialog
        onClose={handleModalVisibility}
        onConfirm={deleteSection}
        showDialog={isConfirmModalOpened}
        messageText={getText(sectionName)}
      />
    </>
  );
};

export default SortableElement(SortableItem);
import {
  CLEAR_STRIPE_STORE,
  SET_ACTIVE_STRIPE_PRODUCT_ID,
  SET_ACTIVE_STRIPE_SUBSCRIPTION,
  SET_STRIPE_PAID_LINK,
  SET_STRIPE_TRIAL_DAYS_LEFT,
  SET_SUBSCRIPTION_IS_ENDED,
} from 'store/actions/actionTypes';
import { ISetActiveStripeProductIDPayload } from 'types/payment/Payment';
import { IStripeSubscription } from 'types/payment/Stripe';

export const setActiveStripeProductID = (payload: ISetActiveStripeProductIDPayload) => ({
  type: SET_ACTIVE_STRIPE_PRODUCT_ID,
  payload,
});

export const setActiveStripeSubscription = (payload: IStripeSubscription) => ({
  type: SET_ACTIVE_STRIPE_SUBSCRIPTION,
  payload,
});

export const setSubscriptionIsEnded = (payload: boolean) => ({
  type: SET_SUBSCRIPTION_IS_ENDED,
  payload,
});

export const setStripePaidLink = (payload: string | null) => ({
  type: SET_STRIPE_PAID_LINK,
  payload,
});

export const setStripeTrialDaysLeft = (payload: number | null) => ({
  type: SET_STRIPE_TRIAL_DAYS_LEFT,
  payload,
});

export const clearStripeStore = () => ({
  type: CLEAR_STRIPE_STORE,
});

export default undefined;
import { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import ROUTES from 'constants/routes';
import { useRolePermission } from 'hooks/useRolePermission';
import { history } from 'services/history';
import { RootStateType } from 'store/reducers';
import { IRole, LocationStateObject } from 'types/Auth';
import { isThe404Path } from 'utils/createRoutes';
import { toShowBlock } from 'utils/routeHelpers';

const useRoutes = () => {
  const { pathname, search, state: locationState } = useLocation();
  const roles: IRole[] | null = useSelector((state: RootStateType) => state.profile.profileInfo?.roles);
  const isAuthenticated: boolean = useSelector((state: RootStateType) => state.user.isAuthenticated);
  const { routesPermission, routesForAuthorizedUsers } = useRolePermission();
  const firstAvailableRoute = routesPermission[0].path;
  const isLogoutState = (locationState as LocationStateObject)?.logout || false;

  useEffect(() => {
    if (isAuthenticated && !roles) return;

    if (pathname === '/') return history.push(firstAvailableRoute);

    const matchLocation = routesPermission.find((route) => (
      matchPath(pathname, {
        path: route.path,
        exact: true,
        strict: false,
      })
    ));

    if (!isAuthenticated) {
      if (isLogoutState) {
        history.replace({ ...history.location, state: { logout: false } });
      } else if (
        isThe404Path(matchLocation) && pathname !== firstAvailableRoute
      ) {
        const matchAuthorizedLocation = routesForAuthorizedUsers?.find((route) => (
          isThe404Path(route) || toShowBlock(pathname, 'public')
            ? false
            : matchPath(pathname, {
              path: route.path,
              exact: true,
              strict: false,
            })
        ));

        if (matchAuthorizedLocation) {
          toast.warning('You must be authenticated to see that content');
          history.push(`${firstAvailableRoute}?backTo=${pathname}${search}`);
          return;
        }
        history.push(ROUTES.LOGIN);
      }
    }

    if (matchLocation) return;

    history.push(firstAvailableRoute);
  }, [isAuthenticated, roles]);

  return routesPermission;
};

export default useRoutes;
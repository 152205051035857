import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import DocumentNavigator from 'components/DocumentNavigator';
import EditorContainer from 'components/Editor/EditorContainer';
import EditorWrapper from 'components/Editor/EditorWrapper';
import EditorHeader from 'components/EditorHeader/EditorHeader';
import EditorHeaderReadOnly from 'components/EditorHeader/EditorHeaderReadOnly';
import ModalWindow from 'components/Modals';
import ModalContentWindow from 'components/Modals/ModalContentWindow';
import ShareLinkModal from 'components/Modals/ModalWrapper';
import PdfEditor from 'components/PdfEditor/PdfEditor';
import PdfFieldsGeneralWrapper from 'components/PdfEditor/PdfFieldsSidebar/PdfFieldsGeneralWrapper';
import SearchFieldAndContent from 'components/Sidebar/SearchFieldAndContent';
import SyncLoader from 'components/SyncLoader';
import {
  READ_ONLY_STATUSES,
  STATUS_CANCELLED,
  STATUS_COMPLETED,
  STATUS_WAITING,
  STATUSES,
  STATUSES_FOR_SHARING,
} from 'constants/documentStatuses';
import { EditorGridAxisCount, MANAGER_ASSIGNMENT } from 'constants/editors';
import {
  DOCUMENT_NAME_LABEL,
  PDF_TYPE,
  RedirectTypeEnum,
  URL_MANAGER_DOCUMENT,
  URL_PUBLIC_DOCUMENT,
} from 'constants/general';
import { SOMETHING_WENT_WRONG } from 'constants/generalErrors';
import { DOCUMENT_TAB_ID } from 'constants/tabs';
import { useRolePermission } from 'hooks/useRolePermission';
import { saveDocumentExecutedLog } from 'services/api/serverSideEvents';
import {
  clearPdfTemplateData,
  setAllPdfFieldsAndInfo,
  setPdfTemplateFileLink,
  setPdfTemplateInfo,
  setPdfTemplateKey,
  setPdfTemplateWidth,
} from 'store/actions/pdfTemplates';
import {
  createDocumentPublicLink,
  deleteDocumentLinkFromStore,
  setCurrentDocumentActiveTab,
} from 'store/actions/publicPages';
import { clearSignNowStore } from 'store/actions/signNow';
import { editDocumentDetails, getDocumentDetails, setDocumentDetails } from 'store/actions/userData';
import { RootStateType } from 'store/reducers';
import { ICreateDocumentRequestObj, IDocumentDetails } from 'types/Documents';
import { HISTORY_ACTIONS } from 'types/DocumentsHistory';
import { IMetadataId } from 'types/Metadata';
import { MatchParams } from 'types/Route';
import { ProfileInfoType } from 'types/userProfile';
import { IErrorField } from 'types/validation';
import { trackAmplitudeEvent } from 'utils/amplitude/amplitudeTrackingUtlis';
import { getAmplitudeEventName } from 'utils/amplitude/amplitudeUtils';
import { trimHTMLValue } from 'utils/descriptionHelper';
import { getUTCDate } from 'utils/documentsHistory';
import { wasStatusChanged } from 'utils/documentStatusHelpers';
import { addHTMLandCSSifCompleted } from 'utils/fileLinkDownload';
import { filterSortedFormFieldsFromJSONObject, getPdfUrlAndWidth } from 'utils/pdfTemplatesHelpers';
import { getIsDocumentType } from 'utils/PublicPage/documentTypeChecker';
import setDefaultPageValuesHelper from 'utils/reduxHelpers';
import { getEditorPageLink } from 'utils/routeHelpers';
import { isRequiredFieldArrayHasError, validationForm } from 'utils/validation';

const PdfDocumentExecutedDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id }: MatchParams = useParams();

  const { pdfTemplateFields, pdfTemplateInfo } = useSelector((state: RootStateType) => state.pdfTemplates);
  const { templateName, templateTitle, templateDescription } = pdfTemplateInfo;
  const { documentDetails, states, statuses, doctypes } = useSelector((state: RootStateType) => state.user);
  const { documentPublicLink } = useSelector((state: RootStateType) => state.publicPages);
  const { isLoading } = useSelector((state: RootStateType) => state.errorLoading);
  const userInfo: ProfileInfoType = useSelector((state: RootStateType) => state.profile.profileInfo);
  const { assignments } = useSelector((state: RootStateType) => state.editorSlate);

  const { isUserReadOnly } = useRolePermission();

  const [completedMode, setCompletedMode] = useState<boolean>(false);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [readOnlyMode, setReadOnlyMode] = useState<boolean>(false);
  const [documentStatus, setDocumentStatus] = useState<string>('');
  const [formErrors, setFormErrors] = useState<IErrorField>({ template_name: null, title: null });
  const [documentState, setDocumentState] = useState<IMetadataId[]>([{ id: 1 }]);
  const [documentDoctype, setDocumentDoctype] = useState<IMetadataId | null>(null);
  const [showShareLinkModal, setShowShareLinkModal] = useState<boolean>(false);
  const [isShowFillSignButton, setIsShowFillSignButton] = useState<boolean>(false);
  const [showManagerFieldsModal, setShowManagerFieldsModal] = useState<boolean>(false);

  // Executed document history log: manager's action 'viewed'
  useEffect(() => {
    if (id) {
      saveDocumentExecutedLog(
        Number(id),
        { [HISTORY_ACTIONS.VIEWED]: getUTCDate() },
      );
    }
    return () => {
      dispatch(clearSignNowStore(false));
    };
  }, []);

  useEffect(() => {
    if (id && documentDetails) {
      trackAmplitudeEvent({
        eventName: getAmplitudeEventName({ data: documentDetails }).viewedByManager,
        userInfo,
      });
    }
  }, [documentDetails?.id]);

  useEffect(() => {
    if (id) {
      dispatch(setCurrentDocumentActiveTab(DOCUMENT_TAB_ID));
      dispatch(getDocumentDetails(id));
    }
    return () => {
      setDefaultPageValuesHelper(dispatch);
      dispatch(setDocumentDetails(null));
      dispatch(clearPdfTemplateData());
    };
  }, [dispatch, id]);

  useEffect(() => {
    setIsShowFillSignButton(
      Boolean(id) && filterSortedFormFieldsFromJSONObject(pdfTemplateFields, true, MANAGER_ASSIGNMENT, true).length > 0,
    );
  }, [pdfTemplateFields, id]);

  useEffect(() => {
    if (documentDetails) {
      if (getIsDocumentType(documentDetails.type)) {
        history.push(getEditorPageLink(id, documentDetails.type, true));
        return;
      }

      const [pdfFileUrl, pdfFileWidth] = getPdfUrlAndWidth(documentDetails.template?.pdf_file_url);
      dispatch(setAllPdfFieldsAndInfo({
        fields: documentDetails.content_json,
        info: {
          templateName: documentDetails.name,
          templateTitle: documentDetails.document_title,
          templateDescription: documentDetails.description,
        },
      }));
      dispatch(setPdfTemplateKey(pdfFileUrl));
      dispatch(setPdfTemplateWidth(parseInt(pdfFileWidth)));
      dispatch(setPdfTemplateFileLink(documentDetails.pdf_full_link));
      if (documentDetails?.status) {
        setDocumentStatus(documentDetails.status);
        setReadOnlyMode(READ_ONLY_STATUSES.includes(documentDetails.status));
      }

      if (documentDetails.doctype && doctypes) {
        setDocumentDoctype(documentDetails.doctype);
      }
      if (documentDetails.state && states) {
        setDocumentState([documentDetails.state]);
      }

      const templateDetails = documentDetails.template;
      if (!documentDetails.doctype && templateDetails.doctype) {
        setDocumentDoctype(templateDetails.doctype);
      }
      if (!documentDetails.state && templateDetails.states) {
        setDocumentState(templateDetails.states);
      }
    }
  }, [dispatch, documentDetails, states, doctypes]);

  useEffect(() => {
    if (completedMode !== (documentStatus === STATUSES.completed)) {
      setCompletedMode(documentStatus === STATUSES.completed);
    }
  }, [documentStatus, documentPublicLink]);

  const validateFieldsNameTitle = (documentName: string) => {
    const validateArray = [
      { titleField: DOCUMENT_NAME_LABEL, nameField: 'template_name', valueField: documentName, required: true },
    ];
    const validateResult = validationForm(validateArray);
    setFormErrors((prev) => ({ ...prev, ...validateResult.validationFields }));
    return validateResult;
  };

  const onChangeTemplateName: React.ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    dispatch(setPdfTemplateInfo('templateName', target.value));
    if (!templateTitle || templateTitle === templateName) {
      dispatch(setPdfTemplateInfo('templateTitle', target.value));
    }
  };

  const savePdfDocumentHandler = async (openManagerPage: boolean = false, newStatus: string = '') => {
    const validateNameResult = validateFieldsNameTitle(templateName);
    if (validateNameResult.isError) return null;
    if (!documentDoctype) return null;
    const isCompleteFlow: boolean = newStatus === STATUSES.completed;
    const editedPdfDocument: Partial<IDocumentDetails> = {
      name: templateName.trim(),
      document_title: templateTitle?.trim() || templateName.trim(),
      description: trimHTMLValue(templateDescription),
      doctype_id: documentDoctype.id,
      state_id: documentState[0].id,
      content_json: pdfTemplateFields,
      type: PDF_TYPE,
      assignments,
    };
    if (
      newStatus
      && wasStatusChanged(documentStatus, newStatus)
      && statuses.includes(newStatus)
    ) {
      if (isCompleteFlow) {
        const hasError = isRequiredFieldArrayHasError({
          contentData: pdfTemplateFields,
          assignment: MANAGER_ASSIGNMENT,
          isPDF: true,
        });
        if (hasError) {
          setShowManagerFieldsModal(true);
          return null;
        }

        const hasRecipientError = isRequiredFieldArrayHasError({
          contentData: pdfTemplateFields,
          isPDF: true,
        });
        if (hasRecipientError) {
          toast.warning('There are empty recipient required fields.');
          return null;
        }
      }

      editedPdfDocument.status = newStatus;
      setDocumentStatus(newStatus);
      setReadOnlyMode(READ_ONLY_STATUSES.includes(newStatus));
    }
    if (id && openManagerPage) {
      return dispatch(editDocumentDetails({
        id,
        document: editedPdfDocument,
        path: `/${URL_MANAGER_DOCUMENT}/${id}`,
        completeFlow: isCompleteFlow,
      }));
    }
    let editedPdfDocumentWithHtml = null;
    if (editedPdfDocument.status && editedPdfDocument.status === STATUSES.completed) {
      editedPdfDocumentWithHtml = await addHTMLandCSSifCompleted(editedPdfDocument as ICreateDocumentRequestObj, true);
      editedPdfDocumentWithHtml.pdf_fields = Object.values(pdfTemplateFields);
    }
    return dispatch(
      editDocumentDetails(
        {
          id,
          document: editedPdfDocumentWithHtml ?? editedPdfDocument,
          completeFlow: isCompleteFlow,
        },
      ),
    );
  };

  const handlerShareLinkModal = () => {
    const changeStatusTo = completedMode ? '' : STATUSES.waiting;
    const save = savePdfDocumentHandler(false, changeStatusTo);
    if (!save) {
      setShowErrorModal(true);
    } else {
      dispatch(createDocumentPublicLink({ document_id: documentDetails?.id, assignments }));
      setShowShareLinkModal(true);
    }
  };

  const savePdfTemplateAndShowManagerPage = () => {
    savePdfDocumentHandler(true);
  };

  const wrapperSavePdfDocument = (
    redirectType: RedirectTypeEnum | undefined = undefined,
    redirectPath: string | undefined = undefined,
  ) => {
    savePdfDocumentHandler(false);
    if (redirectType && redirectPath) {
      history.push(redirectPath);
    }
  };

  const onChangeFormDescription = (description: string) => {
    dispatch(setPdfTemplateInfo('templateDescription', description));
  };

  const changeStatusAndSaveDocument = (newStatus: string) => {
    savePdfDocumentHandler(false, newStatus);
  };

  if (isLoading || !statuses.length || !doctypes.length) {
    return <SyncLoader />;
  }

  const statusObject = statuses && {
    statuses,
    selectedStatus: documentStatus,
    onSelectStatus: changeStatusAndSaveDocument,
  };

  return (
    <>
      <EditorWrapper>
        {
          !isUserReadOnly ? (
            <EditorHeader
              showPreviewButton={false}
              readOnly={readOnlyMode}
              documentName={templateName}
              onChangeDocumentName={onChangeTemplateName}
              errorText={formErrors.template_name}
              saveDocument={(
                redirectType: RedirectTypeEnum | undefined,
                redirectPath: string | undefined,
              ) => wrapperSavePdfDocument(redirectType, redirectPath)}
              description={templateDescription}
              setDescription={onChangeFormDescription}
              showShareLinkModal={showShareLinkModal}
              handlerShareLinkModal={handlerShareLinkModal}
              isShareButtonAvailable={STATUSES_FOR_SHARING.includes(documentStatus)}
              openManagerPageHandler={isShowFillSignButton ? savePdfTemplateAndShowManagerPage : undefined}
              statusObject={statusObject}
              showDocumentHistoryButton
            />
          ) : (<EditorHeaderReadOnly documentName={templateName} />)
        }
        <EditorContainer
          columns={
            [STATUS_WAITING, STATUS_COMPLETED, STATUS_CANCELLED].includes(documentStatus) || isUserReadOnly
              ? EditorGridAxisCount.One
              : EditorGridAxisCount.Three
          }
        >
          <DocumentNavigator
            panelTitle="Fields"
            readOnlyMode={readOnlyMode}
            renderChildrenUnderSidebar={(
              <SearchFieldAndContent
                content={[]}
                setContent={() => null}
                setString={() => null}
              />
            )}
          >
            <PdfFieldsGeneralWrapper />
          </DocumentNavigator>
          <PdfEditor
            visibleEditorDevTools={!readOnlyMode && !isUserReadOnly}
            readOnlyMode={readOnlyMode || isUserReadOnly}
          />
        </EditorContainer>
      </EditorWrapper>
      <ShareLinkModal
        publicRoute={URL_PUBLIC_DOCUMENT}
        result={documentPublicLink.assignments}
        resourceGuid={documentPublicLink.origin}
        showModal={!isLoading && showShareLinkModal}
        onCloseModal={setShowShareLinkModal}
        deleteLinkFromStore={() => dispatch(deleteDocumentLinkFromStore())}
        showEmbedScript={false}
        readOnlyMode={completedMode}
      />
      <ModalContentWindow
        showModal={Boolean(showErrorModal)}
        onCloseModal={setShowErrorModal}
        titleText={SOMETHING_WENT_WRONG}
        showDefaultText={false}
      >
        <div>Please fill out all required fields.</div>
      </ModalContentWindow>
      <ModalWindow
        isOpen={showManagerFieldsModal}
        onClose={() => setShowManagerFieldsModal(false)}
        onButtonClick={savePdfTemplateAndShowManagerPage}
        title={SOMETHING_WENT_WRONG}
        buttonTitle="Fill & Sign"
      >
        <div>There are empty required fields.<br />You cannot complete this document until they are filled in.</div>
      </ModalWindow>
    </>
  );
};

export default PdfDocumentExecutedDetails;
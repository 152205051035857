import SendDocumentsFinal from 'components/SendDocuments/SendDocumentsFinal';
import ReviewAndSend from 'components/SignNow/ReviewAndSend';
import SelectSigners from 'components/SignNow/SelectSigners';
import SetFieldsAndEditor from 'components/SignNow/SetFieldsAndEditor';
import Upload from 'components/SignNow/Upload';
import { SEND } from 'constants/general';
import { IComponentMap } from 'types/ComponentMap';
import {
  IReadonlyStepManagerConfig,
  IStepManagerChildComponentProps,
} from 'types/signNow/base';
import { IReadonlySignerFlowConfig } from 'types/signNow/selectSigners';

const enum SignNowSteps {
  Upload = 'Upload',
  SelectSigners = 'Select Signers',
  SetFields = 'Set Fields',
  ReviewAndSend = 'Review & Send',
  SendDocumentsFinal = 'Final',
}

export const SIGN_NOW_COMPONENT_MAP: IComponentMap<IStepManagerChildComponentProps> = {
  [SignNowSteps.Upload]: Upload,
  [SignNowSteps.SelectSigners]: SelectSigners,
  [SignNowSteps.SetFields]: SetFieldsAndEditor,
  [SignNowSteps.ReviewAndSend]: ReviewAndSend,
  [SignNowSteps.SendDocumentsFinal]: SendDocumentsFinal,
} as const;

export const SIGN_NOW_STEP_CONFIG: IReadonlyStepManagerConfig = [
  {
    name: SignNowSteps.Upload,
    step: 0,
  },
  {
    name: SignNowSteps.SelectSigners,
    step: 1,
    isNextButtonVisible: true,
  },
  {
    name: SignNowSteps.SetFields,
    step: 2,
    isNextButtonVisible: true,
  },
  {
    name: SignNowSteps.ReviewAndSend,
    step: 3,
    isNextButtonVisible: true,
    nextButtonTitle: SEND,
  },
  {
    name: SignNowSteps.SendDocumentsFinal,
    step: 4,
    isHidden: true,
  },
] as const;

export const enum SignerFlow {
  JustMe = 'Just Me',
  OrderedSigning = 'Ordered Signing',
  NoOrder = 'No Order',
  BulkSend = 'Bulk Send',
}

export const enum SignerRecord {
  One = 'one',
  Many = 'many',
}

export const SIGNER_FLOW_CONFIG: IReadonlySignerFlowConfig = {
  [SignerFlow.JustMe]: {
    key: SignerFlow.JustMe,
    label: 'I\'m the only signer',
    record: SignerRecord.One,
    isHidden: false,
  },
  [SignerFlow.OrderedSigning]: {
    key: SignerFlow.OrderedSigning,
    label: 'Set signer order',
    record: SignerRecord.Many,
    isHidden: false,
  },
  [SignerFlow.NoOrder]: {
    key: SignerFlow.NoOrder,
    label: 'No order',
    record: SignerRecord.Many,
    isHidden: true,
  },
  [SignerFlow.BulkSend]: {
    key: SignerFlow.BulkSend,
    label: 'Bulk Send',
    record: SignerRecord.Many,
    isHidden: true,
  },
} as const;

export const EMPTY_DOCUMENT_TITLE_ERROR = 'The document name is required!';
export const MAX_TEMPLATES_COUNT_REACHED = 'You have reached your max templates count per month.';
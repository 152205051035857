import { FC } from 'react';

import ShareLinkCard from 'components/Modals/ModalWrapper/ShareLinkCard';
import { AssignmentType } from 'types/Editor';

interface IShareLinkAccordionItem {
  index: number;
  item: AssignmentType;
  getFullPublicLink: (item: AssignmentType) => string;
  copyHandler: (link: string) => void;
  recipientName?: string;
}

const ShareLinkAccordionItem: FC<IShareLinkAccordionItem> = ({
  index,
  item,
  getFullPublicLink,
  copyHandler,
  recipientName,
}) => (
  <div className="accordion-item">
    <h2 className="accordion-header" id={`heading${index}`}>
      <button
        className="accordion-button collapsed text-capitalize"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target={`#collapse${index}`}
        aria-expanded="true"
        aria-controls={`collapse${index}`}
      >
        {recipientName ?? item.label}
      </button>
    </h2>
    <div
      id={`collapse${index}`}
      className="accordion-collapse collapse"
      aria-labelledby={`heading${index}`}
    >
      <div className="accordion-body">
        <ShareLinkCard
          key={item.type}
          item={item}
          fullLink={getFullPublicLink(item)}
          copyHandler={copyHandler}
        />
      </div>
    </div>
  </div>
);

export default ShareLinkAccordionItem;
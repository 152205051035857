import { forwardRef, ForwardRefRenderFunction, memo, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import TabNavigation from 'components/Base/TabNavigation';
import ProgressBar from 'components/ProgressBar';
import { PROVIDE_REFERENCE_NUMBER } from 'constants/pageSettings';
import { PUBLIC_PAGE_STATUS } from 'constants/publicPage';
import usePublicPageNavigation from 'hooks/usePublicPageNavigation';
import useReadOnlyMode from 'hooks/useReadOnlyMode';
import { changeFieldInFocusStatus } from 'store/actions/editorSlate';
import { RootStateType } from 'store/reducers';
import ArrowLeftIcon from 'svg/ArrowIcons/ArrowLeftIcon';
import CloseIcon from 'svg/CloseIcon';
import { IPublicPageDocumentStructure } from 'types/PublicPage';
import { PublicPageNavigation } from 'types/Tabs';
import getPageStatus from 'utils/PublicPage/getPageStatus';

import 'scss/components/BasePageTitle/BasePageTitle.scss';

interface IBasePageTitle {
  isPublicPage?: boolean;
  showPublicTabNavigation?: boolean;
  redirectTo: (status: PUBLIC_PAGE_STATUS, redirect?: boolean) => void;
}

const BasePageTitle: ForwardRefRenderFunction<HTMLDivElement, IBasePageTitle> = ({
  isPublicPage = false,
  showPublicTabNavigation = true,
  redirectTo,
  children,
}, ref) => {
  const dispatch = useDispatch();
  const currentDocument: IPublicPageDocumentStructure | null = useSelector((state: RootStateType) => (
    state.publicPages.currentDocument
  ));
  const provideReferenceNumber = currentDocument?.pageSettings
    && currentDocument.pageSettings[PROVIDE_REFERENCE_NUMBER];

  const { documentUUID }: { documentUUID: string } = useSelector((state: RootStateType) => (
    state.publicPages
  ));

  const autofillPIIMondayItem: Record<string, string> | null = useSelector((state: RootStateType) => (
    state.publicPages.autofillPIIMondayItem
  ));
  const [status, setStatus] = useState<PUBLIC_PAGE_STATUS>(PUBLIC_PAGE_STATUS.STARTED);
  const readOnlyMode = useReadOnlyMode();

  const navigationTabs: PublicPageNavigation = usePublicPageNavigation();

  useEffect(() => {
    if (currentDocument) {
      const { currentSlideIndex, totalIndexSlides, emptyDocument } = currentDocument;
      setStatus(
        getPageStatus(currentSlideIndex, totalIndexSlides, emptyDocument, readOnlyMode),
      );
    }
  }, [currentDocument, readOnlyMode]);

  const buttonClickHandler = () => {
    dispatch(changeFieldInFocusStatus(false));
    redirectTo(status);
  };

  return (
    <div className="base-page-title" ref={ref}>
      <ProgressBar />
      <div
        className={`base-page-title__wrapper ${isPublicPage ? 'base-page-title__wrapper--public' : ''}`}
      >
        <div className="base-page-title__left">
          <button type="button" onClick={buttonClickHandler} className="base-page-title__button-back">
            <ArrowLeftIcon className="base-page-title__icon" />
            <span className="base-page-title__button-text">Back</span>
          </button>
        </div>
        <div className="base-page-title__center">
          <h2 className="title">
            {currentDocument?.name || ''}
          </h2>
        </div>
        <div className="base-page-title__right">
          {
            autofillPIIMondayItem && (
              <h2 className="title me-3 base-page-title__monday-subitem">
                Document for: {autofillPIIMondayItem.value}
              </h2>
            )
          }
          {
            provideReferenceNumber && documentUUID && (
              <h2 className="title me-3 base-page-title__monday-subitem">
                Reference #: {documentUUID}
              </h2>
            )
          }
          <button type="button" onClick={buttonClickHandler} className="base-page-title__button-close">
            <CloseIcon />
          </button>
          {children}
        </div>
      </div>
      {showPublicTabNavigation && navigationTabs.length > 1 && <TabNavigation navigationTabs={navigationTabs} />}
    </div>
  );
};

export default memo(forwardRef(BasePageTitle));
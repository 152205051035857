/* eslint-disable */
import { HORIZONTAL_TOOLBAR_DEFAULT_TOP_OFFSET } from 'constants/toolbarList';
import { MutableRefObject } from 'react';
import { Editor as SlateEditor } from 'slate';
import { isBlockFormatType } from 'types/Editor';

export const isEnterCommandMentionToolbar = (editor: SlateEditor) => {
  const [match]: any = SlateEditor.nodes(editor, {
    match: (node) => (
      SlateEditor.isInline(editor, node) ||
      node.text === '@'
    )
  });

  if (!match) return;
  if (isBlockFormatType(match[0].type)) {
    return false;
  }

  return !!match;
}

export const isEnterCommandVerticalToolbar = (editor: SlateEditor) => {
  const [match]: any = SlateEditor.nodes(editor, {
    match: (node) => (
      SlateEditor.isInline(editor, node) ||
      (node.text === '/' || !!(node.text && node.text.search('\n/') !== -1))
    )
  });

  if (!match) return false;
  if (isBlockFormatType(match[0].type)) {
    return false;
  }

  return !!match;
}

export const getRect = (): DOMRect | null => {
  const domSelection = document.getSelection();
  const domRange = domSelection && domSelection.getRangeAt(0);
  const rect = domRange && domRange.getBoundingClientRect();

  if (!rect) return rect;

  const { bottom, height, left, right, top, width, x, y } = rect;

  if (!bottom && !height && !left && !right && !top && !width && !x && !y) return null;

  return rect;
}

export const calculateLeftPosition = (selectedTextObject: DOMRect, toolbarWidth: number, documentWidth=0): number => {
  let positionLeft = selectedTextObject.left;
  if (documentWidth === 0) {
    documentWidth = document.body.clientWidth;
  }
  if (selectedTextObject.left > toolbarWidth/2) {
    positionLeft = selectedTextObject.left - (toolbarWidth/2) + (selectedTextObject.width/2);
  }
  if (positionLeft < 0 || positionLeft > documentWidth - toolbarWidth) {
    positionLeft = (documentWidth - toolbarWidth)/2;
    if (documentWidth >= toolbarWidth*2) {
      positionLeft = selectedTextObject.right - toolbarWidth;
    }
  }
  return positionLeft;
}

export const calculateTopPosition = (selectedTextObject: DOMRect, toolbarHeight: number, documentHeight=0): number => {
  let positionTop = selectedTextObject.top + 40;
  if (documentHeight === 0) {
    documentHeight = document.body.clientHeight;
  }
  if (positionTop > documentHeight - toolbarHeight - 100) {
    positionTop = selectedTextObject.top - toolbarHeight;
  }
  if (positionTop < 0) {
    positionTop = 0;
  }
  return positionTop;
}

export const calculateMentionToolbarTop = (selectedTextObject: DOMRect, toolbarHeight: number, documentHeight=0): number => {
  let positionTop = toolbarHeight/5 + selectedTextObject.top;
  if (documentHeight === 0) {
    documentHeight = document.body.clientHeight;
  }
  if (positionTop > documentHeight - toolbarHeight) {
    positionTop = positionTop - toolbarHeight;
  }
  if (positionTop < 0) {
    positionTop = 0;
  }
  return positionTop;
}

export const openToolbar = (
  toolbarRef: any,
  rect: { top: number, left: number },
) => {
  toolbarRef.current.style.top = `${rect.top >= 0 ? rect.top : HORIZONTAL_TOOLBAR_DEFAULT_TOP_OFFSET}px`;
  toolbarRef.current.style.left = `${rect.left}px`;
  toolbarRef.current.style.visibility = 'visible';
}

export const closeToolbar = (toolbarRef: any) => {
  toolbarRef.current.removeAttribute('style');
  toolbarRef.current.style.visibility = 'hidden';
}
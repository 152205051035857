import { MANAGER_ASSIGNMENT, RECIPIENT_ASSIGNMENT } from 'constants/editors';
import { AssignmentPositions, AssignmentsMainParts, AssignmentsObjectType, AssignmentType } from 'types/Editor';
import { SignersInfoType, SignerType } from 'types/SendDocuments';
import { isManagerAssignment } from 'utils/assignmentsHelpers';

export const getCustomAssignmentsFromSigners = (signers: SignersInfoType[]): AssignmentsObjectType => (
  signers.reduce((assignment, signer, index: number): AssignmentsObjectType => {
    const assignmentsType: AssignmentsMainParts = signer.type === MANAGER_ASSIGNMENT
      ? AssignmentsMainParts.MANAGERS
      : AssignmentsMainParts.RECIPIENTS;

    const assignmentItem: AssignmentType = {
      type: !assignment[assignmentsType] || assignment[assignmentsType].length === 0
        ? signer.type
        : `${signer.type}${assignment[assignmentsType].length + 1}`,
      firstName: signer.firstName,
      lastName: signer.lastName,
      label: `${signer.firstName}${signer.lastName ? ` ${signer.lastName}` : ''}`,
      fieldsKeys: [],
      email: signer.email,
      position: index,
    };

    return ({
      ...assignment,
      [assignmentsType]: assignment[assignmentsType]
        ? [...assignment[assignmentsType], assignmentItem]
        : [assignmentItem],
    });
  }, {
    [AssignmentsMainParts.MANAGERS]: [],
    [AssignmentsMainParts.RECIPIENTS]: [],
  } as AssignmentsObjectType));

export const getDefaultSignerFromAssignments = (assignments: AssignmentsObjectType): AssignmentType => {
  const assignmentsArray: AssignmentType[] = Object
    .values(assignments)
    .flat()
    .sort((a: AssignmentType, b: AssignmentType) => (a.position ?? 0) - (b.position ?? 0));

  const firstRecipient: AssignmentType | undefined = assignmentsArray
    .find((signer: AssignmentType) => signer.type.startsWith(RECIPIENT_ASSIGNMENT));

  if (firstRecipient) return firstRecipient;
  return assignmentsArray[0];
};

export const getDefaultPositionByAssignment = (assignment: string): number => {
  const result = assignment.match(/\d+/);
  if (result) return parseInt(result[0], 10);
  if (assignment === SignerType.MANAGER) return 0;
  return 1;
};

export const getSignersPositionsFromAssignments = (assignments: AssignmentsObjectType): AssignmentPositions => (
  Object
    .values(assignments)
    .flat()
    .reduce((acc, signer: AssignmentType): AssignmentPositions => {
      const signerPosition = signer.position === undefined
        ? getDefaultPositionByAssignment(signer.type)
        : signer.position;
      return {
        ...acc,
        [signer.type]: signerPosition,
      };
    }, {})
);

export const getManagerPositionFromSignersInfo = (
  signersInfo: SignersInfoType[],
  managerEmail: string,
): number | null => {
  const manager: SignersInfoType | undefined = signersInfo.find((signer: SignersInfoType) => (
    signer.email === managerEmail
  ));
  if (!manager || manager.signed) return null;
  return manager.position ?? null;
};

export const getUnitedOrderedAssignments = (assignmentsObject: AssignmentsObjectType) => {
  const unitedSortedAssignmentsArray = assignmentsObject[AssignmentsMainParts.RECIPIENTS]
    .concat(assignmentsObject[AssignmentsMainParts.MANAGERS])
    .sort((a, b) => (a.position ?? 0) - (b.position ?? 1));
  return unitedSortedAssignmentsArray;
};

export const removeAssignmentAndChangePositions = (
  assignmentType: string,
  assignmentsObject: AssignmentsObjectType,
) => {
  const unitedSortedAssignmentsArray = getUnitedOrderedAssignments(assignmentsObject);
  const filteredArray = unitedSortedAssignmentsArray.filter((assignment) => assignment.type !== assignmentType);
  const reorderedArray = filteredArray.map((assignment, index) => ({
    ...assignment,
    position: index,
  }));
  const resultObject: AssignmentsObjectType = {
    [AssignmentsMainParts.MANAGERS]: [],
    [AssignmentsMainParts.RECIPIENTS]: [],
  };
  reorderedArray.forEach((assignment) => {
    if (isManagerAssignment(assignment.type)) {
      resultObject[AssignmentsMainParts.MANAGERS].push(assignment);
    } else {
      resultObject[AssignmentsMainParts.RECIPIENTS].push(assignment);
    }
  });

  return resultObject;
};

export const getAssignmentsWithCorrectTypes = (
  assignments: AssignmentType[],
  currentAssignmentObject: AssignmentType,
  currentAssignmentType: string,
) => {
  const resultAssignment = { ...currentAssignmentObject };
  const typesArray = assignments.map((assignment) => assignment.type);
  if (assignments.length) {
    for (let i = 2; i < assignments.length + 2; i++) {
      if (!typesArray.includes(`${currentAssignmentType}${i}`)) {
        resultAssignment.type = `${currentAssignmentType}${i}`;
      }
    }
  } else {
    resultAssignment.type = currentAssignmentType;
  }

  return [
    ...assignments,
    resultAssignment,
  ];
};
import { useEffect, useState } from 'react';

import { Timeout } from 'react-number-format/types/types';
import { useDispatch, useSelector } from 'react-redux';

import { getAutoUpdateResourcesEvent } from 'services/api/autoUpdateResources';
import { updateResource } from 'store/actions/serverSideEvents';
import { RootStateType } from 'store/reducers';
import { getUniqueTabId } from 'utils/localStorage';

const useAutoUpdateResources = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state: RootStateType) => state.profile.profileInfo);
  const isUserAuthenticated = useSelector((state: RootStateType) => state.user.isAuthenticated);
  const [localIntervalId, setLocalIntervalId] = useState<Timeout | null>(null);

  useEffect(() => {
    if (isUserAuthenticated && userInfo?.company_id && userInfo?.id) {
      if (!localIntervalId) {
        const updateResourcesIntervalId = setInterval(async () => {
          const response = await getAutoUpdateResourcesEvent(userInfo.company_id, userInfo.id, getUniqueTabId());
          if (response) {
            const parsedData = JSON.parse((response as any).message);
            dispatch(updateResource({
              resourceType: parsedData.type,
              resourceId: parsedData.id,
            }));
          }
        }, 10000);
        setLocalIntervalId(updateResourcesIntervalId);
      }
    }

    return () => {
      if (localIntervalId) {
        clearInterval(localIntervalId);
        setLocalIntervalId(null);
      }
    };
  }, [
    dispatch,
    isUserAuthenticated,
    userInfo?.company_id,
    userInfo?.id,
    localIntervalId,
  ]);
};

export default useAutoUpdateResources;